import React from "react";
import Container from "../../components/container/container";
import Col from "../../components/grid/col";
import Grid from "../../components/grid/grid";
import Row from "../../components/grid/row";
import "./footer.scss";

const Footer: React.FC = () => {
  return (
    <footer>
      <Container>
        <Grid>
          <Row>
            <Col size={6} className="company-col">
              <p>EkoHigiena Aparatura &copy; 2021</p>
            </Col>
            <Col size={6} className="creator-col">
              <p>
                Realizacja: <a href="https://codeebo.pl">Codeebo</a>
              </p>
            </Col>
          </Row>
        </Grid>
      </Container>
    </footer>
  )
}

export default Footer;
import React from "react";
import HeroSection from "../../../sections/hero-section/hero-section";
import HeroImg from "./../../../assets/bplusr.jpg";


const HeroSectionWrapper: React.FC = () => {
  return (
    <HeroSection img={HeroImg} alt="Example img">
      <h1>Blog</h1>
      <p>
        Publikujemy aktualnosci dotyczące norm, metod pomiarów oraz nowości w naszej firmie.
      </p>
    </HeroSection>
  );
};

export default HeroSectionWrapper;

import React from "react";
import TextBlock from "../../../../components/writing-tools/text-block/text-block";
import { ProductContentDto, ProductDto } from "../../../../dto/productDto";
import { ProductTileDto } from "../../../../dto/productTileDto";
import { RoutePath } from "../../../../route-paths";
import LighthouseLogo from "./../../../../assets/products/LWS LOGO - BW-transparent.png";
import Solair3100 from "./../../../../assets/products/przenosne_liczniki_czastek/b_Solair-3100.jpeg";
import Solair3350 from "./../../../../assets/products/przenosne_liczniki_czastek/b_Solair-3350_2.jpeg";
import Solair5100 from "./../../../../assets/products/przenosne_liczniki_czastek/b_Solair-5100.jpeg";
import Solair1100 from "./../../../../assets/products/przenosne_liczniki_czastek/b_Solair-1100.jpeg";
import BoulderCounter from "./../../../../assets/products/przenosne_liczniki_czastek/b_Solair-Boulder-Counter.jpeg";
import ApexZ3 from "./../../../../assets/products/przenosne_liczniki_czastek/b_ApexZ3-Image19.jpeg";
import ApexZ3Top from "./../../../../assets/products/przenosne_liczniki_czastek/b_Apex_Z_top_measurement.jpeg";
import ApexZ3Front from "./../../../../assets/products/przenosne_liczniki_czastek/b_ApexZ_Front_measurement.jpeg";
import ApexZ50 from "./../../../../assets/products/przenosne_liczniki_czastek/b_ApexZ50-Image19.jpeg";
import ApexZ50Top from "./../../../../assets/products/przenosne_liczniki_czastek/b_ApexZ50-top.png";
import ApexZ50Front from "./../../../../assets/products/przenosne_liczniki_czastek/b_ApexZ50-front.png";
import ApexP3 from "./../../../../assets/products/przenosne_liczniki_czastek/b_Apex-P3.jpeg";
import ApexP5 from "./../../../../assets/products/przenosne_liczniki_czastek/b_Apex-P5.jpeg";
import TitleBlock from "../../../../components/title-block/title-block";
import SolairBrochure from "./../../../../assets/products/przenosne_liczniki_czastek/Solair_Brochure_1100LD_3100_3350_5100_Boulder_Counter.pdf";
import Solair1100Datasheet from "./../../../../assets/products/przenosne_liczniki_czastek/Solair_Datasheet_1100LD_200527.pdf";
import Solair1100Manual from "./../../../../assets/products/przenosne_liczniki_czastek/248083395-1_R9_(OpMan_S1100LD_Gen_E)_A4.pdf";
import Solair3100Datasheet from "./../../../../assets/products/przenosne_liczniki_czastek/Solair_Datasheet_3100_3350_5100_Boulder_Counter_181113.pdf";
import Solair31005100Manual from "./../../../../assets/products/przenosne_liczniki_czastek/248083389-1_R8_(OpMan_S3100_S5100_BC_Gen_E)_Letter.pdf";
import Solair33505350Manual from "./../../../../assets/products/przenosne_liczniki_czastek/248083387-1_R10_(OpMan_S3350_S5350_Gen_E)_A4.pdf";
import ApexZBrochure from "./../../../../assets/products/przenosne_liczniki_czastek/ApexZ_Trifold_Brochure_200729_LTR.pdf";
import ApexZDatasheet from "./../../../../assets/products/przenosne_liczniki_czastek/ApexZ_Datasheet_200513_LTR.pdf";
import ApexZManual from "./../../../../assets/products/przenosne_liczniki_czastek/248083466-1_R10_OpMan_ApexZ_Letter_2.pdf";
import ApexPBrochure from "./../../../../assets/products/przenosne_liczniki_czastek/ApexPortable_Brochure_P3_P5.pdf";
import ApexPDatasheet from "./../../../../assets/products/przenosne_liczniki_czastek/ApexPortable_Datasheet_P3_P5.pdf";
import ApexPManual from "./../../../../assets/products/przenosne_liczniki_czastek/248083440-1_R7_OpMan_ApexP_Letter_2.pdf";
import PdfIcon from "../../../../components/pdf-icon/pdf-icon";
import LinkWithIcon from "../../../../components/link-with-icon/link-with-icon";
import LinkWithIconWrapper from "../../../../components/link-with-icon/link-with-icon-wrapper";

export const ProductsPrzenosneLicznikiCzastek = [
  {
    tile: {
      title: "Solair 5100",
      subtitle: "Producent: Lighthouse",
      price: "",
      img: Solair5100,
      to: RoutePath.PRODUCT,
    } as ProductTileDto,
    url: "solair-5100",
    content: {
      images: [LighthouseLogo],
      body: (
        <TextBlock>
          <TitleBlock title="Licznik cząstek Solair 5100" smallPaddingTop />
          <hr />
          <p>
            SOLAIR 5100 zawiera czujnik w technologii diody laserowej o ekstremalnej żywotności, aby zapewnić najdłuższą w branży żywotność diody laserowej wynoszącą ponad 20 lat MTTF (w oparciu o ciągłą pracę 24/7). Używając nowej, lżejszej baterii o przedłużonej żywotności i zewnętrznego zasilacza AC, SOLAIR 5100 jest jeszcze bardziej przenośny. SOLAIR 5100 jest wyposażony w jeden z najlepszych w branży interfejsów użytkownika (UI) do konfiguracji i obsługi instrumentu za pomocą intuicyjnych ekranów i menu opartych na ikonach, które są proste i łatwe do nauczenia i obsługi.
            <br />
            <br />
            SOLAIR 5100 może przechowywać do 3000 rekordów danych o liczbie cząstek z maksymalnie 8 kanałów cząstek, a konfigurowalna baza danych receptur może przechowywać do 50 receptur do pobierania próbek i raportów. Wszystkie dane można szybko i niezawodnie przenieść do komputera, Systemu Monitorowania Obiektów lub Systemu Automatyki Budynku. Bezpieczny transfer danych (zgodnie z 21 CFR Part 11) na dysk flash USB można wykonać za pomocą funkcji bezpiecznego przesyłania danych Lighthouse i oprogramowania LMS Express lub Express RT do ich odczytu. Elastyczna komunikacja z tymi przyrządami jest możliwa dzięki zintegrowanym interfejsom Ethernet, RS-485, USB i pamięci flash USB. SOLAIR 5100 został zaprojektowany, wyprodukowany i skalibrowany zgodnie z normą ISO 21501-4, aby zapewnić dokładność liczenia cząstek.
            <br />
            <br />
          </p>
        </TextBlock>
      ),
      tabs: [
        {
          name: "Specifikacja",
          body: (
            <TextBlock>
              <table>
                <tbody>
                  <tr>
                    <td>Zakres rozmiarów cząstek</td>
                    <td>0.5 - 25.0 µm</td>
                  </tr>
                  <tr>
                    <td>Kanały pomiarowe</td>
                    <td>
                      0.5, 0.7, 1.0, 3.0, 5.0, 10.0 µm
                      <br />
                      lub
                      <br />
                      0.5, 1.0, 3.0, 5.0, 10.0, 25.0 µm
                    </td>
                  </tr>
                  <tr>
                    <td>Przepływ</td>
                    <td>1.0 CFM (28.3LPM)</td>
                  </tr>
                  <tr>
                    <td>Limit stężenia</td>
                    <td>1,000,000 cząstek/ft3, (35,300,000 cząstek/m3) przy 10% utracie zgodności dla pomieszczeń czystych klas ISO 1-8</td>
                  </tr>
                  <tr>
                    <td>Waga</td>
                    <td>
                      Z baterią 13.95 lbs (6.33 kg)
                      <br />
                      Bez baterii 12.35 lbs (5.6 kg)
                    </td>
                  </tr>
                  <tr>
                    <td>Komunikacja</td>
                    <td>Port szeregowy, Ethernet, RS-485, pamięć flash USB</td>
                  </tr>
                  <tr>
                    <td>Typy protokołu Modbus</td>
                    <td>TCP, ASCII</td>
                  </tr>
                  <tr>
                    <td>Pojemność pamięci</td>
                    <td>3,000 rekordów</td>
                  </tr>
                  <tr>
                    <td>Obudowa</td>
                    <td>Stal nierdzewna</td>
                  </tr>
                  <tr>
                    <td>Zasilanie</td>
                    <td>100-250V AC, 47-63 HZ</td>
                  </tr>
                  <tr>
                    <td>Źródło światła</td>
                    <td>Dioda laserowa</td>
                  </tr>
                  <tr>
                    <td>Kalibracja</td>
                    <td>Zgodnie z ISO 21501-4</td>
                  </tr>
                  <tr>
                    <td>Zerowanie</td>
                    <td>&lt; 1 cząstki / 5 minut zgodnie z ISO 21501-4</td>
                  </tr>
                  <tr>
                    <td>Efektywność zliczania</td>
                    <td>50% dla kanału 0.3µm, 100% dla cząstek &gt; 0.45 µm</td>
                  </tr>
                  <tr>
                    <td>Wymiary</td>
                    <td>9.5” x 8.0” x 10.2” (24.13 x 20.32 x 25.9 cm)</td>
                  </tr>
                </tbody>
              </table>
            </TextBlock>
          ),
        },
        {
          name: "Do pobrania",
          body: (
            <TextBlock>
              <LinkWithIconWrapper>
                <LinkWithIcon icon={<PdfIcon />} to={SolairBrochure}>
                  Ulotka
                </LinkWithIcon>
                <LinkWithIcon icon={<PdfIcon />} to={Solair3100Datasheet}>
                  Dane techniczne
                </LinkWithIcon>
                <LinkWithIcon icon={<PdfIcon />} to={Solair31005100Manual}>
                  Instrukcja obsługi
                </LinkWithIcon>
              </LinkWithIconWrapper>
            </TextBlock>
          ),
        },
      ],
    } as ProductContentDto,
  },
  {
    tile: {
      title: "Solair 3100",
      subtitle: "Producent: Lighthouse",
      price: "",
      img: Solair3100,
      to: RoutePath.PRODUCT,
    } as ProductTileDto,
    url: "solair-3100",
    content: {
      images: [LighthouseLogo],
      body: (
        <TextBlock>
          <TitleBlock title="Licznik cząstek Solair 3100" smallPaddingTop />
          <hr />
          <h4>Wszechstronność</h4>
          <p>
            SOLAIR 3100 jest obecnie najbardziej wszechstronnym licznikiem cząstek na rynku. <br />
            <br />
            Niezależnie od tego, czy certyfikujesz swój cleanroom zgodnie z normami ISO, przeprowadzasz rutynowe testy, zliczasz cząsteczki w przewodach sprężonego gazu, skanujesz filtry HEPA pod kątem wycieków, testujesz filtry HEPA pod kątem wyzwań lub mierzysz parametry środowiskowe, takie jak TRH, DP lub AV, SOLAIR Przenośny licznik cząstek 3100 jest odpowiednim instrumentem do wykonania zadania.
            <br />
            <br />
          </p>

          <h4>Kwalifikacje pomieszczeń czystych</h4>
          <li>
            Niezależnie od tego, czy kwalifikujesz pomieszczenie zgodnie z aktualnymi normami ISO 14644, EU GMP Annex 1, czy federalnymi standardami 209E, SOLAIR 3100 zapewnia Ci ochronę dzięki kreatorowi raportów certyfikacyjnych.
            <br />
            <br />
          </li>

          <h4>Rutynowe testy</h4>
          <li>
            Rutynowe testy to pestka dzięki intuicyjnemu interfejsowi, powiększeniu ekranu i przykładowym receptom na pokładzie.
            <br />
            <br />
          </li>

          <h4>Rozwiązywanie problemów</h4>
          <li>
            Dzięki trybom sygnału dźwiękowego i próbkowania stężenia SOLAIR 3100 uprości identyfikację źródeł cząstek w czystym pomieszczeniu.
            <br />
            <br />
          </li>

          <h4>Testowanie sprężonego powietrza</h4>
          <li>
            SOLAIR 3100 może być używany do badania liczby cząstek w sprężonym powietrzu za pomocą dyfuzora wysokiego ciśnienia lub kontrolera wysokiego ciśnienia. Cząsteczki mogą być testowane do 0,3 mikrona.
            <br />
            <br />
          </li>

          <h4>Testowanie filtrów HEPA</h4>
          <li>Skanowanie filtrów HEPA jest krytycznym krokiem zapewniającym integralność filtra HEPA i ogólną czystość pomieszczenia czystego. Przystawka ScanAir firmy Lighthouse ułatwia skanowanie filtrów.</li>

          <h4>Czujniki środowiskowe</h4>
          <li>SOLAIR 3100 może rejestrować dane z maksymalnie 4 czujników środowiskowych, które są podłączane i zasilane przez SOLAIR 3100. Dane są rejestrowane w buforze danych o liczbie 3000 rekordów i można je pobrać wraz z danymi o liczbie cząstek do komputera lub za pomocą nośnika pamięci USB.</li>

          <h4>Łatwość użytkowania</h4>
          <p>
            Spędzaj mniej czasu na konfigurowaniu licznika cząstek, a więcej na liczeniu cząstek. SOLAIR 3100 jest wyposażony w jeden z najlepszych i najłatwiejszych w obsłudze interfejsu z ekranem dotykowym (UI) w branży. Dzięki menu opartemu na ikonach, zwięzłemu układowi i zwiększonej responsywności konfiguracja próbki jest dziecinnie prosta.
            <br />
            <br />
            Do certyfikacji pomieszczeń czystych SOLAIR 3100 posiada zintegrowany kreator raportów, który sprawia, że ​​certyfikacja pomieszczeń czystych jest dziecinnie prosta. Po wprowadzeniu podstawowych parametrów próbki, kreator poprosi użytkownika końcowego o przejście do następnej lokalizacji próbki, co pomaga zapewnić, że żadna lokalizacja próbki nie zostanie pominięta.
            <br />
            <br />
            Konfigurowalna baza danych receptur może przechowywać do 50 receptur do pobierania próbek i raportów. Receptury te redukują błędy konfiguracji operatora, a także czas konfiguracji.
            <br />
            <br />
            Lżejsze jest lepsze, a licznik cząstek SOLAIR 3100 jest jednym z najlżejszych liczników cząstek na rynku, waży zaledwie 5,5kg. bez baterii i 6kg z baterią.
            <br />
            <br />
            SOLAIR 3100 może być zasilany z wbudowanej baterii lub podłączony bezpośrednio do gniazdka elektrycznego w celu dłuższego użytkowania. Dzięki najkrótszemu czasowi ładowania baterii ze wszystkich przenośnych urządzeń dostępnych na rynku, SOLAIR 3100 oferuje niezrównany czas pracy bez przestojów.
            <br />
            <br />
            Dane z SOLAIR 3100 można pobrać bezpośrednio do samodzielnego pakietu oprogramowania lub systemu monitorowania w czasie rzeczywistym firmy Lighthouse.
            <br />
            <br />
            Elastyczna komunikacja z tymi przyrządami jest możliwa dzięki zintegrowanym interfejsom Ethernet, RS-485, USB i pamięci flash USB.
            <br />
            <br />
          </p>

          <h4>Niezawodność</h4>
          <p>
            SOLAIR 3100 zawiera nasz czujnik w technologii diody laserowej o ekstremalnej żywotności, aby zapewnić najdłuższą w branży żywotność diody laserowej wynoszącą ponad 20 lat MTTF (w oparciu o ciągłą pracę 24/7).
            <br />
            <br />
            System próżniowy w SOLAIR 3100 został zaprojektowany ze sprzężeniem zwrotnym w pętli zamkniętej, aby zapewnić dokładną objętość próbki. Pompa próżniowa została zaprojektowana i zbudowana przez Lighthouse, aby zapewnić długoterminową niezawodność.
            <br />
            <br />
            SOLAIR 3100 są zaprojektowane, wyprodukowane i skalibrowane zgodnie z normą ISO 21501-4, aby zapewnić dokładność zliczania cząstek.
            <br />
            <br />
            SOLAIR 3100 może przechowywać do 3000 rekordów danych o liczbie cząstek z maksymalnie 8 kanałów cząstek, a konfigurowalna baza danych receptur może przechowywać do 50 receptur do pobierania próbek i raportów. Wszystkie dane można szybko i niezawodnie przenieść do komputera. Bezpieczny transfer danych (zgodnie z 21 CFR Part 11) na dysk flash USB można zrealizować za pomocą funkcji bezpiecznego przesyłania danych Lighthouse i oprogramowania LMS Express.
            <br />
            <br />
          </p>
        </TextBlock>
      ),
      tabs: [
        {
          name: "Specifikacja",
          body: (
            <TextBlock>
              <table>
                <tbody>
                  <tr>
                    <td>Zakres rozmiarów cząstek</td>
                    <td>0.3 - 25.0 µm</td>
                  </tr>
                  <tr>
                    <td>Kanały pomiarowe</td>
                    <td>
                      0.3, 0.5, 1.0, 3.0, 5.0, 10.0 µm
                      <br />
                      lub
                      <br />
                      0.3, 0.5, 1.0, 5.0, 10.0, 25.0 µm
                    </td>
                  </tr>
                  <tr>
                    <td>Przepływ</td>
                    <td>1.0 CFM (28.3LPM)</td>
                  </tr>
                  <tr>
                    <td>Limit stężenia</td>
                    <td>1,000,000 cząstek/ft3 (35,300,000 cząstek/m3) przy 10% utracie zgodności dla pomieszczeń czystych klas ISO 1-8</td>
                  </tr>
                  <tr>
                    <td>Waga</td>
                    <td>
                      Z baterią 13.95 lbs (6.33 kg)
                      <br />
                      Bez baterii 12.35 lbs (5.6 kg)
                    </td>
                  </tr>
                  <tr>
                    <td>Komunikacja</td>
                    <td>Port szeregowy, Ethernet, RS-485, pamięć flash USB</td>
                  </tr>
                  <tr>
                    <td>Typy protokołu Modbus</td>
                    <td>TCP, ASCII</td>
                  </tr>
                  <tr>
                    <td>Pojemność pamięci</td>
                    <td>3,000 rekordów</td>
                  </tr>
                  <tr>
                    <td>Obudowa</td>
                    <td>Stal nierdzewna</td>
                  </tr>
                  <tr>
                    <td>Zasilanie</td>
                    <td>100-250V AC, 47-63 HZ</td>
                  </tr>
                  <tr>
                    <td>Źródło światła</td>
                    <td>Dioda laserowa</td>
                  </tr>
                  <tr>
                    <td>Kalibracja</td>
                    <td>Zgodnie z ISO 21501-4</td>
                  </tr>
                  <tr>
                    <td>Zerowanie</td>
                    <td>&lt; 1 cząstki / 5 minut zgodnie z ISO 21501-4</td>
                  </tr>
                  <tr>
                    <td>Efektywność zliczania</td>
                    <td>50% dla kanału 0.3µm, 100% dla cząstek &gt; 0.45 µm</td>
                  </tr>
                  <tr>
                    <td>Wymiary</td>
                    <td>6.38”x 2.93“x 4.63”(16.2x7.4x11.7cm)</td>
                  </tr>
                </tbody>
              </table>
            </TextBlock>
          ),
        },
        {
          name: "Do pobrania",
          body: (
            <TextBlock>
              <LinkWithIconWrapper>
                <LinkWithIcon icon={<PdfIcon />} to={SolairBrochure}>
                  Ulotka
                </LinkWithIcon>
                <LinkWithIcon icon={<PdfIcon />} to={Solair3100Datasheet}>
                  Dane techniczne
                </LinkWithIcon>
                <LinkWithIcon icon={<PdfIcon />} to={Solair31005100Manual}>
                  Instrukcja obsługi
                </LinkWithIcon>
              </LinkWithIconWrapper>
            </TextBlock>
          ),
        },
      ],
    } as ProductContentDto,
  },
  {
    tile: {
      title: "Solair 3350",
      subtitle: "Producent: Lighthouse",
      price: "",
      img: Solair3350,
      to: RoutePath.PRODUCT,
    } as ProductTileDto,
    url: "solair-3350",
    content: {
      images: [LighthouseLogo],
      body: (
        <TextBlock>
          <TitleBlock title="Licznik cząstek Solair 3350" smallPaddingTop />
          <hr />
          <p>
            SOLAIR 3350 zawiera czujnik w technologii diody laserowej o ekstremalnej żywotności, aby zapewnić najdłuższą w branży żywotność diody laserowej wynoszącą ponad 20 lat MTTF (w oparciu o ciągłą pracę 24/7). Używając nowej, lżejszej baterii o przedłużonej żywotności i zewnętrznego zasilacza AC, SOLAIR 3350 jest jeszcze bardziej przenośny. SOLAIR 3350 jest wyposażony w jeden z najlepszych w branży interfejsów użytkownika (UI) do konfiguracji i obsługi instrumentu za pomocą intuicyjnych ekranów i menu opartych na ikonach, które są proste i łatwe do nauczenia i obsługi.
            <br />
            <br />
            SOLAIR 3350 może przechowywać do 3000 rekordów danych o liczbie cząstek z maksymalnie 8 kanałów cząstek, a konfigurowalna baza danych receptur może przechowywać do 50 receptur do pobierania próbek i raportów. Wszystkie dane można szybko i niezawodnie przenieść do komputera, Systemu Monitorowania Obiektów lub Systemu Automatyki Budynku. Bezpieczny transfer danych (zgodnie z 21 CFR Part 11) na dysk flash USB można wykonać za pomocą funkcji bezpiecznego przesyłania danych Lighthouse i oprogramowania LMS Express lub Express RT do ich odczytu. Elastyczna komunikacja z tymi przyrządami jest możliwa dzięki zintegrowanym interfejsom Ethernet, RS-485, USB i pamięci flash USB. SOLAIR 3350 został zaprojektowany, wyprodukowany i skalibrowany zgodnie z normą ISO 21501-4, aby zapewnić dokładność liczenia cząstek.
            <br />
            <br />
          </p>
        </TextBlock>
      ),
      tabs: [
        {
          name: "Specifikacja",
          body: (
            <TextBlock>
              <table>
                <tbody>
                  <tr>
                    <td>Zakres rozmiarów cząstek</td>
                    <td>0.3 - 25.0 µm</td>
                  </tr>
                  <tr>
                    <td>Kanały pomiarowe</td>
                    <td>
                      0.3, 0.5, 1.0, 3.0, 5.0, 10.0 µm
                      <br />
                      lub
                      <br />
                      0.3, 0.5, 1.0, 5.0, 10.0, 25.0 µm
                    </td>
                  </tr>
                  <tr>
                    <td>Przepływ</td>
                    <td>100LPM</td>
                  </tr>
                  <tr>
                    <td>Limit stężenia</td>
                    <td>400,000 cząstek/ft3, (14,120,000 cząstek/m3) przy 10% utracie zgodności dla pomieszczeń czystych klas ISO 1-8</td>
                  </tr>
                  <tr>
                    <td>Waga</td>
                    <td>
                      Z baterią 25.83 lbs (11.73 kg)
                      <br />
                      Bez baterii 22.75 lbs (10.32 kg)
                    </td>
                  </tr>
                  <tr>
                    <td>Komunikacja</td>
                    <td>Port szeregowy, Ethernet, RS-485, pamięć flash USB</td>
                  </tr>
                  <tr>
                    <td>Typy protokołu Modbus</td>
                    <td>TCP, ASCII</td>
                  </tr>
                  <tr>
                    <td>Pojemność pamięci</td>
                    <td>3,000 rekordów</td>
                  </tr>
                  <tr>
                    <td>Obudowa</td>
                    <td>Stal nierdzewna</td>
                  </tr>
                  <tr>
                    <td>Zasilanie</td>
                    <td>100-250V AC, 47-63 HZ</td>
                  </tr>
                  <tr>
                    <td>Źródło światła</td>
                    <td>Dioda laserowa</td>
                  </tr>
                  <tr>
                    <td>Kalibracja</td>
                    <td>Zgodnie z ISO 21501-4</td>
                  </tr>
                  <tr>
                    <td>Zerowanie</td>
                    <td>&lt; 1 cząstki / 5 minut zgodnie z ISO 21501-4</td>
                  </tr>
                  <tr>
                    <td>Efektywność zliczania</td>
                    <td>50% dla kanału 0.3µm, 100% dla cząstek &gt; 0.45 µm</td>
                  </tr>
                  <tr>
                    <td>Wymiary</td>
                    <td>14.65” x 8.0” x 10.4”(37.0 x 20.0 x 26.0 cm)</td>
                  </tr>
                </tbody>
              </table>
            </TextBlock>
          ),
        },
        {
          name: "Do pobrania",
          body: (
            <TextBlock>
              <LinkWithIconWrapper>
                <LinkWithIcon icon={<PdfIcon />} to={SolairBrochure}>
                  Ulotka
                </LinkWithIcon>
                <LinkWithIcon icon={<PdfIcon />} to={Solair3100Datasheet}>
                  Dane techniczne
                </LinkWithIcon>
                <LinkWithIcon icon={<PdfIcon />} to={Solair33505350Manual}>
                  Instrukcja obsługi
                </LinkWithIcon>
              </LinkWithIconWrapper>
            </TextBlock>
          ),
        },
      ],
    } as ProductContentDto,
  },
  {
    tile: {
      title: "Solair 1100LD",
      subtitle: "Producent: Lighthouse",
      price: "",
      img: Solair1100,
      to: RoutePath.PRODUCT,
    } as ProductTileDto,
    url: "solair-1100LD",
    content: {
      images: [LighthouseLogo],
      body: (
        <TextBlock>
          <TitleBlock title="Licznik cząstek Solair 1100LD" smallPaddingTop />
          <hr />
          <p>
            SOLAIR 1100LD (Laser Diode) jest pierwszym w branży przenośnym licznikiem cząstek o czułości 0,1 mikrona i prędkości przepływu 1,0 CFM (28,3 LPM) przy użyciu technologii diody laserowej. Konwencjonalne liczniki cząstek HeNe (helium-neon) wymagają częstego czyszczenia, okresowej wymiany lampy laserowej i charakteryzują się niskimi limitami stężeń. SOLAIR 1100LD jest przeznaczony do pracy w czystych pomieszczeniach ISO klasy 1 do klasy 7 bez obaw o zanieczyszczenie środowiska lub przekroczenie limitów stężenia.
            <br />
            <br />
          </p>
          <h4>Wszechstronność</h4>
          <p>
            Linia liczników cząstek SOLAIR jest obecnie najbardziej wszechstronną na rynku.
            <br />
            <br />
            Niezależnie od tego, czy certyfikujesz swój cleanroom zgodnie z normami ISO, przeprowadzasz rutynowe testy, zliczasz cząsteczki w przewodach sprężonego gazu, skanujesz filtry HEPA pod kątem wycieków, testujesz filtry HEPA pod kątem wyzwań lub mierzysz parametry środowiskowe, takie jak TRH, DP lub AV, Przenośny licznik cząstek SOLAIR jest właściwym instrumentem do wykonania zadania.
            <br />
            <br />
          </p>

          <h4>Kwalifikacje pomieszczeń czystych</h4>
          <p>
            Niezależnie od tego, czy przeprowadzaxsz kwalifikację zgodnie z ISO 14644, EU GMP Annex 1, czy Federal Standards 209E, SOLAIR zapewnia Ci kreator raportów certyfikacyjnych.
            <br />
            <br />
          </p>

          <h4>Testy rutynowe</h4>
          <p>
            Rutynowe testowanie to pestka dzięki intuicyjnemu interfejsowi, powiększonemu ekranowi i wbudowanym przykładowym recepturom.
            <br />
            <br />
          </p>

          <h4>Rozwiązywanie problemów</h4>
          <p>
            Dzięki trybom sygnału dźwiękowego i próbkowania stężenia, SOLAIR uprości identyfikację źródeł cząstek w pomieszczeniu czystym.
            <br />
            <br />
          </p>

          <h4>Testowanie sprężonego powietrza</h4>
          <p>
            SOLAIR może testować liczbę cząstek w sprężonym powietrzu za pomocą wysokociśnieniowego dyfuzora lub wysokociśnieniowego kontrolera. Cząstki mogą być testowane do 0,1 mikrona.
            <br />
            <br />
          </p>

          <h4>Skanowanie filtrów HEPA</h4>
          <p>
            Skanowanie filtrów HEPA jest krytycznym krokiem zapewniającym integralność filtra HEPA i ogólną czystość pomieszczenia czystego. Przystawka ScanAir firmy Lighthouse sprawia, że skanowanie filtrów jest łatwiejsze.
            <br />
            <br />
          </p>

          <h4>Czujniki środowiskowe</h4>
          <p>
            SOLAIR może rejestrować dane z maksymalnie 4 czujników środowiskowych, które są podłączane i zasilane przez SOLAIR. Dane są rejestrowane w buforze danych o liczbie 3000 rekordów i można je pobrać wraz z danymi o liczbie cząstek do komputera lub za pomocą nośnika pamięci USB.
            <br />
            <br />
          </p>

          <h4>Lepsza technologia prowadzi do większej elastyczności</h4>
          <p>
            SOLAIR 1100 wykorzystuje diodę laserową jako źródło lasera. Ta technologia laserowa zapewnia SOLAIR 1100 10-krotny wzrost limitów stężenia w stosunku do konkurencji. Wyższy limit stężenia pozwala na używanie przyrządu w środowiskach ISO klasy 1 do klasy 7.
            <br />
            <br />
          </p>

          <h4>Oszczędność czasu</h4>
          <p>
            Spędzaj mniej czasu na konfigurowaniu licznika cząstek, a więcej na liczeniu cząstek. SOLAIR jest wyposażony w jeden z najlepszych i najłatwiejszych w obsłudze interfejsów z ekranem dotykowym (UI). Przykładowa konfiguracja to pestka dzięki menu opartemu na ikonach, prostemu układowi i zwiększonej responsywności.
            <br />
            <br />
            Intuicyjny interfejs skraca czas pracy bez przestojów nowych użytkowników i upraszcza pisanie SOP do obsługi.
            <br />
            <br />
            SOLAIR posiada zintegrowany kreator raportów do certyfikacji pomieszczeń czystych, który sprawia, że certyfikacja pomieszczeń czystych jest dziecinnie prosta. Po wprowadzeniu podstawowych parametrów próbki kreator poprosi użytkownika końcowego o przejście do następującej lokalizacji próbki, co pomaga zapewnić, że żadna lokalizacja próbki nie zostanie pominięta.
            <br />
            <br />
            Konfigurowalna baza danych receptur może przechowywać do 50 receptur do pobierania próbek i raportów. Receptury te redukują błędy konfiguracji operatora, a także czas konfiguracji.
            <br />
            <br />
          </p>

          <h4>Łatwość użytkowania</h4>
          <p>
            Lżejszy jest lepszy, a SOLAIR 1100 jest najlżejszym na rynku licznikiem cząstek o wielkości 0,1 mikrona, ważącym zaledwie 23lbs bez baterii i 26 z baterią.
            <br />
            <br />
            SOLAIR może być zasilany z wbudowanej baterii lub podłączony bezpośrednio do gniazdka elektrycznego w celu dłuższego użytkowania. Dzięki najkrótszemu czasowi ładowania baterii ze wszystkich przenośnych urządzeń na rynku, SOLAIR oferuje niezrównany czas pracy bez przestojów.
            <br />
            <br />
            Z sygnałem dźwiękowym i trybami próbkowania stężenia,
            <br />
            <br />
            SOLAIR uprości identyfikację źródeł cząstek w pomieszczeniu czystym.
            <br />
            <br />
            Dane z SOLAIR można pobrać bezpośrednio do samodzielnego pakietu oprogramowania lub systemu monitorowania w czasie rzeczywistym firmy Lighthouse.
            <br />
            <br />
            Elastyczna komunikacja z tymi przyrządami jest możliwa dzięki zintegrowanym interfejsom Ethernet, RS-485, USB i pamięci flash USB.
            <br />
            <br />
            <h4>Niezawodność</h4>
            <p>
              Linia przenośnych liczników cząstek SOLAIR wykorzystuje technologię diod laserowych.
              <br />
              <br />
              System próżniowy w SOLAIR został zaprojektowany z sprzężeniem zwrotnym w pętli zamkniętej, aby zapewnić dokładne objętości próbek. Pompa próżniowa została zaprojektowana i zbudowana przez Lighthouse, aby zapewnić długotrwałą niezawodność.
              <br />
              <br />
              SOLAIR 1100LD został zaprojektowany, wyprodukowany i skalibrowany zgodnie z ISO 21501-4, aby zapewnić dokładność zliczania cząstek.
              <br />
              <br />
              SOLAIR może przechowywać do 3000 rekordów danych o liczbie cząstek z maksymalnie 8 kanałów cząstek, a konfigurowalna baza danych receptur może przechowywać do 50 receptur do pobierania próbek i raportów. Wszystkie dane można szybko i niezawodnie przenieść do komputera, Systemu Monitorowania Obiektów lub Systemu Automatyki Budynku. Bezpieczny transfer danych (zgodnie z 21 CFR Part 11) na dysk flash USB można zrealizować za pomocą funkcji bezpiecznego przesyłania danych Lighthouse i oprogramowania LMS Express.
              <br />
              <br />
            </p>
          </p>
        </TextBlock>
      ),
      tabs: [
        {
          name: "Specifikacja",
          body: (
            <TextBlock>
              <table>
                <tbody>
                  <tr>
                    <td>Zakres rozmiarów cząstek</td>
                    <td>0.10 - 1.0 µm</td>
                  </tr>
                  <tr>
                    <td>Kanały pomiarowe</td>
                    <td> 0.10, 0.15, 0.2, 0.25, 0.3, 0.5, 0.7, 1.0 µm</td>
                  </tr>
                  <tr>
                    <td>Przepływ</td>
                    <td>1.0 CFM (28.3LPM)</td>
                  </tr>
                  <tr>
                    <td>Limit stężenia</td>
                    <td>1,000,000 cząstek/ft3 (35,300,000 cząstek/m3) przy 10% utracie zgodności dla pomieszczeń czystych klas ISO 1-8</td>
                  </tr>
                  <tr>
                    <td>Waga</td>
                    <td>
                      Z baterią 23.55 lbs (10.68 kg)
                      <br />
                      Bez baterii 20.45 lbs (9.28 kg)
                    </td>
                  </tr>
                  <tr>
                    <td>Komunikacja</td>
                    <td>Port szeregowy, Ethernet, RS-485, pamięć flash USB</td>
                  </tr>
                  <tr>
                    <td>Typy protokołu Modbus</td>
                    <td>TCP, ASCII</td>
                  </tr>
                  <tr>
                    <td>Pojemność pamięci</td>
                    <td>3,000 rekordów</td>
                  </tr>
                  <tr>
                    <td>Obudowa</td>
                    <td>Stal nierdzewna</td>
                  </tr>
                  <tr>
                    <td>Zasilanie</td>
                    <td>100-250V AC, 47-63 HZ</td>
                  </tr>
                  <tr>
                    <td>Źródło światła</td>
                    <td>Dioda laserowa</td>
                  </tr>
                  <tr>
                    <td>Kalibracja</td>
                    <td>Zgodnie z ISO 21501-4</td>
                  </tr>
                  <tr>
                    <td>Zerowanie</td>
                    <td>&lt; 1 cząstki / 5 minut zgodnie z ISO 21501-4</td>
                  </tr>
                  <tr>
                    <td>Efektywność zliczania</td>
                    <td>50% dla kanału 0.1µm, 100% dla cząstek &gt; 0.15 µm</td>
                  </tr>
                  <tr>
                    <td>Wymiary</td>
                    <td>14.65"x8.0"x10.4"(37x20x26cm)</td>
                  </tr>
                </tbody>
              </table>
            </TextBlock>
          ),
        },
        {
          name: "Do pobrania",
          body: (
            <TextBlock>
              <LinkWithIconWrapper>
                <LinkWithIcon icon={<PdfIcon />} to={SolairBrochure}>
                  Ulotka
                </LinkWithIcon>
                <LinkWithIcon icon={<PdfIcon />} to={Solair1100Datasheet}>
                  Dane techniczne
                </LinkWithIcon>
                <LinkWithIcon icon={<PdfIcon />} to={Solair1100Manual}>
                  Instrukcja obsługi
                </LinkWithIcon>
              </LinkWithIconWrapper>
            </TextBlock>
          ),
        },
      ],
    } as ProductContentDto,
  },
  {
    tile: {
      title: "Boulder Counter",
      subtitle: "Producent: Lighthouse",
      price: "",
      img: BoulderCounter,
      to: RoutePath.PRODUCT,
    } as ProductTileDto,
    url: "solair-boulder-counter",
    content: {
      images: [LighthouseLogo],
      body: (
        <TextBlock>
          <TitleBlock title="Licznik cząstek Solair Boulder Counter" smallPaddingTop />
          <hr />
          <p>
            SSOLAIR Boulder Counter zawiera czujnik Extreme Life Laser Diode Technology, aby zapewnić najdłuższą w branży żywotność diody laserowej wynoszącą ponad 20 lat MTTF (w oparciu o ciągłą pracę 24/7). Zaprojektowany do korzystania z nowej, lżejszej baterii o przedłużonej żywotności i zewnętrznego zasilacza sieciowego, licznik głazów SOLAIR jest jeszcze bardziej przenośny. SOLAIR Boulder Counter oferuje jeden z najlepszych w branży interfejsów użytkownika (UI) do konfiguracji i obsługi instrumentu za pomocą intuicyjnych ekranów i menu z ikonami, które można szybko udokumentować dla SOP, są łatwe do nauczenia i proste w użyciu. SOLAIR Boulder Counter może przechowywać do 3000 rekordów danych dotyczących liczby cząstek z maksymalnie 6 kanałów cząstek, a konfigurowalna baza danych receptur może przechowywać do 50 receptur do pobierania próbek i raportów. Wszystkie dane można szybko i niezawodnie przenieść do komputera, Systemu Monitorowania Obiektów lub Systemu Automatyki Budynku. Bezpieczny transfer danych (zgodnie z 21 CFR część 11) do pamięci flash USB można zrealizować za pomocą funkcji transferu danych Lighthouse Secure Data i oprogramowania LMS Express lub Express RT. Elastyczna komunikacja z tymi instrumentami jest możliwa dzięki zintegrowanym interfejsom Ethernet, RS-485, USB i USB Flash Drive. Licznik głazów SOLAIR został zaprojektowany, wyprodukowany i skalibrowany zgodnie ze standardami, które zapewniają dokładność i powtarzalność zliczania cząstek.
            <br />
            <br />
          </p>
        </TextBlock>
      ),
      tabs: [
        {
          name: "Specifikacja",
          body: (
            <TextBlock>
              <table>
                <tbody>
                  <tr>
                    <td>Zakres rozmiarów cząstek</td>
                    <td>5.0 - 100.0 µm</td>
                  </tr>
                  <tr>
                    <td>Kanały pomiarowe</td>
                    <td>5.0, 10.0, 25.0, 40.0, 50.0, 100.0 µm</td>
                  </tr>
                  <tr>
                    <td>Przepływ</td>
                    <td>1.0 CFM (28.3LPM)</td>
                  </tr>
                  <tr>
                    <td>Limit stężenia</td>
                    <td>1,000,000 cząstek/ft3 (35,300,000 cząstek/m3) przy 10% utracie zgodności dla pomieszczeń czystych klas ISO 1-8</td>
                  </tr>
                  <tr>
                    <td>Waga</td>
                    <td>
                      Z baterią 13.95 lbs (6.33 kg)
                      <br />
                      Bez baterii 12.35 lbs (5.6 kg)
                    </td>
                  </tr>
                  <tr>
                    <td>Komunikacja</td>
                    <td>Port szeregowy, Ethernet, RS-485, pamięć flash USB</td>
                  </tr>
                  <tr>
                    <td>Typy protokołu Modbus</td>
                    <td>TCP, ASCII</td>
                  </tr>
                  <tr>
                    <td>Pojemność pamięci</td>
                    <td>3,000 rekordów</td>
                  </tr>
                  <tr>
                    <td>Obudowa</td>
                    <td>Stal nierdzewna</td>
                  </tr>
                  <tr>
                    <td>Zasilanie</td>
                    <td>100-250V AC, 47-63 HZ</td>
                  </tr>
                  <tr>
                    <td>Źródło światła</td>
                    <td>Dioda laserowa</td>
                  </tr>
                  <tr>
                    <td>Kalibracja</td>
                    <td>Zgodnie z ISO 21501-4</td>
                  </tr>
                  <tr>
                    <td>Zerowanie</td>
                    <td>&lt; 1 cząstki / 5 minut zgodnie z ISO 21501-4</td>
                  </tr>
                  <tr>
                    <td>Efektywność zliczania</td>
                    <td>50% dla kanału 0.1µm, 100% dla cząstek &gt; 0.15 µm</td>
                  </tr>
                  <tr>
                    <td>Wymiary</td>
                    <td>14.65"x8.0"x10.4"(37x20x26cm)</td>
                  </tr>
                </tbody>
              </table>
            </TextBlock>
          ),
        },
        {
          name: "Do pobrania",
          body: (
            <TextBlock>
              <LinkWithIconWrapper>
                <LinkWithIcon icon={<PdfIcon />} to={SolairBrochure}>
                  Ulotka
                </LinkWithIcon>
                <LinkWithIcon icon={<PdfIcon />} to={Solair1100Datasheet}>
                  Dane techniczne
                </LinkWithIcon>
                <LinkWithIcon icon={<PdfIcon />} to={Solair1100Manual}>
                  Instrukcja obsługi
                </LinkWithIcon>
              </LinkWithIconWrapper>
            </TextBlock>
          ),
        },
      ],
    } as ProductContentDto,
  },
  {
    tile: {
      title: "ApexZ3",
      subtitle: "Producent: Lighthouse",
      price: "",
      img: ApexZ3,
      to: RoutePath.PRODUCT,
    } as ProductTileDto,
    url: "apex-z3",
    content: {
      images: [ApexZ3Top, ApexZ3Front, LighthouseLogo],
      body: (
        <TextBlock>
          <TitleBlock title="Licznik cząstek ApexZ3" smallPaddingTop />
          <hr />
          <p>
            Jeśli chcesz przenieść swój program monitorowania środowiska na wyższy poziom, ApexZ jest Twoim rozwiązaniem. Siła ApexZ zaczyna się od kompletnego systemu autodiagnostyki i jest uzupełniana przez najbardziej elastyczny system zarządzania danymi w branży, a wszystko to w najlżejszym i najmniejszym przenośnym liczniku cząstek powietrza na rynku. ApexZ jest najbardziej innowacyjnym dostępnym licznikiem cząstek i będzie ponadczasowym dodatkiem do każdej strategii kontroli zanieczyszczeń.
            <br />
            <br />
            Na przykład dane mogą być zbierane z wbudowanej drukarki, przesyłane bezprzewodowo bezpośrednio na serwery lub integrowane bezpośrednio do systemu zarządzania informacjami laboratoryjnymi, zabezpieczając swoją strategię kontroli zanieczyszczeń na przyszłość.
            <br />
            <br />
            Wszystkie te i inne funkcje są zamknięte w łatwej do wyczyszczenia, chemicznie kompatybilnej obudowie.
            <br />
            <br />
          </p>
        </TextBlock>
      ),
      tabs: [
        {
          name: "Specifikacja",
          body: (
            <TextBlock>
              <table>
                <tbody>
                  <tr>
                    <td>Zakres rozmiarów cząstek</td>
                    <td>0.3 - 10.0 µm</td>
                  </tr>
                  <tr>
                    <td>Kanały pomiarowe</td>
                    <td>0.3, 0.5, 1.0, 3.0, 5.0, 10.0 µm</td>
                  </tr>
                  <tr>
                    <td>Przepływ</td>
                    <td>1.0 CFM (28.3LPM)</td>
                  </tr>
                  <tr>
                    <td>Limit stężenia</td>
                    <td>1,000,000 cząstek/ft3 (35,300,000 cząstek/m3) przy 10% utracie zgodności dla pomieszczeń czystych klas ISO 1-8</td>
                  </tr>
                  <tr>
                    <td>Waga</td>
                    <td>
                      Z baterią 7.05 lbs (3.19 kg)
                      <br />
                      Bez baterii 1.35 lbs (0.61 kg)
                    </td>
                  </tr>
                  <tr>
                    <td>Średnica króćca wlotowego</td>
                    <td>3/8“</td>
                  </tr>
                  <tr>
                    <td>Komunikacja</td>
                    <td>Ethernet, USB standard, Wi-Fi (802.11 b/g/n)</td>
                  </tr>
                  <tr>
                    <td>Pojemność pamięci</td>
                    <td>10 000 rekordów próbek, 2500 lokalizacji, 250 grup, 500 ustawień wstępnych, 500 planów próbek, 500 certyfikatów, 100 raportów, 250 użytkowników</td>
                  </tr>
                  <tr>
                    <td>Obudowa</td>
                    <td>Odporna chemicznie mieszanka poliwęglanu, wyściółka przewodząca, kompatybilna z VHP</td>
                  </tr>
                  <tr>
                    <td>Zasilanie</td>
                    <td>24 V DC, 5 A</td>
                  </tr>
                  <tr>
                    <td>Źródło światła</td>
                    <td>Dioda laserowa</td>
                  </tr>
                  <tr>
                    <td>Kalibracja</td>
                    <td>Zgodnie z ISO 21501-4</td>
                  </tr>
                  <tr>
                    <td>Zerowanie</td>
                    <td>&lt; 1 cząstki / 5 minut zgodnie z ISO 21501-4</td>
                  </tr>
                  <tr>
                    <td>Efektywność zliczania</td>
                    <td>50% dla kanału 0.3µm, 100% dla cząstek &gt; 1,5X rozmiar cząstek kanału</td>
                  </tr>
                  <tr>
                    <td>Wymiary</td>
                    <td>12.76“ x 6.23” x 8.88”(32.41x15.82x22.55cm)</td>
                  </tr>
                </tbody>
              </table>
            </TextBlock>
          ),
        },
        {
          name: "Do pobrania",
          body: (
            <TextBlock>
              <LinkWithIconWrapper>
                <LinkWithIcon icon={<PdfIcon />} to={ApexZBrochure}>
                  Ulotka
                </LinkWithIcon>
                <LinkWithIcon icon={<PdfIcon />} to={ApexZDatasheet}>
                  Dane techniczne
                </LinkWithIcon>
                <LinkWithIcon icon={<PdfIcon />} to={ApexZManual}>
                  Instrukcja obsługi
                </LinkWithIcon>
              </LinkWithIconWrapper>
            </TextBlock>
          ),
        },
      ],
    } as ProductContentDto,
  },
  {
    tile: {
      title: "ApexZ50",
      subtitle: "Producent: Lighthouse",
      price: "",
      img: ApexZ50,
      to: RoutePath.PRODUCT,
    } as ProductTileDto,
    url: "apex-z50",
    content: {
      images: [ApexZ50Top, ApexZ50Front, LighthouseLogo],
      body: (
        <TextBlock>
          <TitleBlock title="Licznik cząstek ApexZ50" smallPaddingTop />
          <hr />
          <p>
            Jeśli chcesz przenieść swój program monitorowania środowiska na wyższy poziom, ApexZ jest Twoim rozwiązaniem. Siła ApexZ zaczyna się od kompletnego systemu autodiagnostyki i jest uzupełniana przez najbardziej elastyczny system zarządzania danymi w branży, a wszystko to w najlżejszym i najmniejszym przenośnym liczniku cząstek powietrza na rynku. ApexZ jest najbardziej innowacyjnym dostępnym licznikiem cząstek i będzie ponadczasowym dodatkiem do każdej strategii kontroli zanieczyszczeń.
            <br />
            <br />
            Na przykład dane mogą być zbierane z wbudowanej drukarki, przesyłane bezprzewodowo bezpośrednio na serwery lub integrowane bezpośrednio do systemu zarządzania informacjami laboratoryjnymi, zabezpieczając swoją strategię kontroli zanieczyszczeń na przyszłość.
            <br />
            <br />
            Wszystkie te i inne funkcje są zamknięte w łatwej do wyczyszczenia, chemicznie kompatybilnej obudowie.
            <br />
            <br />
          </p>
        </TextBlock>
      ),
      tabs: [
        {
          name: "Specifikacja",
          body: (
            <TextBlock>
              <table>
                <tbody>
                  <tr>
                    <td>Zakres rozmiarów cząstek</td>
                    <td>0.5 - 10.0 µm</td>
                  </tr>
                  <tr>
                    <td>Kanały pomiarowe</td>
                    <td>0.5, 0.7, 1.0, 3.0, 5.0, 10.0 µm</td>
                  </tr>
                  <tr>
                    <td>Przepływ</td>
                    <td>100LPM</td>
                  </tr>
                  <tr>
                    <td>Limit stężenia</td>
                    <td>300,000 cząstek/ft3 (10,594,400 cząstek/m3) przy 10% utracie zgodności dla pomieszczeń czystych klas ISO 1-8</td>
                  </tr>
                  <tr>
                    <td>Waga</td>
                    <td>
                      Z baterią 7.05 lbs (3.19 kg)
                      <br />
                      Bez baterii 1.35 lbs (0.61 kg)
                    </td>
                  </tr>
                  <tr>
                    <td>Średnica króćca wlotowego</td>
                    <td>1/2“</td>
                  </tr>
                  <tr>
                    <td>Komunikacja</td>
                    <td>Ethernet, USB standard, Wi-Fi (802.11 b/g/n)</td>
                  </tr>
                  <tr>
                    <td>Pojemność pamięci</td>
                    <td>10 000 rekordów próbek, 2500 lokalizacji, 250 grup, 500 ustawień wstępnych, 500 planów próbek, 500 certyfikatów, 100 raportów, 250 użytkowników</td>
                  </tr>
                  <tr>
                    <td>Obudowa</td>
                    <td>Odporna chemicznie mieszanka poliwęglanu, wyściółka przewodząca, kompatybilna z VHP</td>
                  </tr>
                  <tr>
                    <td>Zasilanie</td>
                    <td>24 V DC, 5 A</td>
                  </tr>
                  <tr>
                    <td>Źródło światła</td>
                    <td>Dioda laserowa</td>
                  </tr>
                  <tr>
                    <td>Kalibracja</td>
                    <td>Zgodnie z ISO 21501-4</td>
                  </tr>
                  <tr>
                    <td>Zerowanie</td>
                    <td>&lt; 1 cząstki / 5 minut zgodnie z ISO 21501-4</td>
                  </tr>
                  <tr>
                    <td>Efektywność zliczania</td>
                    <td>50% dla kanału 0.5µm, 100% dla cząstek &gt; 1,5X rozmiar cząstek kanału</td>
                  </tr>
                  <tr>
                    <td>Wymiary</td>
                    <td>12.76“ x 6.23” x 8.88”(32.41x15.82x22.55cm)</td>
                  </tr>
                </tbody>
              </table>
            </TextBlock>
          ),
        },
        {
          name: "Do pobrania",
          body: (
            <TextBlock>
              <LinkWithIconWrapper>
                <LinkWithIcon icon={<PdfIcon />} to={ApexZBrochure}>
                  Ulotka
                </LinkWithIcon>
                <LinkWithIcon icon={<PdfIcon />} to={ApexZDatasheet}>
                  Dane techniczne
                </LinkWithIcon>
                <LinkWithIcon icon={<PdfIcon />} to={ApexZManual}>
                  Instrukcja obsługi
                </LinkWithIcon>
              </LinkWithIconWrapper>
            </TextBlock>
          ),
        },
      ],
    } as ProductContentDto,
  },
  {
    tile: {
      title: "ApexP3",
      subtitle: "Producent: Lighthouse",
      price: "",
      img: ApexP3,
      to: RoutePath.PRODUCT,
    } as ProductTileDto,
    url: "apex-p3",
    content: {
      images: [LighthouseLogo],
      body: (
        <TextBlock>
          <TitleBlock title="Licznik cząstek ApexP3" smallPaddingTop />
          <hr />
          <p>
            Przenośny licznik cząstek ApexP3 może przechowywać do 3000 rekordów danych dotyczących liczby cząstek z maksymalnie 4 kanałów cząstek, a konfigurowalna baza danych może przechowywać do 50 receptur do pobierania próbek i raportów.
            <br />
            <br />
          </p>

          <h4>Autodiagnostyka</h4>
          <p>
            ApexP3 został zaprojektowany z wewnętrzną technologią autodiagnostyki, która stale monitoruje stan lasera, zapewniając wiarygodność danych o liczbie cząstek. Autodiagnostyka Apex w zakresie lasera, natężenia przepływu i detektora cząstek zapewnia, że urządzenie działa w określonych tolerancjach.
            <br />
            <br />
          </p>

          <h4>Bezpieczny transfer danych</h4>
          <p>
            Bezpieczny transfer danych ApexP3 umożliwia szybkie i niezawodne przesyłanie wszystkich danych dotyczących liczby cząstek do pamięci flash USB, komputera, systemu monitorowania obiektu lub systemu automatyki budynku. ApexP3 posiada elastyczną komunikację danych poprzez zintegrowane interfejsy RS-485 Modbus i USB flash drive.
            <br />
            <br />
          </p>

          <h4>Dostępność danych</h4>
          <p>
            Przeglądaj dane ApexP3 na wbudowanym kolorowym ekranie dotykowym o przekątnej 5,7 cala, interfejsie USB, zewnętrznej drukarce, serwerze WWW lub komunikacji Modbus/TCP/IP z aplikacjami innych firm.
            <br />
            <br />
          </p>

          <h4>Serwer sieciowy</h4>
          <p>
            Przeglądaj dane w czasie rzeczywistym na swoim laptopie, tablecie lub urządzeniu inteligentnym.
            <br />
            <br />
          </p>

          <h4>Drukowanie</h4>
          <p>
            Wybierz naszą opcjonalną drukarkę USB, aby drukować raporty pozytywne/negatywne certyfikacji pomieszczeń czystych: Federal Standard 209E, ISO 14644-1: 1999 i ISO 14644-1: 2015 oraz EU GMP Annex 1<br />
            <br />
          </p>

          <h4>Kompatybilność środowiskowa</h4>
          <p>
            Kompatybilny ze wszystkimi procesami dekontaminacji ApexP3 wykorzystuje obudowę ze stali nierdzewnej 304, uszczelnione wloty i jest kompatybilny z odkażaniem oparów H2O2.
            <br />
            <br />
          </p>
        </TextBlock>
      ),
      tabs: [
        {
          name: "Specifikacja",
          body: (
            <TextBlock>
              <table>
                <tbody>
                  <tr>
                    <td>Zakres rozmiarów cząstek</td>
                    <td>0.3 - 5.0 µm</td>
                  </tr>
                  <tr>
                    <td>Kanały pomiarowe</td>
                    <td>0.3, 0.5, 0.7, 1.0, 3.0, 5.0 µm</td>
                  </tr>
                  <tr>
                    <td>Przepływ</td>
                    <td>1 CFM (28.3 LPM)</td>
                  </tr>
                  <tr>
                    <td>Limit stężenia</td>
                    <td>1,000,000 cząstek/ft3 (34,300,000 cząstek/m3) przy 10% utracie zgodności dla pomieszczeń czystych klas ISO 1-8</td>
                  </tr>
                  <tr>
                    <td>Waga</td>
                    <td>10.9 lbs (4.76 kg)</td>
                  </tr>
                  <tr>
                    <td>Komunikacja</td>
                    <td>Port szeregowy, Ethernet</td>
                  </tr>
                  <tr>
                    <td>Typy protokołu Modbus</td>
                    <td>TCP, RTU, ASCII</td>
                  </tr>
                  <tr>
                    <td>Pojemność pamięci</td>
                    <td>3,000 rekordów</td>
                  </tr>
                  <tr>
                    <td>Obudowa</td>
                    <td>Stal nierdzewna 304</td>
                  </tr>
                  <tr>
                    <td>Zasilanie</td>
                    <td>24 VDC, 5 A</td>
                  </tr>
                  <tr>
                    <td>Źródło światła</td>
                    <td>Dioda laserowa</td>
                  </tr>
                  <tr>
                    <td>Kalibracja</td>
                    <td>Zgodnie z ISO 21501-4</td>
                  </tr>
                  <tr>
                    <td>Zerowanie</td>
                    <td>&lt; 1 cząstki / 5 minut zgodnie z ISO 21501-4</td>
                  </tr>
                  <tr>
                    <td>Efektywność zliczania</td>
                    <td>50% dla minimalnego rozmiaru kanału, 100% dla cząstek &gt; 1,5X - 2X rozmiar cząstek kanału</td>
                  </tr>
                  <tr>
                    <td>Wymiary</td>
                    <td>9.00” x 6.69” x 8.46” (22.86 x 16.99 x 21.48 cm)</td>
                  </tr>
                </tbody>
              </table>
            </TextBlock>
          ),
        },
        {
          name: "Do pobrania",
          body: (
            <TextBlock>
              <LinkWithIconWrapper>
                <LinkWithIcon icon={<PdfIcon />} to={ApexPBrochure}>
                  Ulotka
                </LinkWithIcon>
                <LinkWithIcon icon={<PdfIcon />} to={ApexPDatasheet}>
                  Dane techniczne
                </LinkWithIcon>
                <LinkWithIcon icon={<PdfIcon />} to={ApexPManual}>
                  Instrukcja obsługi
                </LinkWithIcon>
              </LinkWithIconWrapper>
            </TextBlock>
          ),
        },
      ],
    } as ProductContentDto,
  },
  {
    tile: {
      title: "ApexP5",
      subtitle: "Producent: Lighthouse",
      price: "",
      img: ApexP5,
      to: RoutePath.PRODUCT,
    } as ProductTileDto,
    url: "apex-p5",
    content: {
      images: [LighthouseLogo],
      body: (
        <TextBlock>
          <TitleBlock title="Licznik cząstek ApexP5" smallPaddingTop />
          <hr />
          <p>
            Przenośny licznik cząstek ApexP5 może przechowywać do 3000 rekordów danych dotyczących liczby cząstek z maksymalnie 4 kanałów cząstek, a konfigurowalna baza danych może przechowywać do 50 receptur do pobierania próbek i raportów.
            <br />
            <br />
          </p>

          <h4>Autodiagnostyka</h4>
          <p>
            ApexP5 został zaprojektowany z wewnętrzną technologią autodiagnostyki, która stale monitoruje stan lasera, zapewniając wiarygodność danych o liczbie cząstek. Autodiagnostyka Apex w zakresie lasera, natężenia przepływu i detektora cząstek zapewnia, że urządzenie działa w określonych tolerancjach.
            <br />
            <br />
          </p>

          <h4>Bezpieczny transfer danych</h4>
          <p>
            Bezpieczny transfer danych ApexP5 umożliwia szybkie i niezawodne przesyłanie wszystkich danych dotyczących liczby cząstek do pamięci flash USB, komputera, systemu monitorowania obiektu lub systemu automatyki budynku. ApexP5 posiada elastyczną komunikację danych poprzez zintegrowane interfejsy RS-485 Modbus i USB flash drive.
            <br />
            <br />
          </p>

          <h4>Dostępność danych</h4>
          <p>
            Przeglądaj dane ApexP5 na wbudowanym kolorowym ekranie dotykowym o przekątnej 5,7 cala, interfejsie USB, zewnętrznej drukarce, serwerze WWW lub komunikacji Modbus/TCP/IP z aplikacjami innych firm.
            <br />
            <br />
          </p>

          <h4>Serwer sieciowy</h4>
          <p>
            Przeglądaj dane w czasie rzeczywistym na swoim laptopie, tablecie lub urządzeniu inteligentnym.
            <br />
            <br />
          </p>

          <h4>Drukowanie</h4>
          <p>
            Wybierz naszą opcjonalną drukarkę USB, aby drukować raporty pozytywne/negatywne certyfikacji pomieszczeń czystych: Federal Standard 209E, ISO 14644-1: 1999 i ISO 14644-1: 2015 oraz EU GMP Annex 1<br />
            <br />
          </p>

          <h4>Kompatybilność środowiskowa</h4>
          <p>
            Kompatybilny ze wszystkimi procesami dekontaminacji ApexP5 wykorzystuje obudowę ze stali nierdzewnej 304, uszczelnione wloty i jest kompatybilny z odkażaniem oparów H2O2.
            <br />
            <br />
          </p>
        </TextBlock>
      ),
      tabs: [
        {
          name: "Specifikacja",
          body: (
            <TextBlock>
              <table>
                <tbody>
                  <tr>
                    <td>Zakres rozmiarów cząstek</td>
                    <td>0.5 - 10.0 µm</td>
                  </tr>
                  <tr>
                    <td>Kanały pomiarowe</td>
                    <td>0.5, 0.7, 1.0, 3.0, 5.0, 7.0, 10.0 µm</td>
                  </tr>
                  <tr>
                    <td>Przepływ</td>
                    <td>1 CFM (28.3 LPM)</td>
                  </tr>
                  <tr>
                    <td>Limit stężenia</td>
                    <td>1,000,000 cząstek/ft3 (34,300,000 cząstek/m3) przy 10% utracie zgodności dla pomieszczeń czystych klas ISO 1-8</td>
                  </tr>
                  <tr>
                    <td>Waga</td>
                    <td>10.9 lbs (4.76 kg)</td>
                  </tr>
                  <tr>
                    <td>Komunikacja</td>
                    <td>Port szeregowy, Ethernet</td>
                  </tr>
                  <tr>
                    <td>Typy protokołu Modbus</td>
                    <td>TCP, RTU, ASCII</td>
                  </tr>
                  <tr>
                    <td>Pojemność pamięci</td>
                    <td>3,000 rekordów</td>
                  </tr>
                  <tr>
                    <td>Obudowa</td>
                    <td>Stal nierdzewna 304</td>
                  </tr>
                  <tr>
                    <td>Zasilanie</td>
                    <td>24 VDC, 5 A</td>
                  </tr>
                  <tr>
                    <td>Źródło światła</td>
                    <td>Dioda laserowa</td>
                  </tr>
                  <tr>
                    <td>Kalibracja</td>
                    <td>Zgodnie z ISO 21501-4</td>
                  </tr>
                  <tr>
                    <td>Zerowanie</td>
                    <td>&lt; 1 cząstki / 5 minut zgodnie z ISO 21501-4</td>
                  </tr>
                  <tr>
                    <td>Efektywność zliczania</td>
                    <td>50% dla minimalnego rozmiaru kanału, 100% dla cząstek &gt; 1,5X - 2X rozmiar cząstek kanału</td>
                  </tr>
                  <tr>
                    <td>Wymiary</td>
                    <td>9.00” x 6.69” x 8.46” (22.86 x 16.99 x 21.48 cm)</td>
                  </tr>
                </tbody>
              </table>
            </TextBlock>
          ),
        },
        {
          name: "Do pobrania",
          body: (
            <TextBlock>
              <LinkWithIconWrapper>
                <LinkWithIcon icon={<PdfIcon />} to={ApexPBrochure}>
                  Ulotka
                </LinkWithIcon>
                <LinkWithIcon icon={<PdfIcon />} to={ApexPDatasheet}>
                  Dane techniczne
                </LinkWithIcon>
                <LinkWithIcon icon={<PdfIcon />} to={ApexPManual}>
                  Instrukcja obsługi
                </LinkWithIcon>
              </LinkWithIconWrapper>
            </TextBlock>
          ),
        },
      ],
    } as ProductContentDto,
  },
] as Array<ProductDto>;

import React from "react";
import PostBlock from "../../../../components/writing-tools/post-block/post-block";
import TextBlock from "../../../../components/writing-tools/text-block/text-block";
import LinkWithIconWrapper from "../../../../components/link-with-icon/link-with-icon-wrapper";
import BlogSection from "../../../../sections/blog-section/blog-section";
import LinkWithIcon from "../../../../components/link-with-icon/link-with-icon";
import PdfIcon from "../../../../components/pdf-icon/pdf-icon";
import CertyfikatFH019
  from "../../../../assets/blog/nowa-norma/CertyfikatFH019ENPL.pdf";
import CertyfikatFH022
  from "../../../../assets/blog/nowa-norma/CertyfikatFH022ENPL.pdf";
import InstrukcjaFH019
  from "../../../../assets/blog/nowa-norma/InstrukcjaFH019ENPL.pdf";
import InstrukcjaFH022
  from "../../../../assets/blog/nowa-norma/InstrukcjaFH022ENPL.pdf";
import MDHS14_3
  from "../../../../assets/blog/nowa-norma/MDHS14_3ENPL.pdf";
import MDHS14_4
  from "../../../../assets/blog/nowa-norma/MDHS14_4ENPL.pdf";
import RaportFH019Plastik
  from "../../../../assets/blog/nowa-norma/RaportRadwagFH019plastik.pdf";
import RaportFH019Stal
  from "../../../../assets/blog/nowa-norma/RaportRadwagFH019stal.pdf";

const BlogPostNowaNorma: React.FC = () => {
  return (
    <section>
      <BlogSection
        className="example-post-section">
        <PostBlock>
          <TextBlock>
            <p>
              W związku z opublikowaniem norm PN-Z-04507:2022-05 i PN-Z-04508:2022-05, które określają wymagania dla
              głowic do poboru pyłu na stanowiskach pracy, pojawił się wymóg dla producentów i dostawców, przedstawienia
              dodatkowych dokumentów do oferowanych głowic.<br/><br/>

              Firma Ekohigiena Aparatura Ryszard Putyra Sp. J. Oferuje następujące głowice:<br/>
              <li> FH019: głowica do poboru pyłu wdychalnego typu Inhalable Dust Sampler z kasetką na filtry fi 25mm.
              </li>
              <li>FH022: głowica do poboru pyłu respirabilnego typu Higgins Dewell Cyclone.
                Głowica może być stosowana z kasetką Ø 25mm (kod FH022-25) lub z kasetką Ø 37mm (kod FH022-37).
              </li>
              <br/><br/>

              Informujemy, iż producentem wyżej wymienionych głowic jest firma JSH Holdings z Wielkiej Brytanii.
              Producent nie określa w swoich instrukcjach, w jaki sposób należy ważyć filtry, to znaczy czy ważyć filtr
              razem z kasetką, czy tylko sam filtr. W instrukcjach producenta jest informacja, iż należy zapoznać się z
              dokumentem MDHS 14/3, który określa sposoby analizy grawimetrycznej i postępować zgodnie z wytycznymi
              zawartymi w tym dokumencie.<br/><br/>

              W związku z powyższym, przedstawiamy następujące dokumenty.<br/><br/>
            </p>
          </TextBlock>
          <TextBlock>
            <LinkWithIconWrapper>
              <LinkWithIcon icon={<PdfIcon/>} to={CertyfikatFH019}>
                Certyfikat zgodności z normą EN-PN 481 dla głowicy FH019.
              </LinkWithIcon>
              <LinkWithIcon icon={<PdfIcon/>} to={CertyfikatFH022}>
                Certyfikat zgodności z normą EN-PN 481 dla głowicy FH022.
              </LinkWithIcon>
              <LinkWithIcon icon={<PdfIcon/>} to={InstrukcjaFH019}>
                Instrukcja obsługi producenta z tłumaczeniem na język polski do głowicy FH019.
              </LinkWithIcon>
              <LinkWithIcon icon={<PdfIcon/>} to={InstrukcjaFH022}>
                Instrukcja obsługi producenta z tłumaczeniem na język polski do głowicy FH022.
              </LinkWithIcon>
              <LinkWithIcon icon={<PdfIcon/>} to={MDHS14_3}>
                Dokument MDHS 14/3 wydany przez Instytut HSE w Wielkiej Brytanii, określający sposoby poboru i analizy
                pyłu na stanowiskach pracy.
              </LinkWithIcon>
              <LinkWithIcon icon={<PdfIcon/>} to={MDHS14_4}>
                Dokument MDHS 14/4 wydany przez Instytut HSE w Wielkiej Brytanii, określający sposoby poboru i analizy
                pyłu na stanowiskach pracy.
              </LinkWithIcon>
              <LinkWithIcon icon={<PdfIcon/>} to={RaportFH019Plastik}>
                Raport firmy RADWAG z ważenia filtra wraz z kasetką wykonaną z tworzywa, do głowicy FH019.
              </LinkWithIcon>
              <LinkWithIcon icon={<PdfIcon/>} to={RaportFH019Stal}>
                Raport firmy RADWAG z ważenia filtra wraz z kasetką wykonaną ze stali, do głowicy FH019.
              </LinkWithIcon>
            </LinkWithIconWrapper>
          </TextBlock>
        </PostBlock>

      </BlogSection>
    </section>
  );
};

export default BlogPostNowaNorma;

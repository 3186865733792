import React from "react";
import Button from "../../../components/button/button";
import TopLink from "../../../components/top-link/top-link";
import { RoutePath } from "../../../route-paths";
import CtaSection from "../../../sections/cta-section/cta-section";
import ServicesImg from "./../../../assets/uslugi.png";

const CtaSectionWrapper: React.FC = () => {
  return (
    <CtaSection img={ServicesImg} alt="Uslugi img">
      <h3>Zobacz pozostałe usługi</h3>
      <p>
        Zajmujemy się również produkcją, kalibracją i serwisowaniem aparatury laboratoryjnej na terenie całego kraju.
      </p>
      <TopLink to={RoutePath.SERVICES}>
        <Button color={"light"}>
          Sprawdź usługi
        </Button>
      </TopLink>
    </CtaSection>
  );
};

export default CtaSectionWrapper;

import React from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { Switch, Route, Redirect } from "react-router-dom";
import { RoutePath } from "../../route-paths";
import BlogPostNowaNorma from "./blog-post-nowa-norma/blog-post-nowa-norma";

const BlogSwitch: React.FC = () => {
  return (
    <>
      <BreadcrumbsItem to={RoutePath.BLOG}>Blog</BreadcrumbsItem>
      <Switch>
        <Route exact path={RoutePath.BLOG__DOKUMENTACJA_DO_GLOWIC} render={() => <BlogPostNowaNorma />} />

        <Route exact path={RoutePath.BLOG} render={() => <Redirect to={RoutePath.BLOG} />} />
      </Switch>
    </>
  );
};

export default BlogSwitch;

import React, { useEffect, useState } from "react";
import { ProductContentTabDto } from "../../dto/productDto";
import "./product-tabs.scss";

interface Props {
  items: Array<ProductContentTabDto>;
}

const ProductTabs: React.FC<Props> = (props: Props) => {
  const { items } = props;
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    if (items[activeTab] === undefined) setActiveTab(0);
  }, [items, activeTab]);

  return (
    <div className="product-tabs">
      <nav>
        {items.map((item: ProductContentTabDto, index: number) => {
          return (
            <div key={index} className={`tab ${activeTab === index ? "tab--active" : ""}`} onClick={() => setActiveTab(index)}>
              {item.name}
            </div>
          );
        })}
      </nav>
      <div className="tab__body">{items[activeTab]?.body}</div>
    </div>
  );
};

export default ProductTabs;

import React from "react";
import Col from "../../components/grid/col";
import "./info-tiles.scss";

interface Props {
  children: React.ReactNode;
  initColSize?: 4 | 6;
}

const InfoTilesMapper: React.FC<Props> = (props: Props) => {
  const { children, initColSize = 4 } = props;

  return (
    <>
      {React.Children.map(children, (child) => {
        return <Col size={[initColSize, initColSize, 6, 12]} className="py-xl-3 py-1">{child}</Col>;
      })}
    </>
  );
};

export default InfoTilesMapper;

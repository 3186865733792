import React from "react";
import {ProductContentDto, ProductDto} from "../../../../dto/productDto";
import {ProductTileDto} from "../../../../dto/productTileDto";
import {RoutePath} from "../../../../route-paths";

import TextBlock from "../../../../components/writing-tools/text-block/text-block";
import TitleBlock from "../../../../components/title-block/title-block";
import SensidyneLogo from "./../../../../assets/products/sensidyne-logo.png";
import MesaLabsLogo from "./../../../../assets/partnerMesaLabs.png";
import Gilibrator3Img from "./../../../../assets/products/kalibratory_przepływu/gilibrator-3-single.jpeg";
import GilibratorModules3Img from "./../../../../assets/products/kalibratory_przepływu/gilibrator-3-w-modules.jpeg";
import Gilibrator2Img from "./../../../../assets/products/kalibratory_przepływu/Gilibrator-2.png";
import Gilibrator2HighFlowImg from "./../../../../assets/products/kalibratory_przepływu/gilibrator2-high-flow.png";
import Gilibrator2LowFlowImg from "./../../../../assets/products/kalibratory_przepływu/gilibrator2-low-flow.png";
import DefenderImg from "./../../../../assets/products/kalibratory_przepływu/Defender-530-web.png";
import DefenderAngleImg from "./../../../../assets/products/kalibratory_przepływu/Defender-530-angled-web.png";
import ChallengerImg from "./../../../../assets/products/kalibratory_przepływu/challenger-air-flow-calibrator.png";
import LinkWithIconWrapper from "../../../../components/link-with-icon/link-with-icon-wrapper";
import LinkWithIcon from "../../../../components/link-with-icon/link-with-icon";
import PdfIcon from "../../../../components/pdf-icon/pdf-icon";
import Gilibrator3Ulotka from "../../../../assets/products/kalibratory_przepływu/Ulotka_Gilibrator_3_20-0901.pdf";
import Gilibrator3ManualPL
  from "../../../../assets/products/kalibratory_przepływu/Gilibrator_3_Quick_Start_Guide-Polish_360-0216-13rB.pdf";
import Gilibrator3ManualENG
  from "../../../../assets/products/kalibratory_przepływu/Gilibrator_3_Operation_Manual_360-0213-01-RrC_.pdf";
import Gilibrator2Brochure from "../../../../assets/products/kalibratory_przepływu/Ulotka_Gilibrator_2_14-1201.pdf";
import Gilibrator2ManualPL from "../../../../assets/products/kalibratory_przepływu/Instrukcja_Giibrator-2_08-0101.pdf";
import Gilibrator2ConnectionManualPL
  from "../../../../assets/products/kalibratory_przepływu/Instrukcja_podczenie_kalibratora_do_aspiratora.pdf";
import DefenderBrochurePL from "../../../../assets/products/kalibratory_przepływu/Ulotka_DEFENDER_16-0701.pdf";
import DefenderBrochureENG from "../../../../assets/products/kalibratory_przepływu/Defender.RevH_.07JUN2016.pdf";
import DefenderManualENG from "../../../../assets/products/kalibratory_przepływu/Defender_510_520.8.5x11-Rev-F.pdf";
import ChallengerBrochurePL from "../../../../assets/products/kalibratory_przepływu/Ulotka_Challenger_14-1201.pdf";

export const ProductsKalibratoryPrzepływu = [
  {
    tile: {
      title: "Gilibrator 3 ",
      subtitle: "Producent: Sensidyne",
      price: "",
      img: Gilibrator3Img,
      to: RoutePath.PRODUCT,
    } as ProductTileDto,
    url: "gilibrator-3",
    content: {
      images: [GilibratorModules3Img, SensidyneLogo],
      body: (
        <TextBlock>
          <TitleBlock title="Przepływomierz Gilibrator 3" smallPaddingTop/>
          <hr/>

          <p>
            Stosowany z aspiratorami serii GilAir 3 i GilAir 5<br/>
            <br/>
            <li> Pozwala na uzyskanie przepływu:</li>
            <ol>
              <li>5-450 ml/min z modułem niskich przepływów</li>
              <li>50-5000 ml/min z modułem standardowym</li>
              <li>1-30 l/min z modułem wysokich przepływów</li>
            </ol>
            <li> Kompensacja przepływu +/- 5%</li>
            <li> Montaż bezpośrednio do aspiratora,</li>
            <li> Regulowany przepływ</li>
            <li> Możliwość stosowania przy poborach na filtry i rurki sorbentowe</li>
          </p>
        </TextBlock>
      ),
      tabs: [
        {
          name: "Specyfikacja,",
          body: (
            <TextBlock>
              <table>
                <tbody>
                <tr>
                  <td></td>
                  <td>
                    <b>Cela standardowa</b>
                  </td>
                  <td>
                    <b>Cela niskich przepływów</b>
                  </td>
                  <td>
                    <b>Cela wysokich przepływów</b>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Zakres przepływu:</b>
                  </td>
                  <td>5 – 450 ml/min</td>
                  <td>50 – 5000 ml/min</td>
                  <td>1 000 – 30 000 ml/min</td>
                </tr>
                <tr>
                  <td>
                    <b>Dokładność wskazań:</b>
                  </td>
                  <td>± 1 %</td>
                  <td>± 1 %</td>
                  <td>± 1 %</td>
                </tr>
                <tr>
                  <td>
                    <b>Wymiary:</b>
                  </td>
                  <td>168 x 234 x 81mm</td>
                  <td>168 x 234 x 81mm</td>
                  <td>168 x 234 x 81mm</td>
                </tr>
                <tr>
                  <td>
                    <b>Waga:</b>
                  </td>
                  <td>1630 g</td>
                  <td>1650 g</td>
                  <td>1720 g</td>
                </tr>
                </tbody>
              </table>
            </TextBlock>
          ),
        },
        {
          name: "Do pobrania",
          body: (
            <TextBlock>
              <LinkWithIconWrapper>
                <LinkWithIcon icon={<PdfIcon/>} to={Gilibrator3Ulotka}>
                  Ulotka
                </LinkWithIcon>
                <LinkWithIcon icon={<PdfIcon/>} to={Gilibrator3ManualPL}>
                  Instrukcja PL
                </LinkWithIcon>
                <LinkWithIcon icon={<PdfIcon/>} to={Gilibrator3ManualENG}>
                  Instrukcja ENG
                </LinkWithIcon>
              </LinkWithIconWrapper>
            </TextBlock>
          ),
        },
      ],
    } as ProductContentDto,
  },
  {
    tile: {
      title: "Gilibrator 2 ",
      subtitle: "Producent: Sensidyne",
      price: "",
      img: Gilibrator2Img,
      to: RoutePath.PRODUCT,
    } as ProductTileDto,
    url: "gilibrator-2",
    content: {
      images: [Gilibrator2HighFlowImg, Gilibrator2LowFlowImg, SensidyneLogo],
      body: (
        <TextBlock>
          <TitleBlock title="Przepływomierz Gilibrator 2" smallPaddingTop/>
          <hr/>

          <p>Przepływomierz Gilibrator 2 umożliwia pomiar przepływu powietrza w trzech zakresach pomiarowych.
            Przepływomierz posiada wbudowany akumulator oraz wyświetlacz umożliwiający odczyt mierzonego przepływu i
            średniej wartości z kilku pomiarów.</p>
        </TextBlock>
      ),
      tabs: [
        {
          name: "Specyfikacja,",
          body: (
            <TextBlock>
              <table>
                <tbody>
                <tr>
                  <td></td>
                  <td>
                    <b>Cela standardowa</b>
                  </td>
                  <td>
                    <b>Cela niskich przepływów</b>
                  </td>
                  <td>
                    <b>Cela wysokich przepływów</b>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Zakres przepływu:</b>
                  </td>
                  <td>20 – 6000 ml/min</td>
                  <td>1 – 250 ml/min</td>
                  <td>2 000 – 30 000 ml/min</td>
                </tr>
                <tr>
                  <td>
                    <b>Dokładność wskazań:</b>
                  </td>
                  <td>± 1 %</td>
                  <td>± 1 %</td>
                  <td>± 1 %</td>
                </tr>
                <tr>
                  <td>
                    <b>Wymiary:</b>
                  </td>
                  <td>64 x 152 x 66mm</td>
                  <td>51 x 102 x 53mm</td>
                  <td>89 x 206 x 94mm</td>
                </tr>
                <tr>
                  <td>
                    <b>Waga:</b>
                  </td>
                  <td>370 g</td>
                  <td>180 g</td>
                  <td>1020 g</td>
                </tr>
                </tbody>
              </table>
            </TextBlock>
          ),
        },
        {
          name: "Do pobrania",
          body: (
            <TextBlock>
              <LinkWithIconWrapper>
                <LinkWithIcon icon={<PdfIcon/>} to={Gilibrator2Brochure}>
                  Ulotka
                </LinkWithIcon>
                <LinkWithIcon icon={<PdfIcon/>} to={Gilibrator2ManualPL}>
                  Instrukcja PL
                </LinkWithIcon>
                <LinkWithIcon icon={<PdfIcon/>} to={Gilibrator2ConnectionManualPL}>
                  Instrukcja podłączenia do aspiratora
                </LinkWithIcon>
              </LinkWithIconWrapper>
            </TextBlock>
          ),
        },
      ],
    } as ProductContentDto,
  },
  {
    tile: {
      title: "DryCal Defender",
      subtitle: "Producent: MesaLabs",
      price: "",
      img: DefenderImg,
      to: RoutePath.PRODUCT,
    } as ProductTileDto,
    url: "drycal-defender",
    content: {
      images: [DefenderAngleImg, MesaLabsLogo],
      body: (
        <TextBlock>
          <TitleBlock title="Przepływomierz DryCal Defender" smallPaddingTop/>
          <hr/>

          <p>Przepływomierz DryCal Defender umożliwia pomiar przepływu w trzech zakresach pomiarowych. Przepływomierz
            posiada wbudowany akumulator i wyświetlacz umożliwiający odczyt mierzonego przepływu i średnią wartość z
            kilku pomiarów. Za pomocą oprogramowania DryCal Pro możliwe jest sterowanie pomiarami z poziomu komputera PC
            (połączenie RS 232 lub USB).</p>
        </TextBlock>
      ),
      tabs: [
        {
          name: "Specyfikacja,",
          body: (
            <TextBlock>
              <table>
                <tbody>
                <tr>
                  <td></td>
                  <td>
                    <b>Cela standardowa</b>
                  </td>
                  <td>
                    <b>Cela niskich przepływów</b>
                  </td>
                  <td>
                    <b>Cela wysokich przepływów</b>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Zakres przepływu:</b>
                  </td>
                  <td>50 – 5000 ml/min</td>
                  <td>5 – 500 ml/min</td>
                  <td>300 – 30 000 ml/min</td>
                </tr>
                <tr>
                  <td>
                    <b>Dokładność wskazań:</b>
                  </td>
                  <td>± 1 %</td>
                  <td>± 1 %</td>
                  <td>± 1 %</td>
                </tr>
                <tr>
                  <td>
                    <b>Wymiary:</b>
                  </td>
                  <td>140 x 150 x 75 mm</td>
                  <td>140 x 150 x 75 mm</td>
                  <td>140 x 150 x 75 mm</td>
                </tr>
                <tr>
                  <td>
                    <b>Waga:</b>
                  </td>
                  <td>820 g</td>
                  <td>820 g</td>
                  <td>820 g</td>
                </tr>
                <tr>
                  <td>
                    <b>Czas pomiaru:</b>
                  </td>
                  <td>1 – 15 s</td>
                  <td>1 – 15 s</td>
                  <td>1 – 15 s</td>
                </tr>
                <tr>
                  <td colSpan={4}>Zasilanie: Sieciowe 220V + Akumulator (6-8 godzin.), Panel wskazań: zmierzona wartość
                    przepływu, wartość średnia próbek, ciśnienie i temperatura (model 520&530) . Oprogramowanie PC.
                  </td>
                </tr>
                </tbody>
              </table>
            </TextBlock>
          ),
        },
        {
          name: "Do pobrania",
          body: (
            <TextBlock>
              <LinkWithIconWrapper>
                <LinkWithIcon icon={<PdfIcon/>} to={DefenderBrochurePL}>
                  Ulotka PL
                </LinkWithIcon>
                <LinkWithIcon icon={<PdfIcon/>} to={DefenderBrochureENG}>
                  Ulotka ENG
                </LinkWithIcon>
                <LinkWithIcon icon={<PdfIcon/>} to={DefenderManualENG}>
                  Instrukcja
                </LinkWithIcon>
              </LinkWithIconWrapper>
            </TextBlock>
          ),
        },
      ],
    } as ProductContentDto,
  },
  {
    tile: {
      title: "Challenger",
      subtitle: "Producent: Sensidyne",
      price: "",
      img: ChallengerImg,
      to: RoutePath.PRODUCT,
    } as ProductTileDto,
    url: "challenger",
    content: {
      images: [SensidyneLogo],
      body: (
        <TextBlock>
          <TitleBlock title="Przepływomierz Challenger" smallPaddingTop/>
          <hr/>

          <p>Kalibrator przepływu powietrza pracujący w zakresie przepływu 1-30 l/min przy temperaturze otoczenia od -30
            do +55 °C. Wskazujący bieżącą wartość ciśnienia atmosferycznego i temperatury otoczenia. Zasilany czterema
            bateriami typu AA.</p>
        </TextBlock>
      ),
      tabs: [
        {
          name: "Specyfikacja,",
          body: (
            <TextBlock>
              <table>
                <tbody>
                <tr>
                  <td>Zakres przepływu</td>
                  <td>1 - 30 l/min</td>
                  <td>5 – 500 ml/min</td>
                  <td>300 – 30 000 ml/min</td>
                </tr>
                <tr>
                  <td>Dokładność wskazań</td>
                  <td>± 0.75 %</td>
                </tr>
                <tr>
                  <td>Czas ciągłej pracy</td>
                  <td>min 20h</td>
                </tr>
                <tr>
                  <td>Wymiary</td>
                  <td>4.0 x 9.9 x 19.6 cm</td>
                </tr>
                <tr>
                  <td>Temperatura pracy</td>
                  <td>-30 – 55 °C</td>
                </tr>
                <tr>
                  <td>Zasilanie</td>
                  <td>4 X baterie AA</td>
                </tr>
                </tbody>
              </table>
            </TextBlock>
          ),
        },
        {
          name: "Do pobrania",
          body: (
            <TextBlock>
              <LinkWithIconWrapper>
                <LinkWithIcon icon={<PdfIcon/>} to={ChallengerBrochurePL}>
                  Ulotka
                </LinkWithIcon>
              </LinkWithIconWrapper>
            </TextBlock>
          ),
        },
      ],
    } as ProductContentDto,
  },
] as Array<ProductDto>;

import React, { useEffect, useState } from "react";
import { generatePath, useParams } from "react-router-dom";
import Container from "../../../components/container/container";
import Col from "../../../components/grid/col";
import Grid from "../../../components/grid/grid";
import Row from "../../../components/grid/row";
import InputText from "../../../components/input-text/input-text";
import ProductTile from "../../../components/product-tile/product-tile";
import Sidenav from "../../../components/sidenav/sidenav";
import { CategoryDto } from "../../../dto/categoryDto";
import { ProductDto } from "../../../dto/productDto";
import { TileDto } from "../../../dto/tileDto";
import { UsageDto } from "../../../dto/usageDto";
import { RoutePath } from "../../../route-paths";
import { usageData } from "../data/usages-data";

const ProductsSection: React.FC = () => {
  let { usageId, categoryId }: any = useParams();
  const [products, setProducts] = useState([] as Array<ProductDto>);
  const [category, setCategory] = useState({} as CategoryDto);
  const [usage, setUsage] = useState({} as UsageDto);

  const [searchText, setSearchText] = useState("");

  const joinAllCategories = (usageDataa: Array<UsageDto>): Array<CategoryDto> => {
    var categoriess: Array<CategoryDto> = [];
    for (let i = 0; i < usageDataa.length; i++) {
      const categoriesGroup = usageDataa[i].categories;
      categoriess = [...categoriess, ...categoriesGroup];
    }
    return categoriess;
  };

  const joinAllProducts = (usageDataa: Array<UsageDto>): Array<ProductDto> => {
    var categoriess: Array<CategoryDto> = [];
    for (let i = 0; i < usageDataa.length; i++) {
      const categoriesGroup = usageDataa[i].categories;
      categoriess = [...categoriess, ...categoriesGroup];
    }
    var productss: Array<ProductDto> = [];
    for (let i = 0; i < categoriess.length; i++) {
      const productsGroup = categoriess[i].products;
      productss = [...productss, ...productsGroup];
    }

    return productss;
  };

  const generatePathForAllProducts = (url: string): string => {
    for (let i = 0; i < usageData.length; i++) {
      const usageCategories = usageData[i].categories;
      for (let y = 0; y < usageCategories.length; y++) {
        const categoryProducts = usageCategories[y].products;

        for (let z = 0; z < categoryProducts.length; z++) {
          const element = categoryProducts[z];
          if (element.url === url) {
            return generatePath(RoutePath.PRODUCT, {
              usageId: usageData[i].url,
              categoryId: usageCategories[y].url,
              productId: url,
            });
          }
        }
      }
    }

    return generatePath(RoutePath.PRODUCT, {
      usageId: "wszystkie",
    });
  };

  useEffect(() => {
    if (usage.categories) {
      let data = usage.categories.find((x) => x.url === categoryId);
      if (usageId === "wszystkie")
        data = {
          tile: {
            subtitle: "wszystkie - subtitle",
            title: "wszystkie - title",
            to: "wszystkie",
            img: "",
          },
          url: "wszystkie",
          content: <></>,
          products: joinAllProducts(usageData),
        };
      if (data) {
        setCategory(data);
        setProducts(data.products);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryId, usage]);

  useEffect(() => {
    if (usageId === "wszystkie") {
      setUsage({
        tile: {
          title: "Wszystkie",
          subtitle: "Produkty bez podziału na kategorie",
          to: RoutePath.USAGE,
        } as TileDto,
        url: "wszystkie-kategorie",
        content: <p></p>,
        categories: joinAllCategories(usageData),
      });
    } else {
      var data = usageData.find((x) => x.url === usageId);
      if (data) {
        setUsage(data);
      }
    }
  }, [usageId]);

  useEffect(() => {
    if (category.products && searchText) {
      const filteredProducts = category.products.filter((x) => x.tile.title.toLocaleLowerCase().indexOf(searchText.toLocaleLowerCase()) !== -1);
      setProducts(filteredProducts);
    } else if (category.products) {
      setProducts(category.products);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  return (
    <section className="products-section">
      <Container className="categories-nav">
        <Grid>
          <Row gap={16}>
            <Col size={[3, 4, 5, 12]}>
              <Sidenav
                title="Zastosowania"
                items={[
                  {
                    name: "Wszystkie kategorie",
                    to: generatePath(RoutePath.CATEGORY, { usageId: "wszystkie", categoryId: "wszystkie" }),
                    isActive: usageId === "wszystkie",
                  },
                  ...usageData.map((x) => {
                    return {
                      name: x.tile.title,
                      to: x.categories && x.categories.length > 0 ? generatePath(RoutePath.CATEGORY, { usageId: x.url, categoryId: x.categories[0].url }) : generatePath(RoutePath.USAGE, { usageId: x.url }),
                      isActive: usageId === x.url,
                      items: x.categories.map((y) => {
                        return { name: y.tile.title, to: generatePath(RoutePath.CATEGORY, { usageId: x.url, categoryId: y.url }) };
                      }),
                    };
                  }),
                ]}
              />
            </Col>
            <Col size={[9, 8, 7, 12]}>
              <div className="search-field">
                <InputText
                  name="searchText"
                  type="text"
                  label={
                    <>
                      <span className="search-icon">&#9906;</span> Wyszukiwanie produktu...
                    </>
                  }
                  value={searchText}
                  onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setSearchText(e.target.value)}
                  required
                />
              </div>
              {category.tile ? (
                <Row gap={16}>
                  {products.map((x: ProductDto, index: number) => {
                    return (
                      <ProductTile key={index} product={x} to={usageId === "wszystkie" ? generatePathForAllProducts(x.url) : generatePath(RoutePath.PRODUCT, { usageId: usageId, categoryId: category.url, productId: x.url })}>
                        {x.tile.title}
                      </ProductTile>
                    );
                  })}
                </Row>
              ) : null}
              {category.tableInfo ? (
                <div className="pt-5">
                  {category.tableInfo}
                </div>
              ) : null}
            </Col>
          </Row>
        </Grid>
      </Container>
    </section>
  );
};

export default ProductsSection;

import React from "react";
import FullImgBlock from "../../../../components/fullImg-block/fullImg-block";
import Col from "../../../../components/grid/col";
import Row from "../../../../components/grid/row";
import PostBlock from "../../../../components/writing-tools/post-block/post-block";
import TextBlock from "../../../../components/writing-tools/text-block/text-block";
import FaceMaskImg from "../../../../assets/realizations/eha-stm/face-mask.jpg";
import CovidImg from "../../../../assets/realizations/eha-stm/covid.jpg";
import EhaStmBudowaImg from "../../../../assets/realizations/eha-stm/eha-stm-budowa.png";
import EhaStmAppBadanieImg from "../../../../assets/realizations/eha-stm/eha-stm-app-badanie.png";
import EhaStmAppOdczytImg from "../../../../assets/realizations/eha-stm/eha-stm-app-odczyt-wynikow.png";
import EhaStmAppUstawieniaImg from "../../../../assets/realizations/eha-stm/eha-stm-app-ustawienia.png";
import EhaStmRaportImg from "../../../../assets/realizations/eha-stm/eha-stm-raport.png";
import Container from "../../../../components/container/container";
import Grid from "../../../../components/grid/grid";
import HeroSection from "../../../../sections/hero-section/hero-section";
import TopLink from "../../../../components/top-link/top-link";
import {RoutePath} from "../../../../route-paths";
import {BreadcrumbsItem} from "react-breadcrumbs-dynamic";

const RealizationMikroklimat: React.FC = () => {
  return (
    <>
      <BreadcrumbsItem to={RoutePath.SERVICES__PRODUKCJA}>Produkcja</BreadcrumbsItem>
      <BreadcrumbsItem to={RoutePath.SERVICES__PRODUKCJA__ODDYCHALNOSC}>Oddychalność masek</BreadcrumbsItem>
      <HeroSection>
        <h1>Stanowisko testowania masek</h1>
        <h2>EHA STM</h2>
      </HeroSection>
      <section className="services-section">
        <Container className="mt-xl-5 mt-4">
          <Grid>
            <Row gap={16}>
              <Col size={[8, 8, 10, 12]} offset={[2, 2, 1, 0]}>
                <PostBlock className="no-padding-top" size={24}>
                  <TextBlock>
                    <h2>Historia powstania</h2>
                    <p>Na początku 2020 roku nasza firma na zamówienie z Centralnego Instytutu Ochrony Pracy,
                      zaprojektowała i wykonała stanowisko do testowania masek. Wybuch pandemii wywołanej wirusem
                      SARS-Cov-2 spowodował wzrost zapotrzebowania na maski i tym samym zwiększenie zapotrzebowania na
                      nasze urządzenie. Do końca 2021 roku sprzedaliśmy jeszcze ... egzemplarzy.</p>
                  </TextBlock>
                </PostBlock>
                <PostBlock size={24}>
                  <Row gap={16}>
                    <Col size={6}>
                      <FullImgBlock img={FaceMaskImg} alt="Face mask"/>
                    </Col>
                    <Col size={6}>
                      <FullImgBlock img={CovidImg} alt="Covid"/>
                    </Col>
                  </Row>
                </PostBlock>
                <PostBlock size={24}>
                  <TextBlock>
                    <h2>Zastosowanie</h2>
                    <p>Stanowisko do testowania masek EHA STM, służy do przeprowadzania testu oporu przepływu powietrza
                      przez maskę, zgodnie z normą EN 14683.</p>
                  </TextBlock>
                </PostBlock>
                <PostBlock>
                  <TextBlock>
                    <h2>Budowa stanowiska</h2>
                    <FullImgBlock img={EhaStmBudowaImg} alt="Budowa EHA STM"/>
                    <p>Całe stanowisko pomiarowe składa się z następujących elementów (patrz rysunek powyżej):</p>
                    <ol>
                      <li>Uchwyt pneumatyczny</li>
                      <li>Miernik przepływu i spadku ciśnienia</li>
                      <li>Pompa próżniowa</li>
                    </ol>
                    <p>Kluczowym elementem stanowiska jest uchwyt pneumatyczny. Największym wyzwaniem przy stworzeniu
                      chwytu był fakt, że aby spełniać normę EN 14683, elementy uchwytu przytrzymujące maskę muszą być
                      wykonane z metalu i przylegać do siebie ściśle, tak aby nie było spadku ciśnienia przy zamkniętym
                      uchwycie bez maski. Było to trudne do wykonania, gdyż norma wyraźnie mówi, że niedozowlone jest
                      stosowanie gumowych uszczelek ani pianek uszczelniających.</p>
                  </TextBlock>
                </PostBlock>
                <PostBlock>
                  <TextBlock>
                    <h2>Oprogramowanie</h2>
                    <p>Do stanowiska pomiarowego dołączona jest aplikacja, która pozwala na generowanie raportów z
                      badania. Aplikacja ta daje nam możliwość automatycznego rejestrowania pomiarów, od użytkownika
                      wymagane jest jedynie otwieranie uchwytu pneumatyczniego i przesuwanie maski tak aby znajdowała
                      się w odpowiednich punktach pomiarowych.</p>
                    <Row gap={16}>
                      <Col size={4}>
                        <FullImgBlock img={EhaStmAppBadanieImg} alt="EHA STM app badanie"/>
                      </Col>
                      <Col size={4}>
                        <FullImgBlock img={EhaStmAppOdczytImg} alt="EHA STM app odczyt"/>
                      </Col>
                      <Col size={4}>
                        <FullImgBlock img={EhaStmAppUstawieniaImg} alt="EHA STM ustawienia"/>
                      </Col>
                      <p>Tak wygląda przykładowy raport z badania, wygenerowany przez aplikację.</p>
                      <Col size={8}>
                        <FullImgBlock img={EhaStmRaportImg} alt="EHA STM raport"/>
                      </Col>
                    </Row>
                  </TextBlock>
                </PostBlock>
                <PostBlock>
                  <TextBlock>
                    <h2>Procedura testowania</h2>
                    <p>Testowanie masek przy użyciu stanowiska EHA STM wygląda następująco:</p>
                    <ol>
                      <li>Zamknąć uchwyt i sprawdzić czy opór przepływu wynosi 0 a przepływ pompy 8 l/min.</li>
                      <li>Otworzyć uchwyt i umieścić maskę w pierwszym punkcie pomiarowym.</li>
                      <li>W trybie automatycznym aplikacja sama odczyta wartość oporów przepływu.</li>
                      <li>Powtórzyć pomiar dla 4 kolejnych punktów pomiarowych na masce.</li>
                    </ol>
                    <div className="back-link">
                      <TopLink to={RoutePath.SERVICES}>Powrót do wszystkich usług</TopLink>
                    </div>
                  </TextBlock>
                </PostBlock>
              </Col>
            </Row>
          </Grid>
        </Container>
      </section>
    </>
  );
};

export default RealizationMikroklimat;

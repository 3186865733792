import React from "react";
import "./box-link.scss";
import {useDispatch} from "react-redux";
import {showSlideOverlay} from "../../redux/callbacksState/callbacksState.actions";
import ArrRightImg from "./arrRight.svg";

interface Props {
  to: string;
  children: React.ReactNode | string;
  ref?: any;
  className?: string;
  title?: string;
  noSlide?: boolean;
  image?: React.ReactNode;
  dateOfPublishing?: string;
}

const BoxLink: React.FC<Props> = (props: Props) => {
  const dispatch = useDispatch();

  const slideAndScroll = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    e.preventDefault();
    if (!props.noSlide) {
      dispatch(showSlideOverlay(props.to as string));
    }
    // setTimeout(() => {
    //   window.scrollTo(0, 0)
    // }, 200);
  };

  return (
    <div className="box-link-wrapper">
      <a
        href={props.to}
        title={props.title ? props.title : ""}
        onClick={(e) => slideAndScroll(e)}
        className={`box-link${props.className ? " " + props.className : ""}`}
      >
        <div>
          {props.image}
        </div>
        {props.children}
        <img src={ArrRightImg} className="icon-arrow" alt="arrow right"/>
      </a>
      <div>
        <br/>
        {props.dateOfPublishing !== undefined ? "Data publikacji: " + props.dateOfPublishing : null}
        <br/>
      </div>
    </div>
  );
};

export default BoxLink;

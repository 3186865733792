import React from "react";
import SplitBlock from "../../../components/split-block/split-block";
import TitleBlock from "../../../components/title-block/title-block";
import {RoutePath} from "../../../route-paths";
import SplitSection from "../../../sections/split-section/split-section";
import HeroImg from "./../../../assets/heroImg.jpg";
import ExposureImg from "./../../../assets/exposure.png";
import TargiImg from "./../../../assets/targi.png";
import SzkoleniaImg from "./../../../assets/szkolenia.png";

const SplitSectionWrapper: React.FC = () => {

  return (
    <SplitSection titleBlock={
      <TitleBlock title="Kompleksowe usługi dla laboratoriów"
                  subtitle="Uzyskasz u nas wsparcie na każdym etapie – od wyboru sprzętu, przez jego montaż,
                  po użytkowanie. Jeśli na rynku nie ma produktu, który spełniałby Twoje oczekiwania, możemy go wyprodukować."/>
    }>
      <SplitBlock img={SzkoleniaImg} to={RoutePath.SERVICES__PRODUKCJA} toLabel="Zobacz ofertę" title="Doradztwo produktowe" reversed>
        Opowiedz nam o swoim celu badawczym, a my pomożemy Ci dobrać urządzenia, które odpowiedzą na Twoje potrzeby i
        będą zgodne z wszelkimi obowiązującymi normami.
      </SplitBlock>
      <SplitBlock img={ExposureImg} to={RoutePath.SERVICES__SZKOLENIA} toLabel="Dowiedz się więcej" title="Szkolenia">
        Przeszkolimy Twój zespół z obsługi urządzeń laboratoryjnych i pomiarów oraz zapewniamy telefoniczne i
        mailowe wsparcie merytoryczne w tym zakresie.
      </SplitBlock>
      <SplitBlock img={TargiImg} to={RoutePath.SERVICES__PRODUKCJA} toLabel="Skontaktuj się z nami" title="Współpraca badawcza" reversed>
        Masz pomysł na produkt, którego jeszcze nie ma na rynku? Pomożemy Ci wcielić go w życie. Współpracujemy
        z jednostkami badawczymi i uczelniami.
      </SplitBlock>
    </SplitSection>
  )
}

export default SplitSectionWrapper;
import React from "react";
import { ProductContentDto, ProductDto } from "../../../../dto/productDto";
import { ProductTileDto } from "../../../../dto/productTileDto";
import { RoutePath } from "../../../../route-paths";

import TextBlock from "../../../../components/writing-tools/text-block/text-block";
import TitleBlock from "../../../../components/title-block/title-block";
import LinkWithIconWrapper from "../../../../components/link-with-icon/link-with-icon-wrapper";
import LinkWithIcon from "../../../../components/link-with-icon/link-with-icon";
import PdfIcon from "../../../../components/pdf-icon/pdf-icon";
import EHASTM1 from "./../../../../assets/products/badanie_masek_medycznych/ehastm.png";
import EHASTM1Side1 from "./../../../../assets/products/badanie_masek_medycznych/ehastmside1.png";
import EHASTM1Side2 from "./../../../../assets/products/badanie_masek_medycznych/ehastmside2.png";
import BFE from "./../../../../assets/products/badanie_masek_medycznych/BFE.png";
import BFEBrochure from "./../../../../assets/products/badanie_masek_medycznych/Bacterial Filteration Efficiency.pdf";
import EkohigienaLogo from "./../../../../assets/products/mierniki_mikroklimatu/logoEkohigiena.svg";
import ChLogo from "./../../../../assets/products/ch-technologies.png";

import RelatedProducts from "../../../../components/RelatedProducts/RelatedProducts";

export const ProductsBadanieMasekMedycznych = [
  {
    tile: {
      title: "EHA STM1",
      subtitle: "Producent: Ekohigiena Aparatura",
      price: "",
      img: EHASTM1,
      to: RoutePath.PRODUCT,
    } as ProductTileDto,
    url: "eha-stm1",
    content: {
      images: [EHASTM1Side1, EHASTM1Side2, EkohigienaLogo],
      body: (
        <TextBlock>
          <TitleBlock title="Stanowisko do badania oddychalności EHA STM1" smallPaddingTop />
          <hr />
          <h4>
            System rozcieńczania DIL 554 do statycznego rozcieńczania (1:100) aerozoli przy statycznym natężeniu przepływu powietrza (28,3 l/min) do pracy mobilnej
            <br />
            <br />
          </h4>

          <p>
            Głównym zastosowaniem Aerosol Dilution System DIL 554 jest weryfikacja aerozoli testowych do badania pomieszczeń czystych.
            <br />
            <br />
            Norma EN ISO 14644-3 określa konieczność stosowania odpowiednich układów rozcieńczania podczas używania aerozoli testowych do weryfikacji. Zgodnie z normą VDI 2083 do badania pomieszczeń czystych i stołów roboczych obowiązkowe jest wykonywanie pomiarów przy podwyższonych stężeniach w doprowadzanym aerozolu.
            <br />
            <br />
          </p>

          <h4>Zalety</h4>
          <li>przeznaczony do użytku mobilnego (nie wymaga dodatkowego sprężonego powietrza i dodatkowego wywiewu)</li>
          <li>zasilany bateryjnie ze wskaźnikiem stanu (opcjonalnie z zasilaczem)</li>
          <li>stałe i powtarzalne rozcieńczanie aerozolu nawet w zmiennych warunkach pracy</li>
          <li>ciągła kontrola i wyświetlanie aktualnego współczynnika rozcieńczenia</li>
          <li>długotrwała żywotność i niezawodność, wymagana minimalna konserwacja</li>
          <li>precyzyjna praca zarówno w trybie nad i pod ciśnieniem</li>
          <br />
          <br />

          <h4>Zastosowania</h4>
          <li>walidacja komór z laminarnym przepływem powietrza oraz pomieszczeń/środowisk czystych</li>
          <li>ocena skuteczności filtracji</li>
          <li>pomiar silnie stężonych aerozoli</li>
          <li>badania aerozoli</li>
          <br />
          <br />

          <h4>Zasada działania</h4>
          <p>Próbka ze strumienia aerozolu do rozcieńczenia przepuszczana jest przez cienką kapilarę. Stężenie cząstek w natężeniu przepływu kapilarnego pozostaje niezmienione, podczas gdy filtr HEPA usuwa prawie wszystkie cząstki z strumienia omijającego. Po kapilarze i filtrze oba przepływy są ponownie mieszane. Stosunek rozcieńczenia wynika ze stosunku dwóch pojedynczych szybkości przepływu.</p>
        </TextBlock>
      ),
      tabs: [
        {
          name: "Specifikacja",
          body: (
            <TextBlock>
              <table>
                <tbody>
                  <tr>
                    <td>Współczynnik rozcieńczenia</td>
                    <td>1:100</td>
                  </tr>
                  <tr>
                    <td>Przepływ objętościowy</td>
                    <td>28,3 L/min</td>
                  </tr>
                  <tr>
                    <td>Metoda regulacji</td>
                    <td>manualna</td>
                  </tr>
                  <tr>
                    <td>Zasilanie</td>
                    <td>9 V zasilanie bateryjne (opcjonalny zasilacz)</td>
                  </tr>
                  <tr>
                    <td>Średnica króćca wlotowego i wylotowego</td>
                    <td>10 mm</td>
                  </tr>
                  <tr>
                    <td>Spadek ciśnienia</td>
                    <td>~ 0,6 kPa</td>
                  </tr>
                  <tr>
                    <td>Warunki środowiskowe, temperatura</td>
                    <td>10 - 50 °C</td>
                  </tr>
                  <tr>
                    <td>Warunki środowiskowe, maksymalne podciśnienie</td>
                    <td>30 kPa</td>
                  </tr>
                </tbody>
              </table>
            </TextBlock>
          ),
        },
        {
          name: "Do pobrania",
          body: (
            <TextBlock>
              <LinkWithIconWrapper>{/*<LinkWithIcon icon={<PdfIcon/>} to={DDS560_ulotka}>Ulotka</LinkWithIcon>*/}</LinkWithIconWrapper>
            </TextBlock>
          ),
        },
        {
          name: "Produkty Powiązane",
          body: <RelatedProducts items={["scan-air", "scan-air-pro"]} />,
        },
      ],
    } as ProductContentDto,
  },
  {
    tile: {
      title: "BFE Test Rig",
      subtitle: "Producent: CH Technologies",
      price: "",
      img: BFE,
      to: RoutePath.PRODUCT,
    } as ProductTileDto,
    url: "bfe",
    content: {
      images: [ChLogo],
      body: (
        <TextBlock>
          <TitleBlock title="Bacterial Filtration Efficiency (BFE) Testing System" smallPaddingTop />
          <hr />
          <p>
            Opracowane przez CH Technologies urządzenie do testowania skuteczności filtrów bakteryjnych (BFE) umożliwia badanie materiałów i filtrów medycznych masek twarzowych zgodnie z normami ASTM 2101, IS 16289 i EN 14683 przy użyciu Staphylococcus Aureus. Ten system zapewnia ilościową metodę określania skuteczności filtrowania materiałów masek z maksymalną skutecznością filtracji 99,9%.
            <br />
            <br />
          </p>

          <p>
            Dostępne w konfiguracjach kolumn szklanych lub ze stali nierdzewnej. BLAM jest wybranym generatorem aerozolu dla tego stanowiska testowego, który umożliwia dostrajalny rozkład wielkości w celu uzyskania bioaerozolu o przeciętnej średnicy 3,0 mikronów.
            <br />
            <br />
          </p>

          <p>
            Więcej informacji na temat tego systemu można znaleźć w następującej publikacji.
            <br />
          </p>

          <p>
            Parthasarathi Kalaiselvan, Shyam Sunder Tummanapalli, Ajay Kumar Vijay, Prateek Bahl, Chandini Raina MacIntyre & Mark D P Willcox. The ability of face masks to reduce the transmission of microbes. Zaakceptowano 13 sierpnia 2021, opublikowano online 30 września 2021.
            <br />
            <a href={"https://doi.org/10.1080/08164622.2021.1971050"}>https://doi.org/10.1080/08164622.2021.1971050</a>
          </p>
        </TextBlock>
      ),
      tabs: [
        {
          name: "Specifikacja",
          body: (
            <TextBlock>
              <table>
                <tbody>
                  <tr>
                    <td>Impaktor</td>
                    <td>6-cio stopniowy impaktor Andersona</td>
                  </tr>
                  <tr>
                    <td>Generator aerozolu</td>
                    <td>Czterodyszowy generator BLAM zapewniający aerozol o średnicy 3.0 ± 0.3μm</td>
                  </tr>
                  <tr>
                    <td>Kolumna na aerozol</td>
                    <td>Prism Research Glass o długości 60 cm i średnicy 8 cm</td>
                  </tr>
                  <tr>
                    <td>Pompa strzykawkowa</td>
                    <td>New Era (N-300) do 42,8 ml/min, może pompować z prędkością 0,01 ml/min</td>
                  </tr>
                  <tr>
                    <td>Regulacja ciśnienia</td>
                    <td>0-100 psi (0-700 kPa)</td>
                  </tr>
                  <tr>
                    <td>Pompa próżniowa</td>
                    <td>Obrotowa łopatkowa pompa próżniowa (GAST101Q), 2,7 CFM (76 l/min) przy podciśnieniu 20” Hg</td>
                  </tr>
                </tbody>
              </table>
            </TextBlock>
          ),
        },
        {
          name: "Do pobrania",
          body: (
            <TextBlock>
              <LinkWithIconWrapper>
                <LinkWithIcon icon={<PdfIcon />} to={BFEBrochure}>
                  Ulotka
                </LinkWithIcon>
              </LinkWithIconWrapper>
            </TextBlock>
          ),
        },
      ],
    } as ProductContentDto,
  },
] as Array<ProductDto>;

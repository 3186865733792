import React from "react";
import Container from "../../components/container/container";
import Col from "../../components/grid/col";
import Grid from "../../components/grid/grid";
import Row from "../../components/grid/row";
import "./blog-section.scss";

interface Props {
  title?: React.ReactNode;
  children: React.ReactNode;
  className?: string;
}

const BlogSection: React.FC<Props> = (props: Props) => {
  const { title, children, className } = props;

  return (
    <section className={`blog-section ${className ? className : ""}`}>
      <Container>
        {title}
        <Grid>
          <Row gap={16} className="blog-section__content">
            <Col size={[8, 10, 12]} offset={[2, 1, 0]}>
              {children}
            </Col>
          </Row>
        </Grid>
      </Container>
    </section>
  );
};

export default BlogSection;

import React from "react";
import Container from "../../components/container/container";
import Col from "../../components/grid/col";
import Grid from "../../components/grid/grid";
import Row from "../../components/grid/row";
import "./partners.scss";
import TitleBlock from "../../components/title-block/title-block";
import Partners from "./partners";

interface PartnersSectionProps {
  hideTitle?: boolean;
}

const PartnersSection: React.FC<PartnersSectionProps> = (props: PartnersSectionProps) => {
  return (
    <section className={`partners-section ${props.hideTitle ? 'pt-5' : ''}`}>
      <Container className={`${props.hideTitle ? 'pt-5' : ''}`}>
        {props.hideTitle ? null :
          <TitleBlock title="Partnerzy"
            subtitle="Współpracujemy z doświadczonymi, sprawdzonymi dostawcami sprzętu i technologii z
        całego świata, często na wyłączność."/>
        }
        <Grid>
          <Row gap={16}>
            <Col size={[8, 10, 12]} offset={[2, 1, 0]}>
                <Partners />
            </Col>
          </Row>
        </Grid>
      </Container>
    </section>
  )
}

export default PartnersSection;
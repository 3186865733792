import React from "react";
import { ProductContentDto, ProductDto } from "../../../../dto/productDto";
import { ProductTileDto } from "../../../../dto/productTileDto";
import { RoutePath } from "../../../../route-paths";

import TextBlock from "../../../../components/writing-tools/text-block/text-block";
import TitleBlock from "../../../../components/title-block/title-block";
import LinkWithIconWrapper from "../../../../components/link-with-icon/link-with-icon-wrapper";
import LinkWithIcon from "../../../../components/link-with-icon/link-with-icon";
import PdfIcon from "../../../../components/pdf-icon/pdf-icon";
import TopasLogo from "./../../../../assets/products/topas-logo.svg";
import DIL554S from "./../../../../assets/products/dilutery/P0448H01-DIL554-S-TOPAS.jpeg";
import DIL554R from "./../../../../assets/products/dilutery/P0448H02-DIL554-R-TOPAS.jpeg";
import DDS560 from "./../../../../assets/products/dilutery/dds_560.jpg";
import DDS560_ulotka from "./../../../../assets/products/dilutery/dds_560_ulotka.pdf";

import RelatedProducts from "../../../../components/RelatedProducts/RelatedProducts";

export const ProductsDilutery = [
  {
    tile: {
      title: "DIL 554",
      subtitle: "Producent: Topas",
      price: "",
      img: DIL554S,
      to: RoutePath.PRODUCT,
    } as ProductTileDto,
    url: "dil554",
    content: {
      images: [DIL554R, TopasLogo],
      body: (
        <TextBlock>
          <TitleBlock title="Diluter DIL 554" smallPaddingTop />
          <hr />
          <h4>
            System rozcieńczania DIL 554 do statycznego rozcieńczania (1:100) aerozoli przy statycznym natężeniu przepływu powietrza (28,3 l/min) do pracy mobilnej
            <br />
            <br />
          </h4>

          <p>
            Głównym zastosowaniem Aerosol Dilution System DIL 554 jest weryfikacja aerozoli testowych do badania pomieszczeń czystych.
            <br />
            <br />
            Norma EN ISO 14644-3 określa konieczność stosowania odpowiednich układów rozcieńczania podczas używania aerozoli testowych do weryfikacji. Zgodnie z normą VDI 2083 do badania pomieszczeń czystych i stołów roboczych obowiązkowe jest wykonywanie pomiarów przy podwyższonych stężeniach w doprowadzanym aerozolu.
            <br />
            <br />
          </p>

          <h4>Zalety</h4>
          <li>przeznaczony do użytku mobilnego (nie wymaga dodatkowego sprężonego powietrza i dodatkowego wywiewu)</li>
          <li>zasilany bateryjnie ze wskaźnikiem stanu (opcjonalnie z zasilaczem)</li>
          <li>stałe i powtarzalne rozcieńczanie aerozolu nawet w zmiennych warunkach pracy</li>
          <li>ciągła kontrola i wyświetlanie aktualnego współczynnika rozcieńczenia</li>
          <li>długotrwała żywotność i niezawodność, wymagana minimalna konserwacja</li>
          <li>precyzyjna praca zarówno w trybie nad i pod ciśnieniem</li>
          <br />
          <br />

          <h4>Zastosowania</h4>
          <li>walidacja komór z laminarnym przepływem powietrza oraz pomieszczeń/środowisk czystych</li>
          <li>ocena skuteczności filtracji</li>
          <li>pomiar silnie stężonych aerozoli</li>
          <li>badania aerozoli</li>
          <br />
          <br />

          <h4>Zasada działania</h4>
          <p>Próbka ze strumienia aerozolu do rozcieńczenia przepuszczana jest przez cienką kapilarę. Stężenie cząstek w natężeniu przepływu kapilarnego pozostaje niezmienione, podczas gdy filtr HEPA usuwa prawie wszystkie cząstki z strumienia omijającego. Po kapilarze i filtrze oba przepływy są ponownie mieszane. Stosunek rozcieńczenia wynika ze stosunku dwóch pojedynczych szybkości przepływu.</p>
        </TextBlock>
      ),
      tabs: [
        {
          name: "Specifikacja",
          body: (
            <TextBlock>
              <table>
                <tbody>
                  <tr>
                    <td>Współczynnik rozcieńczenia</td>
                    <td>1:100</td>
                  </tr>
                  <tr>
                    <td>Przepływ objętościowy</td>
                    <td>28,3 L/min</td>
                  </tr>
                  <tr>
                    <td>Metoda regulacji</td>
                    <td>manualna</td>
                  </tr>
                  <tr>
                    <td>Zasilanie</td>
                    <td>9 V zasilanie bateryjne (opcjonalny zasilacz)</td>
                  </tr>
                  <tr>
                    <td>Średnica króćca wlotowego i wylotowego</td>
                    <td>10 mm</td>
                  </tr>
                  <tr>
                    <td>Spadek ciśnienia</td>
                    <td>~ 0,6 kPa</td>
                  </tr>
                  <tr>
                    <td>Warunki środowiskowe, temperatura</td>
                    <td>10 - 50 °C</td>
                  </tr>
                  <tr>
                    <td>Warunki środowiskowe, maksymalne podciśnienie</td>
                    <td>30 kPa</td>
                  </tr>
                </tbody>
              </table>
            </TextBlock>
          ),
        },
        {
          name: "Do pobrania",
          body: (
            <TextBlock>
              <LinkWithIconWrapper>{/*<LinkWithIcon icon={<PdfIcon/>} to={DDS560_ulotka}>Ulotka</LinkWithIcon>*/}</LinkWithIconWrapper>
            </TextBlock>
          ),
        },
        {
          name: "Produkty Powiązane",
          body: <RelatedProducts items={["scan-air", "scan-air-pro"]} />,
        },
      ],
    } as ProductContentDto,
  },
  {
    tile: {
      title: "DDS 560",
      subtitle: "Producent: Topas",
      price: "",
      img: DDS560,
      to: RoutePath.PRODUCT,
    } as ProductTileDto,
    url: "dds560",
    content: {
      images: [TopasLogo],
      body: (
        <TextBlock>
          <TitleBlock title="Diluter DDS 560" smallPaddingTop />
          <hr />
          <h4>
            Dynamiczny system rozcieńczania DDS 560 do regulowanego rozcieńczania aerozoli przy zmiennym natężeniu przepływu powietrza
            <br />
            <br />
          </h4>

          <p>
            System dynamicznego rozcieńczania DDS 560 jest przeznaczony do dokładnego i powtarzalnego rozcieńczania aerozoli zgodnie z normą EN ISO 14644 część 3 z możliwością dostosowania współczynnika rozcieńczenia. <br />
            <br />
          </p>

          <p>
            Ustawiony współczynnik rozcieńczenia jest pokazywany na wyświetlaczu. Według normy VDI 3491-15, cząstki są usuwane z próbki zgodnie z określonym stosunkiem rozcieńczenia – rozkład wielkości cząstek pozostaje niezmieniony.
            <br />
            <br />
          </p>

          <p>
            Optyczne liczniki cząstek wykazują maksymalne mierzalne stężenie aerozolu. Jeśli ten limit zostanie przekroczony, nie można wykluczyć poważnych błędów. W przypadku testowania w pomieszczeniu czystym lub w komorze z przepływem laminarnym zgodnie z VDI 2083 wymagane są pomiary wysokich stężeń areozoli. Konwencjonalne liczniki cząstek do pomieszczeń czystych nie działają poprawnie przy tak wysokich stężeniach. Poprzez umieszczenie dynamicznego dilutora DDS 560 przed urządzeniem do pomiaru cząstek, stężenie aerozolu można zmniejszyć o współczynnik wybrany przez użytkownika. W ten sposób wymagane stężenie poprzedzające można rozcieńczyć do stężenia, które mieści się w zakresie pomiarowym licznika cząstek.
            <br />
            <br />
          </p>

          <h4>Zalety</h4>
          <li>płynna regulacja rozcieńczenia areozolu</li>
          <li>płynna regulacja przepływu</li>
          <li>monitorowanie i wizualizacja przepływu próbki i współczynnika rozcieńczenia</li>
          <li>zachowane są właściwości fazy gazowej aerozolu (np. wilgotność)</li>
          <li>samowystarczalny system (tj. brak konieczności doprowadzenia powietrza z zewnątrz, brak przepływu powietrza wywiewanego)</li>
          <br />
          <br />

          <h4>Zastosowania</h4>
          <li>walidacja pomieszczeń/środowisk czystych</li>
          <li>ocena skuteczności filtracji HEPA i ULPA</li>
          <li>weryfikacja mediów filtracyjnych</li>
          <li>weryfikacja generatorów aerozolu i rozpylaczy proszku</li>
          <li>badania aerozoli</li>
          <br />
          <br />

          <h4>Zasada działania</h4>
          <p>Próbka ze strumienia aerozolu do rozcieńczenia przepuszczana jest przez cienką kapilarę. Stężenie cząstek w natężeniu przepływu kapilarnego pozostaje niezmienione, podczas gdy filtr HEPA usuwa prawie wszystkie cząstki z strumienia omijającego. Po kapilarze i filtrze oba przepływy są ponownie mieszane. Stosunek rozcieńczenia wynika ze stosunku dwóch pojedynczych szybkości przepływu.</p>
        </TextBlock>
      ),
      tabs: [
        {
          name: "Specifikacja",
          body: (
            <TextBlock>
              <table>
                <tbody>
                  <tr>
                    <td>Współczynnik rozcieńczenia</td>
                    <td>płynna regulacja w zakresie od 1:5 do 1:370 w zależności od szybkości przepywu (szczegóły w ulotce)</td>
                  </tr>
                  <tr>
                    <td>Przepływ objętościowy</td>
                    <td>płynna regulacja w zakresie 0.5-3.0 l/min</td>
                  </tr>
                  <tr>
                    <td>Metoda regulacji</td>
                    <td>manualna</td>
                  </tr>
                  <tr>
                    <td>Zasilanie</td>
                    <td>12 V DC, 300 mA (przez zasilacz)</td>
                  </tr>
                  <tr>
                    <td>Średnica króćca wlotowego i wylotowego</td>
                    <td>8 mm</td>
                  </tr>
                  <tr>
                    <td>Warunki środowiskowe, maksymalne podciśnienie</td>
                    <td>35 kPa</td>
                  </tr>
                </tbody>
              </table>
            </TextBlock>
          ),
        },
        {
          name: "Do pobrania",
          body: (
            <TextBlock>
              <LinkWithIconWrapper>
                <LinkWithIcon icon={<PdfIcon />} to={DDS560_ulotka}>
                  Ulotka
                </LinkWithIcon>
              </LinkWithIconWrapper>
            </TextBlock>
          ),
        },
      ],
    } as ProductContentDto,
  },
] as Array<ProductDto>;

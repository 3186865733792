import React, {useRef} from "react";
import FullImgBlock from "../../../../components/fullImg-block/fullImg-block";
import Col from "../../../../components/grid/col";
import Row from "../../../../components/grid/row";
import PostBlock from "../../../../components/writing-tools/post-block/post-block";
import TextBlock from "../../../../components/writing-tools/text-block/text-block";
import PvExampleImgA from "../../../../assets/PvExampleImgA.jpg";
import PvExampleImgB from "../../../../assets/PvExampleImgB.jpg";
import Container from "../../../../components/container/container";
import Button from "../../../../components/button/button";
import Grid from "../../../../components/grid/grid";
import TitleBlock from "../../../../components/title-block/title-block";
import HeroSection from "../../../../sections/hero-section/hero-section";
import KalibracjaImg from "./../../../../assets/services/kalibracja/kalibracja.png";
import TopLink from "../../../../components/top-link/top-link";
import {RoutePath} from "../../../../route-paths";
import {BreadcrumbsItem} from "react-breadcrumbs-dynamic";

const KalibracjaPv: React.FC = () => {
  const heroTarget = useRef<any>();

  const handleScrollToSection = (section: React.MutableRefObject<any>) => {
    window.scrollTo(0, section.current!.getBoundingClientRect().top + window.pageYOffset);
  };

  return (
    <>
      <BreadcrumbsItem to={RoutePath.SERVICES__KALIBRACJA}>Kalibracja</BreadcrumbsItem>
      <HeroSection img={KalibracjaImg} alt="Example img">
        <h1>Kalibracja sprzętu</h1>
        <p>Okresowa kontrola i kalibracja niezbędna do prawidłowego funkcjonowania urządzeń pomiarowych</p>
        <Button onClick={() => handleScrollToSection(heroTarget)}>Dowiedz się wiecej</Button>
      </HeroSection>
      <section className="calibration-section" ref={heroTarget}>
        <TitleBlock title="Kalibracja aparatury"
                    subtitle="Przeprowadzamy okresową, najczęściej coroczną kontrolę działania aparatury pomiarowej."
                    smallPaddingTop/>
        <Container className="mt-xl-5 mt-4">
          <Grid>
            <Row gap={16}>
              <Col size={[8, 8, 10, 12]} offset={[2, 2, 1, 0]}>
                <PostBlock className="no-padding-top" size={24}>
                  <TextBlock>
                    <h2>Miarodajne pomiary</h2>
                    <p>Sprawdzamy dostarczane przez nas urządzenia, przeprowadzamy ich kalibrację oraz wzorcowanie w
                      akredytowanych laboratoriach wzorcujących. Kalibracja jest nie tylko zalecana, aby otrzymywać
                      miarodajne pomiary, ale w wielu przypadkach również obowiązkowa, by spełnić warunki ustawy.</p>
                  </TextBlock>
                </PostBlock>
                <PostBlock size={24}>
                  <TextBlock>
                    <h4>Na miejscu i z dojazdem</h4>
                    <p>Wystarczy przesłać nam sprzęt, a my dokonamy jego kalibracji u siebie w zakładzie. Istnieje
                      również możliwość dojazdu naszego serwisanta, co może sprawdzić się w przypadku większej liczby
                      urządzeń wymagających sprawdzenia.</p>
                  </TextBlock>
                </PostBlock>
                {/*<PostBlock size={24}>*/}
                {/*  <Row gap={16}>*/}
                {/*    <Col size={6}>*/}
                {/*      <FullImgBlock img={PvExampleImgA} alt="example img A"/>*/}
                {/*    </Col>*/}
                {/*    <Col size={6}>*/}
                {/*      <FullImgBlock img={PvExampleImgB} alt="example img B"/>*/}
                {/*    </Col>*/}
                {/*  </Row>*/}
                {/*</PostBlock>*/}

                <PostBlock size={24}>
                  <TextBlock>
                    <h4>Procedura kalibracji</h4>
                    <p>Urządzenia, aby działać sprawnie i dawać adekwatne wyniki, powinny być kontrolowane raz do roku.
                      Kontrola polega na porównaniu wskazań aparatury ze wzorcem pomiarowym. Procedura kalibracji,
                      zależnie od sprzętu, może obejmować:</p>
                    <ul>
                      <li>ustawienie punktów odniesienia</li>
                      <li>regulację przepływu powietrza</li>
                      <li>weryfikacja dokładności pomiarów</li>
                      <li>czyszczenie celi pomiarowych</li>
                      <li>sprawdzenie stanu poszczególnych części (rurki, filtry etc.)</li>
                    </ul>
                    <p>Kalibrację stosujemy prewencyjnie i aby spełnić wymagane normy – państwowe lub unijne. Natomiast
                      gdy sprzęt uległ uszkodzeniu lub nie działa właściwie, niezbędny jest jego
                      <TopLink className="back-link" to={RoutePath.SERVICES__SERWIS}> serwis</TopLink></p>
                    <div className="back-link">
                      <TopLink to={RoutePath.SERVICES}>Powrót do wszystkich usług</TopLink>
                    </div>
                  </TextBlock>
                </PostBlock>
              </Col>
            </Row>
          </Grid>
        </Container>
      </section>
    </>
  );
};

export default KalibracjaPv;

import React, {useRef} from "react";
import FullImgBlock from "../../../../components/fullImg-block/fullImg-block";
import Col from "../../../../components/grid/col";
import Row from "../../../../components/grid/row";
import PostBlock from "../../../../components/writing-tools/post-block/post-block";
import TextBlock from "../../../../components/writing-tools/text-block/text-block";
import Container from "../../../../components/container/container";
import Button from "../../../../components/button/button";
import Grid from "../../../../components/grid/grid";
import TitleBlock from "../../../../components/title-block/title-block";
import HeroSection from "../../../../sections/hero-section/hero-section";
import AspiratorImg from "./../../../../assets/services/serwis/aspirator-service.png";
import SerwisImg2 from "./../../../../assets/services/serwis/serwis2.png";
import SerwisImg3 from "./../../../../assets/services/serwis/serwis3.png";
import TopLink from "../../../../components/top-link/top-link";
import {RoutePath} from "../../../../route-paths";
import {BreadcrumbsItem} from "react-breadcrumbs-dynamic";

const SerwisPv: React.FC = () => {
  const heroTarget = useRef<any>();

  const handleScrollToSection = (section: React.MutableRefObject<any>) => {
    window.scrollTo(0, section.current!.getBoundingClientRect().top + window.pageYOffset);
  };

  return (
    <>
      <BreadcrumbsItem to={RoutePath.SERVICES__SERWIS}>Serwis</BreadcrumbsItem>
      <HeroSection img={AspiratorImg} alt="Serwis img">
        <h1>Serwis urządzeń</h1>
        <p>Zapewniamy serwis gwarancyjny i pogwarancyjny urządzeń pomiarowych znajdujących się w naszej ofercie.</p>
        <Button onClick={() => handleScrollToSection(heroTarget)}>Dowiedz się wiecej</Button>
      </HeroSection>
      <section className="services-section" ref={heroTarget}>
        <TitleBlock title="Naprawa sprzętu pomiarowego"
                    subtitle="Serwis oferowanych przez nas urządzeń – bez względu na to, czy zostały zakupione u nas"
                    smallPaddingTop/>
        <Container className="mt-xl-5 mt-4">
          <Grid>
            <Row gap={16}>
              <Col size={[8, 8, 10, 12]} offset={[2, 2, 1, 0]}>
                <PostBlock className="no-padding-top" size={24}>
                  <TextBlock>
                    <h2>Serwis gwarancyjny i pogwarancyjny</h2>
                    <p>W czasie trwania gwarancji zapewniamy bezpłatny serwis aparatury pomiarowej. Przeprowadzamy
                      również naprawę urządzeń po tym czasie – także tych, które nie zostały zakupione u nas.</p>
                  </TextBlock>
                </PostBlock>
                <PostBlock size={24}>
                  <TextBlock>
                    <h4>Wybierz najwygodniejszą opcję</h4>
                    <p>Wystarczy przesłać nam uszkodzony lub niedziałający sprzęt, a my dokonamy serwisu u siebie w
                      zakładzie. Istnieje również możliwość dojazdu, co może być wygodniejsze w przypadku aparatury o
                      większych gabarytach lub większej liczby urządzeń wymagających naprawy.</p>
                  </TextBlock>
                </PostBlock>
                <PostBlock size={24}>
                  <Row gap={16}>
                    <Col size={6}>
                      <FullImgBlock img={SerwisImg2} alt="serwis2"/>
                    </Col>
                    <Col size={6}>
                      <FullImgBlock img={SerwisImg3} alt="serwis3"/>
                    </Col>
                  </Row>
                </PostBlock>
                <PostBlock size={24}>
                  <TextBlock>
                    <h4>Oryginalne części</h4>
                    <p>Do naprawy wykorzystujemy wyłącznie oryginalne części lub te rekomendowane przez producentów. W
                      stałej ofercie posiadamy części eksploatacyjne, akumulatory oraz akcesoria pomiarowe, co pozwala
                      skrócić czas serwisu do minimum. Wyróżnia nas:</p>
                    <ul>
                      <li>oryginalne części lub te dostarczone przez producenta</li>
                      <li>jesteśmy jedynym rekomendowanym serwisantem marek Lighthouse, Topaz i Sensidyne</li>
                      <li>nasz serwis jest objęty miesięczną gwarancją</li>
                      <li>oferujemy serwis u nas lub z dojazdem</li>
                      <li>naprawiamy również aparaturę, która nie została zakupiona u nas</li>
                    </ul>
                    <p>Jeśli interesuje Cię okresowa konserwacja sprzętu i jego kalibracja – przejdź do zakładki
                      <TopLink className="back-link" to={RoutePath.SERVICES__KALIBRACJA}> kalibracja</TopLink></p>
                    <div className="back-link">
                      <TopLink to={RoutePath.SERVICES}>Powrót do wszystkich usług</TopLink>
                    </div>
                  </TextBlock>
                </PostBlock>
              </Col>
            </Row>
          </Grid>
        </Container>
      </section>
    </>
  );
};

export default SerwisPv;

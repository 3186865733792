import React, { useEffect, useState } from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { generatePath, useParams } from "react-router-dom";
import BoxLink from "../../../components/box-link/box-link";
import Container from "../../../components/container/container";
import Col from "../../../components/grid/col";
import Grid from "../../../components/grid/grid";
import Row from "../../../components/grid/row";
import TitleBlock from "../../../components/title-block/title-block";
import TextBlock from "../../../components/writing-tools/text-block/text-block";
import { CategoryDto } from "../../../dto/categoryDto";
import { UsageDto } from "../../../dto/usageDto";
import { RoutePath } from "../../../route-paths";
import { usageData } from "../data/usages-data";

const CategoriesSection: React.FC = () => {
  let { usageId } = useParams<{ usageId: string }>();
  const [usage, setUsage] = useState<UsageDto>();

  useEffect(() => {
    const item = usageData.find((x) => x.url === usageId);
    setUsage(item);
  }, [usageId]);

  return (
    <section className="categories-section">
      <BreadcrumbsItem to={RoutePath.CATEGORIES}>Produkty</BreadcrumbsItem>

      {usageId && usage ? (
        <>
          <TitleBlock title={usage.tile.title} subtitle={usage.tile.subtitle} />
          <BreadcrumbsItem to={generatePath(RoutePath.USAGE, { usageId: usageId })}>{usage.tile.title}</BreadcrumbsItem>
          <Container className="categories-nav">
            <Grid>
              <Row>
                <Col size={[8, 8, 10, 12]} offset={[2, 2, 1, 0]}>
                  <TextBlock>{usage?.content}</TextBlock>
                </Col>
              </Row>
            </Grid>
          </Container>
        </>
      ) : (
        <TitleBlock title="Zobacz wszystkie produkty według zastosowania" subtitle="" />
      )}
      <Container className="categories-nav">
        <Grid>
          <Row gap={16}>
            <Col size={[8, 8, 10, 12]} offset={[2, 2, 1, 0]}>
              {usage ? (
                <>
                  {usage.categories.map((x: CategoryDto, index: number) => {
                    return (
                      <BoxLink key={index} to={generatePath(RoutePath.CATEGORY, { usageId: usage.url, categoryId: x.url })}>
                        {x.tile.title}
                      </BoxLink>
                    );
                  })}
                </>
              ) : (
                usageData.map((usage: UsageDto, index: number) => {
                  return (
                    <BoxLink key={index} to={generatePath(RoutePath.USAGE, { usageId: usage.url })}>
                      {usage.tile.title}
                    </BoxLink>
                  );
                })
              )}
            </Col>
          </Row>
        </Grid>
      </Container>
    </section>
  );
};

export default CategoriesSection;

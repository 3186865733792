import React from "react";
import HeroSectionWrapper from "./sections/hero-section-wrapper";
import "./example-post-page.scss";
import BlogPostNowaNormaSection from "./sections/blog-post-nowa-norma";
import {RoutePath} from "../../../route-paths";
import {BreadcrumbsItem} from "react-breadcrumbs-dynamic";

const BlogPostNowaNorma: React.FC = () => {

  return (
    //TODO: CLEAN UP SECTIONS, RENAME NOWA NORMA
    <div className="example-post-page">
      <BreadcrumbsItem to={RoutePath.BLOG__DOKUMENTACJA_DO_GLOWIC}>Dokumentacja do głowic do poboru pyłu</BreadcrumbsItem>
      <HeroSectionWrapper />
      <BlogPostNowaNormaSection />
    </div>
  );
};

export default BlogPostNowaNorma;

import React from "react";
import { ProductContentDto, ProductDto } from "../../../../dto/productDto";
import { ProductTileDto } from "../../../../dto/productTileDto";
import { RoutePath } from "../../../../route-paths";

import TextBlock from "../../../../components/writing-tools/text-block/text-block";
import TitleBlock from "../../../../components/title-block/title-block";
import LinkWithIconWrapper from "../../../../components/link-with-icon/link-with-icon-wrapper";
import SvantekLogo from "./../../../../assets/partnerSvantek.svg";
import SV971Img from "./../../../../assets/products/mierniki_poziomu_dzwieku/svan-971.jpeg";
import SV971Img2 from "./../../../../assets/products/mierniki_poziomu_dzwieku/971_app_4.jpeg";
import SV971Img3 from "./../../../../assets/products/mierniki_poziomu_dzwieku/svan_971_sound_level_meter-114067920870_57594300.jpeg";
import SV971Brochure from "./../../../../assets/products/mierniki_poziomu_dzwieku/971_PL_internet.pdf";

import LinkWithIcon from "../../../../components/link-with-icon/link-with-icon";
import PdfIcon from "../../../../components/pdf-icon/pdf-icon";

export const ProductsMiernikiPoziomuDzwieku = [
  {
    tile: {
      title: "SV 971",
      subtitle: "Producent: Svantek",
      price: "",
      img: SV971Img,
      to: RoutePath.PRODUCT,
    } as ProductTileDto,
    url: "sv-971",
    content: {
      images: [SV971Img2, SV971Img3, SvantekLogo],
      body: (
        <TextBlock>
          <TitleBlock title="Miernik poziomu dźwięku SV 971" smallPaddingTop />
          <hr />
          <p>SVAN 971 jest cyfrowym analizatorem i miernikiem poziomu dźwięku klasy 1 o niezwykle małych rozmiarach. Niewielkie rozmiary oraz mała waga przyrządu ułatwia prowadzenie zarówno krótkotrwałych pomiarów hałasu w środowisku a także na stanowiskach pracy. Zastosowanie kolorowego wyświetlacza o dużym kontraście zapewnia łatwy odczyt wyników pomiarów. Dzięki rozbudowanemu oprogramowaniu wewnętrznemu miernik jest gotów do prowadzenia pomiarów bez konieczności wykonywania skomplikowanych ustawień. Dzięki swojej nowoczesnej konstrukcji, SVAN 971 daje możliwość wykonania jednoczesnego pomiaru w trzech niezależnych profilach, dla których można niezależnie ustawić: filtr korekcyjny i stałą czasowa. Dla każdego profilu możliwe jest zapamiętanie historii czasowej pomiaru z ustalonym krokiem zapisu od 100 milisekund. Przyrząd umożliwia wykonanie analizy w pasmach oktawowych i tercjowych w czasie pomiaru poziomu dźwięku. Dostępna w mierniku funkcja nagrywania zdarzeń audio, w połączeniu z zapisem historii czasowej umożliwia identyfikację niepożądanych zdarzeń akustycznych zarejestrowanych przez urządzenie. Wyniki zapamiętane na karcie microSD mogą być łatwo przenoszone do komputera za pomocą programu SvanPC++. Oprogramowanie to umożliwia wizualizację danych i ich transfer do arkusza kalkulacyjnego lub dokumentu tekstowego. Oprogramowanie miernika zapewnia automatyczne przeprowadzenie kalibracji toru pomiarowego. Kalibracja miernika została zautomatyzowana. Po nałożeniu kalibratora na mikrofon miernik samoczynnie rozpoznaje i prowadzi proces kalibracji.</p>
        </TextBlock>
      ),
      tabs: [
        {
          name: "Specifikacja",
          body: (
            <TextBlock>
              <table>
                <tbody>
                  <tr>
                    <td>Filtry korekcyjne</td>
                    <td>A, B, C, Z</td>
                  </tr>
                  <tr>
                    <td>Stałe czasowe</td>
                    <td>Slow, Fast, Impulse</td>
                  </tr>
                  <tr>
                    <td>Detektor RMS</td>
                    <td>Cyfrowy z detekcją szczytu, rozdzielczość: 0,1 dB stałe czasowe: Slow, Fast, Impulse</td>
                  </tr>
                  <tr>
                    <td>Mikrofon</td>
                    <td>typu 7052, czułość 35 mV/Pa, prepolaryzowany 1/2”</td>
                  </tr>
                  <tr>
                    <td>Kalibracja</td>
                    <td>Automatyczna kalibracja @ 114 dB/1 kHz</td>
                  </tr>
                  <tr>
                    <td>Zakres liniowości</td>
                    <td>25 dBA RMS - 140 dBA Peak (zgodnie z normą IEC 61672)</td>
                  </tr>
                  <tr>
                    <td>Zakres dynamiczny</td>
                    <td>15 dBA RMS - 140 dBA Peak (od poziomu szumów do wartości maksymalnej)</td>
                  </tr>
                  <tr>
                    <td>Poziom szumów</td>
                    <td>Poniżej 15 dBA RMS</td>
                  </tr>
                  <tr>
                    <td>Zakres dynamiczny</td>
                    <td>&gt; 110 dB</td>
                  </tr>
                  <tr>
                    <td>Zakres częstotliwości</td>
                    <td>10 Hz - 20 kHz</td>
                  </tr>
                  <tr>
                    <td>Wyniki mierzone</td>
                    <td>SPL, Leq, SEL, Lden, Ltm3, Ltm5, LMax, LMin, LPeak Jednoczesny pomiar w trzech profilach (niezależnie ustawiane filtry i stałe czasowe)</td>
                  </tr>
                  <tr>
                    <td>Statystyki</td>
                    <td>Ln (L1-L99), w trybie miernika, histogram w pełnym zakresie</td>
                  </tr>
                  <tr>
                    <td>Interfejsy</td>
                    <td>USB 2.0 RS 232 (opcja)</td>
                  </tr>
                  <tr>
                    <td>Zasilanie</td>
                    <td>Cztery baterie AAA (alkaliczne) lub akumulatory NiMH czas pracy 16 – 24 godzin Interfejs USB 100 mA HUB</td>
                  </tr>
                  <tr>
                    <td>Zewnętrzne warunki pracy</td>
                    <td>
                      Temperatura od -10 oC do 50 oC
                      <br />
                      Wilgotność do 95 % wilgotności względnej bez kondensacji
                    </td>
                  </tr>
                  <tr>
                    <td>Wymiary</td>
                    <td>232,5 mm x 56 x 20 mm (z mikrofonem i przedwzmacniaczem)</td>
                  </tr>
                  <tr>
                    <td>Waga</td>
                    <td>225 g z bateriami</td>
                  </tr>
                </tbody>
              </table>
            </TextBlock>
          ),
        },
        {
          name: "Do pobrania",
          body: (
            <TextBlock>
              <LinkWithIconWrapper>
                <LinkWithIcon icon={<PdfIcon />} to={SV971Brochure}>
                  Ulotka
                </LinkWithIcon>
              </LinkWithIconWrapper>
            </TextBlock>
          ),
        },
      ],
    } as ProductContentDto,
  },
] as Array<ProductDto>;

import React from "react";
import FullImgBlock from "../../../../components/fullImg-block/fullImg-block";
import Col from "../../../../components/grid/col";
import Row from "../../../../components/grid/row";
import PostBlock from "../../../../components/writing-tools/post-block/post-block";
import TextBlock from "../../../../components/writing-tools/text-block/text-block";
import PvExampleImgA from "../../../../assets/PvExampleImgA.jpg";
import PvExampleImgB from "../../../../assets/PvExampleImgB.jpg";
import Container from "../../../../components/container/container";
import Grid from "../../../../components/grid/grid";
import HeroSection from "../../../../sections/hero-section/hero-section";
import TopLink from "../../../../components/top-link/top-link";
import {RoutePath} from "../../../../route-paths";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";

const RealizationMikroklimat: React.FC = () => {
  return (
    <>
      <BreadcrumbsItem to={RoutePath.SERVICES__PRODUKCJA}>Produkcja</BreadcrumbsItem>
      <BreadcrumbsItem to={RoutePath.SERVICES__PRODUKCJA__MIERNIKI_MIKROKLIMATU}>Mierniki mikroklimatu</BreadcrumbsItem>
      <HeroSection>
        <h1>Mierniki mikroklimatu</h1>
      </HeroSection>
      <section className="services-section">
        <Container className="mt-xl-5 mt-4">
          <Grid>
            <Row gap={16}>
              <Col size={[8, 8, 10, 12]} offset={[2, 2, 1, 0]}>
                <PostBlock className="no-padding-top" size={24}>

                  <TextBlock>
                    <h2>Zapotrzebowanie na badania mikroklimatu</h2>
                    <p>
                      Warunki mikroklimatu w pomieszczeniu takie jak temperatura, wilgotność i prędkość przepływu powietrza oraz
                      rozkład temperatury mają istotny wpływ na samopoczucie i zdrowie osób w nim przebywających.
                      Zapewnienie odpowiednich warunków mikroklimatycznych jest szczególnie istotne w środowisku pracy,
                      gdyż poczucie komfortu cieplnego pracowników wiąże się z lepszym nastrojem i koncentracją, co
                      skutkuje zwiększeniem bezpieczeństwa i efektywności pracy.
                    </p>

                  </TextBlock>
                </PostBlock>

                <PostBlock size={24}>
                  <TextBlock>
                    <h2>Zastosowanie mierników mikroklimatu</h2>
                    <p>Miernik mikroklimatu służy do pomiaru i oceny parametrów mikroklimatu w pomieszczeniach
                      zamkniętych. </p>
                  </TextBlock>
                </PostBlock>

                <PostBlock size={24}>
                  <TextBlock>
                    <h2>Czynniki środowiskowe określające mikroklimat</h2>
                    <p>Następujące parametry wpływają na mikroklimat w pomieszczeniu:
                      <ul>
                        <li>Temperatura powietrza - podstawowy parametr wpływający na odczucie komfortu termicznego.
                        Neutralna temperatura mieści się w przedziale 18-21°C </li>
                        <li>Prędkość przepływu powietrza - wpływa na odczuwalną temperaturę. Dodatkowo lokalne wzrosty
                        prędkości powietrza (przeciągi) oddziałują negatywnie na komfort cieplny</li>
                        <li>Wilgotność powietrza - wilgotność również wplywa na komfort termiczny. Zbyt wysoka wilgotność
                        utrudnia odparowywanie potu, zaś zbyt niska powoduje wysuszenie śluzówki nosa i skóry</li>
                        <li>Asymetria rozkladu temperatury - jest to różnica temperatury na poziomie stóp i głowy. Wartość
                          ta nie powinna przekraczać 10°C. Zbyt duża różnica temperatur może przyczyniać się do odczuwania
                          zimna lub nadmiernego ciepła na poziomie stóp lub głowy, podczas gdy reszta ciała znjaduje się
                          w komfortowych warunkach</li>
                      </ul></p>
                  </TextBlock>
                </PostBlock>

              <PostBlock size={24}>
                  <TextBlock>
                    <h2>Miernik mikroklimatu EHA MM203</h2>
                    <p>
                      Nasz miernik został zaprojektowany w oparciu o normę ISO 7726. Z jego pomocą możemy mierzyć
                      temperaturę powietrza, temperaturę czarnej kuli, temperaturę naturalną-wilgotną,
                      wilgotność względną oraz prędkość powietrza. Dodatkowo miernik został wyposażony w trzy zestawy
                      sond do jednoczesnego pomiaru na poziomie stóp, brzucha i głowy osoby opomiarowanej,
                      co skraca czas pomiaru do niezbędnego minimum.
                    </p>

                  </TextBlock>
                </PostBlock>

              </Col>
            </Row>
          </Grid>
        </Container>
      </section>
    </>
  );
};

export default RealizationMikroklimat;

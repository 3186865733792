import React from "react";
import { TileDto } from "../../../dto/tileDto";
import { UsageDto } from "../../../dto/usageDto";
import { RoutePath } from "../../../route-paths";
import { categoryBadaniePyluZawieszonego, categoryIntegralnoscFiltrow, categoryPomieszczeniaCzyste, categoryPoborProbPowietrza, categoryPomiarParametrowFizycznych, categoryBadanieMasek, categoryWagiAnalityczne } from "./categories-data";
import ProbyPowietrzaIcon from "./../../../assets/proby-powietrza.svg";
import PylZawieszonyIcon from "./../../../assets/pyl-zawieszony.svg";
import PomieszczeniaCzysteIcon from "./../../../assets/pomieszczenia-czyste.svg";
import IntegralnoscFiltrowIcon from "./../../../assets/integralnosc-filtrow.svg";
import ParametryFizyczneIcon from "./../../../assets/param-fiz.svg";
import BadaniaMasekIcon from "./../../../assets/badanie-masek.svg";
import WyposazenieLabIcon from "./../../../assets/wyposazenie-lab.svg";

export const usageData = [
  {
    tile: {
      title: "Pobór prób powietrza na stanowiskach pracy",
      subtitle: "Osobiste i stacjonarne aspiratory do poboru zanieczyszczeń gazowych. Kalibratory przepływu, głowice, cyklony i akcesoria",
      img: ProbyPowietrzaIcon,
      to: RoutePath.USAGE,
    } as TileDto,
    url: "pobor-prob-powietrza",
    content: <p></p>,
    categories: categoryPoborProbPowietrza,
  },
  {
    tile: {
      title: "Badanie pyłu zawieszonego",
      subtitle: "Mierniki laserowe i grawimetryczne do pomiaru stężenia pyłu PM2,5 i PM10 zawieszonego w powietrzu na potrzeby badań miejsc pracy i środowiska",
      img: PylZawieszonyIcon,
      to: RoutePath.USAGE,
    } as TileDto,
    url: "badanie-pylu-zawieszonego",
    content: <p></p>,
    categories: categoryBadaniePyluZawieszonego,
  },
  {
    tile: {
      title: "Pomieszczenia czyste",
      subtitle: "Aparatura do monitorowania i kwalifikacji pomieszczeń czystych („clean room”), w których wytwarzane są produkty sterylne",
      img: PomieszczeniaCzysteIcon,
      to: RoutePath.USAGE,
    } as TileDto,
    url: "pomieszczenia-czyste",
    content: <p>Według normy ISO-14644 i dokumentu GMP w pomieszczeniach czystych należy przeprowadzać min. kwalifikację, testy regeneracji, monitoring ciągły. Zapraszamy do zapoznania się z naszą ofertą urządzeń, które pozwolą w sposób prawidłowy przeproawdzć wszystkie pomiary wymagane w pomieszczeniach czystych.</p>,
    categories: categoryPomieszczeniaCzyste,
  },
  {
    tile: {
      title: "Integralność filtrów",
      subtitle: "Urządzenia do badania integralności filtrów HEPA i lokalizowania przecieków oparte o liczniki cząstek lub fotometry – dilutery i generatory aerozolu",
      img: IntegralnoscFiltrowIcon,
      to: RoutePath.USAGE,
    } as TileDto,
    url: "integralnosc-filtrow",
    content: <p></p>,
    categories: categoryIntegralnoscFiltrow,
  },
  {
    tile: {
      title: "Pomiar parametrów fizycznych",
      subtitle: "Mierniki mikroklimatu, drgań, poziomu dźwięku oraz promieniowania elektromagnetycznego, optycznego i słonecznego",
      img: ParametryFizyczneIcon,
      to: RoutePath.USAGE,
    } as TileDto,
    url: "pomiar-parametrow-fizycznych",
    content: <p></p>,
    categories: categoryPomiarParametrowFizycznych,
  },
  {
    tile: {
      title: "Badania masek",
      subtitle: "Sprzęt do weryfikacji skuteczności filtracji i czystości mikrobiologicznej masek ochronnych i medycznych",
      img: BadaniaMasekIcon,
      to: RoutePath.USAGE,
    } as TileDto,
    url: "badanie-masek",
    content: <p></p>,
    categories: categoryBadanieMasek,
  },
  {
    tile: {
      title: "Wyposażenie laboratoriów",
      subtitle: "Urządzenia do pomiaru cząstek stałych i biologicznych w laboratoriach higieny pracy, farmacji i badania środowiska",
      img: WyposazenieLabIcon,
      to: RoutePath.USAGE,
    } as TileDto,
    url: "wyposazenie-laboratoriow",
    content: <p></p>,
    categories: categoryWagiAnalityczne,
  },
] as Array<UsageDto>;

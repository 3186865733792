import React, { useRef } from "react";
import FullImgBlock from "../../../../components/fullImg-block/fullImg-block";
import Col from "../../../../components/grid/col";
import Row from "../../../../components/grid/row";
import PostBlock from "../../../../components/writing-tools/post-block/post-block";
import TextBlock from "../../../../components/writing-tools/text-block/text-block";
import PvExampleImgA from "../../../../assets/PvExampleImgA.jpg";
import PvExampleImgB from "../../../../assets/PvExampleImgB.jpg";
import Container from "../../../../components/container/container";
import Button from "../../../../components/button/button";
import Grid from "../../../../components/grid/grid";
import HeroSection from "../../../../sections/hero-section/hero-section";
import DystrybucjaImg from "./../../../../assets/services/dystrybucja/dystrybucja.png";
import TopLink from "../../../../components/top-link/top-link";
import { RoutePath } from "../../../../route-paths";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import Partners from "../../../../sections/partners-section/partners";

const DystrybucjaPV: React.FC = () => {
  const heroTarget = useRef<any>();

  const handleScrollToSection = (section: React.MutableRefObject<any>) => {
    window.scrollTo(0, section.current!.getBoundingClientRect().top + window.pageYOffset);
  };

  return (
    <>
      <BreadcrumbsItem to={RoutePath.SERVICES__DYSTRYBUCJA}>Dystrybucja</BreadcrumbsItem>
      <HeroSection img={DystrybucjaImg} alt="Dystrybucja img">
        <h1>Dystrybucja</h1>
        <p>Dostarczamy sprzęt do pomiarów oraz zapewniamy pełne wsparcie posprzedażowe.</p>
        <Button onClick={() => handleScrollToSection(heroTarget)}>Dowiedz się wiecej</Button>
      </HeroSection>
      <section className="services-section" ref={heroTarget}>
        <Container className="mt-xl-5 mt-4">
          <Grid>
            <Row gap={16}>
              <Col size={[8, 8, 10, 12]} offset={[2, 2, 1, 0]}>
                <PostBlock className="no-padding-top" size={24}>
                  <TextBlock>
                    <h2>Sprzedaż aparatury pomiarowej</h2>
                    <p>Urządzenia do pomiaru cząstek stałych i biologicznych w laboratoriach higieny pracy, ochrony
                      środowiska i przemysłu farmaceutycznego</p>
                  </TextBlock>
                </PostBlock>
                <PostBlock size={24}>
                  <TextBlock>
                    <h4>Sprawdzeni dostawcy</h4>
                    <p>Mamy w swojej ofercie sprzęt najlepszych producentów z całego świata, spełniający wyśrubowane
                      normy i standardy branżowe np. ISO czy GMP. Wszystkie urządzenia są objęte gwarancją i wsparciem
                      technicznym. </p>
                  </TextBlock>
                </PostBlock>
                <PostBlock size={24}>
                  <TextBlock>
                    <h4>Szeroka gama produktów</h4>
                    <p>Nasz asortyment obejmuje aparaturę do pomiarów: prób powietrza (zanieczyszczeń oraz pomieszczeń o
                      podwyższonym standardzie czystości), hałasu i drgań, pól elektromagnetycznych, promieniowania
                      optycznego, środowisk termicznych oraz mikrobiologicznych.
                    </p>
                  </TextBlock>
                </PostBlock>
                <PostBlock size={24}>
                  <TextBlock>
                    <h4>Oferowane marki</h4>
                    <p>Znajdziesz u nas urządzenia sprawdzonych producentów z całego świata. Jesteśmy wyłącznym
                      dystrybutorem na Polskę części z nich. Dostępne u nas marki to:</p>
                  </TextBlock>
                </PostBlock>
                <PostBlock size={24}>
                  <Partners />
                </PostBlock>
                <PostBlock size={24}>
                  <TextBlock>
                    <p>Jeśli nie znalazłeś w naszej ofercie marki lub produktu, który Cię interesuje – skontaktuj się z
                      nami. Jesteśmy w stanie sprowadzić potrzebny sprzęt, zajmujemy się także produkcją aparatury na
                      zamówienie.</p>
                    <div className="back-link">
                      <TopLink to={RoutePath.SERVICES}>Powrót do wszystkich usług</TopLink>
                    </div>
                  </TextBlock>
                </PostBlock>
              </Col>
            </Row>
          </Grid>
        </Container>
      </section>
    </>
  );
};

export default DystrybucjaPV;

import React, { useState } from "react";
import "./navbar.scss";
import TopLink from "../top-link/top-link";
import Dropdown from "react-multilevel-dropdown";
import useWindowSize from "../../tools/useWindowSize";

const MultiLevelMenu = Dropdown as any;
interface MenuTreeDto {
  to: string;
  title: React.ReactNode | string;
  items: Array<MenuTreeDto>;
  isOpen?: boolean;
  hideItemsOnMobile?: boolean;
}

interface Props {
  onClick: any;
  menuTree: MenuTreeDto;
  title: string;
}

const MultilevelMenuWrapper: React.FC<Props> = (props: Props) => {
  const size = useWindowSize();

  const hideMenu = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    var active = document.querySelector('[class*="Dropdown_active__"]') ?? false;
    if (active) {
      (active as HTMLElement).click();
      props.onClick();
    }
  };

  const Item = ({ to, children, title }: { to: string; title: string | React.ReactNode; children?: React.ReactNode }) => {
    return (
      <MultiLevelMenu.Item onClick={hideMenu}>
        <TopLink to={to}>{title}</TopLink>
        {children ? children : null}
      </MultiLevelMenu.Item>
    );
  };

  const ExpandableMenuItem = ({ item }: { item: MenuTreeDto }) => {
    const [isOpen, setIsOpen] = useState(false);
    return (
      <div className="expandableMenuItem">
        {item.items.length > 0 && !item.hideItemsOnMobile ? (
          <>
            <div className="expandableLink" onClick={() => setIsOpen(!isOpen)}>
              {<>{item.items.length > 0 ? <>{item.title} &#9656;</> : item.title}</>}
            </div>
            {isOpen ? (
              <>
                <br />
                {item.items.map((x: MenuTreeDto, index: number) => {
                  return <ExpandableMenuItem item={x} key={x.title + " " + index} />;
                })}
              </>
            ) : null}
          </>
        ) : (
          <TopLink to={item.to}>{item.title}</TopLink>
        )}
      </div>
    );
  };

  return (
    <>
      {size.isMobile ? (
        <ExpandableMenuItem item={props.menuTree} />
      ) : (
        <MultiLevelMenu id="menu" title={props.title} position="right" menuClassName="first-multilevel-menu" buttonClassName="first-multilevel-button">
          {props.menuTree.items.map((x, index) => {
            return (
              <React.Fragment key={index}>
                <Item to={x.to} title={<>{x.items.length > 0 ? <>{x.title} &#9656;</> : x.title}</>}>
                  {x.items.length > 0 ? (
                    <MultiLevelMenu.Submenu className="first-multilevel-submenu" position="right">
                      {x.items.map((y, index) => {
                        return <Item to={y.to} title={y.title} key={index}></Item>;
                      })}
                    </MultiLevelMenu.Submenu>
                  ) : null}
                </Item>
              </React.Fragment>
            );
          })}
        </MultiLevelMenu>
      )}
    </>
  );
};

export default MultilevelMenuWrapper;

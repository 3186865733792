/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import "./pdf-icon.scss";
import Icon from "./pdf-icon.svg";

interface Props {}

const PdfIcon: React.FC<Props> = (props: Props) => {
  return <img className="pdf-icon" src={Icon} />;
};

export default PdfIcon;

import React, { useRef } from "react";
import ContactSection from "../../sections/contact-section/contact-section";
import HeroSectionWrapper from "./sections/hero-section-wrapper";
import BlogSectionWrapper from "./sections/blog-section-wrapper";
import "./about-page.scss";
import TeamSection from "../../sections/team-section/team-section";
import TeamAdvantagesSection from "../../sections/team-section/team-advantages-section";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { RoutePath } from "../../route-paths";

const AboutPage: React.FC = () => {
  const heroTarget = useRef<any>();

  return (
    <div className="about-page">
      <BreadcrumbsItem to={RoutePath.ABOUT}>O nas</BreadcrumbsItem>
      <HeroSectionWrapper scrollToRef={heroTarget} />
      <BlogSectionWrapper setRef={heroTarget} />
      {/*<TeamSection />*/}
      {/*<TeamAdvantagesSection />*/}
      <ContactSection />
    </div>
  );
};

export default AboutPage;

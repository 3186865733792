import React from "react";
import { ProductContentDto, ProductDto } from "../../../../dto/productDto";
import { ProductTileDto } from "../../../../dto/productTileDto";
import { RoutePath } from "../../../../route-paths";

import TextBlock from "../../../../components/writing-tools/text-block/text-block";
import TitleBlock from "../../../../components/title-block/title-block";
import SensidyneLogo from "./../../../../assets/products/sensidyne-logo.png";
import EkohigienaLogo from "./../../../../assets/products/mierniki_mikroklimatu/logoEkohigiena.svg";
import ModulNPrzepImg from "./../../../../assets/products/akcesoria_do_aspiratorow/modul-n-przep.png";
import GlowicaFrakWdychImg from "../../../../assets/products/akcesoria_do_aspiratorow/glowica-do-frakcji-wdychalnej.png";
import GlowicaFrakRespImg from "../../../../assets/products/akcesoria_do_aspiratorow/Cylkon-do-frakcji-respirabilnej.png";
import CyklonTorakImg from "../../../../assets/products/akcesoria_do_aspiratorow/cyklon_torakalny_b1.png";
import RelatedProducts from "../../../../components/RelatedProducts/RelatedProducts";
import LinkWithIconWrapper from "../../../../components/link-with-icon/link-with-icon-wrapper";
import LinkWithIcon from "../../../../components/link-with-icon/link-with-icon";
import PdfIcon from "../../../../components/pdf-icon/pdf-icon";
import GlowicaFrakWdychBrochure from "../../../../assets/products/akcesoria_do_aspiratorow/Ulotka_GLOWICA_FH_019_18-0901.pdf";
import GlowicaFrakRespBrochure from "../../../../assets/products/akcesoria_do_aspiratorow/Ulotka_Cyklon_FH_022_18-0901.pdf";
import CyklonFrakTorakBrochure from "../../../../assets/products/akcesoria_do_aspiratorow/Ulotka_Cyklon_GK_2.69_16-0601.pdf";
import CyklonFrakPm25Brochure from "../../../../assets/products/akcesoria_do_aspiratorow/Ulotka_Cyklon_GK_2.05_20-1105.pdf";
import InstrukcjaFH019 from "../../../../assets/products/akcesoria_do_aspiratorow/InstrukcjaFH019ENPL.pdf"
import InstrukcjaFH022 from "../../../../assets/products/akcesoria_do_aspiratorow/InstrukcjaFH022ENPL.pdf"
import CertyfikatFH019 from "../../../../assets/products/akcesoria_do_aspiratorow/CertyfikatFH019ENPL.pdf"
import CertyfikatFH022 from "../../../../assets/products/akcesoria_do_aspiratorow/CertyfikatFH022ENPL.pdf"

export const ProductsAkcesoriaDoAspiratorow = [
  {
    tile: {
      title: "Moduł niskich przepływów ",
      subtitle: "Producent: Sensidyne",
      price: "",
      img: ModulNPrzepImg,
      to: RoutePath.PRODUCT,
    } as ProductTileDto,
    url: "modul-niskich-przeplywow",
    content: {
      images: [SensidyneLogo],
      body: (
        <TextBlock>
          <TitleBlock title="Moduł niskich przepływów" smallPaddingTop />
          <hr />

          <p>
            Stosowany z aspiratorami serii GilAir 3 i GilAir 5<br />
            <br />
            <li> Pozwala na uzyskanie przepływu 20-500 ml/min</li>
            <li> Kompensacja przepływu +/- 5%</li>
            <li> Montaż bezpośrednio do aspiratora,</li>
            <li> Regulowany przepływ</li>
            <li> Możliwość stosowania przy poborach na filtry i rurki sorbentowe</li>
          </p>
        </TextBlock>
      ),
      tabs: [
        {
          name: "Produkty Powiązane",
          body: <RelatedProducts items={["gil-air-3", "gil-air-5"]} />,
        },
      ],
    } as ProductContentDto,
  },
  {
    tile: {
      title: "Głowica do frakcji wdychalnej",
      subtitle: "Producent: Ekohigiena Aparatura",
      price: "",
      img: GlowicaFrakWdychImg,
      to: RoutePath.PRODUCT,
    } as ProductTileDto,
    url: "glowica-do-frakcji-wdychalnej",
    content: {
      images: [EkohigienaLogo],
      body: (
        <TextBlock>
          <TitleBlock title="Głowica do frakcji wdychalnej" smallPaddingTop />
          <hr />

          <p>
            Głowica FH 019, umożliwia pobieranie frakcji wdychalnej zanieczyszczeń pyłowych i aerozoli, zgodnie z PN EN 481 przy przepływie 2 l/min.
            <br />
            <br />
            D50%=100 µm (50 % przepuszczalności dla cząsteczek o średnicy aerodynamicznej 100 µm przy zastosowanym przepływie 2 l/min.
            <br />
            <br />
            Głowica jest wykonana z tworzywa sztucznego o niewielkich rozmiarach i wadze.
          </p>
        </TextBlock>
      ),
      tabs: [
        {
          name: "Specifikacja",
          body: (
            <TextBlock>
              <table>
                <tbody>
                  <tr>
                    <td>Zakres przepływu (stały przepływ)</td>
                    <td>
                      750 ml/min - 5 l/min
                      <br />
                      (20 ml/min - 5 l/min z modułem niskich przepływów)
                    </td>
                  </tr>
                  <tr>
                    <td>Stabilizacja przepływu</td>
                    <td>± 5 %</td>
                  </tr>
                  <tr>
                    <td>Czas ciągłej pracy</td>
                    <td>minimum 8 h</td>
                  </tr>
                  <tr>
                    <td>Wymiary</td>
                    <td>10,3 x 10 x 5,1 cm</td>
                  </tr>
                  <tr>
                    <td>Waga</td>
                    <td>640 g</td>
                  </tr>
                  <tr>
                    <td>Zasilanie</td>
                    <td>Akumulator 6 V / 1.8 A</td>
                  </tr>
                  <tr>
                    <td>Czas ładowania</td>
                    <td>14 h</td>
                  </tr>
                  <tr>
                    <td>Żywotność akumulatora</td>
                    <td>300 - 500 cykli</td>
                  </tr>
                  <tr>
                    <td>Temperatura pracy</td>
                    <td>0 - 40 °C</td>
                  </tr>
                </tbody>
              </table>
            </TextBlock>
          ),
        },
        {
          name: "Do pobrania",
          body: (
            <TextBlock>
              <LinkWithIconWrapper>
                <LinkWithIcon icon={<PdfIcon />} to={GlowicaFrakWdychBrochure}>
                  Ulotka
                </LinkWithIcon>
                <LinkWithIcon icon={<PdfIcon />} to={InstrukcjaFH019}>
                  Instrukcja obsługi z tłumaczeniem na język polski
                </LinkWithIcon>
                <LinkWithIcon icon={<PdfIcon />} to={CertyfikatFH019}>
                  Certyfikat zgodności z normą EN-PN 481 dla głowicy FH019
                </LinkWithIcon>
              </LinkWithIconWrapper>
            </TextBlock>
          ),
        },
        {
          name: "Produkty Powiązane",
          body: <RelatedProducts items={["gil-air-3", "gil-air-5"]} />,
        },
      ],
    } as ProductContentDto,
  },
  {
    tile: {
      title: "Głowica do frakcji respirabilnej typu Higgins-Dewell Cyclone",
      subtitle: "Producent: Ekohigiena Aparatura",
      price: "",
      img: GlowicaFrakRespImg,
      to: RoutePath.PRODUCT,
    } as ProductTileDto,
    url: "glowica-do-frakcji-respirabilnej",
    content: {
      images: [EkohigienaLogo],
      body: (
        <TextBlock>
          <TitleBlock title="Głowica do frakcji respirabilnej" smallPaddingTop />
          <hr />

          <p>
            Głowica FH 022, umożliwia pobiernie frakcji respirabilnej zanieczyszczeń pyłowych i aerozoli, zgodnie z PN EN 481 przy przepływie 2,2 l/min
            <br />
            <br />
            D50%= 4 µm (50 % przepuszczalności dla cząsteczek o średnicy aerodynamicznej 4 µm przy zastosowanym przepływie 2.2 l/min)
            <br />
            <br />
            Dostępne są dwa typy głowicy, do stosowania z filtrami fi 25 mm (kod katalogowy FH 022-25) lub filtrami fi 37 mm (kod katalogowy FH 022-37)
            <br />
            <br />
            Głowica jest wykonana z tworzywa sztucznego o niewielkich rozmiarach i wadze.
            <br />
            <br />
            Budodowa głowicy typu Higgins Dewell Cyclone jest zgodna z konstrukcją cyklonu typu Higgins Dewel.
          </p>
        </TextBlock>
      ),
      tabs: [
        {
          name: "Specifikacja",
          body: (
            <TextBlock>
              <table>
                <tbody>
                  <tr>
                    <td>Wymiary</td>
                    <td>10 x 3,3 x 5 cm</td>
                  </tr>
                  <tr>
                    <td>Waga</td>
                    <td>40 g</td>
                  </tr>
                  <tr>
                    <td>Średnica filtra</td>
                    <td>25 mm (kod katalogowy: FH 022 - 25) 37 mm (kod katalogowy: FH 022 - 37)</td>
                  </tr>
                  <tr>
                    <td>Frakcja całkowita</td>
                    <td>D50%= 4 µm (2,2 l/min)</td>
                  </tr>
                </tbody>
              </table>
            </TextBlock>
          ),
        },
        {
          name: "Do pobrania",
          body: (
            <TextBlock>
              <LinkWithIconWrapper>
                <LinkWithIcon icon={<PdfIcon />} to={GlowicaFrakRespBrochure}>
                  Ulotka
                </LinkWithIcon>
                <LinkWithIcon icon={<PdfIcon />} to={InstrukcjaFH022}>
                  Instrukcja obsługi z tłumaczeniem na język polski
                </LinkWithIcon>
                <LinkWithIcon icon={<PdfIcon />} to={CertyfikatFH022}>
                  Certyfikat zgodności z normą EN-PN 481 dla głowicy FH022
                </LinkWithIcon>
              </LinkWithIconWrapper>
            </TextBlock>
          ),
        },
        {
          name: "Produkty Powiązane",
          body: <RelatedProducts items={["gil-air-3", "gil-air-5"]} />,
        },
      ],
    } as ProductContentDto,
  },
  {
    tile: {
      title: "Cyklon do poboru frakcji torakalnej i respirabilnej",
      subtitle: "Producent: Ekohigiena Aparatura",
      price: "",
      img: CyklonTorakImg,
      to: RoutePath.PRODUCT,
    } as ProductTileDto,
    url: "cyklon-do-frakcji-torakalnej",
    content: {
      images: [EkohigienaLogo],
      body: (
        <TextBlock>
          <TitleBlock title="Cyklon do frakcji torakalnej" smallPaddingTop />
          <hr />

          <p>
            Pobór frakcji torakalnej pyłu i areozoli zgodnie z PN EN 481 przy zastosowanym przepływie 1,6 l/min, D50%= 10 µm
            <br />
            <br />
            Pobór frakcji respirabilnej pyłu i areozoli zgodnie z PN EN 481 przy zastosowanym przepływie 4,2 l/min, D50%= 4 µm
            <br />
            <br />
            Konstrukacja cyklonu wykonana z anodowanego aluminium.
            <br />
            <br />
          </p>
        </TextBlock>
      ),
      tabs: [
        {
          name: "Specifikacja",
          body: (
            <TextBlock>
              <table>
                <tbody>
                  <tr>
                    <td>Wymiary</td>
                    <td>9,5 x 5 x 5 cm</td>
                  </tr>
                  <tr>
                    <td>Waga</td>
                    <td>86 g</td>
                  </tr>
                  <tr>
                    <td>Średnica filtra</td>
                    <td>37 mm</td>
                  </tr>
                  <tr>
                    <td>Frakcja respirabilna</td>
                    <td>D50%= 4 µm (4,2 l/min)</td>
                  </tr>
                  <tr>
                    <td>Frakcja torakalna</td>
                    <td>D50%= 10 µm (1,6 l/min)</td>
                  </tr>
                </tbody>
              </table>
            </TextBlock>
          ),
        },
        {
          name: "Do pobrania",
          body: (
            <TextBlock>
              <LinkWithIconWrapper>
                <LinkWithIcon icon={<PdfIcon />} to={CyklonFrakTorakBrochure}>
                  Ulotka
                </LinkWithIcon>
              </LinkWithIconWrapper>
            </TextBlock>
          ),
        },
        {
          name: "Produkty Powiązane",
          body: <RelatedProducts items={["gil-air-3", "gil-air-5"]} />,
        },
      ],
    } as ProductContentDto,
  },
  {
    tile: {
      title: "Cyklon do poboru frakcji PM 2.5 i respirabilnej",
      subtitle: "Producent: Ekohigiena Aparatura",
      price: "",
      img: CyklonTorakImg,
      to: RoutePath.PRODUCT,
    } as ProductTileDto,
    url: "cyklon-do-frakcji-2.5",
    content: {
      images: [EkohigienaLogo],
      body: (
        <TextBlock>
          <TitleBlock title="Cyklon do poboru frakcji PM 2.5 i respirabilnej" smallPaddingTop />
          <hr />

          <p>
            Pobieranie frakcji respirabilnej pyłu i areozoli zgodnie z PN EN 481 przy zastosowanym przepływie 2,7 l/min, D50%= 4 µm
            <br />
            <br />
            Pobieranie frakcji PM 2.5 pyłu i areozoli przy zastosowanym przepływie 4,0 l/min, D50%= 2.5 µm
            <br />
            <br />
            Konstrukcja cyklonu wykonana z anodowanego aluminium
            <br />
            <br />
          </p>
        </TextBlock>
      ),
      tabs: [
        {
          name: "Specifikacja",
          body: (
            <TextBlock>
              <table>
                <tbody>
                  <tr>
                    <td>Wymiary</td>
                    <td>9,5 x 4 x 5 cm</td>
                  </tr>
                  <tr>
                    <td>Waga</td>
                    <td>113 g</td>
                  </tr>
                  <tr>
                    <td>Średnica filtra</td>
                    <td>37 mm</td>
                  </tr>
                  <tr>
                    <td>Frakcja PM 2.5</td>
                    <td>D50%= 2.5 µm (4,00 l/min)</td>
                  </tr>
                  <tr>
                    <td>Frakcja respirabilna</td>
                    <td>D50%= 4 µm (2.7 l/min)</td>
                  </tr>
                </tbody>
              </table>
            </TextBlock>
          ),
        },
        {
          name: "Do pobrania",
          body: (
            <TextBlock>
              <LinkWithIconWrapper>
                <LinkWithIcon icon={<PdfIcon />} to={CyklonFrakPm25Brochure}>
                  Ulotka
                </LinkWithIcon>
              </LinkWithIconWrapper>
            </TextBlock>
          ),
        },
        {
          name: "Produkty Powiązane",
          body: <RelatedProducts items={["gil-air-3", "gil-air-5"]} />,
        },
      ],
    } as ProductContentDto,
  },
] as Array<ProductDto>;

import React from "react";
import "./link-with-icon.scss";

interface Props {
  children: React.ReactNode;
}

const LinkWithIconWrapper: React.FC<Props> = (props: Props) => {
  return <div className="link-with-icon-wrapper">{props.children}</div>;
};
export default LinkWithIconWrapper;

import React from "react";
import "./navbar.scss";
import Col from "../grid/col";
import Button from "../button/button";
import { RoutePath } from "../../route-paths";
import TopLink from "../top-link/top-link";
import useWindowSize from "../../tools/useWindowSize";
import MultilevelMenuWrapper from "./multilevelMenuWrapper";
import { generatePath } from "react-router-dom";
import { usageData } from "../../pages/categories-page/data/usages-data";

interface MenuTreeDto {
  to: string;
  title: React.ReactNode | string;
  items: Array<MenuTreeDto>;
  isOpen?: boolean;
  hideItemsOnMobile?: boolean;
}
const servicesMenuTree = {
  to: RoutePath.SERVICES,
  title: "Usługi",
  isOpen: false,
  items: [
    {
      to: RoutePath.SERVICES__PRODUKCJA,
      title: "Produkcja",
      isOpen: false,
      hideItemsOnMobile: true,
      items: [
        {
          to: RoutePath.SERVICES__PRODUKCJA__MIERNIKI_MIKROKLIMATU,
          title: "Mierniki mikroklimatu",
          items: [],
        },
        {
          to: RoutePath.SERVICES__PRODUKCJA__ODDYCHALNOSC,
          title: "Stanowisko testowania masek",
          items: [],
        },
      ],
    },
    {
      to: RoutePath.SERVICES__DYSTRYBUCJA,
      title: "Dystrybucja",
      items: [],
    },
    {
      to: RoutePath.SERVICES__SZKOLENIA,
      title: "Szkolenia",
      items: [],
    },
    {
      to: RoutePath.SERVICES__SERWIS,
      title: "Serwis",
      items: [],
    },
    {
      to: RoutePath.SERVICES__KALIBRACJA,
      title: "Kalibracja",
      items: [],
    },
  ],
} as MenuTreeDto;

const productsMenuItem = (): Array<MenuTreeDto> => {
  return usageData.map((usageNode) => {
    return {
      to: generatePath(RoutePath.USAGE, { usageId: usageNode.url }),
      title: usageNode.tile.title,
      items: usageNode.categories.map((category) => {
        return {
          to: generatePath(RoutePath.CATEGORY, { usageId: usageNode.url, categoryId: category.url }),
          title: category.tile.title,
          items: [],
        };
      }),
    };
  });
};

const productsMenuTree = {
  to: RoutePath.CATEGORIES,
  title: "Produkty",
  isOpen: false,
  items: productsMenuItem(),
} as MenuTreeDto;

interface Props {
  onClick: any;
}

const SubNavbar: React.FC<Props> = (props: Props) => {
  const size = useWindowSize();

  return (
    <>
      <Col size={[8, 8, 8, 12]} className="main">
        <MultilevelMenuWrapper menuTree={productsMenuTree} onClick={props.onClick} title="Produkty" />
        <MultilevelMenuWrapper menuTree={servicesMenuTree} onClick={props.onClick} title="Usługi" />
        <TopLink to={RoutePath.ABOUT}>O&nbsp;nas</TopLink>
        <TopLink to={RoutePath.BLOG}>Blog</TopLink>
        {!size.isMobile ? null : <TopLink to={RoutePath.CONTACT}>Kontakt</TopLink>}
      </Col>
      {size.isMobile ? null : (
        <Col size={[2, 2, 2, 12]} className="action-btn">
          {/* todo podpiac link do buttona */}
          <TopLink to={RoutePath.CONTACT}>
            <Button color="transparent">Kontakt</Button>
          </TopLink>
        </Col>
      )}
    </>
  );
};

export default SubNavbar;

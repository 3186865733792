import React from "react";
import Container from "../../components/container/container";
import Col from "../../components/grid/col";
import Grid from "../../components/grid/grid";
import Row from "../../components/grid/row";
import "./cta-section.scss";

interface Props {
  children: React.ReactNode;
  img: string;
  alt?: string;
}

const CtaSection: React.FC<Props> = (props: Props) => {
  const { children, img, alt = "CTA" } = props;
  return (
    <section className="cta-section">
      <Container>
        <Grid>
          <Row gap={16}>
            <Col size={[6, 6, 6, 12]}>
              <div
                className="cta__image"
                style={{ backgroundImage: `url("${img}")` }}
                title={alt}
              />
            </Col>
            <Col size={[4, 5, 6, 12]} offset={[1, 1, 0]}>
              <div className="cta__content">{children}</div>
            </Col>
          </Row>
        </Grid>
      </Container>
    </section>
  );
};

export default CtaSection;

import React from "react";
import Col from "../../components/grid/col";
import "./partners.scss";

interface Props {
  img: string;
  alt: string;
  href?: string;
}

const PartnerBlock: React.FC<Props> = (props: Props) => {
  const { img, alt, href } = props;

  return (
    <Col size={[4, 4, 4, 6]} className="partner-block">
      {href ? (
        <a href={href} target="_blank" rel="noopener noreferrer">
          <img src={img} alt={alt} />
        </a>
      ) : (
        <img src={img} alt={alt} />
      )}
    </Col>
  );
};

export default PartnerBlock;

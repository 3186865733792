import React, { useRef } from "react";
import BoxLink from "../../../components/box-link/box-link";
import Button from "../../../components/button/button";
import Container from "../../../components/container/container";
import Col from "../../../components/grid/col";
import Grid from "../../../components/grid/grid";
import Row from "../../../components/grid/row";
import TitleBlock from "../../../components/title-block/title-block";
import { RoutePath } from "../../../route-paths";
import HeroSection from "../../../sections/hero-section/hero-section";
import HeroImg from "./../../../assets/heroImg.jpg";

const ServicesNav: React.FC = () => {
  const heroTarget = useRef<any>();
  const handleScrollToSection = (section: React.MutableRefObject<any>) => {
    window.scrollTo(0, section.current!.getBoundingClientRect().top + window.pageYOffset);
  };
  return (
    <>
      <HeroSection img={HeroImg} alt="Example img">
        <h1>Usługi</h1>
        <p>Świadczymy usługi związane z aparaturą pomiarową do środowiska pracy</p>
        <Button onClick={() => handleScrollToSection(heroTarget)}>Dowiedz się wiecej</Button>
      </HeroSection>
      <section className="services-section" ref={heroTarget}>
        <TitleBlock title="Poznaj naszą ofertę" subtitle="" smallPaddingTop />
        <Container className="pt-5">
          <Grid>
            <Row gap={16}>
              <Col size={[8, 8, 10, 12]} offset={[2, 2, 1, 0]}>
                <BoxLink to={RoutePath.SERVICES__PRODUKCJA}>Produkcja</BoxLink>
                <BoxLink to={RoutePath.SERVICES__DYSTRYBUCJA}>Dystrybucja</BoxLink>
                <BoxLink to={RoutePath.SERVICES__SZKOLENIA}>Szkolenia</BoxLink>
                <BoxLink to={RoutePath.SERVICES__SERWIS}>Serwis</BoxLink>
                <BoxLink to={RoutePath.SERVICES__KALIBRACJA}>Kalibracja</BoxLink>
              </Col>
            </Row>
          </Grid>
        </Container>
      </section>
    </>
  );
};

export default ServicesNav;

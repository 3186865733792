import React from "react";
import { ProductContentDto, ProductDto } from "../../../../dto/productDto";
import { ProductTileDto } from "../../../../dto/productTileDto";
import { RoutePath } from "../../../../route-paths";

import TextBlock from "../../../../components/writing-tools/text-block/text-block";
import TitleBlock from "../../../../components/title-block/title-block";
import LinkWithIconWrapper from "../../../../components/link-with-icon/link-with-icon-wrapper";
import SvantekLogo from "./../../../../assets/partnerSvantek.svg";
import SV106Img from "./../../../../assets/products/mierniki_drgan/sv_106_human_vibration_meter__analyser-814077611010_88094600.jpeg";
import SV106Img2 from "./../../../../assets/products/mierniki_drgan/sv_105a_triaxial_handarm_accelerometer-5214078375870_05187100.jpeg";
import SV106Img3 from "./../../../../assets/products/mierniki_drgan/sv_105a_triaxial_handarm_accelerometer-5214078375970_27340900.jpeg";
import SV106Img4 from "./../../../../assets/products/mierniki_drgan/sv_38v_seat_accelerometer-4214048261260_21510100.jpeg";
import SV106Img5 from "./../../../../assets/products/mierniki_drgan/150_3.jpeg";
import SV106Brochure from "./../../../../assets/products/mierniki_drgan/150_3.jpeg";
import PrzetwornikBrochure from "./../../../../assets/products/mierniki_drgan/150_3.jpeg";

import LinkWithIcon from "../../../../components/link-with-icon/link-with-icon";
import PdfIcon from "../../../../components/pdf-icon/pdf-icon";

export const ProductsMiernikiDrgan = [
  {
    tile: {
      title: "SV 106",
      subtitle: "Producent: Svantek",
      price: "",
      img: SV106Img,
      to: RoutePath.PRODUCT,
    } as ProductTileDto,
    url: "sv-106",
    content: {
      images: [SV106Img2, SV106Img3, SV106Img4, SV106Img5, SvantekLogo],
      body: (
        <TextBlock>
          <TitleBlock title="Miernik i analizator drgań SV 106" smallPaddingTop />
          <hr />
          <p>SV 106 jest sześciokanałowym miernikiem i analizatorem drgań przeznaczonym do pomiaru drgań oddziałujących przez kończyny górne oraz na cały organizm pracownika. Spełnia wymagania normy ISO 8041:2005 i pozwala mierzyć drgania zgodnie z normami ISO 2631-1,2,5 oraz ISO 5349. Ten przełomowy instrument o kieszonkowych rozmiarach posiada możliwość jednoczesnego wykonywania pomiarów z użyciem dwóch trójosiowych czujników przyspieszenia (przykładowo można wykonać równocześnie pomiar drgań oddziałujących na kończyny górne oraz pomiar drgań oddziałujących na całe ciało) Miernik pozwala na mierzenie wielkości takich jak: RMS, Peak, Peak-Peak, VDV, MTVV, A(8) oraz pomiary dawki ze wszystkimi wymaganymi filtrami korekcyjnymi oraz niezbędnym ograniczeniem pasma. Duża wydajność procesora sygnałowego użytego w konstrukcji miernika SV106 umożliwia wykonywanie jednocześnie z pomiarem analizy drgań w pasmach oktawowych albo tercjowych. Zaawansowany zapis historii czasowej oraz funkcja rejestracji przebiegu czasowego (zgodnie z normą ISO 2631-5) do wbudowanej pamięci na karcie MikroSD daje niemal nieograniczone możliwości magazynowania danych pomiarowych. Wyniki pomiarów mogą być z łatwością przeniesione z przyrządu do komputera za pomocą złącza USB oraz oprogramowania Supervisor. Program Supervisor pozwala także na wprowadzanie ustawień do miernika z komputera za pomocą prostego edytora. Pomiary drgań oddziałujących na całe ciało mogą być przeprowadzone z łatwością dzięki zastosowaniu siedziskowego czujnika przyspieszenia SV 38V. Sam czujnik może być umiejscowiony bezpośrednio na siedzisku, na podłożu czy też przymocowany do oparcia siedzenia.</p>
        </TextBlock>
      ),
      tabs: [
        {
          name: "Specifikacja",
          body: (
            <TextBlock>
              <table>
                <tbody>
                  <tr>
                    <td>Wejście miernika</td>
                    <td>6 kanałów: wejście w standardzie IEPE lub lub napięciowe oraz dwa kanały do przetwornika siły 2 złącza LEMO 5-pin</td>
                  </tr>
                  <tr>
                    <td>Zakres dynamiczny</td>
                    <td>90 dB</td>
                  </tr>
                  <tr>
                    <td>Zakres siły</td>
                    <td>0.2 N - 200 N</td>
                  </tr>
                  <tr>
                    <td>Częstotliwość próbkowania</td>
                    <td>6 kHz</td>
                  </tr>
                  <tr>
                    <td>Pamięć Wewnętrzna</td>
                    <td>
                      pamięć nieulotna 16 MB
                      <br />
                      gniazdo na kartę mikroSD do 16 GB (w standardzie 4 GB)
                    </td>
                  </tr>
                  <tr>
                    <td>Wyświetlacz</td>
                    <td>Kolorowy OLED 2.4” 320 x 240 pikseli Super kontrast 10000 : 1</td>
                  </tr>
                  <tr>
                    <td>Interfejsy</td>
                    <td>USB 1.1 Client, wielofunkcyjne we/wy - wyjście AC (1 V Peak) lub wejście/wyjście cyfrowe - wyzwalanie</td>
                  </tr>
                  <tr>
                    <td>Zasilanie</td>
                    <td>Cztery baterie AA (alkaliczne) czas pracy &gt; 12 h (6,0 V / 1,6 Ah) Cztery akumulatory AA (NiMH) czas pracy &gt; 16 h (4,8 V / 2,6 Ah) Interfejs USB 500 mA HUB</td>
                  </tr>
                  <tr>
                    <td>Warunki pracy</td>
                    <td>
                      Temperatura od -10 °C do 50 °C
                      <br />
                      Wilgotność do 90 % wilgotności względnej bez kondensacji
                    </td>
                  </tr>
                  <tr>
                    <td>Wymiary</td>
                    <td>140 x 83 x 33 mm (bez przetwornika)</td>
                  </tr>
                  <tr>
                    <td>Waga</td>
                    <td>~390 g razem z bateriami (bez przetwornika)</td>
                  </tr>
                </tbody>
              </table>
            </TextBlock>
          ),
        },
        {
          name: "Do pobrania",
          body: (
            <TextBlock>
              <LinkWithIconWrapper>
                <LinkWithIcon icon={<PdfIcon />} to={SV106Brochure}>
                  Ulotka SV106
                </LinkWithIcon>
                <LinkWithIcon icon={<PdfIcon />} to={PrzetwornikBrochure}>
                  Ulotka Przetwornik
                </LinkWithIcon>
              </LinkWithIconWrapper>
            </TextBlock>
          ),
        },
      ],
    } as ProductContentDto,
  },
] as Array<ProductDto>;

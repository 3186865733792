import React from "react";
import "./top-link.scss";
import { useDispatch } from "react-redux";
import { showSlideOverlay } from "../../redux/callbacksState/callbacksState.actions";
import { NavLink } from "react-router-dom";

interface Props {
  to: string;
  children: React.ReactNode | string;
  ref?: any;
  className?: string;
  title?: string;
  noSlide?: boolean;
}

const TopLink: React.FC<Props> = (props: Props) => {
  const dispatch = useDispatch();

  const slideAndScroll = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    if (!props.noSlide) {
      dispatch(showSlideOverlay(props.to as string));
    }
    // setTimeout(() => {
    //   window.scrollTo(0, 0)
    // }, 200);
  };

  return (
    <NavLink to={props.to} title={props.title ? props.title : ""} activeClassName="selected" onClick={(e) => slideAndScroll(e)} className={`topLinkWrapper${props.className ? " " + props.className : ""}`}>
      {props.children}
    </NavLink>
  );
};

export default TopLink;

export class RoutePath {
  [key: string]: any;
  static RULES: string = '/warunki-uzytkowania';
  static ABOUT: string = '/o-nas';
  static CATEGORIES: string = '/produkty';
  static USAGE: string = '/produkty/:usageId';
  static CATEGORY: string = '/produkty/:usageId/:categoryId';
  static PRODUCT: string = '/produkty/:usageId/:categoryId/:productId';
  static SERVICES: string = '/uslugi';
  static BLOG: string = '/blog';
  static CONTACT: string = '/kontakt';
  static BLOG__DOKUMENTACJA_DO_GLOWIC: string = '/blog/dokumentacja-do-glowic';

  static SERVICES__PRODUKCJA: string = '/uslugi/produkcja';
  static SERVICES__DYSTRYBUCJA: string = '/uslugi/dystrybucja';
  static SERVICES__SZKOLENIA: string = '/uslugi/szkolenia';
  static SERVICES__SERWIS: string = '/uslugi/serwis';
  static SERVICES__KALIBRACJA: string = '/uslugi/kalibracja';

  static SERVICES__PRODUKCJA__MIERNIKI_MIKROKLIMATU: string = '/uslugi/produkcja/mierniki-mikroklimatu';
  static SERVICES__PRODUKCJA__ODDYCHALNOSC: string = '/uslugi/produkcja/oddychalnosc-masek';

  static HOME: string = '/';
}
import React from "react";
import Container from "../../components/container/container";
import Col from "../../components/grid/col";
import Grid from "../../components/grid/grid";
import Row from "../../components/grid/row";
import "./contact-section.scss";
import TitleBlock from "../../components/title-block/title-block";
import ExamplePerson from "./../../assets/examplePerson.jpg";

const ContactSection: React.FC = () => {
  return (
    <section className="contact-section">
      <Container>
        <TitleBlock title="Skontakuj się z nami" subtitle="Do każdego naszego klienta podchodzimy indywidualnie.
        Skontaktuj się z nami, by uzyskać fachowe wsparcie w wyborze produktów i ich późniejszej eksploatacji." />
        <Grid>
          <Row gap={16} className="contact-wrapper">
            {/*<Col size={[2, 4, 4, 12]} offset={[2, 0]} className="contact-person">*/}
            {/*  <img src={ExamplePerson} alt="Ryszard Putyra, CEO" />*/}
            {/*  <p><b>Ryszard Putyra, CEO</b></p>*/}
            {/*</Col>*/}
            <Col size={[3, 4, 4, 12]} offset={[1, 0]}>
              <h4>Dane kontaktowe</h4>
              <p><b>Siedziba:</b> <br />ul. Roberta Kocha 2 <br />55-330 Błonie</p>
              <p><b>Zadzwoń:</b><br />(+48) 713-176-850</p>
              <p><b>Napisz:</b> <br />biuro@ekohigiena.com.pl</p>
            </Col>
            <Col size={[4, 4, 4, 12]}>
              <h4>Dane rejestrowe</h4>
              <p>
                Ekohigiena Aparatura Ryszard Putyra Sp.J.
              </p>
              <p>
                ul. Strzelecka 19, <br />
                Środa Śląska 55-300
              </p>
              <p>
                NIP: 913-160-96-86 <br />
                Regon: 021292911 <br />
                KRS: 0000356994
              </p>
            </Col>
          </Row>
        </Grid>
      </Container>
    </section >
  )
}

export default ContactSection;
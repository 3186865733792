import React from "react";
import TopLink from "../../components/top-link/top-link";
import "./info-tiles.scss";
import DefaultIcon from "./../../assets/logo_icon.svg";

interface Props {
  to?: string;
  title: string;
  icon?: string;
  children: React.ReactNode;
}

const InfoTileBlock: React.FC<Props> = (props: Props) => {
  const { to, icon = DefaultIcon, children, title } = props;

  const InfoTile = () => {
    return (
      <div className="info-tile-block">
        <div className="info-tile__icon">
          <img src={icon} alt={title} />
        </div>
        <div className="info-tile__content">
          <h4>{title}</h4>
          <p>{children}</p>
        </div>
      </div>
    );
  };
  return to ? (
    <TopLink to={to}>
      <InfoTile />
    </TopLink>
  ) : (
    <InfoTile />
  );
};

export default InfoTileBlock;

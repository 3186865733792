import React from "react";
import TextBlock from "../../../../components/writing-tools/text-block/text-block";
import { ProductContentDto, ProductDto } from "../../../../dto/productDto";
import { ProductTileDto } from "../../../../dto/productTileDto";
import { RoutePath } from "../../../../route-paths";
import LighthouseLogo from "./../../../../assets/products/LWS LOGO - BW-transparent.png";
import ApexR3 from "./../../../../assets/products/liczniki_czastek_do_monitoringu_ciaglego/b_Apex-R3.jpeg";
import ApexR5 from "./../../../../assets/products/liczniki_czastek_do_monitoringu_ciaglego/b_Apex-R5.jpeg";
import ApexR02 from "./../../../../assets/products/liczniki_czastek_do_monitoringu_ciaglego/b_Apex-R02.jpeg";
import ApexR03 from "./../../../../assets/products/liczniki_czastek_do_monitoringu_ciaglego/b_Apex-R03.jpeg";
import ApexR05 from "./../../../../assets/products/liczniki_czastek_do_monitoringu_ciaglego/b_Apex-R05.jpeg";
import ApexR3p from "./../../../../assets/products/liczniki_czastek_do_monitoringu_ciaglego/b_ApexR3p_Image.jpeg";
import ApexR5p from "./../../../../assets/products/liczniki_czastek_do_monitoringu_ciaglego/b_ApexR5p_Image.jpeg";
import ApexR02p from "./../../../../assets/products/liczniki_czastek_do_monitoringu_ciaglego/b_ApexR02p_Image.jpeg";
import ApexR03p from "./../../../../assets/products/liczniki_czastek_do_monitoringu_ciaglego/b_ApexR03p_Image.jpeg";
import ApexR05p from "./../../../../assets/products/liczniki_czastek_do_monitoringu_ciaglego/b_ApexR05p_Image.jpeg";
import Remote1104LD from "./../../../../assets/products/liczniki_czastek_do_monitoringu_ciaglego/b_Remote_1104LD.jpeg";
import Remote2012 from "./../../../../assets/products/liczniki_czastek_do_monitoringu_ciaglego/b_Remote_2012.jpeg";
import Remote3012 from "./../../../../assets/products/liczniki_czastek_do_monitoringu_ciaglego/b_Remote_3012.jpeg";
import Remote5012 from "./../../../../assets/products/liczniki_czastek_do_monitoringu_ciaglego/b_Remote_5012.jpeg";
import Remote5102 from "./../../../../assets/products/liczniki_czastek_do_monitoringu_ciaglego/b_Remote-5102.jpeg";
import Remote3102 from "./../../../../assets/products/liczniki_czastek_do_monitoringu_ciaglego/b_Remote-3102.jpeg";
import Remote2014 from "./../../../../assets/products/liczniki_czastek_do_monitoringu_ciaglego/b_Remote-2014.jpeg";
import Remote3014 from "./../../../../assets/products/liczniki_czastek_do_monitoringu_ciaglego/b_Remote-3014.jpeg";
import TitleBlock from "../../../../components/title-block/title-block";
import ApexRDatasheet from "./../../../../assets/products/liczniki_czastek_do_monitoringu_ciaglego/ApexRemote_Datasheet_190723.pdf";
import ApexRManual from "./../../../../assets/products/liczniki_czastek_do_monitoringu_ciaglego/248083447-1_R1_OpMan_ApexRemote_Letter.pdf";
import ApexRpDatasheet from "./../../../../assets/products/liczniki_czastek_do_monitoringu_ciaglego/ApexRemote_w_pump_Datasheet_R3p_R5p_R02p_R03p_R05p_200214.pdf";
import ApexRpManual from "./../../../../assets/products/liczniki_czastek_do_monitoringu_ciaglego/248083480-1_R3_OpMan_ApexRXp_Letter.pdf";
import Remote1104LDDatasheet from "./../../../../assets/products/liczniki_czastek_do_monitoringu_ciaglego/Remote_Datasheet_1104LD_191031.pdf";
import RemoteDatasheet from "./../../../../assets/products/liczniki_czastek_do_monitoringu_ciaglego/Remote_Datasheet_2012_2014_3012_3014_3102_3104_5012_5014_5102_5104.pdf";
import RemoteManual from "./../../../../assets/products/liczniki_czastek_do_monitoringu_ciaglego/248083363-1_R4_(OpMan_R1100_R1104_Gen_D)_Letter.pdf";
import RemoteManualSeria4A from "./../../../../assets/products/liczniki_czastek_do_monitoringu_ciaglego/248083213-1_R8_(OpMan_Remote_4_Series_Gen_A)_Letter.pdf";
import RemoteManualSeria4B from "./../../../../assets/products/liczniki_czastek_do_monitoringu_ciaglego/248083446-1_R1_(OpMan_Remote_4_Series_Gen_B)_Letter.pdf";
import PdfIcon from "../../../../components/pdf-icon/pdf-icon";
import LinkWithIcon from "../../../../components/link-with-icon/link-with-icon";
import LinkWithIconWrapper from "../../../../components/link-with-icon/link-with-icon-wrapper";
import RelatedProducts from "../../../../components/RelatedProducts/RelatedProducts";

export const ProductsLicznikiCzastekDoMonitoringuCiaglego = [
  {
    tile: {
      title: "ApexR3",
      subtitle: "Producent: Lighthouse",
      price: "",
      img: ApexR3,
      to: RoutePath.PRODUCT,
    } as ProductTileDto,
    url: "apex-r3",
    content: {
      images: [LighthouseLogo],
      body: (
        <TextBlock>
          <TitleBlock title="Licznik cząstek ApexR3" smallPaddingTop />
          <hr />
          <p>
            Zapewniając najwyższą integralność danych, zmniejszanie ryzyka i zgodność środowiskową, zdalny licznik cząstek ApexR3 nie ma sobie równych w branży. Zaprojektowany z ulepszoną autodiagnostyką w celu ograniczenia ryzyka, ApexR3 zapewnia najwyższą pewność danych. Dzięki minimalnemu wykrywaniu 0,3 μm i czujnikowi z diodą laserową o długiej żywotności, ApexR3 zapewnia niezrównaną wydajność, dokładność i niezawodność. Nachylona powierzchnia przednia i opcjonalne uszczelnione połączenie zapewniają zgodność z procesami odkażania, w tym VHP. ApexR3 wyróżnia się w branży i jest naprawdę najlepszy w swojej klasie.
            <br />
            <br />
          </p>
        </TextBlock>
      ),
      tabs: [
        {
          name: "Specifikacja",
          body: (
            <TextBlock>
              <table>
                <tbody>
                  <tr>
                    <td>Zakres rozmiarów cząstek</td>
                    <td>0.3 – 5.0 μm</td>
                  </tr>
                  <tr>
                    <td>Kanały pomiarowe</td>
                    <td>
                      0.3, 0.5 μm
                      <br />
                      opcjonalnie 0.3, 0.5, 1.0, 5.0 µm
                      <br />
                      lub 0.3, 0.5, 0.7, 1.0, 3.0, 5.0 µm
                    </td>
                  </tr>
                  <tr>
                    <td>Przepływ</td>
                    <td>1.0 CFM (28.3LPM)</td>
                  </tr>
                  <tr>
                    <td>Pompa</td>
                    <td>Zewnętrzna</td>
                  </tr>
                  <tr>
                    <td>Średnica króća wlotowego i wylotowego</td>
                    <td>1/4”</td>
                  </tr>
                  <tr>
                    <td>Waga</td>
                    <td>1.6 lbs (0.73 kg)</td>
                  </tr>
                  <tr>
                    <td>Komunikacja</td>
                    <td>Port szeregowy lub Ethernet</td>
                  </tr>
                  <tr>
                    <td>Typy protokołu Modbus</td>
                    <td>TCP, RTU, ASCII</td>
                  </tr>
                  <tr>
                    <td>Pojemność pamięci</td>
                    <td>3,000 rekordów</td>
                  </tr>
                  <tr>
                    <td>Autodiagnostyka</td>
                    <td>Zasilanie lasera, prąd lasera, moc lasera, zasilanie fotodetektora, napięcie wsteczne, stan fotodetektora.</td>
                  </tr>
                  <tr>
                    <td>Serwer web</td>
                    <td>Dostęp zdalny przez przeglądarkę internetową.</td>
                  </tr>
                  <tr>
                    <td>Tryb walidacji</td>
                    <td>TAK</td>
                  </tr>
                  <tr>
                    <td>Lampka alarmowa</td>
                    <td>TAK</td>
                  </tr>
                  <tr>
                    <td>Obudowa</td>
                    <td>Stal nierdzewna 304</td>
                  </tr>
                  <tr>
                    <td>Zasilanie</td>
                    <td>24V DC, PoE</td>
                  </tr>
                  <tr>
                    <td>Źródło światła</td>
                    <td>Dioda laserowa</td>
                  </tr>
                  <tr>
                    <td>Kalibracja</td>
                    <td>Zgodnie z ISO 21501-4</td>
                  </tr>
                  <tr>
                    <td>Zerowanie</td>
                    <td>&lt; 1 cząstki / 5 minut zgodnie z ISO 21501-4</td>
                  </tr>
                  <tr>
                    <td>Limit stężenia</td>
                    <td>1,000,000 cząstek/ft3 (34,300,000 cząstek/m3) przy 10% utracie zgodności dla pomieszczeń czystych klas ISO 1-8</td>
                  </tr>
                  <tr>
                    <td>Efektywność zliczania</td>
                    <td>50% dla minimalnego rozmiaru kanału, 100% dla cząstek &gt; 1,5X - 2X rozmiar cząstek kanału</td>
                  </tr>
                  <tr>
                    <td>Wymiary</td>
                    <td>6.38”x 2.93“x 4.63”(16.2x7.4x11.7cm)</td>
                  </tr>
                </tbody>
              </table>
            </TextBlock>
          ),
        },
        {
          name: "Do pobrania",
          body: (
            <TextBlock>
              <LinkWithIconWrapper>
                <LinkWithIcon icon={<PdfIcon />} to={ApexRDatasheet}>
                  Dane techniczne
                </LinkWithIcon>
                <LinkWithIcon icon={<PdfIcon />} to={ApexRManual}>
                  Instrukcja obsługi
                </LinkWithIcon>
              </LinkWithIconWrapper>
            </TextBlock>
          ),
        },
        {
          name: "Produkty Powiązane",
          body: <RelatedProducts items={["apex-r5", "apex-r02", "remote-1104ld", { categoryId: "przenosne-liczniki-czastek", productId: "apex-z3" }, { categoryId: "liczniki-czastek-do-monitoringu-ciaglego", productId: "remote-5012" }, "apex-r03"]} />,
        },
      ],
    } as ProductContentDto,
  },
  {
    tile: {
      title: "ApexR5",
      subtitle: "Producent: Lighthouse",
      price: "",
      img: ApexR5,
      to: RoutePath.PRODUCT,
    } as ProductTileDto,
    url: "apex-r5",
    content: {
      images: [LighthouseLogo],
      body: (
        <TextBlock>
          <TitleBlock title="Licznik cząstek ApexR5" smallPaddingTop />
          <hr />
          <p>
            Zapewniając najwyższą integralność danych, zmniejszanie ryzyka i zgodność środowiskową, zdalny licznik cząstek ApexR5 nie ma sobie równych w branży. Zaprojektowany z ulepszoną autodiagnostyką w celu ograniczenia ryzyka, ApexR5 zapewnia najwyższą pewność danych. Dzięki minimalnemu wykrywaniu 0,5 μm i czujnikowi z diodą laserową o długiej żywotności, ApexR5 zapewnia niezrównaną wydajność, dokładność i niezawodność. Nachylona powierzchnia przednia i opcjonalne uszczelnione połączenie zapewniają zgodność z procesami odkażania, w tym VHP. ApexR5 wyróżnia się w branży i jest naprawdę najlepszy w swojej klasie.
            <br />
            <br />
          </p>
        </TextBlock>
      ),
      tabs: [
        {
          name: "Specifikacja",
          body: (
            <TextBlock>
              <table>
                <tbody>
                  <tr>
                    <td>Zakres rozmiarów cząstek</td>
                    <td>0.5 – 10.0 μm</td>
                  </tr>
                  <tr>
                    <td>Kanały pomiarowe</td>
                    <td>
                      0.5, 5.0 μm <br />
                      opcjonalnie 0.5, 1.0, 5.0, 10.0 µm
                      <br />
                      lub 0.5, 0.7, 1.0, 3.0, 5.0, 7.0, 10.0 µm
                    </td>
                  </tr>
                  <tr>
                    <td>Przepływ</td>
                    <td>1.0 CFM (28.3LPM)</td>
                  </tr>
                  <tr>
                    <td>Pompa</td>
                    <td>Zewnętrzna</td>
                  </tr>
                  <tr>
                    <td>Średnica króća wlotowego i wylotowego</td>
                    <td>1/4”</td>
                  </tr>
                  <tr>
                    <td>Waga</td>
                    <td>1.6 lbs (0.73 kg)</td>
                  </tr>
                  <tr>
                    <td>Komunikacja</td>
                    <td>Port szeregowy lub Ethernet</td>
                  </tr>
                  <tr>
                    <td>Typy protokołu Modbus</td>
                    <td>TCP, RTU, ASCII</td>
                  </tr>
                  <tr>
                    <td>Pojemność pamięci</td>
                    <td>3,000 rekordów</td>
                  </tr>
                  <tr>
                    <td>Autodiagnostyka</td>
                    <td>Zasilanie lasera, prąd lasera, moc lasera, zasilanie fotodetektora, napięcie wsteczne, stan fotodetektora.</td>
                  </tr>
                  <tr>
                    <td>Serwer web</td>
                    <td>Dostęp zdalny przez przeglądarkę internetową.</td>
                  </tr>
                  <tr>
                    <td>Tryb walidacji</td>
                    <td>TAK</td>
                  </tr>
                  <tr>
                    <td>Lampka alarmowa</td>
                    <td>TAK</td>
                  </tr>
                  <tr>
                    <td>Obudowa</td>
                    <td>Stal nierdzewna 304</td>
                  </tr>
                  <tr>
                    <td>Zasilanie</td>
                    <td>24V DC, PoE</td>
                  </tr>
                  <tr>
                    <td>Źródło światła</td>
                    <td>Dioda laserowa</td>
                  </tr>
                  <tr>
                    <td>Kalibracja</td>
                    <td>Zgodnie z ISO 21501-4</td>
                  </tr>
                  <tr>
                    <td>Zerowanie</td>
                    <td>&lt; 1 cząstki / 5 minut zgodnie z ISO 21501-4</td>
                  </tr>
                  <tr>
                    <td>Limit stężenia</td>
                    <td>1,000,000 cząstek/ft3 (34,300,000 cząstek/m3) przy 10% utracie zgodności dla pomieszczeń czystych klas ISO 1-8</td>
                  </tr>
                  <tr>
                    <td>Efektywność zliczania</td>
                    <td>50% dla minimalnego rozmiaru kanału, 100% dla cząstek &gt; 1,5X - 2X rozmiar cząstek kanału</td>
                  </tr>
                  <tr>
                    <td>Wymiary</td>
                    <td>6.38”x 2.93“x 4.63”(16.2x7.4x11.7cm)</td>
                  </tr>
                </tbody>
              </table>
            </TextBlock>
          ),
        },
        {
          name: "Do pobrania",
          body: (
            <TextBlock>
              <LinkWithIconWrapper>
                <LinkWithIcon icon={<PdfIcon />} to={ApexRDatasheet}>
                  Dane techniczne
                </LinkWithIcon>
                <LinkWithIcon icon={<PdfIcon />} to={ApexRManual}>
                  Instrukcja obsługi
                </LinkWithIcon>
              </LinkWithIconWrapper>
            </TextBlock>
          ),
        },
      ],
    } as ProductContentDto,
  },
  {
    tile: {
      title: "ApexR02",
      subtitle: "Producent: Lighthouse",
      price: "",
      img: ApexR02,
      to: RoutePath.PRODUCT,
    } as ProductTileDto,
    url: "apex-r02",
    content: {
      images: [LighthouseLogo],
      body: (
        <TextBlock>
          <TitleBlock title="Licznik cząstek ApexR02" smallPaddingTop />
          <hr />
          <p>
            Zapewniając najwyższą integralność danych, łagodzenie ryzyka i zgodność środowiskową, zdalny licznik cząstek ApexR02 nie ma sobie równych w branży. Zaprojektowany z ulepszoną autodiagnostyką w celu ograniczenia ryzyka, ApexR02 zapewnia najwyższą pewność danych. Dzięki minimalnej detekcji 0,2 μm i czujnikowi z diodą laserową o długiej żywotności, ApexR02 zapewnia niezrównaną wydajność, dokładność i niezawodność. Nachylona powierzchnia przednia i opcjonalne uszczelnione połączenie zapewniają zgodność z procesami odkażania, w tym VHP. ApexR02 wyróżnia się w branży i jest naprawdę najlepszy w swojej klasie.
            <br />
            <br />
          </p>
        </TextBlock>
      ),
      tabs: [
        {
          name: "Specifikacja",
          body: (
            <TextBlock>
              <table>
                <tbody>
                  <tr>
                    <td>Zakres rozmiarów cząstek</td>
                    <td>0.2 – 2.0 μm</td>
                  </tr>
                  <tr>
                    <td>Kanały pomiarowe</td>
                    <td>
                      0.2, 0.3 μm
                      <br />
                      opcjonalnie 0.2, 0.3, 0.5, 1.0 µm
                      <br />
                      lub 0.2, 0.3, 0.5, 0.7, 1.0, 2.0 µm
                    </td>
                  </tr>
                  <tr>
                    <td>Przepływ</td>
                    <td>0.1 CFM (2.83LPM)</td>
                  </tr>
                  <tr>
                    <td>Pompa</td>
                    <td>Zewnętrzna</td>
                  </tr>
                  <tr>
                    <td>Średnica króća wlotowego i wylotowego</td>
                    <td>1/8”</td>
                  </tr>
                  <tr>
                    <td>Waga</td>
                    <td>1.4 lbs (0.63 kg)</td>
                  </tr>
                  <tr>
                    <td>Komunikacja</td>
                    <td>Port szeregowy lub Ethernet</td>
                  </tr>
                  <tr>
                    <td>Typy protokołu Modbus</td>
                    <td>TCP, RTU, ASCII</td>
                  </tr>
                  <tr>
                    <td>Pojemność pamięci</td>
                    <td>3,000 rekordów</td>
                  </tr>
                  <tr>
                    <td>Autodiagnostyka</td>
                    <td>Zasilanie lasera, prąd lasera, moc lasera, zasilanie fotodetektora, napięcie wsteczne, stan fotodetektora.</td>
                  </tr>
                  <tr>
                    <td>Serwer web</td>
                    <td>Dostęp zdalny przez przeglądarkę internetową.</td>
                  </tr>
                  <tr>
                    <td>Tryb walidacji</td>
                    <td>TAK</td>
                  </tr>
                  <tr>
                    <td>Lampka alarmowa</td>
                    <td>TAK</td>
                  </tr>
                  <tr>
                    <td>Obudowa</td>
                    <td>Stal nierdzewna 304</td>
                  </tr>
                  <tr>
                    <td>Zasilanie</td>
                    <td>24V DC, PoE</td>
                  </tr>
                  <tr>
                    <td>Źródło światła</td>
                    <td>Dioda laserowa</td>
                  </tr>
                  <tr>
                    <td>Kalibracja</td>
                    <td>Zgodnie z ISO 21501-4</td>
                  </tr>
                  <tr>
                    <td>Zerowanie</td>
                    <td>&lt; 1 cząstki / 5 minut zgodnie z ISO 21501-4</td>
                  </tr>
                  <tr>
                    <td>Limit stężenia</td>
                    <td>4,000,000 cząstek/ft3 (137,200,000 cząstek/m3) przy 10% utracie zgodności dla pomieszczeń czystych klas ISO 1-8</td>
                  </tr>
                  <tr>
                    <td>Efektywność zliczania</td>
                    <td>50% dla minimalnego rozmiaru kanału, 100% dla cząstek &gt; 1,5X - 2X rozmiar cząstek kanału</td>
                  </tr>
                  <tr>
                    <td>Wymiary</td>
                    <td>6.38”x 2.93“x 4.63”(16.2x7.4x11.7cm)</td>
                  </tr>
                </tbody>
              </table>
            </TextBlock>
          ),
        },
        {
          name: "Do pobrania",
          body: (
            <TextBlock>
              <LinkWithIconWrapper>
                <LinkWithIcon icon={<PdfIcon />} to={ApexRDatasheet}>
                  Dane techniczne
                </LinkWithIcon>
                <LinkWithIcon icon={<PdfIcon />} to={ApexRManual}>
                  Instrukcja obsługi
                </LinkWithIcon>
              </LinkWithIconWrapper>
            </TextBlock>
          ),
        },
      ],
    } as ProductContentDto,
  },
  {
    tile: {
      title: "ApexR03",
      subtitle: "Producent: Lighthouse",
      price: "",
      img: ApexR03,
      to: RoutePath.PRODUCT,
    } as ProductTileDto,
    url: "apex-r03",
    content: {
      images: [LighthouseLogo],
      body: (
        <TextBlock>
          <TitleBlock title="Licznik cząstek ApexR03" smallPaddingTop />
          <hr />
          <p>
            Zapewniając najwyższą integralność danych, łagodzenie ryzyka i zgodność środowiskową, zdalny licznik cząstek ApexR03 nie ma sobie równych w branży. Zaprojektowany z ulepszoną autodiagnostyką w celu ograniczenia ryzyka, ApexR03 zapewnia najwyższą pewność danych. Dzięki minimalnemu wykrywaniu 0,3 μm i czujnikowi z diodą laserową o długiej żywotności, ApexR03 zapewnia niezrównaną wydajność, dokładność i niezawodność. Nachylona powierzchnia przednia i opcjonalne uszczelnione połączenie zapewniają zgodność z procesami odkażania, w tym VHP. ApexR03 wyróżnia się w branży i jest naprawdę najlepszy w swojej klasie.
            <br />
            <br />
          </p>
        </TextBlock>
      ),
      tabs: [
        {
          name: "Specifikacja",
          body: (
            <TextBlock>
              <table>
                <tbody>
                  <tr>
                    <td>Zakres rozmiarów cząstek</td>
                    <td>0.3 – 5.0 μm</td>
                  </tr>
                  <tr>
                    <td>Kanały pomiarowe</td>
                    <td>
                      0.3, 0.5 μm
                      <br />
                      opcjonalnie 0.3, 0.5, 1.0, 5.0 µm
                      <br />
                      lub 0.3, 0.5, 0.7, 1.0, 3.0, 5.0 µm
                    </td>
                  </tr>
                  <tr>
                    <td>Przepływ</td>
                    <td>0.1 CFM (2.83LPM)</td>
                  </tr>
                  <tr>
                    <td>Pompa</td>
                    <td>Zewnętrzna</td>
                  </tr>
                  <tr>
                    <td>Średnica króća wlotowego i wylotowego</td>
                    <td>1/8”</td>
                  </tr>
                  <tr>
                    <td>Waga</td>
                    <td>1.4 lbs (0.63 kg)</td>
                  </tr>
                  <tr>
                    <td>Komunikacja</td>
                    <td>Port szeregowy lub Ethernet</td>
                  </tr>
                  <tr>
                    <td>Typy protokołu Modbus</td>
                    <td>TCP, RTU, ASCII</td>
                  </tr>
                  <tr>
                    <td>Pojemność pamięci</td>
                    <td>3,000 rekordów</td>
                  </tr>
                  <tr>
                    <td>Autodiagnostyka</td>
                    <td>Zasilanie lasera, prąd lasera, moc lasera, zasilanie fotodetektora, napięcie wsteczne, stan fotodetektora.</td>
                  </tr>
                  <tr>
                    <td>Serwer web</td>
                    <td>Dostęp zdalny przez przeglądarkę internetową.</td>
                  </tr>
                  <tr>
                    <td>Tryb walidacji</td>
                    <td>TAK</td>
                  </tr>
                  <tr>
                    <td>Lampka alarmowa</td>
                    <td>TAK</td>
                  </tr>
                  <tr>
                    <td>Obudowa</td>
                    <td>Stal nierdzewna 304</td>
                  </tr>
                  <tr>
                    <td>Zasilanie</td>
                    <td>24V DC, PoE</td>
                  </tr>
                  <tr>
                    <td>Źródło światła</td>
                    <td>Dioda laserowa</td>
                  </tr>
                  <tr>
                    <td>Kalibracja</td>
                    <td>Zgodnie z ISO 21501-4</td>
                  </tr>
                  <tr>
                    <td>Zerowanie</td>
                    <td>&lt; 1 cząstki / 5 minut zgodnie z ISO 21501-4</td>
                  </tr>
                  <tr>
                    <td>Limit stężenia</td>
                    <td>4,000,000 cząstek/ft3 (137,200,000 cząstek/m3) przy 10% utracie zgodności dla pomieszczeń czystych klas ISO 1-8</td>
                  </tr>
                  <tr>
                    <td>Efektywność zliczania</td>
                    <td>50% dla minimalnego rozmiaru kanału, 100% dla cząstek &gt; 1,5X - 2X rozmiar cząstek kanału</td>
                  </tr>
                  <tr>
                    <td>Wymiary</td>
                    <td>6.38”x 2.93“x 4.63”(16.2x7.4x11.7cm)</td>
                  </tr>
                </tbody>
              </table>
            </TextBlock>
          ),
        },
        {
          name: "Do pobrania",
          body: (
            <TextBlock>
              <LinkWithIconWrapper>
                <LinkWithIcon icon={<PdfIcon />} to={ApexRDatasheet}>
                  Dane techniczne
                </LinkWithIcon>
                <LinkWithIcon icon={<PdfIcon />} to={ApexRManual}>
                  Instrukcja obsługi
                </LinkWithIcon>
              </LinkWithIconWrapper>
            </TextBlock>
          ),
        },
      ],
    } as ProductContentDto,
  },
  {
    tile: {
      title: "ApexR05",
      subtitle: "Producent: Lighthouse",
      price: "",
      img: ApexR05,
      to: RoutePath.PRODUCT,
    } as ProductTileDto,
    url: "apex-r05",
    content: {
      images: [LighthouseLogo],
      body: (
        <TextBlock>
          <TitleBlock title="Licznik cząstek ApexR05" smallPaddingTop />
          <hr />
          <p>
            Zapewniając najwyższą integralność danych, łagodzenie ryzyka i kompatybilność środowiskową, zdalny licznik cząstek ApexR05 nie ma sobie równych w branży. Zaprojektowany z ulepszoną autodiagnostyką w celu ograniczenia ryzyka, ApexR05 zapewnia najwyższą pewność danych. Dzięki minimalnemu wykrywaniu 0,5 μm i czujnikowi z diodą laserową o długiej żywotności, ApexR05 zapewnia niezrównaną wydajność, dokładność i niezawodność. Nachylona powierzchnia przednia i opcjonalne uszczelnione połączenie zapewniają zgodność z procesami odkażania, w tym VHP. ApexR05 wyróżnia się w branży i jest naprawdę najlepszy w swojej klasie.
            <br />
            <br />
          </p>
        </TextBlock>
      ),
      tabs: [
        {
          name: "Specifikacja",
          body: (
            <TextBlock>
              <table>
                <tbody>
                  <tr>
                    <td>Zakres rozmiarów cząstek</td>
                    <td>0.5 – 10.0 μm</td>
                  </tr>
                  <tr>
                    <td>Kanały pomiarowe</td>
                    <td>
                      0.5, 5.0 μm
                      <br />
                      opcjonalnie 0.5, 1.0, 5.0, 10.0 µm
                      <br />
                      lub 0.5, 0.7, 1.0, 3.0, 5.0, 7.0, 10.0 µm
                    </td>
                  </tr>
                  <tr>
                    <td>Przepływ</td>
                    <td>0.1 CFM (2.83LPM)</td>
                  </tr>
                  <tr>
                    <td>Pompa</td>
                    <td>Zewnętrzna</td>
                  </tr>
                  <tr>
                    <td>Średnica króća wlotowego i wylotowego</td>
                    <td>1/8”</td>
                  </tr>
                  <tr>
                    <td>Waga</td>
                    <td>1.4 lbs (0.63 kg)</td>
                  </tr>
                  <tr>
                    <td>Komunikacja</td>
                    <td>Port szeregowy lub Ethernet</td>
                  </tr>
                  <tr>
                    <td>Typy protokołu Modbus</td>
                    <td>TCP, RTU, ASCII</td>
                  </tr>
                  <tr>
                    <td>Pojemność pamięci</td>
                    <td>3,000 rekordów</td>
                  </tr>
                  <tr>
                    <td>Autodiagnostyka</td>
                    <td>Zasilanie lasera, prąd lasera, moc lasera, zasilanie fotodetektora, napięcie wsteczne, stan fotodetektora.</td>
                  </tr>
                  <tr>
                    <td>Serwer web</td>
                    <td>Dostęp zdalny przez przeglądarkę internetową.</td>
                  </tr>
                  <tr>
                    <td>Tryb walidacji</td>
                    <td>TAK</td>
                  </tr>
                  <tr>
                    <td>Lampka alarmowa</td>
                    <td>TAK</td>
                  </tr>
                  <tr>
                    <td>Obudowa</td>
                    <td>Stal nierdzewna 304</td>
                  </tr>
                  <tr>
                    <td>Zasilanie</td>
                    <td>24V DC, PoE</td>
                  </tr>
                  <tr>
                    <td>Źródło światła</td>
                    <td>Dioda laserowa</td>
                  </tr>
                  <tr>
                    <td>Kalibracja</td>
                    <td>Zgodnie z ISO 21501-4</td>
                  </tr>
                  <tr>
                    <td>Zerowanie</td>
                    <td>&lt; 1 cząstki / 5 minut zgodnie z ISO 21501-4</td>
                  </tr>
                  <tr>
                    <td>Limit stężenia</td>
                    <td>4,000,000 cząstek/ft3 (137,200,000 cząstek/m3) przy 10% utracie zgodności dla pomieszczeń czystych klas ISO 1-8</td>
                  </tr>
                  <tr>
                    <td>Efektywność zliczania</td>
                    <td>50% dla minimalnego rozmiaru kanału, 100% dla cząstek &gt; 1,5X - 2X rozmiar cząstek kanału</td>
                  </tr>
                  <tr>
                    <td>Wymiary</td>
                    <td>6.38”x 2.93“x 4.63”(16.2x7.4x11.7cm)</td>
                  </tr>
                </tbody>
              </table>
            </TextBlock>
          ),
        },
        {
          name: "Do pobrania",
          body: (
            <TextBlock>
              <LinkWithIconWrapper>
                <LinkWithIcon icon={<PdfIcon />} to={ApexRDatasheet}>
                  Dane techniczne
                </LinkWithIcon>
                <LinkWithIcon icon={<PdfIcon />} to={ApexRManual}>
                  Instrukcja obsługi
                </LinkWithIcon>
              </LinkWithIconWrapper>
            </TextBlock>
          ),
        },
      ],
    } as ProductContentDto,
  },
  {
    tile: {
      title: "ApexR3p",
      subtitle: "Producent: Lighthouse",
      price: "",
      img: ApexR3p,
      to: RoutePath.PRODUCT,
    } as ProductTileDto,
    url: "apex-r3p",
    content: {
      images: [LighthouseLogo],
      body: (
        <TextBlock>
          <TitleBlock title="Licznik cząstek ApexR3p" smallPaddingTop />
          <hr />
          <p>
            Apex reprezentuje wszystko, co jest ważne w światowej klasy Systemie Monitoringu Środowiska. Jest to najlepszy w swojej klasie czujnik i spełnia wszystkie standardy branżowe. Funkcje zmniejszania ryzyka w Self-Diagnostics praktycznie eliminują błędy danych.
            <br />
            <br />
            Model ApexR3p z pompą jest dostępny z natężeniem przepływu powietrza 1,0 CFM. Niezależna pompa próżniowa przepuszcza powietrze wylotowe przez filtr HEPA i nie zanieczyszcza otaczającego środowiska.
            <br />
            <br />
            Apex zapewnia ciągłe zbieranie danych w czasie rzeczywistym w przystępnej cenie, oferując niezrównaną wydajność, dokładność i niezawodność.
            <br />
            <br />
            Apex zapewnia to wszystko w obudowie, która jest kompatybilna ze standardowymi procesami odkażania w branży.
            <br />
            <br />
          </p>
        </TextBlock>
      ),
      tabs: [
        {
          name: "Specifikacja",
          body: (
            <TextBlock>
              <table>
                <tbody>
                  <tr>
                    <td>Zakres rozmiarów cząstek</td>
                    <td>0.3 – 5.0 μm</td>
                  </tr>
                  <tr>
                    <td>Kanały pomiarowe</td>
                    <td>
                      0.3, 0.5 μm
                      <br />
                      opcjonalnie 0.3, 0.5, 1.0, 5.0 µm
                      <br />
                      lub 0.3, 0.5, 0.7, 1.0, 3.0, 5.0 µm
                    </td>
                  </tr>
                  <tr>
                    <td>Przepływ</td>
                    <td>1 CFM (28.3LPM)</td>
                  </tr>
                  <tr>
                    <td>Pompa</td>
                    <td>Wbudowana</td>
                  </tr>
                  <tr>
                    <td>Średnica króća wlotowego i wylotowego</td>
                    <td>3/8”</td>
                  </tr>
                  <tr>
                    <td>Waga</td>
                    <td>6.3 lbs (2.9 kg)</td>
                  </tr>
                  <tr>
                    <td>Komunikacja</td>
                    <td>Port szeregowy lub Ethernet</td>
                  </tr>
                  <tr>
                    <td>Typy protokołu Modbus</td>
                    <td>TCP, RTU, ASCII</td>
                  </tr>
                  <tr>
                    <td>Pojemność pamięci</td>
                    <td>3,000 rekordów</td>
                  </tr>
                  <tr>
                    <td>Autodiagnostyka</td>
                    <td>Zasilanie lasera, prąd lasera, moc lasera, zasilanie fotodetektora, napięcie wsteczne, stan fotodetektora.</td>
                  </tr>
                  <tr>
                    <td>Serwer web</td>
                    <td>Dostęp zdalny przez przeglądarkę internetową.</td>
                  </tr>
                  <tr>
                    <td>Tryb walidacji</td>
                    <td>TAK</td>
                  </tr>
                  <tr>
                    <td>Lampka alarmowa</td>
                    <td>TAK</td>
                  </tr>
                  <tr>
                    <td>Obudowa</td>
                    <td>Stal nierdzewna 304</td>
                  </tr>
                  <tr>
                    <td>Zasilanie</td>
                    <td>24V DC, 120W</td>
                  </tr>
                  <tr>
                    <td>Źródło światła</td>
                    <td>Dioda laserowa</td>
                  </tr>
                  <tr>
                    <td>Kalibracja</td>
                    <td>Zgodnie z ISO 21501-4</td>
                  </tr>
                  <tr>
                    <td>Zerowanie</td>
                    <td>&lt; 1 cząstki / 5 minut zgodnie z ISO 21501-4</td>
                  </tr>
                  <tr>
                    <td>Limit stężenia</td>
                    <td>1,000,000 cząstek/ft3 (34,300,000 cząstek/m3) przy 10% utracie zgodności dla pomieszczeń czystych klas ISO 1-8</td>
                  </tr>
                  <tr>
                    <td>Efektywność zliczania</td>
                    <td>50% dla minimalnego rozmiaru kanału, 100% dla cząstek &gt; 1,5X - 2X rozmiar cząstek kanału</td>
                  </tr>
                  <tr>
                    <td>Wymiary</td>
                    <td>6.65” x 9.13” x 4.75” (16.89 x 23.19 x 12.06 cm)</td>
                  </tr>
                </tbody>
              </table>
            </TextBlock>
          ),
        },
        {
          name: "Do pobrania",
          body: (
            <TextBlock>
              <LinkWithIconWrapper>
                <LinkWithIcon icon={<PdfIcon />} to={ApexRpDatasheet}>
                  Dane techniczne
                </LinkWithIcon>
                <LinkWithIcon icon={<PdfIcon />} to={ApexRpManual}>
                  Instrukcja obsługi
                </LinkWithIcon>
              </LinkWithIconWrapper>
            </TextBlock>
          ),
        },
      ],
    } as ProductContentDto,
  },
  {
    tile: {
      title: "ApexR5p",
      subtitle: "Producent: Lighthouse",
      price: "",
      img: ApexR5p,
      to: RoutePath.PRODUCT,
    } as ProductTileDto,
    url: "apex-r5p",
    content: {
      images: [LighthouseLogo],
      body: (
        <TextBlock>
          <TitleBlock title="Licznik cząstek ApexR5p" smallPaddingTop />
          <hr />
          <p>
            Apex reprezentuje wszystko, co jest ważne w światowej klasy Systemie Monitoringu Środowiska. Jest to najlepszy w swojej klasie czujnik i spełnia wszystkie standardy branżowe. Funkcje zmniejszania ryzyka w Self-Diagnostics praktycznie eliminują błędy danych.
            <br />
            <br />
            Model ApexR5p z pompą jest dostępny z natężeniem przepływu powietrza 1,0 CFM. Niezależna pompa próżniowa przepuszcza powietrze wylotowe przez filtr HEPA i nie zanieczyszcza otaczającego środowiska.
            <br />
            <br />
            Apex zapewnia ciągłe zbieranie danych w czasie rzeczywistym w przystępnej cenie, oferując niezrównaną wydajność, dokładność i niezawodność.
            <br />
            <br />
            Apex zapewnia to wszystko w obudowie, która jest kompatybilna ze standardowymi procesami odkażania w branży.
            <br />
            <br />
          </p>
        </TextBlock>
      ),
      tabs: [
        {
          name: "Specifikacja",
          body: (
            <TextBlock>
              <table>
                <tbody>
                  <tr>
                    <td>Zakres rozmiarów cząstek</td>
                    <td>0.5 – 10.0 μm</td>
                  </tr>
                  <tr>
                    <td>Kanały pomiarowe</td>
                    <td>
                      0.5, 5.0 μm
                      <br />
                      opcjonalnie 0.5, 1.0, 5.0, 10.0 µm
                      <br />
                      lub 0.5, 0.7, 1.0, 3.0, 5.0, 7.0, 10.0 µm
                    </td>
                  </tr>
                  <tr>
                    <td>Przepływ</td>
                    <td>1 CFM (28.3LPM)</td>
                  </tr>
                  <tr>
                    <td>Pompa</td>
                    <td>Wbudowana</td>
                  </tr>
                  <tr>
                    <td>Średnica króća wlotowego i wylotowego</td>
                    <td>3/8”</td>
                  </tr>
                  <tr>
                    <td>Waga</td>
                    <td>6.3 lbs (2.9 kg)</td>
                  </tr>
                  <tr>
                    <td>Komunikacja</td>
                    <td>Port szeregowy lub Ethernet</td>
                  </tr>
                  <tr>
                    <td>Typy protokołu Modbus</td>
                    <td>TCP, RTU, ASCII</td>
                  </tr>
                  <tr>
                    <td>Pojemność pamięci</td>
                    <td>3,000 rekordów</td>
                  </tr>
                  <tr>
                    <td>Autodiagnostyka</td>
                    <td>Zasilanie lasera, prąd lasera, moc lasera, zasilanie fotodetektora, napięcie wsteczne, stan fotodetektora.</td>
                  </tr>
                  <tr>
                    <td>Serwer web</td>
                    <td>Dostęp zdalny przez przeglądarkę internetową.</td>
                  </tr>
                  <tr>
                    <td>Tryb walidacji</td>
                    <td>TAK</td>
                  </tr>
                  <tr>
                    <td>Lampka alarmowa</td>
                    <td>TAK</td>
                  </tr>
                  <tr>
                    <td>Obudowa</td>
                    <td>Stal nierdzewna 304</td>
                  </tr>
                  <tr>
                    <td>Zasilanie</td>
                    <td>24V DC, 120W</td>
                  </tr>
                  <tr>
                    <td>Źródło światła</td>
                    <td>Dioda laserowa</td>
                  </tr>
                  <tr>
                    <td>Kalibracja</td>
                    <td>Zgodnie z ISO 21501-4</td>
                  </tr>
                  <tr>
                    <td>Zerowanie</td>
                    <td>&lt; 1 cząstki / 5 minut zgodnie z ISO 21501-4</td>
                  </tr>
                  <tr>
                    <td>Limit stężenia</td>
                    <td>1,000,000 cząstek/ft3 (34,300,000 cząstek/m3) przy 10% utracie zgodności dla pomieszczeń czystych klas ISO 1-8</td>
                  </tr>
                  <tr>
                    <td>Efektywność zliczania</td>
                    <td>50% dla minimalnego rozmiaru kanału, 100% dla cząstek &gt; 1,5X - 2X rozmiar cząstek kanału</td>
                  </tr>
                  <tr>
                    <td>Wymiary</td>
                    <td>6.65” x 9.13” x 4.75” (16.89 x 23.19 x 12.06 cm)</td>
                  </tr>
                </tbody>
              </table>
            </TextBlock>
          ),
        },
        {
          name: "Do pobrania",
          body: (
            <TextBlock>
              <LinkWithIconWrapper>
                <LinkWithIcon icon={<PdfIcon />} to={ApexRpDatasheet}>
                  Dane techniczne
                </LinkWithIcon>
                <LinkWithIcon icon={<PdfIcon />} to={ApexRpManual}>
                  Instrukcja obsługi
                </LinkWithIcon>
              </LinkWithIconWrapper>
            </TextBlock>
          ),
        },
      ],
    } as ProductContentDto,
  },
  {
    tile: {
      title: "ApexR02p",
      subtitle: "Producent: Lighthouse",
      price: "",
      img: ApexR02p,
      to: RoutePath.PRODUCT,
    } as ProductTileDto,
    url: "apex-r02p",
    content: {
      images: [LighthouseLogo],
      body: (
        <TextBlock>
          <TitleBlock title="Licznik cząstek ApexR02p" smallPaddingTop />
          <hr />
          <p>
            Apex reprezentuje wszystko, co jest ważne w światowej klasy Systemie Monitoringu Środowiska. Jest to najlepszy w swojej klasie czujnik i spełnia wszystkie standardy branżowe. Funkcje zmniejszania ryzyka w Self-Diagnostics praktycznie eliminują błędy danych.
            <br />
            <br />
            Model ApexR02p z pompą jest dostępny z natężeniem przepływu powietrza 0.1 CFM. Niezależna pompa próżniowa przepuszcza powietrze wylotowe przez filtr HEPA i nie zanieczyszcza otaczającego środowiska.
            <br />
            <br />
            Apex zapewnia ciągłe zbieranie danych w czasie rzeczywistym w przystępnej cenie, oferując niezrównaną wydajność, dokładność i niezawodność.
            <br />
            <br />
            Apex zapewnia to wszystko w obudowie, która jest kompatybilna ze standardowymi procesami odkażania w branży.
            <br />
            <br />
          </p>
        </TextBlock>
      ),
      tabs: [
        {
          name: "Specifikacja",
          body: (
            <TextBlock>
              <table>
                <tbody>
                  <tr>
                    <td>Zakres rozmiarów cząstek</td>
                    <td>0.2 – 2.0 μm</td>
                  </tr>
                  <tr>
                    <td>Kanały pomiarowe</td>
                    <td>
                      0.2, 0.3 μm
                      <br />
                      opcjonalnie 0.2, 0.3, 0.5, 1.0 µm
                      <br />
                      lub 0.2, 0.3, 0.5, 0.7, 1.0, 2.0 µm
                    </td>
                  </tr>
                  <tr>
                    <td>Przepływ</td>
                    <td>0.1 CFM (2.83 LPM)</td>
                  </tr>
                  <tr>
                    <td>Pompa</td>
                    <td>Wbudowana</td>
                  </tr>
                  <tr>
                    <td>Średnica króća wlotowego i wylotowego</td>
                    <td>1/8”</td>
                  </tr>
                  <tr>
                    <td>Waga</td>
                    <td>3.1 lbs (1.4 kg)</td>
                  </tr>
                  <tr>
                    <td>Komunikacja</td>
                    <td>Port szeregowy lub Ethernet</td>
                  </tr>
                  <tr>
                    <td>Typy protokołu Modbus</td>
                    <td>TCP, RTU, ASCII</td>
                  </tr>
                  <tr>
                    <td>Pojemność pamięci</td>
                    <td>3,000 rekordów</td>
                  </tr>
                  <tr>
                    <td>Autodiagnostyka</td>
                    <td>Zasilanie lasera, prąd lasera, moc lasera, zasilanie fotodetektora, napięcie wsteczne, stan fotodetektora.</td>
                  </tr>
                  <tr>
                    <td>Serwer web</td>
                    <td>Dostęp zdalny przez przeglądarkę internetową.</td>
                  </tr>
                  <tr>
                    <td>Tryb walidacji</td>
                    <td>TAK</td>
                  </tr>
                  <tr>
                    <td>Lampka alarmowa</td>
                    <td>TAK</td>
                  </tr>
                  <tr>
                    <td>Obudowa</td>
                    <td>Stal nierdzewna 304</td>
                  </tr>
                  <tr>
                    <td>Zasilanie</td>
                    <td>24V DC, 25W</td>
                  </tr>
                  <tr>
                    <td>Źródło światła</td>
                    <td>Dioda laserowa</td>
                  </tr>
                  <tr>
                    <td>Kalibracja</td>
                    <td>Zgodnie z ISO 21501-4</td>
                  </tr>
                  <tr>
                    <td>Zerowanie</td>
                    <td>&lt; 1 cząstki / 5 minut zgodnie z ISO 21501-4</td>
                  </tr>
                  <tr>
                    <td>Limit stężenia</td>
                    <td>4,000,000 cząstek/ft3 (137,200,000 cząstek/m3) przy 10% utracie zgodności dla pomieszczeń czystych klas ISO 1-8</td>
                  </tr>
                  <tr>
                    <td>Efektywność zliczania</td>
                    <td>50% dla minimalnego rozmiaru kanału, 100% dla cząstek &gt; 1,5X - 2X rozmiar cząstek kanału</td>
                  </tr>
                  <tr>
                    <td>Wymiary</td>
                    <td>4.87” x 6.50” x 3.26” (12.37 x 16.51 x 8.28 cm)</td>
                  </tr>
                </tbody>
              </table>
            </TextBlock>
          ),
        },
        {
          name: "Do pobrania",
          body: (
            <TextBlock>
              <LinkWithIconWrapper>
                <LinkWithIcon icon={<PdfIcon />} to={ApexRpDatasheet}>
                  Dane techniczne
                </LinkWithIcon>
                <LinkWithIcon icon={<PdfIcon />} to={ApexRpManual}>
                  Instrukcja obsługi
                </LinkWithIcon>
              </LinkWithIconWrapper>
            </TextBlock>
          ),
        },
      ],
    } as ProductContentDto,
  },
  {
    tile: {
      title: "ApexR03p",
      subtitle: "Producent: Lighthouse",
      price: "",
      img: ApexR03p,
      to: RoutePath.PRODUCT,
    } as ProductTileDto,
    url: "apex-r03p",
    content: {
      images: [LighthouseLogo],
      body: (
        <TextBlock>
          <TitleBlock title="Licznik cząstek ApexR03p" smallPaddingTop />
          <hr />
          <p>
            Apex reprezentuje wszystko, co jest ważne w światowej klasy Systemie Monitoringu Środowiska. Jest to najlepszy w swojej klasie czujnik i spełnia wszystkie standardy branżowe. Funkcje zmniejszania ryzyka w Self-Diagnostics praktycznie eliminują błędy danych.
            <br />
            <br />
            Model ApexR03p z pompą jest dostępny z natężeniem przepływu powietrza 0.1 CFM. Niezależna pompa próżniowa przepuszcza powietrze wylotowe przez filtr HEPA i nie zanieczyszcza otaczającego środowiska.
            <br />
            <br />
            Apex zapewnia ciągłe zbieranie danych w czasie rzeczywistym w przystępnej cenie, oferując niezrównaną wydajność, dokładność i niezawodność.
            <br />
            <br />
            Apex zapewnia to wszystko w obudowie, która jest kompatybilna ze standardowymi procesami odkażania w branży.
            <br />
            <br />
          </p>
        </TextBlock>
      ),
      tabs: [
        {
          name: "Specifikacja",
          body: (
            <TextBlock>
              <table>
                <tbody>
                  <tr>
                    <td>Zakres rozmiarów cząstek</td>
                    <td>0.3 – 5.0 μm</td>
                  </tr>
                  <tr>
                    <td>Kanały pomiarowe</td>
                    <td>
                      0.3, 0.5 μm
                      <br />
                      opcjonalnie 0.3, 0.5, 1.0, 5.0 µm
                      <br />
                      lub 0.3, 0.5, 0.7, 1.0, 3.0, 5.0 µm
                    </td>
                  </tr>
                  <tr>
                    <td>Przepływ</td>
                    <td>0.1 CFM (2.83 LPM)</td>
                  </tr>
                  <tr>
                    <td>Pompa</td>
                    <td>Wbudowana</td>
                  </tr>
                  <tr>
                    <td>Średnica króća wlotowego i wylotowego</td>
                    <td>1/8”</td>
                  </tr>
                  <tr>
                    <td>Waga</td>
                    <td>3.1 lbs (1.4 kg)</td>
                  </tr>
                  <tr>
                    <td>Komunikacja</td>
                    <td>Port szeregowy lub Ethernet</td>
                  </tr>
                  <tr>
                    <td>Typy protokołu Modbus</td>
                    <td>TCP, RTU, ASCII</td>
                  </tr>
                  <tr>
                    <td>Pojemność pamięci</td>
                    <td>3,000 rekordów</td>
                  </tr>
                  <tr>
                    <td>Autodiagnostyka</td>
                    <td>Zasilanie lasera, prąd lasera, moc lasera, zasilanie fotodetektora, napięcie wsteczne, stan fotodetektora.</td>
                  </tr>
                  <tr>
                    <td>Serwer web</td>
                    <td>Dostęp zdalny przez przeglądarkę internetową.</td>
                  </tr>
                  <tr>
                    <td>Tryb walidacji</td>
                    <td>TAK</td>
                  </tr>
                  <tr>
                    <td>Lampka alarmowa</td>
                    <td>TAK</td>
                  </tr>
                  <tr>
                    <td>Obudowa</td>
                    <td>Stal nierdzewna 304</td>
                  </tr>
                  <tr>
                    <td>Zasilanie</td>
                    <td>24V DC, 25W</td>
                  </tr>
                  <tr>
                    <td>Źródło światła</td>
                    <td>Dioda laserowa</td>
                  </tr>
                  <tr>
                    <td>Kalibracja</td>
                    <td>Zgodnie z ISO 21501-4</td>
                  </tr>
                  <tr>
                    <td>Zerowanie</td>
                    <td>&lt; 1 cząstki / 5 minut zgodnie z ISO 21501-4</td>
                  </tr>
                  <tr>
                    <td>Limit stężenia</td>
                    <td>4,000,000 cząstek/ft3 (137,200,000 cząstek/m3) przy 10% utracie zgodności dla pomieszczeń czystych klas ISO 1-8</td>
                  </tr>
                  <tr>
                    <td>Efektywność zliczania</td>
                    <td>50% dla minimalnego rozmiaru kanału, 100% dla cząstek &gt; 1,5X - 2X rozmiar cząstek kanału</td>
                  </tr>
                  <tr>
                    <td>Wymiary</td>
                    <td>4.87” x 6.50” x 3.26” (12.37 x 16.51 x 8.28 cm)</td>
                  </tr>
                </tbody>
              </table>
            </TextBlock>
          ),
        },
        {
          name: "Do pobrania",
          body: (
            <TextBlock>
              <LinkWithIconWrapper>
                <LinkWithIcon icon={<PdfIcon />} to={ApexRpDatasheet}>
                  Dane techniczne
                </LinkWithIcon>
                <LinkWithIcon icon={<PdfIcon />} to={ApexRpManual}>
                  Instrukcja obsługi
                </LinkWithIcon>
              </LinkWithIconWrapper>
            </TextBlock>
          ),
        },
      ],
    } as ProductContentDto,
  },
  {
    tile: {
      title: "ApexR05p",
      subtitle: "Producent: Lighthouse",
      price: "",
      img: ApexR05p,
      to: RoutePath.PRODUCT,
    } as ProductTileDto,
    url: "apex-r05p",
    content: {
      images: [LighthouseLogo],
      body: (
        <TextBlock>
          <TitleBlock title="Licznik cząstek ApexR05p" smallPaddingTop />
          <hr />
          <p>
            Apex reprezentuje wszystko, co jest ważne w światowej klasy Systemie Monitoringu Środowiska. Jest to najlepszy w swojej klasie czujnik i spełnia wszystkie standardy branżowe. Funkcje zmniejszania ryzyka w Self-Diagnostics praktycznie eliminują błędy danych.
            <br />
            <br />
            Model ApexR05p z pompą jest dostępny z natężeniem przepływu powietrza 0.1 CFM. Niezależna pompa próżniowa przepuszcza powietrze wylotowe przez filtr HEPA i nie zanieczyszcza otaczającego środowiska.
            <br />
            <br />
            Apex zapewnia ciągłe zbieranie danych w czasie rzeczywistym w przystępnej cenie, oferując niezrównaną wydajność, dokładność i niezawodność.
            <br />
            <br />
            Apex zapewnia to wszystko w obudowie, która jest kompatybilna ze standardowymi procesami odkażania w branży.
            <br />
            <br />
          </p>
        </TextBlock>
      ),
      tabs: [
        {
          name: "Specifikacja",
          body: (
            <TextBlock>
              <table>
                <tbody>
                  <tr>
                    <td>Zakres rozmiarów cząstek</td>
                    <td>0.5 – 10.0 μm</td>
                  </tr>
                  <tr>
                    <td>Kanały pomiarowe</td>
                    <td>
                      0.5, 5.0 μm
                      <br />
                      opcjonalnie 0.5, 1.0, 5.0, 10.0 µm
                      <br />
                      lub 0.5, 0.7, 1.0, 3.0, 5.0, 7.0, 10.0 µm
                    </td>
                  </tr>
                  <tr>
                    <td>Przepływ</td>
                    <td>0.1 CFM (2.83 LPM)</td>
                  </tr>
                  <tr>
                    <td>Pompa</td>
                    <td>Wbudowana</td>
                  </tr>
                  <tr>
                    <td>Średnica króća wlotowego i wylotowego</td>
                    <td>1/8”</td>
                  </tr>
                  <tr>
                    <td>Waga</td>
                    <td>3.1 lbs (1.4 kg)</td>
                  </tr>
                  <tr>
                    <td>Komunikacja</td>
                    <td>Port szeregowy lub Ethernet</td>
                  </tr>
                  <tr>
                    <td>Typy protokołu Modbus</td>
                    <td>TCP, RTU, ASCII</td>
                  </tr>
                  <tr>
                    <td>Pojemność pamięci</td>
                    <td>3,000 rekordów</td>
                  </tr>
                  <tr>
                    <td>Autodiagnostyka</td>
                    <td>Zasilanie lasera, prąd lasera, moc lasera, zasilanie fotodetektora, napięcie wsteczne, stan fotodetektora.</td>
                  </tr>
                  <tr>
                    <td>Serwer web</td>
                    <td>Dostęp zdalny przez przeglądarkę internetową.</td>
                  </tr>
                  <tr>
                    <td>Tryb walidacji</td>
                    <td>TAK</td>
                  </tr>
                  <tr>
                    <td>Lampka alarmowa</td>
                    <td>TAK</td>
                  </tr>
                  <tr>
                    <td>Obudowa</td>
                    <td>Stal nierdzewna 304</td>
                  </tr>
                  <tr>
                    <td>Zasilanie</td>
                    <td>24V DC, 25W</td>
                  </tr>
                  <tr>
                    <td>Źródło światła</td>
                    <td>Dioda laserowa</td>
                  </tr>
                  <tr>
                    <td>Kalibracja</td>
                    <td>Zgodnie z ISO 21501-4</td>
                  </tr>
                  <tr>
                    <td>Zerowanie</td>
                    <td>&lt; 1 cząstki / 5 minut zgodnie z ISO 21501-4</td>
                  </tr>
                  <tr>
                    <td>Limit stężenia</td>
                    <td>4,000,000 cząstek/ft3 (137,200,000 cząstek/m3) przy 10% utracie zgodności dla pomieszczeń czystych klas ISO 1-8</td>
                  </tr>
                  <tr>
                    <td>Efektywność zliczania</td>
                    <td>50% dla minimalnego rozmiaru kanału, 100% dla cząstek &gt; 1,5X - 2X rozmiar cząstek kanału</td>
                  </tr>
                  <tr>
                    <td>Wymiary</td>
                    <td>4.87” x 6.50” x 3.26” (12.37 x 16.51 x 8.28 cm)</td>
                  </tr>
                </tbody>
              </table>
            </TextBlock>
          ),
        },
        {
          name: "Do pobrania",
          body: (
            <TextBlock>
              <LinkWithIconWrapper>
                <LinkWithIcon icon={<PdfIcon />} to={ApexRpDatasheet}>
                  Dane techniczne
                </LinkWithIcon>
                <LinkWithIcon icon={<PdfIcon />} to={ApexRpManual}>
                  Instrukcja obsługi
                </LinkWithIcon>
              </LinkWithIconWrapper>
            </TextBlock>
          ),
        },
      ],
    } as ProductContentDto,
  },
  {
    tile: {
      title: "Remote 1104LD",
      subtitle: "Producent: Lighthouse",
      price: "",
      img: Remote1104LD,
      to: RoutePath.PRODUCT,
    } as ProductTileDto,
    url: "remote-1104ld",
    content: {
      images: [LighthouseLogo],
      body: (
        <TextBlock>
          <TitleBlock title="Licznik cząstek Remote 1104LD" smallPaddingTop />
          <hr />
          <p>Wykorzystując najnowocześniejszą technologię diod laserowych o długiej żywotności, Lighthouse Remote 1104LD jest pierwszym w branży zdalnym licznikiem cząstek opartym na diodach laserowych o czułości 0,10 mikrona przy wysokim natężeniu przepływu 1,0 CFM (28,3 LPM), który wykorzystuje system optyki i fotodiody zaprojektowany w celu zmniejszenia lub wyeliminowania problemów z temperaturą, związanych z czujnikami mającymi diody laserowe o dużej mocy. Zaprojektowany z myślą o niezawodności i jakości, Remote 1104LD wykorzystuje naszą technologię diody laserowej o długiej żywotności, która zapewnia wyższe limity stężenia, lepszy stosunek sygnału do szumu i wymaga mniej rutynowej konserwacji niż konwencjonalne liczniki cząstek oparte na laserze HeNe. Remote 1104LD dostarcza nieprzerwane dane w czasie rzeczywistym po opłacalnej cenie za punkt. Bardzo prosty w integracji z systemami monitorowania i zarządzania dużymi obiektami, Remote 1104LD może przesyłać do 8 kanałów jednoczesnych danych zliczania cząstek przez RS-485 MODBUS.</p>
        </TextBlock>
      ),
      tabs: [
        {
          name: "Specifikacja",
          body: (
            <TextBlock>
              <table>
                <tbody>
                  <tr>
                    <td>Zakres rozmiarów cząstek</td>
                    <td>0.1 – 1.0 μm</td>
                  </tr>
                  <tr>
                    <td>Kanały pomiarowe</td>
                    <td>0.10, 0.15, 0.2, 0.25, 0.3, 0.5 0.7, 1.0 µm</td>
                  </tr>
                  <tr>
                    <td>Przepływ</td>
                    <td>1.0 CFM (28.3 LPM)</td>
                  </tr>
                  <tr>
                    <td>Pompa</td>
                    <td>Zewnętrzna</td>
                  </tr>
                  <tr>
                    <td>Średnica króća wlotowego i wylotowego</td>
                    <td>1/4”</td>
                  </tr>
                  <tr>
                    <td>Waga</td>
                    <td>7.0 lbs (3.18 kg)</td>
                  </tr>
                  <tr>
                    <td>Komunikacja</td>
                    <td>RS-485 MODBUS</td>
                  </tr>
                  <tr>
                    <td>Typy protokołu Modbus</td>
                    <td>ASCII</td>
                  </tr>
                  <tr>
                    <td>Pojemność pamięci</td>
                    <td>3,000 rekordów</td>
                  </tr>
                  <tr>
                    <td>Lampka alarmowa</td>
                    <td>TAK</td>
                  </tr>
                  <tr>
                    <td>Obudowa</td>
                    <td>Stal nierdzewna</td>
                  </tr>
                  <tr>
                    <td>Zasilanie</td>
                    <td>24V DC</td>
                  </tr>
                  <tr>
                    <td>Źródło światła</td>
                    <td>Dioda laserowa</td>
                  </tr>
                  <tr>
                    <td>Kalibracja</td>
                    <td>Zgodnie z ISO 21501-4</td>
                  </tr>
                  <tr>
                    <td>Zerowanie</td>
                    <td>&lt; 1 cząstki / 5 minut zgodnie z ISO 21501-4</td>
                  </tr>
                  <tr>
                    <td>Limit stężenia</td>
                    <td>1,000,000 cząstek/ft3 (34,300,000 cząstek/m3) przy 10% utracie zgodności dla pomieszczeń czystych klas ISO 1-8</td>
                  </tr>
                  <tr>
                    <td>Diody informacyjne</td>
                    <td>Zasilanie, Przepływ, Serwis, Próbkowanie</td>
                  </tr>
                  <tr>
                    <td>Efektywność zliczania</td>
                    <td>50% dla cząstek 0.1µm, 100% dla cząstek &gt; 0.15 µm</td>
                  </tr>
                  <tr>
                    <td>Wymiary</td>
                    <td>10.5” x 7.65” x 3.35” (26.67 x 19.43 x 8.51 cm)</td>
                  </tr>
                </tbody>
              </table>
            </TextBlock>
          ),
        },
        {
          name: "Do pobrania",
          body: (
            <TextBlock>
              <LinkWithIconWrapper>
                <LinkWithIcon icon={<PdfIcon />} to={Remote1104LDDatasheet}>
                  Dane techniczne
                </LinkWithIcon>
                <LinkWithIcon icon={<PdfIcon />} to={RemoteManual}>
                  Instrukcja obsługi
                </LinkWithIcon>
              </LinkWithIconWrapper>
            </TextBlock>
          ),
        },
      ],
    } as ProductContentDto,
  },
  {
    tile: {
      title: "Remote 2012",
      subtitle: "Producent: Lighthouse",
      price: "",
      img: Remote2012,
      to: RoutePath.PRODUCT,
    } as ProductTileDto,
    url: "remote-2012",
    content: {
      images: [LighthouseLogo],
      body: (
        <TextBlock>
          <TitleBlock title="Licznik cząstek Remote 2012" smallPaddingTop />
          <hr />
          <p>
            Wykorzystując najnowszą technologię laserowego optycznego liczenia cząstek, firma Lighthouse zaprojektowała Remote 2012 do ciągłej, bezproblemowej pracy.
            <br />
            <br />
            Dzięki minimalnemu zakresowi czułości 0,2 µm i natężeniu przepływu 0,1 CFM, Remote 2012 zapewnia ciągłe zbieranie danych w czasie rzeczywistym po opłacalnej cenie za punkt.
            <br />
            <br />
            Wykorzystując zewnętrzne źródło podciśnienia, Remote 2012 zapewnia wszechstronne opcje montażu i może być instalowany tam, gdzie przestrzeń jest na wagę złota.
            <br />
            <br />
            Remote 2012 zintegruje się bezproblemowo z systemami monitorowania i zarządzania dużymi obiektami.
            <br />
            <br />
          </p>
        </TextBlock>
      ),
      tabs: [
        {
          name: "Specifikacja",
          body: (
            <TextBlock>
              <table>
                <tbody>
                  <tr>
                    <td>Zakres rozmiarów cząstek</td>
                    <td>0.2 – 2.0 μm</td>
                  </tr>
                  <tr>
                    <td>Kanały pomiarowe</td>
                    <td>0.2, 0.3 µm lub 0.2, 0.5 µm</td>
                  </tr>
                  <tr>
                    <td>Przepływ</td>
                    <td>0.1 CFM (2.83 LPM)</td>
                  </tr>
                  <tr>
                    <td>Pompa</td>
                    <td>Zewnętrzna</td>
                  </tr>
                  <tr>
                    <td>Średnica króća wlotowego</td>
                    <td>1/8”</td>
                  </tr>
                  <tr>
                    <td>Średnica króća wylotowego</td>
                    <td>1/4”</td>
                  </tr>
                  <tr>
                    <td>Waga</td>
                    <td>0.78 lbs (0.35 kg)</td>
                  </tr>
                  <tr>
                    <td>Komunikacja</td>
                    <td>4-20mA: Ch1, Ch2</td>
                  </tr>
                  <tr>
                    <td>Obudowa</td>
                    <td>Stal nierdzewna</td>
                  </tr>
                  <tr>
                    <td>Zasilanie</td>
                    <td>24V DC</td>
                  </tr>
                  <tr>
                    <td>Źródło światła</td>
                    <td>Dioda laserowa</td>
                  </tr>
                  <tr>
                    <td>Kalibracja</td>
                    <td>Zgodnie z ISO 21501-4</td>
                  </tr>
                  <tr>
                    <td>Zerowanie</td>
                    <td>&lt; 1 cząstki / 5 minut zgodnie z ISO 21501-4</td>
                  </tr>
                  <tr>
                    <td>Limit stężenia</td>
                    <td>4,000,000 cząstek/ft3 (137,200,000 cząstek/m3) przy 10% utracie zgodności dla pomieszczeń czystych klas ISO 1-8</td>
                  </tr>
                  <tr>
                    <td>Diody informacyjne</td>
                    <td>Zasilanie, Przepływ, Serwis, Próbkowanie</td>
                  </tr>
                  <tr>
                    <td>Efektywność zliczania</td>
                    <td>50% dla cząstek 0.2µm, 100% dla cząstek &gt; 0.3 µm</td>
                  </tr>
                  <tr>
                    <td>Wymiary</td>
                    <td>10.5” x 7.65” x 3.35” (26.67 x 19.43 x 8.51 cm)</td>
                  </tr>
                </tbody>
              </table>
            </TextBlock>
          ),
        },
        {
          name: "Do pobrania",
          body: (
            <TextBlock>
              <LinkWithIconWrapper>
                <LinkWithIcon icon={<PdfIcon />} to={RemoteDatasheet}>
                  Dane techniczne
                </LinkWithIcon>
                <LinkWithIcon icon={<PdfIcon />} to={RemoteManual}>
                  Instrukcja obsługi
                </LinkWithIcon>
              </LinkWithIconWrapper>
            </TextBlock>
          ),
        },
      ],
    } as ProductContentDto,
  },
  {
    tile: {
      title: "Remote 3012",
      subtitle: "Producent: Lighthouse",
      price: "",
      img: Remote3012,
      to: RoutePath.PRODUCT,
    } as ProductTileDto,
    url: "remote-3012",
    content: {
      images: [LighthouseLogo],
      body: (
        <TextBlock>
          <TitleBlock title="Licznik cząstek Remote 3012" smallPaddingTop />
          <hr />
          <p>
            Wykorzystując najnowszą technologię laserowego optycznego liczenia cząstek, firma Lighthouse zaprojektowała Remote 3012 do ciągłej, bezproblemowej pracy.
            <br />
            <br />
            Dzięki minimalnemu zakresowi czułości 0,3 µm i natężeniu przepływu 0,1 CFM, Remote 3012 zapewnia ciągłe zbieranie danych w czasie rzeczywistym po opłacalnej cenie za punkt.
            <br />
            <br />
            Wykorzystując zewnętrzne źródło podciśnienia, Remote 3012 zapewnia wszechstronne opcje montażu i może być instalowany tam, gdzie przestrzeń jest na wagę złota.
            <br />
            <br />
            Remote 3012 zintegruje się bezproblemowo z systemami monitorowania i zarządzania dużymi obiektami.
            <br />
            <br />
          </p>
        </TextBlock>
      ),
      tabs: [
        {
          name: "Specifikacja",
          body: (
            <TextBlock>
              <table>
                <tbody>
                  <tr>
                    <td>Zakres rozmiarów cząstek</td>
                    <td>0.3 – 25.0 μm</td>
                  </tr>
                  <tr>
                    <td>Kanały pomiarowe</td>
                    <td>0.3, 0.5 µm lub 0.3, 1.0 µm lub 0.3, 3.0 µm</td>
                  </tr>
                  <tr>
                    <td>Przepływ</td>
                    <td>0.1 CFM (2.83 LPM)</td>
                  </tr>
                  <tr>
                    <td>Pompa</td>
                    <td>Zewnętrzna</td>
                  </tr>
                  <tr>
                    <td>Średnica króća wlotowego</td>
                    <td>1/8”</td>
                  </tr>
                  <tr>
                    <td>Średnica króća wylotowego</td>
                    <td>1/4”</td>
                  </tr>
                  <tr>
                    <td>Waga</td>
                    <td>0.78 lbs (0.35 kg)</td>
                  </tr>
                  <tr>
                    <td>Komunikacja</td>
                    <td>4-20mA: Ch1, Ch2</td>
                  </tr>
                  <tr>
                    <td>Obudowa</td>
                    <td>Stal nierdzewna</td>
                  </tr>
                  <tr>
                    <td>Zasilanie</td>
                    <td>24V DC</td>
                  </tr>
                  <tr>
                    <td>Źródło światła</td>
                    <td>Dioda laserowa</td>
                  </tr>
                  <tr>
                    <td>Kalibracja</td>
                    <td>Zgodnie z ISO 21501-4</td>
                  </tr>
                  <tr>
                    <td>Zerowanie</td>
                    <td>&lt; 1 cząstki / 5 minut zgodnie z ISO 21501-4</td>
                  </tr>
                  <tr>
                    <td>Limit stężenia</td>
                    <td>4,000,000 cząstek/ft3 (137,200,000 cząstek/m3) przy 10% utracie zgodności dla pomieszczeń czystych klas ISO 1-8</td>
                  </tr>
                  <tr>
                    <td>Diody informacyjne</td>
                    <td>Zasilanie, Przepływ, Serwis, Próbkowanie</td>
                  </tr>
                  <tr>
                    <td>Efektywność zliczania</td>
                    <td>50% dla cząstek 0.3µm, 100% dla cząstek &gt; 0.45 µm</td>
                  </tr>
                  <tr>
                    <td>Wymiary</td>
                    <td>10.5” x 7.65” x 3.35” (26.67 x 19.43 x 8.51 cm)</td>
                  </tr>
                </tbody>
              </table>
            </TextBlock>
          ),
        },
        {
          name: "Do pobrania",
          body: (
            <TextBlock>
              <LinkWithIconWrapper>
                <LinkWithIcon icon={<PdfIcon />} to={RemoteDatasheet}>
                  Dane techniczne
                </LinkWithIcon>
                <LinkWithIcon icon={<PdfIcon />} to={RemoteManual}>
                  Instrukcja obsługi
                </LinkWithIcon>
              </LinkWithIconWrapper>
            </TextBlock>
          ),
        },
      ],
    } as ProductContentDto,
  },
  {
    tile: {
      title: "Remote 5012",
      subtitle: "Producent: Lighthouse",
      price: "",
      img: Remote5012,
      to: RoutePath.PRODUCT,
    } as ProductTileDto,
    url: "remote-5012",
    content: {
      images: [LighthouseLogo],
      body: (
        <TextBlock>
          <TitleBlock title="Licznik cząstek Remote 5012" smallPaddingTop />
          <hr />
          <p>
            Wykorzystując najnowszą technologię laserowego optycznego liczenia cząstek, firma Lighthouse zaprojektowała Remote 5012 do ciągłej, bezproblemowej pracy.
            <br />
            <br />
            Dzięki minimalnemu zakresowi czułości 0,5 µm i natężeniu przepływu 0,1 CFM, Remote 5012 zapewnia ciągłe zbieranie danych w czasie rzeczywistym po opłacalnej cenie za punkt.
            <br />
            <br />
            Wykorzystując zewnętrzne źródło podciśnienia, Remote 5012 zapewnia wszechstronne opcje montażu i może być instalowany tam, gdzie przestrzeń jest na wagę złota.
            <br />
            <br />
            Remote 5012 zintegruje się bezproblemowo z systemami monitorowania i zarządzania dużymi obiektami.
            <br />
            <br />
          </p>
        </TextBlock>
      ),
      tabs: [
        {
          name: "Specifikacja",
          body: (
            <TextBlock>
              <table>
                <tbody>
                  <tr>
                    <td>Zakres rozmiarów cząstek</td>
                    <td>0.5 – 25.0 μm</td>
                  </tr>
                  <tr>
                    <td>Kanały pomiarowe</td>
                    <td>0.5, 5.0 µm lub 0.5, 10.0 µm</td>
                  </tr>
                  <tr>
                    <td>Przepływ</td>
                    <td>0.1 CFM (2.83 LPM)</td>
                  </tr>
                  <tr>
                    <td>Pompa</td>
                    <td>Zewnętrzna</td>
                  </tr>
                  <tr>
                    <td>Średnica króća wlotowego</td>
                    <td>1/8”</td>
                  </tr>
                  <tr>
                    <td>Średnica króća wylotowego</td>
                    <td>1/4”</td>
                  </tr>
                  <tr>
                    <td>Waga</td>
                    <td>0.78 lbs (0.35 kg)</td>
                  </tr>
                  <tr>
                    <td>Komunikacja</td>
                    <td>4-20mA: Ch1, Ch2</td>
                  </tr>
                  <tr>
                    <td>Obudowa</td>
                    <td>Stal nierdzewna</td>
                  </tr>
                  <tr>
                    <td>Zasilanie</td>
                    <td>24V DC</td>
                  </tr>
                  <tr>
                    <td>Źródło światła</td>
                    <td>Dioda laserowa</td>
                  </tr>
                  <tr>
                    <td>Kalibracja</td>
                    <td>Zgodnie z ISO 21501-4</td>
                  </tr>
                  <tr>
                    <td>Zerowanie</td>
                    <td>&lt; 1 cząstki / 5 minut zgodnie z ISO 21501-4</td>
                  </tr>
                  <tr>
                    <td>Limit stężenia</td>
                    <td>4,000,000 cząstek/ft3 (137,200,000 cząstek/m3) przy 10% utracie zgodności dla pomieszczeń czystych klas ISO 1-8</td>
                  </tr>
                  <tr>
                    <td>Diody informacyjne</td>
                    <td>Zasilanie, Przepływ, Serwis, Próbkowanie</td>
                  </tr>
                  <tr>
                    <td>Efektywność zliczania</td>
                    <td>50% dla cząstek 0.3µm, 100% dla cząstek &gt; 0.75 µm</td>
                  </tr>
                  <tr>
                    <td>Wymiary</td>
                    <td>10.5” x 7.65” x 3.35” (26.67 x 19.43 x 8.51 cm)</td>
                  </tr>
                </tbody>
              </table>
            </TextBlock>
          ),
        },
        {
          name: "Do pobrania",
          body: (
            <TextBlock>
              <LinkWithIconWrapper>
                <LinkWithIcon icon={<PdfIcon />} to={RemoteDatasheet}>
                  Dane techniczne
                </LinkWithIcon>
                <LinkWithIcon icon={<PdfIcon />} to={RemoteManual}>
                  Instrukcja obsługi
                </LinkWithIcon>
              </LinkWithIconWrapper>
            </TextBlock>
          ),
        },
      ],
    } as ProductContentDto,
  },
  {
    tile: {
      title: "Remote 5102",
      subtitle: "Producent: Lighthouse",
      price: "",
      img: Remote5102,
      to: RoutePath.PRODUCT,
    } as ProductTileDto,
    url: "remote-5102",
    content: {
      images: [LighthouseLogo],
      body: (
        <TextBlock>
          <TitleBlock title="Licznik cząstek Remote 5102" smallPaddingTop />
          <hr />
          <p>
            Wykorzystując najnowszą technologię laserowego optycznego liczenia cząstek, firma Lighthouse zaprojektowała Remote 5102 do ciągłej, bezproblemowej pracy.
            <br />
            <br />
            Dzięki minimalnemu zakresowi czułości 0,5 µm i natężeniu przepływu 1.0 CFM, Remote 5102 zapewnia ciągłe zbieranie danych w czasie rzeczywistym po opłacalnej cenie za punkt.
            <br />
            <br />
            Wykorzystując zewnętrzne źródło podciśnienia, Remote 5102 zapewnia wszechstronne opcje montażu i może być instalowany tam, gdzie przestrzeń jest na wagę złota.
            <br />
            <br />
            Remote 5102 zintegruje się bezproblemowo z systemami monitorowania i zarządzania dużymi obiektami.
            <br />
            <br />
          </p>
        </TextBlock>
      ),
      tabs: [
        {
          name: "Specifikacja",
          body: (
            <TextBlock>
              <table>
                <tbody>
                  <tr>
                    <td>Zakres rozmiarów cząstek</td>
                    <td>0.5 – 25.0 μm</td>
                  </tr>
                  <tr>
                    <td>Kanały pomiarowe</td>
                    <td>0.5, 5.0 µm lub 0.5, 10.0 µm</td>
                  </tr>
                  <tr>
                    <td>Przepływ</td>
                    <td>1.0 CFM (28.3 LPM)</td>
                  </tr>
                  <tr>
                    <td>Pompa</td>
                    <td>Zewnętrzna</td>
                  </tr>
                  <tr>
                    <td>Średnica króća wlotowego</td>
                    <td>1/4”</td>
                  </tr>
                  <tr>
                    <td>Średnica króća wylotowego</td>
                    <td>1/4”</td>
                  </tr>
                  <tr>
                    <td>Waga</td>
                    <td>0.78 lbs (0.35 kg)</td>
                  </tr>
                  <tr>
                    <td>Komunikacja</td>
                    <td>4-20mA: Ch1, Ch2</td>
                  </tr>
                  <tr>
                    <td>Obudowa</td>
                    <td>Stal nierdzewna</td>
                  </tr>
                  <tr>
                    <td>Zasilanie</td>
                    <td>24V DC</td>
                  </tr>
                  <tr>
                    <td>Źródło światła</td>
                    <td>Dioda laserowa</td>
                  </tr>
                  <tr>
                    <td>Kalibracja</td>
                    <td>Zgodnie z ISO 21501-4</td>
                  </tr>
                  <tr>
                    <td>Zerowanie</td>
                    <td>&lt; 1 cząstki / 5 minut zgodnie z ISO 21501-4</td>
                  </tr>
                  <tr>
                    <td>Limit stężenia</td>
                    <td>1,000,000 cząstek/ft3 (34,300,000 cząstek/m3) przy 10% utracie zgodności dla pomieszczeń czystych klas ISO 1-8</td>
                  </tr>
                  <tr>
                    <td>Diody informacyjne</td>
                    <td>Zasilanie, Przepływ, Serwis, Próbkowanie</td>
                  </tr>
                  <tr>
                    <td>Efektywność zliczania</td>
                    <td>50% dla cząstek 0.3µm, 100% dla cząstek &gt; 0.75 µm</td>
                  </tr>
                  <tr>
                    <td>Wymiary</td>
                    <td>10.5” x 7.65” x 3.35” (26.67 x 19.43 x 8.51 cm)</td>
                  </tr>
                </tbody>
              </table>
            </TextBlock>
          ),
        },
        {
          name: "Do pobrania",
          body: (
            <TextBlock>
              <LinkWithIconWrapper>
                <LinkWithIcon icon={<PdfIcon />} to={RemoteDatasheet}>
                  Dane techniczne
                </LinkWithIcon>
                <LinkWithIcon icon={<PdfIcon />} to={RemoteManual}>
                  Instrukcja obsługi
                </LinkWithIcon>
              </LinkWithIconWrapper>
            </TextBlock>
          ),
        },
      ],
    } as ProductContentDto,
  },
  {
    tile: {
      title: "Remote 3102",
      subtitle: "Producent: Lighthouse",
      price: "",
      img: Remote3102,
      to: RoutePath.PRODUCT,
    } as ProductTileDto,
    url: "remote-3102",
    content: {
      images: [LighthouseLogo],
      body: (
        <TextBlock>
          <TitleBlock title="Licznik cząstek Remote 3102" smallPaddingTop />
          <hr />
          <p>
            Wykorzystując najnowszą technologię laserowego optycznego liczenia cząstek, firma Lighthouse zaprojektowała Remote 3102 do ciągłej, bezproblemowej pracy.
            <br />
            <br />
            Dzięki minimalnemu zakresowi czułości 0,3 µm i natężeniu przepływu 1.0 CFM, Remote 3102 zapewnia ciągłe zbieranie danych w czasie rzeczywistym po opłacalnej cenie za punkt.
            <br />
            <br />
            Wykorzystując zewnętrzne źródło podciśnienia, Remote 3102 zapewnia wszechstronne opcje montażu i może być instalowany tam, gdzie przestrzeń jest na wagę złota.
            <br />
            <br />
            Remote 3102 zintegruje się bezproblemowo z systemami monitorowania i zarządzania dużymi obiektami.
            <br />
            <br />
          </p>
        </TextBlock>
      ),
      tabs: [
        {
          name: "Specifikacja",
          body: (
            <TextBlock>
              <table>
                <tbody>
                  <tr>
                    <td>Zakres rozmiarów cząstek</td>
                    <td>0.3 – 25.0 μm</td>
                  </tr>
                  <tr>
                    <td>Kanały pomiarowe</td>
                    <td>0.3, 0.5 µm</td>
                  </tr>
                  <tr>
                    <td>Przepływ</td>
                    <td>1.0 CFM (28.3 LPM)</td>
                  </tr>
                  <tr>
                    <td>Pompa</td>
                    <td>Zewnętrzna</td>
                  </tr>
                  <tr>
                    <td>Średnica króća wlotowego</td>
                    <td>1/4”</td>
                  </tr>
                  <tr>
                    <td>Średnica króća wylotowego</td>
                    <td>1/4”</td>
                  </tr>
                  <tr>
                    <td>Waga</td>
                    <td>2 lbs (0.9 kg)</td>
                  </tr>
                  <tr>
                    <td>Komunikacja</td>
                    <td>4-20mA: Ch1, Ch2</td>
                  </tr>
                  <tr>
                    <td>Obudowa</td>
                    <td>Stal nierdzewna</td>
                  </tr>
                  <tr>
                    <td>Zasilanie</td>
                    <td>24V DC</td>
                  </tr>
                  <tr>
                    <td>Źródło światła</td>
                    <td>Dioda laserowa</td>
                  </tr>
                  <tr>
                    <td>Kalibracja</td>
                    <td>Zgodnie z ISO 21501-4</td>
                  </tr>
                  <tr>
                    <td>Zerowanie</td>
                    <td>&lt; 1 cząstki / 5 minut zgodnie z ISO 21501-4</td>
                  </tr>
                  <tr>
                    <td>Limit stężenia</td>
                    <td>1,000,000 cząstek/ft3 (34,300,000 cząstek/m3) przy 10% utracie zgodności dla pomieszczeń czystych klas ISO 1-8</td>
                  </tr>
                  <tr>
                    <td>Diody informacyjne</td>
                    <td>Zasilanie, Przepływ, Serwis, Próbkowanie</td>
                  </tr>
                  <tr>
                    <td>Efektywność zliczania</td>
                    <td>50% dla cząstek 0.3µm, 100% dla cząstek &gt; 0.45 µm</td>
                  </tr>
                  <tr>
                    <td>Wymiary</td>
                    <td>3.5” x 4.2“ x 3.3” (8.89 x 10.66 x 8.38 cm)</td>
                  </tr>
                </tbody>
              </table>
            </TextBlock>
          ),
        },
        {
          name: "Do pobrania",
          body: (
            <TextBlock>
              <LinkWithIconWrapper>
                <LinkWithIcon icon={<PdfIcon />} to={RemoteDatasheet}>
                  Dane techniczne
                </LinkWithIcon>
                <LinkWithIcon icon={<PdfIcon />} to={RemoteManual}>
                  Instrukcja obsługi
                </LinkWithIcon>
              </LinkWithIconWrapper>
            </TextBlock>
          ),
        },
      ],
    } as ProductContentDto,
  },
  {
    tile: {
      title: "Remote 2014",
      subtitle: "Producent: Lighthouse",
      price: "",
      img: Remote2014,
      to: RoutePath.PRODUCT,
    } as ProductTileDto,
    url: "remote-2014",
    content: {
      images: [LighthouseLogo],
      body: (
        <TextBlock>
          <TitleBlock title="Licznik cząstek Remote 2014" smallPaddingTop />
          <hr />
          <p>
            Wykorzystując najnowszą technologię laserowego optycznego liczenia cząstek, firma Lighthouse zaprojektowała Remote 2014 do ciągłej, bezproblemowej pracy.
            <br />
            <br />
            Przy minimalnym zakresie czułości 0,2 µm i natężeniu przepływu 0,1 CFM, Remote 2014 zapewnia ciągłe zbieranie danych w czasie rzeczywistym po opłacalnej cenie za punkt.
            <br />
            <br />
            Wykorzystując zewnętrzne źródło podciśnienia, Remote 2014 zapewnia wszechstronne opcje montażu i może być instalowany tam, gdzie przestrzeń jest na wagę złota.
            <br />
            <br />
            Remote 2014 zintegruje się bezproblemowo z systemami monitorowania i zarządzania dużymi obiektami.
            <br />
            <br />
          </p>
        </TextBlock>
      ),
      tabs: [
        {
          name: "Specifikacja",
          body: (
            <TextBlock>
              <table>
                <tbody>
                  <tr>
                    <td>Zakres rozmiarów cząstek</td>
                    <td>0.2– 2.0 μm</td>
                  </tr>
                  <tr>
                    <td>Kanały pomiarowe</td>
                    <td>
                      0.2, 0.3 µm lub 0.2, 0.5 µm <br />
                      <br />
                      opcjonalnie 0.2, 0.3, 0.5, 1.0 µm lub 0.2, 0.3, 0.5, 2.0 µm
                    </td>
                  </tr>
                  <tr>
                    <td>Przepływ</td>
                    <td>0.1 CFM (2.83 LPM)</td>
                  </tr>
                  <tr>
                    <td>Pompa</td>
                    <td>Zewnętrzna</td>
                  </tr>
                  <tr>
                    <td>Średnica króća wlotowego</td>
                    <td>1/8”</td>
                  </tr>
                  <tr>
                    <td>Średnica króća wylotowego</td>
                    <td>1/4”</td>
                  </tr>
                  <tr>
                    <td>Waga</td>
                    <td>0.97 lbs (0.44 kg)</td>
                  </tr>
                  <tr>
                    <td>Komunikacja</td>
                    <td>
                      485 Modbus
                      <br />
                      <br />
                      RJ45
                    </td>
                  </tr>
                  <tr>
                    <td>Obudowa</td>
                    <td>Stal nierdzewna</td>
                  </tr>
                  <tr>
                    <td>Zasilanie</td>
                    <td>24V DC</td>
                  </tr>
                  <tr>
                    <td>Źródło światła</td>
                    <td>Dioda laserowa</td>
                  </tr>
                  <tr>
                    <td>Kalibracja</td>
                    <td>Zgodnie z ISO 21501-4</td>
                  </tr>
                  <tr>
                    <td>Zerowanie</td>
                    <td>&lt; 1 cząstki / 5 minut zgodnie z ISO 21501-4</td>
                  </tr>
                  <tr>
                    <td>Limit stężenia</td>
                    <td>4,000,000 cząstek/ft3 (137,200,000 cząstek/m3) przy 10% utracie zgodności dla pomieszczeń czystych klas ISO 1-8</td>
                  </tr>
                  <tr>
                    <td>Diody informacyjne</td>
                    <td>Zasilanie, Przepływ, Serwis, Próbkowanie</td>
                  </tr>
                  <tr>
                    <td>Efektywność zliczania</td>
                    <td>50% dla cząstek 0.2µm, 100% dla cząstek &gt; 0.3 µm</td>
                  </tr>
                  <tr>
                    <td>Wymiary</td>
                    <td>1.7” x 4.2” x 3” (4.3 x 10.7 x 7.6 cm)</td>
                  </tr>
                </tbody>
              </table>
            </TextBlock>
          ),
        },
        {
          name: "Do pobrania",
          body: (
            <TextBlock>
              <LinkWithIconWrapper>
                <LinkWithIcon icon={<PdfIcon />} to={RemoteDatasheet}>
                  Dane techniczne
                </LinkWithIcon>
                <LinkWithIcon icon={<PdfIcon />} to={RemoteManualSeria4A}>
                  Instrukcja obsługi (Gen-A)
                </LinkWithIcon>
                <LinkWithIcon icon={<PdfIcon />} to={RemoteManualSeria4B}>
                  Instrukcja obsługi (Gen-B)
                </LinkWithIcon>
              </LinkWithIconWrapper>
            </TextBlock>
          ),
        },
      ],
    } as ProductContentDto,
  },
  {
    tile: {
      title: "Remote 3014",
      subtitle: "Producent: Lighthouse",
      price: "",
      img: Remote3014,
      to: RoutePath.PRODUCT,
    } as ProductTileDto,
    url: "remote-3014",
    content: {
      images: [LighthouseLogo],
      body: (
        <TextBlock>
          <TitleBlock title="Licznik cząstek Remote 3014" smallPaddingTop />
          <hr />
          <p>
            Wykorzystując najnowszą technologię laserowego optycznego liczenia cząstek, firma Lighthouse zaprojektowała Remote 3014 do ciągłej, bezproblemowej pracy.
            <br />
            <br />
            Dzięki minimalnemu zakresowi czułości 0,3 µm i natężeniu przepływu 0,1 CFM, Remote 3014 zapewnia ciągłe zbieranie danych w czasie rzeczywistym po opłacalnej cenie za punkt.
            <br />
            <br />
            Wykorzystując zewnętrzne źródło podciśnienia, Remote 3014 zapewnia wszechstronne opcje montażu i może być instalowany tam, gdzie przestrzeń jest na wagę złota.
            <br />
            <br />
            Remote 3014 bezproblemowo zintegruje się z systemami monitorowania i zarządzania dużymi obiektami.
            <br />
            <br />
          </p>
        </TextBlock>
      ),
      tabs: [
        {
          name: "Specifikacja",
          body: (
            <TextBlock>
              <table>
                <tbody>
                  <tr>
                    <td>Zakres rozmiarów cząstek</td>
                    <td>0.3 – 25.0 μm</td>
                  </tr>
                  <tr>
                    <td>Kanały pomiarowe</td>
                    <td>
                      0.3, 0.5 µm lub 0.3, 1.0 µm lub 0.3, 3.0 µm
                      <br />
                      <br />
                      opcjonalnie 0.3, 0.5, 1.0, 5.0 µm
                    </td>
                  </tr>
                  <tr>
                    <td>Przepływ</td>
                    <td>0.1 CFM (2.83 LPM)</td>
                  </tr>
                  <tr>
                    <td>Pompa</td>
                    <td>Zewnętrzna</td>
                  </tr>
                  <tr>
                    <td>Średnica króća wlotowego</td>
                    <td>1/8”</td>
                  </tr>
                  <tr>
                    <td>Średnica króća wylotowego</td>
                    <td>1/4”</td>
                  </tr>
                  <tr>
                    <td>Waga</td>
                    <td>0.97 lbs (0.44 kg)</td>
                  </tr>
                  <tr>
                    <td>Komunikacja</td>
                    <td>
                      485 Modbus
                      <br />
                      <br />
                      RJ45
                    </td>
                  </tr>
                  <tr>
                    <td>Obudowa</td>
                    <td>Stal nierdzewna</td>
                  </tr>
                  <tr>
                    <td>Zasilanie</td>
                    <td>24V DC</td>
                  </tr>
                  <tr>
                    <td>Źródło światła</td>
                    <td>Dioda laserowa</td>
                  </tr>
                  <tr>
                    <td>Kalibracja</td>
                    <td>Zgodnie z ISO 21501-4</td>
                  </tr>
                  <tr>
                    <td>Zerowanie</td>
                    <td>&lt; 1 cząstki / 5 minut zgodnie z ISO 21501-4</td>
                  </tr>
                  <tr>
                    <td>Limit stężenia</td>
                    <td>4,000,000 cząstek/ft3 (137,200,000 cząstek/m3) przy 10% utracie zgodności dla pomieszczeń czystych klas ISO 1-8</td>
                  </tr>
                  <tr>
                    <td>Diody informacyjne</td>
                    <td>Zasilanie, Przepływ, Serwis, Próbkowanie</td>
                  </tr>
                  <tr>
                    <td>Efektywność zliczania</td>
                    <td>50% dla cząstek 0.3µm, 100% dla cząstek &gt; 0.45 µm</td>
                  </tr>
                  <tr>
                    <td>Wymiary</td>
                    <td>1.7” x 4.2” x 3” (4.3 x 10.7 x 7.6 cm)</td>
                  </tr>
                </tbody>
              </table>
            </TextBlock>
          ),
        },
        {
          name: "Do pobrania",
          body: (
            <TextBlock>
              <LinkWithIconWrapper>
                <LinkWithIcon icon={<PdfIcon />} to={RemoteDatasheet}>
                  Dane techniczne
                </LinkWithIcon>
                <LinkWithIcon icon={<PdfIcon />} to={RemoteManualSeria4A}>
                  Instrukcja obsługi (Gen-A)
                </LinkWithIcon>
                <LinkWithIcon icon={<PdfIcon />} to={RemoteManualSeria4B}>
                  Instrukcja obsługi (Gen-B)
                </LinkWithIcon>
              </LinkWithIconWrapper>
            </TextBlock>
          ),
        },
      ],
    } as ProductContentDto,
  },
] as Array<ProductDto>;

import React from "react";
import { ProductContentDto, ProductDto } from "../../../../dto/productDto";
import { ProductTileDto } from "../../../../dto/productTileDto";
import { RoutePath } from "../../../../route-paths";

import TextBlock from "../../../../components/writing-tools/text-block/text-block";
import TitleBlock from "../../../../components/title-block/title-block";
import LinkWithIconWrapper from "../../../../components/link-with-icon/link-with-icon-wrapper";
import LighthouseLogo from "./../../../../assets/products/LWS LOGO - BW-transparent.png";
import KlotzLogo from "./../../../../assets/products/przenosne_liczniki_czastek/klotz_logo.jpg";
import AC100HImg from "./../../../../assets/products/poborniki_mikrobiologiczne/b_ActiveCount100H_Screen_Image_Handle_down3.jpeg";
import AC100HImg2 from "./../../../../assets/products/poborniki_mikrobiologiczne/b_8de68feb-5dea-4e24-b96b-4386c63e9f92.jpeg";
import AC100HImg3 from "./../../../../assets/products/poborniki_mikrobiologiczne/b_97bb9233-6c8d-41c2-874b-378e0ad9725d_1.jpeg";
import AC100HImg4 from "./../../../../assets/products/poborniki_mikrobiologiczne/b_a3bddc60-8f76-4ea3-9ae9-b0ff1ba41776_1.jpeg";
import FH6Img from "./../../../../assets/products/poborniki_mikrobiologiczne/impactor-klotz.png";
import FH6AccessoriesImg from "./../../../../assets/products/poborniki_mikrobiologiczne/impactor-accessories.png";
import FH6WithAdapterImg from "./../../../../assets/products/poborniki_mikrobiologiczne/impactor-with-adapter.png";
import AC100BrochurePL from "./../../../../assets/products/poborniki_mikrobiologiczne/Ulotka_PL_AC_100H-2.pdf";
import AC100BrochureENG from "./../../../../assets/products/poborniki_mikrobiologiczne/ActiveCount_AC100H_and_AC25H_Datasheet_191010.pdf";
import FH6BrochureENG from "./../../../../assets/products/poborniki_mikrobiologiczne/E-Impaktor-fh6.doc.pdf";
import FH6BrochurePL from "./../../../../assets/products/poborniki_mikrobiologiczne/Ulotka PL FH-6 14-0401.pdf";
import LinkWithIcon from "../../../../components/link-with-icon/link-with-icon";
import PdfIcon from "../../../../components/pdf-icon/pdf-icon";

export const ProductsPobornikiMikrobiologiczne = [
  {
    tile: {
      title: "AC-100H",
      subtitle: "Producent: Lighthouse",
      price: "",
      img: AC100HImg,
      to: RoutePath.PRODUCT,
    } as ProductTileDto,
    url: "ac-100h",
    content: {
      images: [AC100HImg2, AC100HImg3, AC100HImg4, LighthouseLogo],
      body: (
        <TextBlock>
          <TitleBlock title="Pobornik mikrobiologiczny AC-100H" smallPaddingTop />
          <hr />
          <h4>Próbnik drobnoustrojów z filtrem wydechowym z filtrem Hepa</h4>
          <p>
            Próbnik drobnoustrojów z filtrem wydechowym z filtrem Hepa ActiveCount100H to przenośny, aktywny próbnik powietrza o wysokiej wydajności, odpowiedni do stosowania w pomieszczeniach czystych i środowiskach aseptycznych. ActiveCount100H jest zgodny z normą ISO 14698. ActiveCount100H oferuje samoregulującą kontrolę przepływu, aby zapewnić dokładne próbkowanie oraz tryby próbkowania ciągłego i okresowego dla optymalnej elastyczności próbkowania.
            <br />
            <br />
            Głowica impaktora, osłona przeciwpyłowa i regulowany uchwyt na szalkę Petriego można w całości sterylizować w autoklawie. Elementy te można łatwo usunąć i zainstalować na chemicznie kompatybilnej i dającej się odkażać obudowie ze stali nierdzewnej.
            <br />
            <br />
            Wylot tego urządzenia zabezpieczony filtrem HEPA, aby zapewnić, że ActiveCount100H nie zanieczyści krytycznych środowisk.
            <br />
            <br />
            Opcjonalna zdalna głowica do pobierania próbek minimalizuje zakłócenia jednokierunkowego przepływu powietrza w komorze bezpieczeństwa biologicznego lub stanowisku z przepływem laminarnym.
            <br />
            <br />
          </p>
        </TextBlock>
      ),
      tabs: [
        {
          name: "Specifikacja",
          body: (
            <TextBlock>
              <table>
                <tbody>
                  <tr>
                    <td>Przepływ</td>
                    <td>100 LPM ± 4%</td>
                  </tr>
                  <tr>
                    <td>Obudowa</td>
                    <td>Stal niedzewna 316L</td>
                  </tr>
                  <tr>
                    <td>Głowica</td>
                    <td>Stal niedzewna 316L 300 otworów</td>
                  </tr>
                  <tr>
                    <td>Pokrywa przeciwpyłowa</td>
                    <td>Stal nierdzewna316L, odpowiednie do sterylizacji w autoklawie</td>
                  </tr>
                  <tr>
                    <td>Podstawka szalki Petriego</td>
                    <td>Wyjmowana, odpowiednia do sterylizacji w autoklawie</td>
                  </tr>
                  <tr>
                    <td>Wylot</td>
                    <td>Filtrowanie HEPA, wychwytuje 99.97% cząstek powyżej 0.3 µm</td>
                  </tr>
                  <tr>
                    <td>Bateria</td>
                    <td>8 godzin ciągłej pracy, 10 godzin normalnej pracy, Li-ion, czas ładowania 4 godziny</td>
                  </tr>
                  <tr>
                    <td>Waga</td>
                    <td>3 kg</td>
                  </tr>
                  <tr>
                    <td>Zapis danych</td>
                    <td>Nielimitowany; zapisywany na USB w formacie .csv</td>
                  </tr>
                  <tr>
                    <td>Zasilanie</td>
                    <td>24 VDC (100-240V ~ 1.5A 50-60Hz)</td>
                  </tr>
                  <tr>
                    <td>Wymiary</td>
                    <td>19.4 x 12.0 x 12.0 cm</td>
                  </tr>
                </tbody>
              </table>
            </TextBlock>
          ),
        },
        {
          name: "Do pobrania",
          body: (
            <TextBlock>
              <LinkWithIconWrapper>
                <LinkWithIcon icon={<PdfIcon />} to={AC100BrochurePL}>
                  Ulotka PL
                </LinkWithIcon>
                <LinkWithIcon icon={<PdfIcon />} to={AC100BrochureENG}>
                  Ulotka ENG
                </LinkWithIcon>
              </LinkWithIconWrapper>
            </TextBlock>
          ),
        },
      ],
    } as ProductContentDto,
  },
  {
    tile: {
      title: "FH-6",
      subtitle: "Producent: Klotz",
      price: "",
      img: FH6Img,
      to: RoutePath.PRODUCT,
    } as ProductTileDto,
    url: "fh-6",
    content: {
      images: [FH6AccessoriesImg, FH6WithAdapterImg, KlotzLogo],
      body: (
        <TextBlock>
          <TitleBlock title="Pobornik mikrobiologiczny FH-6" smallPaddingTop />
          <hr />
          <p>mpaktor FH5 jest urządzeniem pomiarowym służącym do poboru prób powietrza w celu badania w nim stężenia drobnoustrojów. Może on pobierać próby na podłoża aktywne - standardowe szalki Petriego (tzw. metoda impaktorowa), oraz na filtry (metoda filtracyjna).</p>
        </TextBlock>
      ),
      tabs: [
        {
          name: "Specifikacja",
          body: (
            <TextBlock>
              <table>
                <tbody>
                  <tr>
                    <td>Metoda</td>
                    <td>Impaktorowa i filtracyjna</td>
                  </tr>
                  <tr>
                    <td>Pobierane objętości</td>
                    <td>25 - 1000 L</td>
                  </tr>
                  <tr>
                    <td rowSpan={2}>Przepływ</td>
                    <td>100 LPM (metoda impaktorowa)</td>
                  </tr>
                  <tr>
                    <td>30 LPM (metoda filtracyjna)</td>
                  </tr>
                  <tr>
                    <td>Czas ciągłej pracy</td>
                    <td>minimum 7 h</td>
                  </tr>
                  <tr>
                    <td>Wymiary</td>
                    <td>25 x 15 x 15 cm</td>
                  </tr>
                  <tr>
                    <td>Waga</td>
                    <td>3 kg</td>
                  </tr>
                  <tr>
                    <td>Zasilanie</td>
                    <td>Akumulator 16.8V/450mA</td>
                  </tr>
                </tbody>
              </table>
            </TextBlock>
          ),
        },
        {
          name: "Do pobrania",
          body: (
            <TextBlock>
              <LinkWithIconWrapper>
                <LinkWithIcon icon={<PdfIcon />} to={FH6BrochurePL}>
                  Ulotka PL
                </LinkWithIcon>
                <LinkWithIcon icon={<PdfIcon />} to={FH6BrochureENG}>
                  Ulotka ENG
                </LinkWithIcon>
              </LinkWithIconWrapper>
            </TextBlock>
          ),
        },
      ],
    } as ProductContentDto,
  },
] as Array<ProductDto>;

import React from "react";
import "./partners.scss";
import PartnerSensidyne from "../../assets/partnerSensidyne.png";
import PartnerSvantek from "../../assets/partnerSvantek.svg";
import PartnerLighthouse from "../../assets/partnerLighthouse.png";
import PartnerTopas from "../../assets/partnerTopas.png";
import PartnerGrimm from "../../assets/partnerGrimm.png";
import PartnerKlotz from "../../assets/partnerKlotz.jpeg";
import PartnerAti from "../../assets/partnerAti.png";
import PartnerTesto from "../../assets/partnerTesto.svg";
import PartnerMaassen from "../../assets/partnerMaassen.png";
import PartnerDelta from "../../assets/partnerDeltaOhm.png";
import PartnerTecora from "../../assets/partnerTecora.png";
import PartnerMicrotrac from "../../assets/partnerMicrotrac.svg";
import PartnerMicrorad from "../../assets/partnerMicrorad.png";
import PartnerBs from "../../assets/partnerBsPartikel.png";
import PartnerDado from "../../assets/partnerDado.png";
import PartnerBlock from "./partner-block";
import Row from "../../components/grid/row";

const Partners: React.FC = () => {
  return (
    <Row gap={16} className="partners-wrapper">
      <PartnerBlock img={PartnerSensidyne} alt="Sensidyne" href="https://www.sensidyne.com/" />
      <PartnerBlock img={PartnerSvantek} alt="Svantek" href="https://svantek.com/pl/" />
      <PartnerBlock img={PartnerLighthouse} alt="Lighthouse" href="https://www.golighthouse.com/" />
      <PartnerBlock img={PartnerTopas} alt="Topas" href="https://www.topas-gmbh.de/en/" />
      <PartnerBlock img={PartnerGrimm} alt="Grimm" href="https://www.grimm-aerosol.com/" />
      <PartnerBlock img={PartnerKlotz} alt="Klotz" href="https://www.fa-klotz.de/particlecounters/" />
      <PartnerBlock img={PartnerAti} alt="Ati" href="https://www.atitest.com/products/" />
      <PartnerBlock img={PartnerTesto} alt="Testo" href="https://www.testo.com/pl-PL/" />
      <PartnerBlock img={PartnerMaassen} alt="Maassen" href="https://www.maassen-gmbh.de/" />
      <PartnerBlock img={PartnerDelta} alt="DeltaOhm" href="https://www.deltaohm.com/" />
      <PartnerBlock img={PartnerTecora} alt="Tecora" href="https://www.tecora.com/en/home/" />
      <PartnerBlock img={PartnerBs} alt="BsPartikel" href="https://bs-partikel.de/ENGL/size.htm" />
      <PartnerBlock img={PartnerMicrorad} alt="Microrad" href="https://www.microrad.it/en/" />
      <PartnerBlock img={PartnerMicrotrac} alt="Microtrac" href="https://www.microtrac.com/" />
      <PartnerBlock img={PartnerDado} alt="DadoLab" href="https://www.dadolab.com/en/" />
    </Row>
  )
}

export default Partners;
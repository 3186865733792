import React from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { RoutePath } from "../../route-paths";
import ContactSection from "../../sections/contact-section/contact-section";
import "./contact-page.scss";
import HeroSectionWrapper from "./sections/hero-section-wrapper";

const ContactPage: React.FC = () => {
  return (
    <div className="contact-page">
      <BreadcrumbsItem to={RoutePath.CONTACT}>Kontakt</BreadcrumbsItem>
      <HeroSectionWrapper />
      <ContactSection />
    </div>
  );
};

export default ContactPage;

import React, {useRef} from "react";
import PartnersSection from "../../sections/partners-section/partners-section";
import ClientsSection from "../../sections/clients-section/clients-section";
import ContactSection from "../../sections/contact-section/contact-section";
import InfoTilesSectionWrapper from "./sections/info-tiles-section-wrapper";
import SplitSectionWrapper from "./sections/split-section-wrapper";
import HeroSectionWrapper from "./sections/hero-section-wrapper";
import "./home-page.scss";
import CtaSectionWrapper from "./sections/cta-section-wrapper";
import TitleBlock from "../../components/title-block/title-block";
import {RoutePath} from "../../route-paths";

const HomePage: React.FC = () => {
  const heroTarget = useRef<any>();

  return (
    <div className="home-page">
      <TitleBlock smallPaddingTop
                  smallTitle="Dokumentacja do głowic do poboru pyłu w związku z normą PN-Z-04507:2022-05 i PN-Z-04508:2022-05."
                  subtitle="FH019 - głowica do frakcji wdychalnej, FH022 - głowica do frakcji respirabilnej"
                  link={RoutePath.BLOG__DOKUMENTACJA_DO_GLOWIC}
                  linktxt={"Kliknij tutaj"}
      />
      <HeroSectionWrapper scrollToRef={heroTarget}/>
      <InfoTilesSectionWrapper setRef={heroTarget}/>
      <SplitSectionWrapper/>
      <CtaSectionWrapper/>
      <PartnersSection/>
      {/*TODO: ADD RECOMMENDATIONS*/}
      {/*<ClientsSection />*/}
      <ContactSection/>
    </div>
  );
};

export default HomePage;

import React from "react";
import TopLink from "../../components/top-link/top-link";
import DefaultIcon from "./../../assets/logo_icon.svg";
import Row from "../grid/row";
import Col from "../grid/col";
import "./split-block.scss";
import ArrowIcon from "./../../assets/link_arrow_icon.svg";

interface Props {
  to?: string;
  toLabel?: string;
  title: string;
  img?: string;
  reversed?: boolean;
  children: React.ReactNode;
}

const SplitBlock: React.FC<Props> = (props: Props) => {
  const { to, toLabel, img = DefaultIcon, children, title, reversed } = props;

  const Split = () => {
    return <Row className={`split-block ${reversed ? "split-block--reversed" : ""}`} gap={0}>
      <Col size={6} className="split-block__image" style={{ backgroundImage: `url("${img}")` }} />
      {reversed ? <Col size={1} /> : null}
      <Col size={4} offset={1} className="split-block__content">
        <h3>{title}</h3>
        <p>{children}</p>
        {to ? <div className="split-block__content__action" ><span>{toLabel}</span><img src={ArrowIcon} alt={title} /></div> : null}
      </Col>
    </Row>
  }
  return (
    to ? <TopLink to={to} title={title}><Split /></TopLink > : <Split />
  )
}

export default SplitBlock;
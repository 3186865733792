import React from "react";
import ContactSection from "../../sections/contact-section/contact-section";
import "./services-page.scss";
import CtaSectionWrapper from "./sections/cta-section-wrapper";
import { Switch, Route } from "react-router-dom";
import { RoutePath } from "../../route-paths";
import ServicesNav from "./sections/services-nav";
import ServicesSwitch from "./sections/services-switch";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";

const ServicesPage: React.FC = () => {

  return (
    <div className="services-page">
      <BreadcrumbsItem to={RoutePath.SERVICES}>Usługi</BreadcrumbsItem>
      <Switch>
        <Route exact path={RoutePath.SERVICES + "/*"} render={() => <ServicesSwitch />} />
        <Route exact path={RoutePath.SERVICES} render={() => <ServicesNav />} />
      </Switch>
      <CtaSectionWrapper />
      <ContactSection />
    </div>
  );
};

export default ServicesPage;

import React from "react";
import "./button.scss";

interface Props {
    children: React.ReactNode;
    color?: 'dark' | 'light' | 'transparent';
    onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

const Button: React.FC<Props> = (props: Props) => {
    const { color = "dark", children, onClick } = props;
    return (
        <button className={`button button--${color}`} onClick={onClick}>
            {children}
        </button>
    )
}

export default Button;
import React from "react";
import { ProductContentDto, ProductDto } from "../../../../dto/productDto";
import { ProductTileDto } from "../../../../dto/productTileDto";
import { RoutePath } from "../../../../route-paths";

import TextBlock from "../../../../components/writing-tools/text-block/text-block";
import TitleBlock from "../../../../components/title-block/title-block";
import LinkWithIconWrapper from "../../../../components/link-with-icon/link-with-icon-wrapper";
import LinkWithIcon from "../../../../components/link-with-icon/link-with-icon";
import PdfIcon from "../../../../components/pdf-icon/pdf-icon";
import GrimmLogo from "./../../../../assets/products/grimm-logo.png";
import Grimm11DImg from "./../../../../assets/products/spektrometry_aerozolu/11-D_aDust-Decoder_full-view-front.png";
import Grimm11DImgA from "./../../../../assets/products/spektrometry_aerozolu/11-D_Dust-Decoder_full-view-tilted.png";
import Grimm11DImgB from "./../../../../assets/products/spektrometry_aerozolu/11-D-1145A_Dust-Decoder-in bag_full-view-perspective.png";
import Grimm11DDatasheetPL from "./../../../../assets/products/spektrometry_aerozolu/ULOTKA_GRIMM_11D_20-1100.pdf";
import Grimm11DDatasheetENG from "./../../../../assets/products/spektrometry_aerozolu/Datasheet_11-D_ENG.pdf";
import Grimm11DManual from "./../../../../assets/products/spektrometry_aerozolu/Manual_E_11-D_V2-3_(25-03-21).pdf";
import GrimmMiniWRASImg from "./../../../../assets/products/spektrometry_aerozolu/1371_Mini-Wide-Range-Aerosol-Spectrometer_full-view-handle-perspective.png";
import GrimmMiniWRASImgA from "./../../../../assets/products/spektrometry_aerozolu/1371_Mini-Wide-Range-Aerosol-Spectrometer_full-view-top.png";
import GrimmMiniWRASDatasheetPL from "./../../../../assets/products/spektrometry_aerozolu/ULOTKA_GRIMM_MiniWRAS1371_20-1100.pdf";
import GrimmMiniWRASDatasheetENG from "./../../../../assets/products/spektrometry_aerozolu/MiniWRAS_1371.pdf";
import RelatedProducts from "../../../../components/RelatedProducts/RelatedProducts";

export const ProductsAnalizatoryOptyczneZapylenia = [
  {
    tile: {
      title: "Grimm 11-D",
      subtitle: "Producent: Grimm",
      price: "",
      img: Grimm11DImg,
      to: RoutePath.PRODUCT,
    } as ProductTileDto,
    url: "grimm-11D",
    content: {
      images: [Grimm11DImgA, Grimm11DImgB, GrimmLogo],
      body: (
        <TextBlock>
          <TitleBlock title="Spektrometr aerozolu 11-D" smallPaddingTop />
          <hr />
          <h4>
            Uniwersalny 11-D:
            <br />
            <br />
          </h4>

          <p>
            Model 11-D, w swojej kompaktowej i wytrzymałej konstrukcji, łączy w sobie wszystkie zalety poprzednich przenośnych spektrometrów aerozolowych GRIMM z ulepszoną detekcją optyczną, długotrwałą pracą na baterii i łatwiejszą obsługą.
            <br />
            <br />
            Ta konfiguracja umieszcza 11-D w wiodącej pozycji przenośnych spektrometrów aerozolowych do monitorowania pyłu wdychanego, klatki piersiowej i respirabilnego, wartości PM i stężenia cząstek.
            <br />
            <br />
            11-D jest optymalnym rozwiązaniem do niezawodnych, elastycznych pomiarów w czasie rzeczywistym do badań aerozoli i jakości powietrza w pomieszczeniach, np. na stanowiskach pracy, we wnętrzach pojazdów lub do analizy procesów.
            <br />
            <br />
          </p>

          <h4>Ogólne informacje:</h4>
          <li>
            Algorytmy Enviro: TSP, PM10, PM4, PM2,5 PM1, PMcoarse <br />
            Algorytmy IAQ: pm10, pm2.5, pm1, frakcja wdychalna, torakalna i respirabilna
          </li>
          <li>Monitorowanie w czasie rzeczywistym liczby cząstek do wielkości, powierzchni cząstek do wielkości, masy cząstek do wielkości</li>
          <li>Dodatkowe informacje o liczbie cząstek, powierzchni cząstek i rozkładzie masy pyłu</li>
          <li>31 równoodległych kanałów wielkości</li>
          <li>Zintegrowany 47 mm filtr PTFE (podwójna technologia GRIMM)</li>
          <li>Wszechstronne interfejsy akwizycji danych i komunikacji (Bluetooth, USB, Ethernet, RS-232)</li>
          <li>Powietrze płuczące do ochrony lasera i detektora w celi optycznej</li>
          <li>Wewnętrzny czujnik temperatury (T) i wilgotności względnej (RH) w celi optycznej</li>
          <li>Całkowity przepływ wlotowy (1,2 l/min) analizowany w celce optycznej</li>
          <li>Autotest wszystkich elementów optycznych i pneumatycznych w celu zapewnienia najwyższych standardów jakości</li>
          <br />
          <br />

          <h4>Zastosowania:</h4>
          <li>Badania aerozoli</li>
          <li>PM2,5 w środowisku wewnętrznym zgodnie z VDI 4300, part 11</li>
          <li>Jakość powietrza w pomieszczeniach (IAQ) w kontroli procesów budynków i pojazdów w przemyśle</li>
          <li>Monitorowanie miejsca pracy (frakcja wdychalna, torakalna i respirabilna) zgodnie z EN 481</li>
          <li>Monitorowanie dopuszczalnego limitu narażenia (PEL) z wysoką rozdzielczością czasową</li>
          <li>Pomiary zanieczyszczeń pyłowych</li>
        </TextBlock>
      ),
      tabs: [
        {
          name: "Specifikacja",
          body: (
            <TextBlock>
              <table>
                <tbody>
                  <tr>
                    <td>Zakres pomiaru</td>
                    <td>frakcje pyłu (EN 481) wdychalna, torakalna, respirabilna Algorytm Enviro: TSP, PM10, PM4, PM2,5, PM1, PMcoarse | Algorytm IAQ pm10, pm2.5 pm1 koncentracja i rozkład wielkości</td>
                  </tr>
                  <tr>
                    <td>Zakres kanałów pomiarowych</td>
                    <td>0.253 - 35.15 μm</td>
                  </tr>
                  <tr>
                    <td>Ilość kanałów</td>
                    <td>Łącznie 31, w równej odległości</td>
                  </tr>
                  <tr>
                    <td>Zakres stężenia w ilości cząstek</td>
                    <td>0 - 5 300 000 cząstek/litr, diluter dostępny dla wyższych stężeń</td>
                  </tr>
                  <tr>
                    <td>Zakres stężenia masowego</td>
                    <td>0 μg/m3 - 100 mg/m3</td>
                  </tr>
                  <tr>
                    <td>Zasada wykrywania cząstek</td>
                    <td>rozpraszanie światła na pojedynczych cząsteczkach za pomocą lasera diodowego;</td>
                  </tr>
                  <tr>
                    <td>Detektor</td>
                    <td>szybkie przetwarzanie sygnału, 2 x 16 kanałów surowych danych</td>
                  </tr>
                  <tr>
                    <td>Rozdzielczość czasowa</td>
                    <td>6 s, 31 kanałów (wybieralne interwały zapisu 6 s, 1, 5, 10, 15, 30, 60 min) 1 s, 16 kanałów wielkości (0,253 μm &lt; Dp &lt; 2,982 μm &lt; Dp &lt; 35,15 μm)</td>
                  </tr>
                  <tr>
                    <td>Przepływ objętościowy</td>
                    <td>1,2 l/min, ± 3% stała dzięki samoregulacji, zgodnie z ISO 21501-1; automatyczna korekta wysokości</td>
                  </tr>
                  <tr>
                    <td>Powietrze do płukania</td>
                    <td>0,4 L/min, ochrona optyki laserowej, powietrze referencyjne do autotestu</td>
                  </tr>
                  <tr>
                    <td>Kontrola grawimetryczna</td>
                    <td>filtr 47 mm PTFE</td>
                  </tr>
                  <tr>
                    <td>Interfejsy</td>
                    <td>Bluetooth, USB, Ethernet, RS-232, SD card</td>
                  </tr>
                  <tr>
                    <td>Wejście analogowe</td>
                    <td>1 port (0 - 10 V) dla czujników zewnętrznych</td>
                  </tr>
                  <tr>
                    <td>Zasilacz</td>
                    <td>wejście: 100 - 240 VAC, 47 - 60 Hz, wyjście: 12 VDC, 2,5 A</td>
                  </tr>
                  <tr>
                    <td>Pobór energii</td>
                    <td>5.4 W</td>
                  </tr>
                  <tr>
                    <td>Bateria</td>
                    <td>inteligentna bateria litowo-jonowa 10,8 V, 6,8 Ah zapewniająca co najmniej 8 godzin pracy</td>
                  </tr>
                  <tr>
                    <td>Wymiary</td>
                    <td>27 x 13 x 7 cm / 10,5 x 4,8 x 2,6 " (L x W x H)</td>
                  </tr>
                  <tr>
                    <td>Waga</td>
                    <td>2.1 kg / 4.6 lbs</td>
                  </tr>
                  <tr>
                    <td>Warunki pracy</td>
                    <td>+4 do +40°C (39 - 104°F), wilgotność względna &lt; 95%, niekondensujące, niekorozyjne i niewybuchowe gazy</td>
                  </tr>
                  <tr>
                    <td>Przechowywanie i transport</td>
                    <td>-20 do +50°C (-4 - 122°F), wilgotność względna &lt; 95%</td>
                  </tr>
                </tbody>
              </table>
            </TextBlock>
          ),
        },
        {
          name: "Do pobrania",
          body: (
            <TextBlock>
              <LinkWithIconWrapper>
                <LinkWithIcon icon={<PdfIcon />} to={Grimm11DDatasheetPL}>
                  Ulotka PL
                </LinkWithIcon>
                <LinkWithIcon icon={<PdfIcon />} to={Grimm11DDatasheetENG}>
                  Ulotka ENG
                </LinkWithIcon>
                <LinkWithIcon icon={<PdfIcon />} to={Grimm11DManual}>
                  Instrukcja
                </LinkWithIcon>
              </LinkWithIconWrapper>
            </TextBlock>
          ),
        },
        {
          name: "Produkty Powiązane",
          body: <RelatedProducts items={[]} />,
        },
      ],
    } as ProductContentDto,
  },
  {
    tile: {
      title: "MiniWRAS",
      subtitle: "Producent: Grimm",
      price: "",
      img: GrimmMiniWRASImg,
      to: RoutePath.PRODUCT,
    } as ProductTileDto,
    url: "grimm-miniwras",
    content: {
      images: [GrimmMiniWRASImgA, GrimmLogo],
      body: (
        <TextBlock>
          <TitleBlock title="Spektrometr aerozolu MiniWRAS" smallPaddingTop />
          <hr />
          <h4>
            Ruchliwość elektryczna i optyczne zliczanie i wymiarowanie cząstek
            <br />
            <br />
          </h4>

          <p>
            Kompaktowy Mini-Szerokozakresowy Spektrometr Aerozolu (MiniWRAS) jest jedynym przenośnym przyrządem na rynku, który umożliwia jednoczesne i precyzyjne monitorowanie w czasie rzeczywistym zarówno mikronów, jak i nanocząstek.
            <br />
            <br />
            Zaprojektowany i specjalnie zbudowany do monitorowania jakości powietrza w pomieszczeniach, MiniWRAS jest odpowiednim, najnowocześniejszym systemem, który łączy optyczne i elektryczne wykrywanie cząstek w jednym urządzeniu.
            <br />
            <br />
            System ten oferuje wiele funkcji, takich jak ultraszeroki zakres wielkości cząstek od 10 nm do 35 µm, jednoczesny pomiar PM1, PM2,5 i PM10, 41 kanałów o wysokiej rozdzielczości wielkości cząstek, zdalna transmisja danych i kontrola przyrządu. Łatwy w użyciu, transporcie i wdrażaniu, może być elastycznie stosowany w różnych projektach monitorowania jakości powietrza.
            <br />
            <br />
            MiniWRAS jest optymalnym rozwiązaniem do monitorowania wartości PM w pomieszczeniach, biorąc pod uwagę nanocząstki i ujawniając rozkład wielkości cząstek w zakresie małych nanometrów. Jest to idealne narzędzie do monitorowania jakości powietrza w pomieszczeniach w miejscach pracy, wewnątrz pojazdów, kabinach samolotów, pociągach, testach badawczo-rozwojowych i wielu innych.
            <br />
            <br />
          </p>

          <h4>Ogólne informacje:</h4>
          <li>Ultraszeroki zakres rozmiarów od 10 nm do 35 μm</li>
          <li>PM10, PM2.5, PM1 i rozkład wielkości cząstek, powierzchnia cząstek i masa pyłu</li>
          <li>Wysoka precyzja ponad 41 równoodległych kanałów</li>
          <li>Brak materiałów eksploatacyjnych</li>
          <li>Nieradioaktywna ładowarka cząstek</li>
          <li>Wszechstronne interfejsy akwizycji danych i komunikacji (Bluetooth, USB, RS232)</li>
          <li>Łatwy w użyciu z oprogramowaniem GRIMM</li>
          <li>Opcjonalny czujnik temperatury i wilgotności względnej</li>
          <li>Autotest wszystkich elementów optycznych i pneumatycznych pod kątem wysokich standardów jakości</li>
          <li>Powietrze płuczące do ochrony lasera i detektora w celi optycznej</li>
          <br />
          <br />

          <h4>Zastosowania:</h4>
          <li>Monitorowanie nanocząstek i PM (np. PM2,5)</li>
          <li>Jakość powietrza w pomieszczeniach (IAQ)</li>
          <li>IAQ w pojazdach, kabinach samolotów, kokpitach, autobusach, pociągach</li>
          <li>Identyfikacja źródła nanocząstek</li>
          <li>Monitorowanie miejsca pracy</li>
          <li>Badania R&D w przemyśle</li>
        </TextBlock>
      ),
      tabs: [
        {
          name: "Specifikacja",
          body: (
            <TextBlock>
              <table>
                <tbody>
                  <tr>
                    <td>Zakres pomiaru</td>
                    <td>frakcje pyłu wg. wg EN 481 (wdychalna, torakalna, respirabilna) PM10, PM2,5, PM1, stężenie i rozkład wielkości</td>
                  </tr>
                  <tr>
                    <td>Zakres kanałów pomiarowych</td>
                    <td>10 nm – 35 μm (10 – 193 nm elektryczne, 0,253 – 35 μm optyczne)</td>
                  </tr>
                  <tr>
                    <td>Ilość kanałów</td>
                    <td>41 (10 elektrycznych i 31 optycznych)</td>
                  </tr>
                  <tr>
                    <td>Zakres stężenia w ilości cząstek</td>
                    <td>
                      3 000 – 500 000 p/cm³ (elektryczne)
                      <br />0 – 3 000 000 p/L (optyczne)
                    </td>
                  </tr>
                  <tr>
                    <td>Zakres stężenia masowego</td>
                    <td>0 – 100 000 μg/m3</td>
                  </tr>
                  <tr>
                    <th rowSpan={4}>Detektor optyczny</th>
                    <td>Optyczna zasada wykrywania cząstek</td>
                    <td>rozpraszanie światła na pojedynczych cząsteczkach</td>
                  </tr>
                  <tr>
                    <td>Cela optyczna</td>
                    <td>laser diodowy 660 nm</td>
                  </tr>
                  <tr>
                    <td>Detektor</td>
                    <td>szybkie przetwarzanie sygnału z długością impulsu 2 μs, 2 x 16 kanałów danych surowych</td>
                  </tr>
                  <tr>
                    <td>Rozdzielczość czasowa</td>
                    <td>6 s, 31 kanałów (interwał zapisu 1 min)</td>
                  </tr>
                  <tr>
                    <th rowSpan={3}>Detektor elektryczny</th>
                    <td>Elektryczna zasada wykrywania cząstek</td>
                    <td>spektrometr mobilności elektrycznej z puszką Faraday'a </td>
                  </tr>
                  <tr>
                    <td>Czułość detektora</td>
                    <td>0.25 fA</td>
                  </tr>
                  <tr>
                    <td>Rozdzielczośc czasowa</td>
                    <td>60 s, 10 kanałów po 6 s każdy (interwał zapisu 1 min)</td>
                  </tr>
                  <tr>
                    <td>Przepływ objętościowy</td>
                    <td>1,2 l/min, ± 3% stała dzięki samoregulacji, zgodnie z ISO 21501-1; automatyczna korekta wysokości</td>
                  </tr>
                  <tr>
                    <td>Powietrze do płukania</td>
                    <td>0,4 L/min, ochrona optyki laserowej, powietrze referencyjne do autotestu</td>
                  </tr>
                  <tr>
                    <td>Kontrola grawimetryczna</td>
                    <td>filtr 47 mm PTFE</td>
                  </tr>
                  <tr>
                    <td>Interfejsy</td>
                    <td>Bluetooth, USB, RS-232</td>
                  </tr>
                  <tr>
                    <td>Wejście analogowe</td>
                    <td>zewnętrzny czujnik temperatury i wilgotności względnej</td>
                  </tr>
                  <tr>
                    <td>Zasilacz</td>
                    <td>wejście: 100 – 240 VAC, 47 – 63 Hz, wyjście: 18 VDC, 2,5 A</td>
                  </tr>
                  <tr>
                    <td>Bateria</td>
                    <td>Akumulator litowo-jonowy, 14,4 VDC, 4,8 Ah na 8 godzin pracy</td>
                  </tr>
                  <tr>
                    <td>Wymiary</td>
                    <td>34 x 31 x 12 cm (13.4 x 12.2 x 4.7 ")</td>
                  </tr>
                  <tr>
                    <td>Waga</td>
                    <td>7.6 kg (16.8 lbs)</td>
                  </tr>
                  <tr>
                    <td>Warunki pracy</td>
                    <td>+4 do +40°C (39 - 104°F), wilgotność względna &lt; 95%, bez kondensacji</td>
                  </tr>
                </tbody>
              </table>
            </TextBlock>
          ),
        },
        {
          name: "Do pobrania",
          body: (
            <TextBlock>
              <LinkWithIconWrapper>
                <LinkWithIcon icon={<PdfIcon />} to={GrimmMiniWRASDatasheetPL}>
                  Ulotka PL
                </LinkWithIcon>
                <LinkWithIcon icon={<PdfIcon />} to={GrimmMiniWRASDatasheetENG}>
                  Ulotka ENG
                </LinkWithIcon>
              </LinkWithIconWrapper>
            </TextBlock>
          ),
        },
        {
          name: "Produkty Powiązane",
          body: <RelatedProducts items={[]} />,
        },
      ],
    } as ProductContentDto,
  },
] as Array<ProductDto>;

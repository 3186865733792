import React from "react";
import HeroSection from "../../../../sections/hero-section/hero-section";

const HeroSectionWrapper: React.FC = () => {
  return (
    <HeroSection>
      <h1>Dokumentacja do głowic do poboru pyłu</h1>
      <p>
        w związku z normą PN-Z-04507:2022-05 i PN-Z-04508:2022-05
      </p>
    </HeroSection>
  );
};

export default HeroSectionWrapper;

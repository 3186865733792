import React from "react";
import "./link-with-icon.scss";

interface Props {
  icon: React.ReactNode;
  children: React.ReactNode;
  to: string;
}

const LinkWithIcon: React.FC<Props> = (props: Props) => {
  return (
    <a href={props.to} className="link-with-icon" rel="noopener noreferrer" target="_blank">
      <div className="img">{props.icon}</div>
      <p>{props.children}</p>
    </a>
  );
};

export default LinkWithIcon;

import React from "react";
import { ProductContentDto, ProductDto } from "../../../../dto/productDto";
import { ProductTileDto } from "../../../../dto/productTileDto";
import { RoutePath } from "../../../../route-paths";

import TextBlock from "../../../../components/writing-tools/text-block/text-block";
import TitleBlock from "../../../../components/title-block/title-block";
import LinkWithIconWrapper from "../../../../components/link-with-icon/link-with-icon-wrapper";
import LinkWithIcon from "../../../../components/link-with-icon/link-with-icon";
import PdfIcon from "../../../../components/pdf-icon/pdf-icon";
import SensidyneLogo from "./../../../../assets/products/sensidyne-logo.png";
import GilAirPlusImg1 from "./../../../../assets/products/aspiratory_osobiste/gilairplus1.png";
import GilAirPlusImg2 from "./../../../../assets/products/aspiratory_osobiste/gilairplus2.png";
import GilAirPlusImg3 from "./../../../../assets/products/aspiratory_osobiste/gilairplus3.png";
import GilAirPlusDocked from "./../../../../assets/products/aspiratory_osobiste/GilAir-plus_Docked-with-PC-app.png";
import GilAir3Img from "./../../../../assets/products/aspiratory_osobiste/gilair3.png";
import GilAir3Img2 from "./../../../../assets/products/aspiratory_osobiste/gilair3angle.png";
import GilAir5Img from "./../../../../assets/products/aspiratory_osobiste/0-aspirator-gilair5_c.png";
import LFSImg from "./../../../../assets/products/aspiratory_osobiste/gilianlfs.png";
import LFSImgA from "./../../../../assets/products/aspiratory_osobiste/gilianlfsangle.png";
import Gilian5000Img from "./../../../../assets/products/aspiratory_osobiste/aspirator-gilian5000.png";
import Gilian800Img from "./../../../../assets/products/aspiratory_osobiste/0-Gilian_800i_300px.png";
import GilAirPlusBrochure from "./../../../../assets/products/aspiratory_osobiste/Ulotka_GilAir_PLUS_14-1201.pdf";
import GilAirPlusManualPL from "../../../../assets/products/aspiratory_osobiste/IO_Apirator_GilAir_PLUS_v_042012.pdf";
import GilAirPlusManualENG from "./../../../../assets/products/aspiratory_osobiste/Gil_Air_PLUS_04.2013_ENG.pdf";
import GilAirPlusShortManual from "./../../../../assets/products/aspiratory_osobiste/IO_SKROCONA_Apirator_GilAir_PLUS__11.2016.pdf";
import GilAir3Brochure from "./../../../../assets/products/aspiratory_osobiste/Ulotka_GilAir_3_14-1201.pdf";
import GilAir3and5ManualPL from "./../../../../assets/products/aspiratory_osobiste/Instrukcja_GilAir_3_5_ver_0101-17.pdf";
import GilAir3and5ManualENG from "./../../../../assets/products/aspiratory_osobiste/GilAir 3-5 Manual-360-0040-01rR.pdf";
import GilAir5Brochure from "./../../../../assets/products/aspiratory_osobiste/Ulotka_GilAir_5_14-1201.pdf";
import LFSBrochurePL from "./../../../../assets/products/aspiratory_osobiste/Ulotka_GilAir_LFS_14-1201.pdf";
import LFSBrochureENG from "./../../../../assets/products/aspiratory_osobiste/Ulotka_GilAir_LFS__eng_14-1201.pdf";
import LFSManualPL from "./../../../../assets/products/aspiratory_osobiste/Instrukcja_LFS-113.pdf";
import LFSManualENG from "./../../../../assets/products/aspiratory_osobiste/LFS-113 User Manual F-PRO-1725rG.pdf";
import Gilian5000Brochure from "./../../../../assets/products/aspiratory_osobiste/Ulotka_GilAir_5000_14-1201.pdf";
import Gilian5000ManualPL from "./../../../../assets/products/aspiratory_osobiste/Instrukcja_obsugi_Gilian_5000_nowa.pdf";
import RelatedProducts from "../../../../components/RelatedProducts/RelatedProducts";
import Col from "../../../../components/grid/col";

export const ProductsAspiratoryOsobiste = [
  {
    tile: {
      title: "GilAir Plus",
      subtitle: "Producent: Sensidyne",
      price: "",
      img: GilAirPlusImg1,
      to: RoutePath.PRODUCT,
    } as ProductTileDto,
    url: "gil-air-plus",
    content: {
      images: [GilAirPlusImg2, GilAirPlusImg3, GilAirPlusDocked, SensidyneLogo],
      body: (
        <TextBlock>
          <TitleBlock title="Aspirator GilAir Plus" smallPaddingTop />
          <hr />
          <h4>
            Najszerszy zakres przepływu spośród wszystkich aspiratorów osobistych do pobierania próbek powietrza <br />
            <br />
          </h4>

          <p>
            GilAir® Plus oferuje szeroki zakres dynamicznych przepływów i ciśnień, aby sprostać wszystkim osobistym metodom pobierania próbek powietrza z zakresami przepływu od 20 do 5100 cm3/min. Dzięki zgłoszonej do opatentowania technologii pobierania próbek powietrza QuadModeSM, GilAir Plus może wykonywać prówkowanie przy zarówno stałym ciśnieniu i stały przepływie dla wysokich przepływów (450-5100 cm3/min), jak i przy stałym ciśnieniu i stałym przepływie dla niskich przepływów (20-499 cm3/min) za pomocą pojedynczej pompy bez zewnętrznych adapterów. To jak dwie pompy w jednej.
            <br />
            <br />
          </p>

          <h4>Niezrównana wszechstronność</h4>
          <li>Próbkuje cząstki stałe, gazy i opary metali</li>
          <li>Działa ze wszystkimi popularnymi pobornikami próbek</li>
          <li>Obsługa wielu języków: angielski, hiszpański, francuski, włoski, holenderski, portugalski i niemiecki</li>
          <li>Port wylotowy do pobierania próbek workiem tedlarowym</li>
          <br />
          <br />

          <h4>Wysoka wydajność</h4>
          <p>
            GilAir Plus to inteligentna pompa do pobierania próbek powietrza, która zapewnia wyświetlanie z korekcją temperatury i ciśnienia otoczenia lub standardowej (model STP) oraz rejestrację danych dla maksymalnie 16 zdarzeń pobierania próbek (model STP i Datalog). Pompa ma wysokie ciśnienie wsteczne do 40" H2O przy dużym przepływie i do 40" H2O przy niskim przepływie. Automatyczny system samokontroli zapewnia dokładność próbki. W przypadku awarii, wybieralna funkcja automatycznego usuwania awarii pompy próbuje ponownie uruchomić pompę do 10 razy co 3 minuty, aby określić, czy stan awarii został usunięty.
            <br />
            <br />
          </p>

          <h4>Łatwość użytkowania</h4>
          <p>
            Intuicyjne menu, interfejs klawiatury i duży, czytelny, podświetlany wyświetlacz sprawiają, że dostosowywanie ustawień pompy jest szybkie i łatwe. Po skonfigurowaniu pompy klawiaturę można zablokować, aby zapobiec manipulacji. Wygodna i innowacyjna funkcja SmartCalSM zapewnia automatyczną kalibrację pompy.
            <br />
            <br />
          </p>

          <h4>Ergonomiczny design</h4>
          <p>
            O jedną trzecią mniejszy od tradycyjnych osobistych aspiratorów i ważący mniej niż 21 uncji. (595 g), GilAir Plus zapewnia najwyższy komfort operatora. Zintegrowany zaczep do paska bezpiecznie mocuje pompę w pozycji poziomej, umożliwiając pracownikom pełny zakres ruchu do wykonywania różnorodnych zadań bez zakłóceń i dyskomfortu.
            <br />
            <br />
          </p>

          <h4>Komunikacja</h4>
          <p>
            Stacja dokująca GilAir Plus zapewnia funkcje ładowania i komunikacji dla modeli STP i rejestracji danych. Po włożeniu w stację dokującą aplikacja na komputer PC umożliwia użytkownikom przeglądanie dzienników danych, generowanie raportów pobierania próbek, zarządzanie programami pobierania próbek i tworzenie profili konfiguracji pomp, które przyspieszają wdrażanie dużych zestawów pomp. Funkcja SmartCalSM wykorzystuje stację dokującą jako łącze komunikacyjne między odpowiednimi urządzeniami kalibracyjnymi a GilAir Plus. SmartCalSM automatyzuje kalibrację i zapisuje kalibracje przed i po próbce w dzienniku danych pompy.
            <br />
            <br />
          </p>

          <h4>Kompensacja ciśnienia barometrycznego</h4>
          <p>
            Podczas próbkowania na wysokościach do 8000 stóp nad lub 8000 stóp poniżej poziomu morza, GilAir Plus ma możliwość automatycznej korekcji natężenia przepływu dla zmian wysokości, aby utrzymać stałą dokładność przepływu +/- 5% w tym zakresie ciśnienia barometrycznego. Ta funkcja umożliwia kalibrację GilAir Plus na poziomie gruntu i pobieranie próbek na dużych wysokościach lub w głębokich kopalniach bez utraty dokładności przepływu lub konieczności obliczania i używania ręcznych współczynników korekcji. GilAir Plus to jedyna osobista pompka do pobierania próbek powietrza z taką możliwością.
            <br />
            <br />
          </p>
        </TextBlock>
      ),
      tabs: [
        {
          name: "Specifikacja",
          body: (
            <TextBlock>
              <table>
                <tbody>
                  <tr>
                    <td>Zakres przepływu (stały przepływ)</td>
                    <td>20 - 5000 cm3/min bez zewnętrznych adapterów</td>
                  </tr>
                  <tr>
                    <td>Zakres przepływu (stałe ciśnienie)</td>
                    <td>1 - 5000 cm3/min bez zewnętrznych adapterów</td>
                  </tr>
                  <tr>
                    <th rowSpan={3}>Dokładność</th>
                    <td>Wyświetlanie przepływu ± 5% lub 3 cm3/min z ustawionego przepływu, w zależności które jest większe</td>
                  </tr>
                  <tr>
                    <td>Kontrola stałego przepływu ± 5% lub 3 cm3/min z ustawionego przepływu, w zależności które jest większe</td>
                  </tr>
                  <tr>
                    <td>Kontrola stałego ciśnienia ± 10% ciśnienia zwrotnego</td>
                  </tr>
                  <tr>
                    <th rowSpan={9}>Możliwość ciśnienia zwrotnego</th>
                    <td>(8-godzinny czas pracy) 5000 cm3/min. do 12” H20 ciśnienia zwrotnego</td>
                  </tr>
                  <tr>
                    <td>4000 cm3/min. do 20” H20 ciśnienia zwrotnego</td>
                  </tr>
                  <tr>
                    <td>3000 cm3/min. do 30” H20 ciśnienia zwrotnego</td>
                  </tr>
                  <tr>
                    <td>2000 cm3/min. do 30” H20 ciśnienia zwrotnego</td>
                  </tr>
                  <tr>
                    <td>1000 cm3/min. do 35” H20 ciśnienia zwrotnego</td>
                  </tr>
                  <tr>
                    <td>450 - 1000 cm3/min. do 40” H20 ciśnienia zwrotnego</td>
                  </tr>
                  <tr>
                    <td>20 - 449 cm3/min. do 40” H20 ciśnienia zwrotnego</td>
                  </tr>
                  <tr>
                    <td>Czas działania minimum 8 godzin</td>
                  </tr>
                  <tr>
                    <td>Błąd przepływu Jeśli zmiany przepływu przekraczają 5% w ramach specyfikacji ciśnienia zwrotnego, pojawia się powiadomienie o błędzie. Jeśli błąd przekracza 30 sekund, pompa wyłącza się. Wybieralne automatyczne usuwanie awarii umożliwia pompie próbę ponownego uruchomienia co 3 minuty do 10 razy lub wstrzymanie pracy do czasu ręcznej interwencji.</td>
                  </tr>
                  <tr>
                    <th rowSpan={3}>Zakresy temperatur</th>
                    <td>Podczas pracy od 32°F do 113°F (0°C do 45°C)</td>
                  </tr>
                  <tr>
                    <td>Przechowywanie -4°F do 113°F (-20°C do 45°C)</td>
                  </tr>
                  <tr>
                    <td>Ładowanie 41°F do 104°F (5°C do 40°C)</td>
                  </tr>
                  <tr>
                    <th rowSpan={2}>Zakresy wilgotności</th>
                    <td>Praca 5-95 % RH, bez kondensacji</td>
                  </tr>
                  <tr>
                    <td>Przechowywanie 5-98 %RH, bez kondensacji</td>
                  </tr>
                  <tr>
                    <td>Wymiary</td>
                    <td>4,3 x 2,4 x 2,4 " (10,9 x 6,1 x 6,1 cm)</td>
                  </tr>
                  <tr>
                    <td>Waga</td>
                    <td>20,5 uncji (581 gramów)</td>
                  </tr>
                  <tr>
                    <td>Zasilanie</td>
                    <td>Wymienny zestaw akumulatorów niklowo-wodorkowych (NiMH), opcjonalny zestaw baterii alkalicznych lub zasilanie prądem stałym</td>
                  </tr>
                  <tr>
                    <td>Czas ładowania</td>
                    <td>Poniżej 3,5 h</td>
                  </tr>
                </tbody>
              </table>
            </TextBlock>
          ),
        },
        {
          name: "Do pobrania",
          body: (
            <TextBlock>
              <LinkWithIconWrapper>
                <LinkWithIcon icon={<PdfIcon />} to={GilAirPlusBrochure}>
                  Ulotka
                </LinkWithIcon>
                <LinkWithIcon icon={<PdfIcon />} to={GilAirPlusManualPL}>
                  Instrukcja PL
                </LinkWithIcon>
                <LinkWithIcon icon={<PdfIcon />} to={GilAirPlusShortManual}>
                  Instrukcja skrócona PL
                </LinkWithIcon>
                <LinkWithIcon icon={<PdfIcon />} to={GilAirPlusManualENG}>
                  Instrukcja ENG
                </LinkWithIcon>
              </LinkWithIconWrapper>
            </TextBlock>
          ),
        },
        {
          name: "Produkty Powiązane",
          body: <RelatedProducts items={[]} />,
        },
      ],
    } as ProductContentDto,
  },
  {
    tile: {
      title: "GilAir 3",
      subtitle: "Producent: Sensidyne",
      price: "",
      img: GilAir3Img,
      to: RoutePath.PRODUCT,
    } as ProductTileDto,
    url: "gil-air-3",
    content: {
      images: [GilAir3Img2, SensidyneLogo],
      body: (
        <TextBlock>
          <TitleBlock title="Aspirator GilAir 3" smallPaddingTop />
          <hr />
          <h4>Najbardziej uznany na rynku aspirator do pobierania próbek powietrza.</h4>

          <p>
            GilAir-3 i GilAir-5 są uznawane za trwałość i niezawodność, gdy liczy się najbardziej.
            <br />
            <br />
            Automatyczny stały przepływ — najważniejsza cecha każdej pompy do pobierania próbek — jest standardem we wszystkich pompach GilAir-3 i GilAir-5. Zapewnia utrzymanie przepływu w granicach 5% początkowej nastawy, nawet przy różnych przeciwciśnieniach wynikających z ograniczeń przepływu lub nagromadzenia materiału na filtrze. Pompy GilAir-3 i GilAir-5 nadają się nie tylko do konwencjonalnych przeciąganych mediów do pobierania próbek, ale także do pobierania próbek w workach i innych zastosowań ciśnieniowych.
            <br />
            <br />
          </p>

          <h4>Akumulatory NiMH są teraz dostępne dla GilAir-3 i GilAir-5.</h4>
          <p>
            GilAir-3 i GilAir-5 są teraz dostępne z akumulatorami niklowo-metalowo-wodorkowymi (NiMH), które praktycznie eliminują efekty pamięci i leniwe problemy z akumulatorem. Akumulatory NiMH są przyjazne dla środowiska, podlegają recyklingowi i nie stanowią zagrożenia dla środowiska podczas utylizacji. Nowe zestawy akumulatorów NiMH są wstecznie kompatybilne z pompami GilAir-3 i GilAir-5, które obecnie używają akumulatorów NiCd i można je wymieniać przez odkręcenie tylko dwóch śrub z tyłu pompy.
            <br />
            <br />
          </p>

          <h4>Pompy GilAir-3 i GilAir-5 mają również następujące cechy:</h4>
          <Col size={[10, 8, 10, 12]} offset={[2, 2, 1, 0]}>
            <p>
              Modele podstawowe, zegarowe i programowalne GilAir-3 i 5 są dostępne w 3 modelach; Podstawowe, zegarowe i programowalne. Model Basic jest prosty i łatwy w użyciu. Modele zegara zapewniają wyświetlanie czasu, który upłynął. Modele programowalne umożliwiają zegar, który może uruchamiać lub zatrzymywać pompę w ustalonych odstępach czasu lub programować opóźniony start.
              <br />
              <br />
            </p>

            <h4>Wskaźniki usterek i testów przepływu</h4>
            <p>
              Wskaźnik świetlny włącza się, gdy sterowanie przepływem pompy wykracza poza +/-5% wymaganego natężenia przepływu, na przykład z powodu niskiego poziomu naładowania baterii, zablokowanego przewodu lub filtra. Jeśli usterka utrzymuje się przez 30-60 sekund, pompa automatycznie wyłącza się i zatrzymuje zegar. Modele z wyświetlaczem timera zachowują czas pracy na wyświetlaczu, zapewniając prawidłowy wynik próbkowania. Gdy usterka zostanie usunięta przed wyłączeniem, pompa wznowi normalne pobieranie próbek, a kontrolka usterki zgaśnie. GilAir-3 i 5 mają również lampkę kontrolną testu baterii, która zapala się, gdy bateria może zapewnić co najmniej 8 godzin pracy w zakresie roboczym.
              <br />
              <br />
            </p>

            <h4>Obudowa filtra zewnętrznego</h4>
            <p>
              Przezroczysta obudowa filtra umożliwia natychmiastową wizualną kontrolę stanu filtra wewnętrznego. Ta funkcja chroni pompę przed brudem i zanieczyszczeniami i jest zamontowana na zewnątrz pompy, co pozwala na szybką kontrolę. Wymiana filtra wymaga odkręcenia zaledwie czterech śrub. Wbudowana pułapka wilgoci pomaga zapobiegać przedostawaniu się wilgoci i uszkodzeniu komory pompy, na przykład przenoszeniu wilgoci z uderzeń.
              <br />
              <br />
            </p>

            <h4>Opcjonalne moduły niskiego przepływu</h4>
            <p>
              Moduł stałego przepływu umożliwia pobieranie próbek od 20-500 cm3/min.
              <br />
              Moduł stałego ciśnienia (wieloprzepływowy) umożliwia pobieranie próbek od 1-750 cm3/min.
              <br />
              <br />
              Moduły te można szybko dodać do pomp, aby dostosować je do niższych przepływów wymaganych do pobierania próbek z rurki sorbentu. Moduł multi-flow utrzymuje stałe ciśnienie i umożliwia pobieranie próbek za pomocą wielu rurek sorbentu, każdy z niezależnie ustawionym natężeniem przepływu.
              <br />
              <br />
            </p>

            <h4>GilAir-3 i GilAir-5 dopuszczenia do stosowania w zagrożeniach:</h4>
            <p>
              Iskrobezpieczeństwo — UL
              <br />
              <li>
                klasa 1, dział 1, grupy A, B, C, D;
                <br />
              </li>
              <li>
                klasa 2, E, F, G; Klasa 3<br />
              </li>
              EMC: Normy emisji
              <br />
              <li>
                EN 55011:2009/A1:2010 Grupa 1, klasa A<br />
              </li>
              <li>
                ICES-003 — wydanie 2 klasa A<br />
              </li>
              <li>
                FCC Part 15 (zgodnie z ANSI C63.4:2015) Weryfikacja klasy A<br />
              </li>
              EMI: standardy odporności
              <br />
              <li>
                EN 61326-1:2013;
                <br />
              </li>
              <li>
                IEC 61000-4-2:2006 / EN 61000-4-2:2009;
                <br />
              </li>
              <li>
                IEC 61000-4-3:2006 / EN 61000-4-3:2006/A1:2008/A2:2010
                <br />
                <br />
              </li>
            </p>
          </Col>
        </TextBlock>
      ),
      tabs: [
        {
          name: "Specifikacja",
          body: (
            <TextBlock>
              <table>
                <tbody>
                  <tr>
                    <td>Zakres przepływu (stały przepływ)</td>
                    <td>
                      750 ml/min÷ 3 l/min
                      <br />
                      (20 ml/min- 3 l/min z modułem niskich przepływów)
                    </td>
                  </tr>
                  <tr>
                    <td>Stabilizacja przepływu</td>
                    <td>± 5 %</td>
                  </tr>
                  <tr>
                    <td>Czas ciągłej pracy</td>
                    <td>minimum 8 h</td>
                  </tr>
                  <tr>
                    <td>Wymiary</td>
                    <td>9 x 10 x 5,1 cm</td>
                  </tr>
                  <tr>
                    <td>Waga</td>
                    <td>600 g</td>
                  </tr>
                  <tr>
                    <td>Zasilanie</td>
                    <td>Akumulator 4.8 V / 1.8 A NiCd (UL) lub NiMH (ATEX)</td>
                  </tr>
                  <tr>
                    <td>Czas ładowania</td>
                    <td>14 h</td>
                  </tr>
                  <tr>
                    <td>Żywotność akumulatora</td>
                    <td>300 - 500 cykli</td>
                  </tr>
                  <tr>
                    <td>Temperatura pracy</td>
                    <td>0 - 40 °C</td>
                  </tr>
                </tbody>
              </table>
            </TextBlock>
          ),
        },
        {
          name: "Do pobrania",
          body: (
            <TextBlock>
              <LinkWithIconWrapper>
                <LinkWithIcon icon={<PdfIcon />} to={GilAir3Brochure}>
                  Ulotka
                </LinkWithIcon>
                <LinkWithIcon icon={<PdfIcon />} to={GilAir3and5ManualPL}>
                  Instrukcja PL
                </LinkWithIcon>
                <LinkWithIcon icon={<PdfIcon />} to={GilAir3and5ManualENG}>
                  Instrukcja ENG
                </LinkWithIcon>
              </LinkWithIconWrapper>
            </TextBlock>
          ),
        },
        {
          name: "Produkty Powiązane",
          body: <RelatedProducts items={["modul-niskich-przeplywow"]} />,
        },
      ],
    } as ProductContentDto,
  },
  {
    tile: {
      title: "GilAir 5 ",
      subtitle: "Producent: Sensidyne",
      price: "",
      img: GilAir5Img,
      to: RoutePath.PRODUCT,
    } as ProductTileDto,
    url: "gil-air-5",
    content: {
      images: [SensidyneLogo],
      body: (
        <TextBlock>
          <TitleBlock title="Aspirator GilAir 5" smallPaddingTop />
          <hr />
          <h4>Najbardziej uznany na rynku aspirator do pobierania próbek powietrza.</h4>

          <p>
            GilAir-3 i GilAir-5 są uznawane za trwałość i niezawodność, gdy liczy się najbardziej.
            <br />
            <br />
            Automatyczny stały przepływ — najważniejsza cecha każdej pompy do pobierania próbek — jest standardem we wszystkich pompach GilAir-3 i GilAir-5. Zapewnia utrzymanie przepływu w granicach 5% początkowej nastawy, nawet przy różnych przeciwciśnieniach wynikających z ograniczeń przepływu lub nagromadzenia materiału na filtrze. Pompy GilAir-3 i GilAir-5 nadają się nie tylko do konwencjonalnych przeciąganych mediów do pobierania próbek, ale także do pobierania próbek w workach i innych zastosowań ciśnieniowych.
            <br />
            <br />
          </p>

          <h4>Akumulatory NiMH są teraz dostępne dla GilAir-3 i GilAir-5.</h4>
          <p>
            GilAir-3 i GilAir-5 są teraz dostępne z akumulatorami niklowo-metalowo-wodorkowymi (NiMH), które praktycznie eliminują efekty pamięci i leniwe problemy z akumulatorem. Akumulatory NiMH są przyjazne dla środowiska, podlegają recyklingowi i nie stanowią zagrożenia dla środowiska podczas utylizacji. Nowe zestawy akumulatorów NiMH są wstecznie kompatybilne z pompami GilAir-3 i GilAir-5, które obecnie używają akumulatorów NiCd i można je wymieniać przez odkręcenie tylko dwóch śrub z tyłu pompy.
            <br />
            <br />
          </p>

          <h4>Pompy GilAir-3 i GilAir-5 mają również następujące cechy:</h4>
          <Col size={[10, 8, 10, 12]} offset={[2, 2, 1, 0]}>
            <p>
              Modele podstawowe, zegarowe i programowalne GilAir-3 i 5 są dostępne w 3 modelach; Podstawowe, zegarowe i programowalne. Model Basic jest prosty i łatwy w użyciu. Modele zegara zapewniają wyświetlanie czasu, który upłynął. Modele programowalne umożliwiają zegar, który może uruchamiać lub zatrzymywać pompę w ustalonych odstępach czasu lub programować opóźniony start.
              <br />
              <br />
            </p>

            <h4>Wskaźniki usterek i testów przepływu</h4>
            <p>
              Wskaźnik świetlny włącza się, gdy sterowanie przepływem pompy wykracza poza +/-5% wymaganego natężenia przepływu, na przykład z powodu niskiego poziomu naładowania baterii, zablokowanego przewodu lub filtra. Jeśli usterka utrzymuje się przez 30-60 sekund, pompa automatycznie wyłącza się i zatrzymuje zegar. Modele z wyświetlaczem timera zachowują czas pracy na wyświetlaczu, zapewniając prawidłowy wynik próbkowania. Gdy usterka zostanie usunięta przed wyłączeniem, pompa wznowi normalne pobieranie próbek, a kontrolka usterki zgaśnie. GilAir-3 i 5 mają również lampkę kontrolną testu baterii, która zapala się, gdy bateria może zapewnić co najmniej 8 godzin pracy w zakresie roboczym.
              <br />
              <br />
            </p>

            <h4>Obudowa filtra zewnętrznego</h4>
            <p>
              Przezroczysta obudowa filtra umożliwia natychmiastową wizualną kontrolę stanu filtra wewnętrznego. Ta funkcja chroni pompę przed brudem i zanieczyszczeniami i jest zamontowana na zewnątrz pompy, co pozwala na szybką kontrolę. Wymiana filtra wymaga odkręcenia zaledwie czterech śrub. Wbudowana pułapka wilgoci pomaga zapobiegać przedostawaniu się wilgoci i uszkodzeniu komory pompy, na przykład przenoszeniu wilgoci z uderzeń.
              <br />
              <br />
            </p>

            <h4>Opcjonalne moduły niskiego przepływu</h4>
            <p>
              Moduł stałego przepływu umożliwia pobieranie próbek od 20-500 cm3/min.
              <br />
              Moduł stałego ciśnienia (wieloprzepływowy) umożliwia pobieranie próbek od 1-750 cm3/min.
              <br />
              <br />
              Moduły te można szybko dodać do pomp, aby dostosować je do niższych przepływów wymaganych do pobierania próbek z rurki sorbentu. Moduł multi-flow utrzymuje stałe ciśnienie i umożliwia pobieranie próbek za pomocą wielu rurek sorbentu, każdy z niezależnie ustawionym natężeniem przepływu.
              <br />
              <br />
            </p>

            <h4>GilAir-3 i GilAir-5 dopuszczenia do stosowania w zagrożeniach:</h4>
            <p>
              Iskrobezpieczeństwo — UL
              <br />
              <li>
                klasa 1, dział 1, grupy A, B, C, D;
                <br />
              </li>
              <li>
                klasa 2, E, F, G; Klasa 3<br />
              </li>
              EMC: Normy emisji
              <br />
              <li>
                EN 55011:2009/A1:2010 Grupa 1, klasa A<br />
              </li>
              <li>
                ICES-003 — wydanie 2 klasa A<br />
              </li>
              <li>
                FCC Part 15 (zgodnie z ANSI C63.4:2015) Weryfikacja klasy A<br />
              </li>
              EMI: standardy odporności
              <br />
              <li>
                EN 61326-1:2013;
                <br />
              </li>
              <li>
                IEC 61000-4-2:2006 / EN 61000-4-2:2009;
                <br />
              </li>
              <li>
                IEC 61000-4-3:2006 / EN 61000-4-3:2006/A1:2008/A2:2010
                <br />
                <br />
              </li>
            </p>
          </Col>
        </TextBlock>
      ),
      tabs: [
        {
          name: "Specifikacja",
          body: (
            <TextBlock>
              <table>
                <tbody>
                  <tr>
                    <td>Zakres przepływu (stały przepływ)</td>
                    <td>
                      750 ml/min - 5 l/min
                      <br />
                      (20 ml/min - 5 l/min z modułem niskich przepływów)
                    </td>
                  </tr>
                  <tr>
                    <td>Stabilizacja przepływu</td>
                    <td>± 5 %</td>
                  </tr>
                  <tr>
                    <td>Czas ciągłej pracy</td>
                    <td>minimum 8 h</td>
                  </tr>
                  <tr>
                    <td>Wymiary</td>
                    <td>10,3 x 10 x 5,1 cm</td>
                  </tr>
                  <tr>
                    <td>Waga</td>
                    <td>640 g</td>
                  </tr>
                  <tr>
                    <td>Zasilanie</td>
                    <td>Akumulator 6 V / 1.8 A</td>
                  </tr>
                  <tr>
                    <td>Czas ładowania</td>
                    <td>14 h</td>
                  </tr>
                  <tr>
                    <td>Żywotność akumulatora</td>
                    <td>300 - 500 cykli</td>
                  </tr>
                  <tr>
                    <td>Temperatura pracy</td>
                    <td>0 - 40 °C</td>
                  </tr>
                </tbody>
              </table>
            </TextBlock>
          ),
        },
        {
          name: "Do pobrania",
          body: (
            <TextBlock>
              <LinkWithIconWrapper>
                <LinkWithIcon icon={<PdfIcon />} to={GilAir5Brochure}>
                  Ulotka
                </LinkWithIcon>
                <LinkWithIcon icon={<PdfIcon />} to={GilAir3and5ManualPL}>
                  Instrukcja PL
                </LinkWithIcon>
                <LinkWithIcon icon={<PdfIcon />} to={GilAir3and5ManualENG}>
                  Instrukcja ENG
                </LinkWithIcon>
              </LinkWithIconWrapper>
            </TextBlock>
          ),
        },
        {
          name: "Produkty Powiązane",
          body: <RelatedProducts items={["modul-niskich-przeplywow"]} />,
        },
      ],
    } as ProductContentDto,
  },
  {
    tile: {
      title: "Gilian LFS",
      subtitle: "Producent: Sensidyne",
      price: "",
      img: LFSImg,
      to: RoutePath.PRODUCT,
    } as ProductTileDto,
    url: "gilian-lfs",
    content: {
      images: [LFSImgA, SensidyneLogo],
      body: (
        <TextBlock>
          <TitleBlock title="Aspirator Gilian LFS-113" smallPaddingTop />
          <hr />
          <br />
          <br />
          <h4>
            Funkcje aspiratora do pobierania próbek powietrza LFS-113:
            <br />
          </h4>
          <li>Podwójny tryb automatycznej kontroli stałego przepływu lub stałego ciśnienia</li>
          <li>Wydajność wieloprzepływowa</li>
          <li>Kompaktowy rozmiar i lekkość</li>
          <li>Model z wyświetlaniem zegara</li>
          <li>Dioda LED sprawdzania baterii</li>
          <br />
          <br />

          <p>
            Próbnik niskoprzepływowy Gilian LFS-113 jest najpotężniejszym i najbardziej niezawodnym kieszonkowym osobistym próbnikiem powietrza dostępnym obecnie na rynku. LFS-113 oferuje dwa tryby próbkowania do wyboru przez użytkownika. Kompaktowa, niskoprzepływowa pompa do pobierania próbek powietrza LFS-113e w trybie stałego przepływu utrzymuje przepływ w zakresie +/-5% ustawionego przepływu, podczas gdy tryb wieloprzepływowy (kontrola stałego ciśnienia) umożliwia jednoczesne pobieranie wielu próbek. Żadna inna pompa kieszonkowa nie dorównuje szerokiemu zakresowi przepływu i możliwości wysokiego ciśnienia wstecznego modelu LFS-113.
            <br />
            <br />
            Modele podstawowe i z zegarem są dostępne zarówno w wersji amerykańskiej, jak i globalnej. Wersje globalne są teraz dostępne z akumulatorem NiMH bez pamięci.
          </p>
        </TextBlock>
      ),
      tabs: [
        {
          name: "Specifikacja",
          body: (
            <TextBlock>
              <table>
                <tbody>
                  <tr>
                    <td>Zakres przepływu (stały przepływ)</td>
                    <td>1 ml/min - 350 ml/min</td>
                  </tr>
                  <tr>
                    <td>Stabilizacja przepływu</td>
                    <td>± 5 %</td>
                  </tr>
                  <tr>
                    <td>Czas ciągłej pracy</td>
                    <td>minimum 8 h</td>
                  </tr>
                  <tr>
                    <td>Wymiary</td>
                    <td>6,4 x 3,5 x 11,7 cm</td>
                  </tr>
                  <tr>
                    <td>Waga</td>
                    <td>340 g</td>
                  </tr>
                  <tr>
                    <td>Zasilanie</td>
                    <td>Akumulator 4.8 V / 1.8 A</td>
                  </tr>
                  <tr>
                    <td>Czas ładowania</td>
                    <td>14 h</td>
                  </tr>
                  <tr>
                    <td>Żywotność akumulatora</td>
                    <td>300 - 500 cykli</td>
                  </tr>
                  <tr>
                    <td>Temperatura pracy</td>
                    <td>0 - 40 °C</td>
                  </tr>
                </tbody>
              </table>
            </TextBlock>
          ),
        },
        {
          name: "Do pobrania",
          body: (
            <TextBlock>
              <LinkWithIconWrapper>
                <LinkWithIcon icon={<PdfIcon />} to={LFSBrochurePL}>
                  Ulotka PL
                </LinkWithIcon>
                <LinkWithIcon icon={<PdfIcon />} to={LFSBrochureENG}>
                  Ulotka ENG
                </LinkWithIcon>
                <LinkWithIcon icon={<PdfIcon />} to={LFSManualPL}>
                  Instrukcja PL
                </LinkWithIcon>
                <LinkWithIcon icon={<PdfIcon />} to={LFSManualENG}>
                  Instrukcja ENG
                </LinkWithIcon>
              </LinkWithIconWrapper>
            </TextBlock>
          ),
        },
      ],
    } as ProductContentDto,
  },
  {
    tile: {
      title: "Gilian 5000",
      subtitle: "Producent: Sensidyne",
      price: "",
      img: Gilian5000Img,
      to: RoutePath.PRODUCT,
    } as ProductTileDto,
    url: "gilian-5000",
    content: {
      images: [SensidyneLogo],
      body: (
        <TextBlock>
          <TitleBlock title="Aspirator Gilian 5000" smallPaddingTop />
          <hr />
          <br />
          <br />
          <p>
            Aspirator osobisty o podwyższonym przepływie i wydłużonym czasie pracy przeznaczony do poboru pyłów posiadający kompensację przepływu na poziomie +/-5%. Wyposażony jest w elektroniczne sterowanie pozwalające na dokładne ustawienie przepływu, oraz duży, czytelny wyświetlacz, na którym pojawiają się informacje o aktualnym przepływie, stanie akumulatora, błędach przepływu itp. Aspirator charakteryzuje się wydłużonym czasem pracy i krótrzym czasem ładowania. Umożliwia pobór przy dużych wartościach podciśnienia, posiada funkcje stałego przepływu. Podczas poboru prób rejestruje zakłucenia przepływu.
            <br />
            <br />
            Ze względu na zwartą konstrukcję, niewielką wagę oraz wbudowane akumulatory GilAir 5000 jest doskonałym narzędziem do poboru prób powietrza w celu określenia narażeń pyłowych na stanowiskach pracy.
            <br />
            <br />
          </p>
        </TextBlock>
      ),
      tabs: [
        {
          name: "Specifikacja",
          body: (
            <TextBlock>
              <table>
                <tbody>
                  <tr>
                    <td>Zakres przepływu</td>
                    <td>800 ml/min ÷ 5 l/min</td>
                  </tr>
                  <tr>
                    <td>Stabilizacja przepływu</td>
                    <td>± 5 %</td>
                  </tr>
                  <tr>
                    <td>Czas ciągłej pracy</td>
                    <td>minimum 8 h</td>
                  </tr>
                  <tr>
                    <td>Wymiary</td>
                    <td>8,1 x 13,7 x 5,8 cm</td>
                  </tr>
                  <tr>
                    <td>Waga</td>
                    <td>550 g</td>
                  </tr>
                  <tr>
                    <td>Zasilanie</td>
                    <td>Akumulator NiMH 7,2 V</td>
                  </tr>
                  <tr>
                    <td>Czas ładowania</td>
                    <td>4 h</td>
                  </tr>
                  <tr>
                    <td>Żywotność akumulatora</td>
                    <td>300 - 500 cykli</td>
                  </tr>
                  <tr>
                    <td>Temperatura pracy</td>
                    <td>0 - 45 °C</td>
                  </tr>
                </tbody>
              </table>
            </TextBlock>
          ),
        },
        {
          name: "Do pobrania",
          body: (
            <TextBlock>
              <LinkWithIconWrapper>
                <LinkWithIcon icon={<PdfIcon />} to={Gilian5000Brochure}>
                  Ulotka PL
                </LinkWithIcon>
                <LinkWithIcon icon={<PdfIcon />} to={Gilian5000ManualPL}>
                  Instrukcja PL
                </LinkWithIcon>
              </LinkWithIconWrapper>
            </TextBlock>
          ),
        },
      ],
    } as ProductContentDto,
  },
  {
    tile: {
      title: "Gilian 800",
      subtitle: "Producent: Sensidyne",
      price: "",
      img: Gilian800Img,
      to: RoutePath.PRODUCT,
    } as ProductTileDto,
    url: "gilian-800",
    content: {
      images: [SensidyneLogo],
      body: (
        <TextBlock>
          <TitleBlock title="Aspirator Gilian 800" smallPaddingTop />
          <hr />
          <br />
          <br />
          <p>Aspirator osobisty o przepływie stosowanym do poboru zanieczyszczeń gazowych. Stosowany do poborów na rurki sorpcyjne, przy zastosowaniu adaptera zewnętrznego pozwala na pobór kilku próbek jednocześnie. Wyposarzony jest w system tłumienia pulsacji, system filtracji zassanego powietrza i odporną obudowę z tworzywa sztucznego. Aspirator spełnia wymagania normy PN-EN 1232 (typ G).</p>
        </TextBlock>
      ),
      tabs: [
        {
          name: "Specifikacja",
          body: (
            <TextBlock>
              <table>
                <tbody>
                  <tr>
                    <td>Zakres przepływu</td>
                    <td>200 - 800 ml/min</td>
                  </tr>
                </tbody>
              </table>
            </TextBlock>
          ),
        },
      ],
    } as ProductContentDto,
  },
] as Array<ProductDto>;

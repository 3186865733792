import React, { useEffect, useState } from "react";
import { generatePath, useParams } from "react-router-dom";
import Container from "../../../components/container/container";
import Col from "../../../components/grid/col";
import Grid from "../../../components/grid/grid";
import Row from "../../../components/grid/row";
import ProductTabs from "../../../components/product-tabs/product-tabs";
import TopLink from "../../../components/top-link/top-link";
import TextBlock from "../../../components/writing-tools/text-block/text-block";
import { ProductDto } from "../../../dto/productDto";
import { RoutePath } from "../../../route-paths";
import useWindowSize from "../../../tools/useWindowSize";
import { allCategoriesData } from "../data/categories-data";
import Zoom from "react-medium-image-zoom";

const ProductSection: React.FC = () => {
  let { usageId, categoryId, productId } = useParams<{ usageId: string; categoryId: string; productId: string }>();
  const [product, setProduct] = useState({} as ProductDto);
  const size = useWindowSize();

  useEffect(() => {
    let data = allCategoriesData.find((x) => x.url === categoryId);
    if (data) {
      if (productId) {
        const result = data.products.find((x) => x.url === productId);
        if (result) {
          setProduct(result);
        }
      }
    }
  }, [categoryId, productId]);

  return (
    <section className="product-section">
      <Container>
        {product.url ? (
          <Grid>
            <Row gap={16}>
              <Col size={[8, 9, 9, 12]}>
                <div className="pr-md-4 pr-0">
                  <div className="body">{product?.content.body}</div>
                  {product.tile?.price ? (
                    <TextBlock className="price">
                      <p>{product.tile?.price}</p>
                      <TopLink to={generatePath(RoutePath.CONTACT)}>Zapytaj o dostępność</TopLink>
                    </TextBlock>
                  ) : null}
                </div>
              </Col>
              <Col size={[4, 3, 3, 12]}>
                <div className="box mt-2">
                  <Zoom zoomMargin={32}>
                    <div className="product-tile__img" style={{ backgroundImage: `url('${product.tile.img}')` }}></div>
                  </Zoom>
                </div>
                {product?.content.images
                  ? product.content.images.map((x, index) => {
                      return (
                        <div className="box my-3" key={index}>
                          <Zoom zoomMargin={32}>
                            <div className="product-tile__img" style={{ backgroundImage: `url('${x}')` }}></div>
                          </Zoom>
                        </div>
                      );
                    })
                  : null}
              </Col>
              <Col size={[8, 12, 12, 12]}>{product.content?.tabs.length > 0 ? <ProductTabs items={product.content.tabs} /> : null}</Col>
              {size.isMobile || true ? (
                <Col size={[12, 12, 12, 12]}>
                  <TextBlock>
                    <TopLink to={generatePath(RoutePath.CATEGORY, { usageId: usageId, categoryId: categoryId })}>Wróć do kategorii</TopLink>
                  </TextBlock>
                </Col>
              ) : null}
            </Row>
          </Grid>
        ) : null}
      </Container>
    </section>
  );
};

export default ProductSection;

import React from "react";
import "./title-block.scss";
import Button from "../button/button";
import TopLink from "../top-link/top-link";

interface Props {
  title?: string;
  smallTitle?: string
  subtitle?: React.ReactNode;
  link?: any;
  linktxt?: string
  align?: 'left' | 'center' | 'right';
  smallPaddingTop?: boolean;
}

const TitleBlock: React.FC<Props> = (props: Props) => {
  const { title, smallTitle, subtitle, link, linktxt, align = "center", smallPaddingTop } = props;
  return (
    <div className={`title-block title-block--${align} ${smallPaddingTop ? "title-block--small-padding-top" : ""}`} >
      <h2>{title}</h2>
      <h3>{smallTitle}</h3>
      {subtitle ? <p>{subtitle}</p> : null}
      {link && linktxt ? <TopLink to={link}>
        <Button color={"dark"}>
          {linktxt}
        </Button>
      </TopLink> : null}
    </div >
  )
}

export default TitleBlock;
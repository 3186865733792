import React from "react";
import HeroSection from "../../../sections/hero-section/hero-section";


const HeroSectionWrapper: React.FC = () => {
  return (
    <HeroSection>
      <h1>Kontakt</h1>
    </HeroSection>
  );
};

export default HeroSectionWrapper;

import React from "react";
import "./post-block.scss";

interface Props {
  children: React.ReactNode;
  className?: string;
  size?: 16 | 24 | 32;
}

const PostBlock: React.FC<Props> = (props: Props) => {
  const { children, size = 32, className } = props;

  return <div className={`post-block post-block--${size} ${className ? " " + className : ""}`}>{children}</div>;
};

export default PostBlock;

import React from "react";
import TitleBlock from "../../../components/title-block/title-block";
import PostBlock from "../../../components/writing-tools/post-block/post-block";
import TextBlock from "../../../components/writing-tools/text-block/text-block";
import BlogSection from "../../../sections/blog-section/blog-section";
import AboutUsImg1 from "../../../assets/about_us/o-nas-1.png"
import AboutUsImg2 from "../../../assets/about_us/o-nas-2.png"
import Row from "../../../components/grid/row";
import Col from "../../../components/grid/col";
import FullImgBlock from "../../../components/fullImg-block/fullImg-block";
import PvExampleImgA from "../../../assets/PvExampleImgA.jpg";
import PvExampleImgB from "../../../assets/PvExampleImgB.jpg";

interface Props {
  setRef: any;
}

const BlogSectionWrapper: React.FC<Props> = (props: Props) => {
  return (
    <div ref={props.setRef}>
      <BlogSection title={<TitleBlock title="Historia naszego zespołu"
        subtitle="Ludzie połączeni dzięki pasji, tworzą wielkie rzeczy" />}>
        <PostBlock>
          <TextBlock>
            <h4>Oferujemy kompleksowe rozwiązania dla laboratoriów już od ponad 20 lat!</h4>
            <p>Jesteśmy polską, rodzinną firmą założoną w 2001 roku. Nasze biuro znajduje się w Błoniach pod
              Wrocławiem. Zajmujemy się sprzedażą aparatury pomiarowej dla laboratoriów środowiska pracy, laboratoriów
              ochrony środowiska oraz przemysłu farmaceutycznego.</p>
          </TextBlock>
        </PostBlock>
        <PostBlock size={24}>
          <Row gap={16}>
            <Col size={6}>
              <FullImgBlock img={AboutUsImg1} alt="apex" height={200} />
            </Col>
            <Col size={6}>
              <FullImgBlock img={AboutUsImg2} alt="impactor" height={200} />
            </Col>
          </Row>
        </PostBlock>
        <PostBlock>
          <TextBlock>
            <p>Ponad 20 lat rozwoju działalności firmy umożliwiło nam zbudowanie solidnej sieci dostawców i
              współpracowników zarówno z Polski, wielu państw Europy, jak i Stanów Zjednoczonych. Jesteśmy jedynymi w
              Polsce dystrybutorami urządzeń produkowanych przez amerykańskie firmy Sensidyne oraz Lighthouse Worldwide
              Solutions.</p><br /><br />

            <p>Jako firma jesteśmy nastawieni na innowację i rozwój. Wykorzystujemy zdobytą wiedzę, aby doskonalić naszą
              ofertę oraz coraz lepiej dopasowywać ją do potrzeb Klienta. Nie boimy się badawczych wyzwań – nasza firma
              wyprodukowała dwa mierniki mikroklimatu – EHA MM101 oraz EHA MM203 oraz stanowisko do testowania masek.
              Dzięki wiedzy, umiejętnościom i długoletniemu doświadczeniu pomagamy realizować również Państwa projekty
              i pomysły badawcze.</p><br /><br />

            <p>Zapraszamy do zapoznania się z naszą ofertą oraz zachęcamy do kontaktu. Chętnie odpowiemy na Państwa
              pytania, doradzimy oraz ud zielimy wsparcia na każdym etapie współpracy – zarówno przy wyborze, podczas
              korzystania z zakupionych urządzeń oraz w trakcie czynności serwisowych.</p><br /><br />
          </TextBlock>
        </PostBlock>
      </BlogSection>
    </div>
  );
};

export default BlogSectionWrapper;

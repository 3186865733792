import React from "react";
import { Breadcrumbs } from "react-breadcrumbs-dynamic";
import { NavLink } from "react-router-dom";
import Container from "../../components/container/container";
import Col from "../../components/grid/col";
import Grid from "../../components/grid/grid";
import Row from "../../components/grid/row";
import "./hero-section.scss";

interface Props {
  img?: string;
  alt?: string;
  children: React.ReactNode;
}

const HeroSection: React.FC<Props> = (props: Props) => {
  const { img, alt, children } = props;

  return (
    <>
      <section className={`hero-section${img ? "" : " compact-version"}`}>
        <main>
          <Container>
            <Grid>
              <Row>
                <Col size={img ? [4, 4, 5, 12] : 12} offset={img ? [1, 1, 0] : 0}>
                  <div className="hero__content">{children}</div>
                </Col>
                {img && (
                  <Col size={[7, 7, 7, 12]}>
                    <div className="hero__image" style={{ backgroundImage: `url("${img}")` }} title={alt} />
                  </Col>
                )}
              </Row>
            </Grid>
          </Container>
        </main>
      </section>
      <div className="breadcrumbs">
        <Container>
          <Grid>
            <Row>
              <Col size={12}>
                <Breadcrumbs
                  separator={<b className="separator"> &rArr; </b>}
                  item={NavLink}
                  finalItem={"b"}
                  finalProps={{
                    style: { color: "#4070f4" },
                  }}
                />
              </Col>
            </Row>
          </Grid>
        </Container>
      </div>
    </>
  );
};

export default HeroSection;

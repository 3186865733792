import React from "react";
import Container from "../../components/container/container";
import Col from "../../components/grid/col";
import Grid from "../../components/grid/grid";
import Row from "../../components/grid/row";
import "./prefooter-section.scss";
import LogoWhite from "./../../assets/logo.svg";
import { RoutePath } from "../../route-paths";
import TopLink from "../../components/top-link/top-link";

const PrefooterSection: React.FC = () => {
  return (
    <section className="prefooter-section">
      <Container>
        <Grid>
          <Row gap={16}>
            <Col size={[3, 4, 12, 12]} className="company-col">
              <img src={LogoWhite} alt="Eko Higiena" />
              <h5>Wyposażenie labolatoryjne</h5>
              <p>Od 20 lat specjalizujemy się w dostarczaniu sprzętu, urządzeń pomiarowych i badań w szerokim zakresie specjalizacji.</p>
            </Col>
            <Col size={[3, 4, 6, 12]} offset={[1, 0]} className="links-col">
              <p className="col-title">Produkty</p>
              <ul>
                <li>
                  <TopLink to={RoutePath.HOME}>Aspiratory do poboru prób powietrza</TopLink>
                </li>
                <li>
                  <TopLink to={RoutePath.HOME}>Mierniki parametrów fizycznych</TopLink>
                </li>
                <li>
                  <TopLink to={RoutePath.HOME}>Liczniki cząstek generatory dilutery aerozolu</TopLink>
                </li>
                <li>
                  <TopLink to={RoutePath.HOME}>Urządzenia laboratoryjne</TopLink>
                </li>
                <li>
                  <TopLink to={RoutePath.HOME}>Analiza wielkości cząstek</TopLink>
                </li>
                <li>
                  <TopLink to={RoutePath.HOME}>Inne urządzenia laboratoryjne</TopLink>
                </li>
              </ul>
            </Col>
            <Col size={[2, 2, 3, 12]} offset={[1, 0]} className="links-col">
              <p className="col-title">Na skróty</p>
              <ul>
                <li>
                  <TopLink to={RoutePath.CATEGORIES}>Produkty</TopLink>
                </li>
                <li>
                  <TopLink to={RoutePath.SERVICES}>Usługi</TopLink>
                </li>
                <li>
                  <TopLink to={RoutePath.ABOUT}>O&nbsp;nas</TopLink>
                </li>
                <li>
                  <TopLink to={RoutePath.SERVICES__SERWIS}>Serwis</TopLink>
                </li>
                <li>
                  <TopLink to={RoutePath.CONTACT}>Kontakt</TopLink>
                </li>
              </ul>
            </Col>
            <Col size={[2, 2, 3, 12]} className="links-col">
              <p className="col-title">Regulaminy</p>
              <ul>
                <li>
                  <TopLink to={RoutePath.RULES}>Warunki użytkowania</TopLink>
                </li>
                <li>
                  <TopLink to={RoutePath.RULES + "#cookies"}>Polityka cookies</TopLink>
                </li>
                <li>
                  <TopLink to={RoutePath.RULES + "#privacy"}>Polityka prywatności</TopLink>
                </li>
              </ul>
            </Col>
          </Row>
        </Grid>
      </Container>
    </section>
  );
};

export default PrefooterSection;

import React, { useEffect, useState } from "react";
import "./navbar.scss";
import Grid from "../grid/grid";
import Row from "../grid/row";
import Col from "../grid/col";
import LogoImg from "../../assets/logo.svg";
import { RoutePath } from "../../route-paths";
import TopLink from "../top-link/top-link";
import Container from "../container/container";
import useWindowSize from "../../tools/useWindowSize";
import HamburgerImg from "./../../assets/hamburger.svg";
import SubNavbar from "./subNavbar";
import { useSelector, shallowEqual } from "react-redux";
import { select_is_slideOverlayActive } from "../../redux/callbacksState/callbacksState.selectors";

const Navbar: React.FC = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const size = useWindowSize();
  const is_slideOverlayActive = useSelector(select_is_slideOverlayActive, shallowEqual);

  const handleClickMenu = (e: any) => {
    if (isMobileMenuOpen && size.isMobile) {
      setTimeout(() => {
        setIsMobileMenuOpen(false);
      }, 300);
    }
  };

  const handleMenuBtnClick = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  useEffect(() => {
    if (!size.isMobile) {
      setIsMobileMenuOpen(false);
    }
  }, [size.isMobile]);

  useEffect(() => {
    setIsMobileMenuOpen(false);
  }, [is_slideOverlayActive]);

  return (
    <nav className="navbar">
      <Container>
        <Grid>
          <Row gap={16}>
            <Col size={[2, 2, 2, 6]} className="logo">
              <TopLink to={RoutePath.HOME}>
                <img src={LogoImg} alt="EkoHigiena Aparatura Ryszard Putyra" />
              </TopLink>
            </Col>
            {size.isMobile ? (
              <Col size={[6, 6, 6, 6]} className="hamburder-col">
                <div>
                  <img className={`navbar__menu${isMobileMenuOpen ? " isOpen" : ""}`} src={HamburgerImg} alt="menu" onClick={handleMenuBtnClick} />
                </div>
              </Col>
            ) : (
              <SubNavbar onClick={handleClickMenu} />
            )}
          </Row>
        </Grid>
      </Container>
      {size.isMobile ? (
        <div className={`mobile${isMobileMenuOpen ? " isOpen" : ""}`}>
          <SubNavbar onClick={handleClickMenu} />
        </div>
      ) : null}
    </nav>
  );
};

export default Navbar;

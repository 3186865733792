/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import "./container.scss";

interface Props {
  children: React.ReactNode;
  isRaw?: boolean;
  className?: string;
}

const Container: React.FC<Props> = (props: Props) => {
  const { children } = props;
  return (
    <div className={`container${props.isRaw ? " isRaw" : ""}${props.className ? " " + props.className : ""}`} >
      {children}
    </div >
  )
}

export default Container;
import React from "react";
import { ProductContentDto, ProductDto } from "../../../../dto/productDto";
import { ProductTileDto } from "../../../../dto/productTileDto";
import { RoutePath } from "../../../../route-paths";

import TextBlock from "../../../../components/writing-tools/text-block/text-block";
import TitleBlock from "../../../../components/title-block/title-block";
import LinkWithIconWrapper from "../../../../components/link-with-icon/link-with-icon-wrapper";
import LinkWithIcon from "../../../../components/link-with-icon/link-with-icon";
import PdfIcon from "../../../../components/pdf-icon/pdf-icon";
import LighthouseLogo from "./../../../../assets/products/LWS LOGO - BW-transparent.png";
import ScanAirPro from "./../../../../assets/products/sondy_do_skanowania/b_ScanAir-Pro.jpeg";
import ScanAir from "./../../../../assets/products/sondy_do_skanowania/b_SOLAIR_with_Filter_Scanning_Wand_2.jpeg";
import ScanAirBrochure from "./../../../../assets/products/sondy_do_skanowania/Scan_Air_Brochure_ScanAir_ScanAir_Pro.pdf";
import ScanAirDatasheet from "./../../../../assets/products/sondy_do_skanowania/Scan_Air_Datasheet_ScanAir.pdf";
import ScanAirProDatasheet from "./../../../../assets/products/sondy_do_skanowania/Scan_Air_Datasheet_ScanAir_Pro.pdf";
import ScanAirProManual from "./../../../../assets/products/sondy_do_skanowania/248083430-1_R4_(OpMan_ScanAir_Pro_and_Diluter)_A4.pdf";

export const ProductsSondyDoSkanowania = [
  {
    tile: {
      title: "ScanAir Pro",
      subtitle: "Producent: Lighthouse",
      price: "",
      img: ScanAirPro,
      to: RoutePath.PRODUCT,
    } as ProductTileDto,
    url: "scan-air-pro",
    content: {
      images: [LighthouseLogo],
      body: (
        <TextBlock>
          <TitleBlock title="Sonda do skanowania filtrów ScanAir Pro" smallPaddingTop />
          <hr />
          <p>
            Inteligentny system próbkowania przed filtrem i skanowanai za.
            <br />
            <br />
            Lighthouse ScanAir Pro System to system skanowania filtrów HEPA/ULPA oparty na liczniku cząstek. Jest to łatwa i lekka metoda testowania i skanowania szczelności PTFE, włókna szklanego oraz innych materiałów filtracyjnych.
            <br />
            <br />
            Zawierający ergonomiczną, lekką sondę skanującą z ekranem dotykowym, system ScanAir Pro zawiera również przenośny licznik cząstek SOLAIR i dwuportowy rozcieńczalnik, który można zdalnie przełączać między pomiarami za pomocą uchwytu ekranu dotykowego.
            <br />
            <br />
            System ScanAir Pro umożliwia wykonywanie pomiarów przed i za filtrem, które wyświetlają w czasie rzeczywistym informacje o procentach nieszczelności na zintegrowanym ekranie dotykowym.
            <br />
            <br />
            Skontaktuj się z nami, aby uzyskać dodatkowe informacje lub umówić się na demonstrację.
            <br />
            <br />
          </p>
        </TextBlock>
      ),
      tabs: [
        {
          name: "Specifikacja",
          body: (
            <TextBlock>
              <table>
                <tbody>
                  <tr>
                    <td>Przepływ</td>
                    <td>1.0 CFM (28.3 LPM)</td>
                  </tr>
                  <tr>
                    <td>Regulowana głowica skanująca</td>
                    <td>TAK</td>
                  </tr>
                  <tr>
                    <td>Ekran dotykowy</td>
                    <td>TAK</td>
                  </tr>
                  <tr>
                    <td>Pomiar przed filtrem</td>
                    <td>TAK</td>
                  </tr>
                  <tr>
                    <td>Pomiar za filtrem</td>
                    <td>TAK</td>
                  </tr>
                  <tr>
                    <td>Standardowa długość kabla sondy i rurki</td>
                    <td>3 metry</td>
                  </tr>
                  <tr>
                    <td>Integracja z licznikami</td>
                    <td>Liczniki cząstek Solair</td>
                  </tr>
                  <tr>
                    <td>Wejście słuchawkowe</td>
                    <td>TAK</td>
                  </tr>
                  <tr>
                    <td>Waga sondy i głowicy</td>
                    <td>6 oz (0.17 kg)</td>
                  </tr>
                  <tr>
                    <td>Waga dilutera</td>
                    <td>9.7 lbs (4.4 kg)</td>
                  </tr>
                </tbody>
              </table>
            </TextBlock>
          ),
        },
        {
          name: "Do pobrania",
          body: (
            <TextBlock>
              <LinkWithIconWrapper>
                <LinkWithIcon icon={<PdfIcon />} to={ScanAirBrochure}>
                  Ulotka
                </LinkWithIcon>
                <LinkWithIcon icon={<PdfIcon />} to={ScanAirProDatasheet}>
                  Dane techniczne
                </LinkWithIcon>
                <LinkWithIcon icon={<PdfIcon />} to={ScanAirProManual}>
                  Instrukcja obsługi
                </LinkWithIcon>
              </LinkWithIconWrapper>
            </TextBlock>
          ),
        },
      ],
    } as ProductContentDto,
  },
  {
    tile: {
      title: "ScanAir",
      subtitle: "Producent: Lighthouse",
      price: "",
      img: ScanAir,
      to: RoutePath.PRODUCT,
    } as ProductTileDto,
    url: "scan-air",
    content: {
      images: [LighthouseLogo],
      body: (
        <TextBlock>
          <TitleBlock title="Sonda do skanowania filtrów ScanAir" smallPaddingTop />
          <hr />
          <p>
            Lighthouse Scan Air zapewnia łatwy w użyciu sposób sprawdzania filtrów HEPA / ULPA i ich uszczelek pod kątem wycieków i wydajności.
            <br />
            <br />
            Scan Air zawiera sondę izokinetyczną zaprojektowaną specjalnie dla natężenia przepływu próbkowania 1,0 CFM (28,3 LPM) na dużym obszarze.
            <br />
            <br />
            Scan Air zawiera przycisk zdalnego uruchamiania/zatrzymywania i kabel o długości 7,62 metra (25 stóp). Przełącznik zdalny umożliwia bezdotykową obsługę przenośnego licznika cząstek SOLAIR, umożliwiając użytkownikowi wydajne pobieranie próbek zgodnie z normą ISO 14644.
            <br />
            <br />
            Wbudowany brzęczyk alarmowy i dioda LED szybko powiadamiają użytkownika, jeśli liczba przekracza próg alarmowy, nawet gdy Scan Air jest używany w środowisku o wysokim poziomie hałasu. Złącze typu twist-lock szybko i łatwo łączy lub odłącza Scan Air od przenośnego licznika cząstek Solair.
            <br />
            <br />
            Solar i Scan Air mogą być używane jako przenośny system wykrywania zanieczyszczeń w całym środowisku pomieszczeń czystych.
            <br />
            <br />
          </p>
        </TextBlock>
      ),
      tabs: [
        {
          name: "Specifikacja",
          body: (
            <TextBlock>
              <table>
                <tbody>
                  <tr>
                    <td>Przepływ</td>
                    <td>1.0 CFM (28.3 LPM)</td>
                  </tr>
                  <tr>
                    <td>Zdalny Start/Stop</td>
                    <td>TAK</td>
                  </tr>
                  <tr>
                    <td>Standardowa długość kabla sondy i rurki</td>
                    <td>3 metry</td>
                  </tr>
                  <tr>
                    <td>Integracja z licznikami</td>
                    <td>Liczniki cząstek Solair</td>
                  </tr>
                  <tr>
                    <td>Wejście słuchawkowe</td>
                    <td>TAK</td>
                  </tr>
                  <tr>
                    <td>Waga sondy i głowicy</td>
                    <td>7 oz (0.20 kg)</td>
                  </tr>
                </tbody>
              </table>
            </TextBlock>
          ),
        },
        {
          name: "Do pobrania",
          body: (
            <TextBlock>
              <LinkWithIconWrapper>
                <LinkWithIcon icon={<PdfIcon />} to={ScanAirBrochure}>
                  Ulotka
                </LinkWithIcon>
                <LinkWithIcon icon={<PdfIcon />} to={ScanAirDatasheet}>
                  Dane techniczne
                </LinkWithIcon>
              </LinkWithIconWrapper>
            </TextBlock>
          ),
        },
      ],
    } as ProductContentDto,
  },
] as Array<ProductDto>;

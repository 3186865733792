import React from "react";
import { ProductContentDto, ProductDto } from "../../../../dto/productDto";
import { ProductTileDto } from "../../../../dto/productTileDto";
import { RoutePath } from "../../../../route-paths";

import TextBlock from "../../../../components/writing-tools/text-block/text-block";
import TitleBlock from "../../../../components/title-block/title-block";
import LinkWithIconWrapper from "../../../../components/link-with-icon/link-with-icon-wrapper";
import WagiRadwag from "./../../../../assets/products/wagi_analityczne/wagi-radwag.jpeg";
import RadwagLogo from "./../../../../assets/products/wagi_analityczne/logo-rw.png";

import RelatedProducts from "../../../../components/RelatedProducts/RelatedProducts";

export const ProductsWagiAnalityczne = [
  {
    tile: {
      title: "Wagi Radwag",
      subtitle: "Producent: Radwag",
      price: "",
      img: WagiRadwag,
      to: RoutePath.PRODUCT,
    } as ProductTileDto,
    url: "radwag",
    content: {
      images: [RadwagLogo],
      body: (
        <TextBlock>
          <TitleBlock title="Wagi analityczne fimry Radwag" smallPaddingTop />
          <hr />

          <p>
            Seria AS R2 PLUS reprezentuje poziom standardowych wag analitycznych linii SYNERGY LAB, którą wyróżnia szereg nowatorskich rozwiązań konstrukcyjnych i systemowych gwarantujących niezawodność, precyzję i dokładność pomiarów oraz ułatwiających codzienną pracę z urządzeniem.
            <br />
            <br />
          </p>

          <p>
            Wagi analityczne XA 4Y PLUS osiągają najlepszą na rynku minimalną naważkę i są dedykowane do zaawansowanych procesów ważenia małych mas. Urządzenia cechują się wysoką odpornością na zmienne warunki środowiskowe, a ergonomiczna konstrukcja zapewnia wygodę pracy. <br />
            <br />
          </p>

          <p>
            Mikrowagi XA 4Y.M PLUS osiągają najlepszą na rynku minimalną naważkę i są dedykowane do zaawansowanych procesów ważenia małych mas. Urządzenia cechują się wysoką odpornością na zmienne warunki środowiskowe, a ergonomiczna konstrukcja zapewnia wygodę pracy.
            <br />
            <br />
          </p>
        </TextBlock>
      ),
      tabs: [
        {
          name: "Specifikacja",
          body: (
            <TextBlock>
              <table>
                <tbody>
                  <tr>
                    <td></td>
                    <td>
                      <b>Waga analityczna AS 62.R2.PLUS</b>
                    </td>
                    <td>
                      <b>Waga analityczna AS 60/220.X2 PLUS</b>
                    </td>
                    <td>
                      <b>Waga analityczna XA 52.4Y PLUS</b>
                    </td>
                    <td>
                      <b>Waga analityczna XA 52.4Y.A PLUS</b>
                    </td>
                    <td>
                      <b>Mikrowaga A 6.4Y.M PLUS</b>
                    </td>
                    <td>
                      <b>MikrowagaXA 52.4Y.A PLUS</b>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Pomiar T, RH, P i drgań</b>
                    </td>
                    <td>&#x2715;</td>
                    <td>&#x2714;</td>
                    <td>&#x2715;</td>
                    <td>&#x2715;</td>
                    <td>&#x2715;</td>
                    <td>&#x2715;</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Automatyczne drzwi</b>
                    </td>
                    <td>&#x2715;</td>
                    <td>&#x2715;</td>
                    <td>&#x2715;</td>
                    <td>&#x2714;</td>
                    <td>&#x2715;</td>
                    <td>&#x2714;</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Wbudowany jonizator</b>
                    </td>
                    <td>&#x2715;</td>
                    <td>&#x2715;</td>
                    <td>&#x2715;</td>
                    <td>&#x2715;</td>
                    <td>&#x2715;</td>
                    <td>&#x2714;</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Wyrównywanie ciśnień </b>
                    </td>
                    <td>&#x2715;</td>
                    <td>&#x2715;</td>
                    <td>&#x2715;</td>
                    <td>&#x2715;</td>
                    <td>&#x2715;</td>
                    <td>&#x2714;</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Dokładność odczytu [d]</b>
                    </td>
                    <td>0,01 mg</td>
                    <td>0,01 mg</td>
                    <td>0,01 mg</td>
                    <td>0,01 mg</td>
                    <td>1 µg</td>
                    <td>1 µg</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Powtarzalność standardowa</b>
                    </td>
                    <td>0,012 mg</td>
                    <td>0,01 mg</td>
                    <td>0,006 mg</td>
                    <td>0,005 mg</td>
                    <td>0,8 µg</td>
                    <td>0,5 µg</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Liniowość</b>
                    </td>
                    <td>±0,05 mg</td>
                    <td>±0,05 mg</td>
                    <td>±0,03 mg</td>
                    <td>±0,03 mg</td>
                    <td>±7 µg</td>
                    <td>±3 µg</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Czas stabilizacji</b>
                    </td>
                    <td>2 s</td>
                    <td>2 s</td>
                    <td>4 s</td>
                    <td>4 s</td>
                    <td>3,5 s</td>
                    <td>4 s</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Wyświetlacz</b>
                    </td>
                    <td>LCD ( z podświetleniem)</td>
                    <td>5" pojemnościowy kolorowy dotykowy</td>
                    <td>5,7” rezystancyjny kolorowy dotykowy</td>
                    <td>5,7” rezystancyjny kolorowy dotykowy</td>
                    <td>5,7” rezystancyjny kolorowy dotykowy</td>
                    <td>5,7” rezystancyjny kolorowy dotykowy</td>
                  </tr>
                </tbody>
              </table>
            </TextBlock>
          ),
        },
        {
          name: "Do pobrania",
          body: (
            <TextBlock>
              <LinkWithIconWrapper>{/*<LinkWithIcon icon={<PdfIcon/>} to={DDS560_ulotka}>Ulotka</LinkWithIcon>*/}</LinkWithIconWrapper>
            </TextBlock>
          ),
        },
        {
          name: "Produkty Powiązane",
          body: <RelatedProducts items={["scan-air", "scan-air-pro"]} />,
        },
      ],
    } as ProductContentDto,
  },
] as Array<ProductDto>;

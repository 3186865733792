import React from "react";
import "./product-tile.scss";
import { useDispatch } from "react-redux";
import { showSlideOverlay } from "../../redux/callbacksState/callbacksState.actions";
import { ProductDto } from "../../dto/productDto";
import Col from "../grid/col";

interface Props {
  to: string;
  ref?: any;
  className?: string;
  noSlide?: boolean;
  product: ProductDto;
}

const ProductTile: React.FC<Props> = (props: Props) => {
  const dispatch = useDispatch();
  const { product } = props;
  const slideAndScroll = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    if (!props.noSlide) {
      dispatch(showSlideOverlay(props.to as string));
    }
    // setTimeout(() => {
    //   window.scrollTo(0, 0)
    // }, 200);
  };

  return (
    <Col size={[4, 4, 6, 12]}>
      <div className="product-tile-wrapper">
        <a href={props.to} title={product.tile.title ? product.tile.title : ""} onClick={(e) => slideAndScroll(e)} className={`product-tile${props.className ? " " + props.className : ""}`}>
          <div className="box">
            <div className="product-tile__img" style={{ backgroundImage: `url('${product.tile.img}')` }}></div>
          </div>
          <div className="description">
            <div className="info-row">
              <div className="subtitle">{product.tile.subtitle}</div>
              <div className="price">{product.tile.price}</div>
            </div>
            <h5>{product.tile.title}</h5>
          </div>
        </a>
      </div>
    </Col>
  );
};

export default ProductTile;

import React, {useEffect, useRef} from "react";
import {useLocation} from "react-router";
import TitleBlock from "../../../components/title-block/title-block";
import PostBlock from "../../../components/writing-tools/post-block/post-block";
import TextBlock from "../../../components/writing-tools/text-block/text-block";
import BlogSection from "../../../sections/blog-section/blog-section";

const RulesSectionWrapper: React.FC = () => {
  const cookiesRef = useRef<any>();
  const privacyRef = useRef<any>();
  const location = useLocation();

  const handleScrollToSection = (section: React.MutableRefObject<any>) => {
    window.scrollTo(0, section.current!.getBoundingClientRect().top + window.pageYOffset - 128);
  };

  useEffect(() => {
    if (location.hash === "#cookies") {
      handleScrollToSection(cookiesRef);
    }
    if (location.hash === "#privacy") {
      handleScrollToSection(privacyRef);
    }
  }, [location]);

  return (
    <div>
      <BlogSection title={<TitleBlock title="Warunki użytkowania"/>}>
        {/*<PostBlock>*/}
        {/*  <TextBlock>*/}
        {/*    <h4>Lorem ipsum ec orci lacinia</h4>*/}
        {/*    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus mattis convallis maximus. Sed aliquet*/}
        {/*      auctor ligula. Maecenas porttitor odio nec orci lacinia congue. Nulla ac porttitor ante, non varius ipsum.*/}
        {/*      Nullam magna lacus, rutruauris vitae dictum leo. Suspendisse eu ante in risus volutpat tristique.</p>*/}
        {/*  </TextBlock>*/}
        {/*</PostBlock>*/}
        {/*<PostBlock>*/}
        {/*  <TextBlock>*/}
        {/*    <h4>Linterdum orem ipsum</h4>*/}
        {/*    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus mattis convallis maximus. Sed aliquet*/}
        {/*      auctor ligula. Maecenas porttitor odio nec orci lacinia congue. Nulla ac porttitor ante, non varius ipsum.*/}
        {/*      Nullam magna lacus, rutrum ut lacinia ac, ultricies eu massa. Pellentesque posuere interdum metus eu*/}
        {/*      auctor. Vivamus vehicula pretium commodo. Nullam malesuada nibh dignissim sem pulvinar, id rhoncus tortor*/}
        {/*      tristique. Mauris vitae dictum leo. Suspendisse eu ante in risus volutpat tristique. Uet auctor ligula.*/}
        {/*      Maecenas porttitor odio nec orci lacinia congue. Nulla ac porttitor ante, non varius ipsum. Nullam magna*/}
        {/*      lacus, rutru</p>*/}
        {/*  </TextBlock>*/}
        {/*</PostBlock>*/}
        {/*<PostBlock>*/}
        {/*  <TextBlock>*/}
        {/*    <h4>Lo onvallis maximus rem ipsum</h4>*/}
        {/*    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus matti sconvallis maximus. Sed aliquet*/}
        {/*      ueris ligula. Maecenas porttitor odio nec oueto lutpatrci lacinia congueris uetolutpat tristique.</p>*/}
        {/*  </TextBlock>*/}
        {/*</PostBlock>*/}
      </BlogSection>
      <div ref={cookiesRef}></div>
      <BlogSection className="no-pt" title={<TitleBlock title="Polityka Cookies"/>}>
        <PostBlock>
          <TextBlock>
            <p>Polityka dotycząca cookies</p><br/><br/>
            <p>W celu świadczenia usług na najwyższym poziomie stosujemy pliki cookies. Korzystanie z naszej witryny
              oznacza, że będą one zamieszczane w Państwa urządzeniu. W każdym momencie można dokonać zmiany ustawień
              Państwa przeglądarki.</p>
          </TextBlock>
        </PostBlock>
        <PostBlock>
          <TextBlock>

            <p>Dla Państwa wygody serwis ekohigiena.com.pl używa plików cookies po to, by dostosować serwis do potrzeb
              użytkowników, a także w celach statystycznych.</p>

            <p>Pliki cookies (tzw. ciasteczka) to niewielkie pliki tekstowe wysyłane przez odwiedzany serwis internetowy
              do urządzenia internauty (komputer, smartfon itp.). Nie zawierają one żadnych danych osobowych.</p>

            <p>Serwis ekohigiena.com.pl stosuje dwa typy plików cookies:</p>

            <ol>
              <li>wydajnościowe (zbierające informacje na temat sposobu używania witryny przez odwiedzających, np.
                najczęściej odwiedzanych stron lub komunikatów o błędach itp.),
              </li>

              <li>funkcjonalne (zapisujące ustawienia użytkownika, np. język, wyrażone zgody itp.), takie jak:</li>
            </ol>
            <ul>
              <li>cookies google-analytics.com – służą do prowadzenia statystyk dla witryny ekohigiena.com.pl; dokładny sposób
                działania i politykę prywatności Google Analytics można znaleźć pod adresem:<br/>
                <a
                  href={'https://marketingplatform.google.com/about/analytics/terms/pl/'}>https://marketingplatform.google.com/about/analytics/terms/pl/</a> ,
                <a href={'https://policies.google.com/privacy'}> https://policies.google.com/privacy</a>
              </li>
            </ul>

            <p>W większości przeglądarek internetowych można: kasować pliki typu cookies z twardego dysku komputera (z
              poziomu ustawień przeglądarki), zablokować wszystkie przysyłane pliki typu cookies lub ustawić ostrzeżenie
              przed zapisaniem ich na dysku.</p><br/><br/>

            <p>Należy mieć na uwadze, że zmiany ustawień tych plików polegające na ograniczeniu ich stosowania mogą
              wpłynąć
              na niektóre funkcjonalności dostępne na stronach internetowych je stosujących np. uniemożliwiając
              zalogowanie się na konto pocztowe. Brak zmiany tych ustawień oznacza akceptację dla stosowania plików typu
              cookies.</p><br/><br/>

            <p>Poniżej wskazujemy sposób zmiany ustawień cookies w najbardziej popularnych wyszukiwarkach:</p><br/><br/>

            <p><b>Google Chrome</b><br/>
              Menu &gt; Ustawienia &gt; Pokaż ustawienia zaawansowane &gt; Prywatność &gt; Ustawienia treści &gt; Pliki
              cookies – należy
              wybrać odpowiednią opcję.</p>

            <p><b>Internet Explorer</b><br/>
              Menu &gt; Narzędzia &gt; Opcje internetowe &gt; Prywatność – należy wybrać odpowiednią opcję.</p>

            <p><b>Mozilla Firefox</b><br/>
              Menu &gt; Opcje &gt; Prywatność &gt; Historia – należy wybrać odpowiednią opcję.</p>

            <p><b>Opera</b><br/>
              Menu &gt; Preferencje &gt; Zaawansowane &gt; Ciasteczka – należy wybrać odpowiednią opcję.</p>

            <p><b>Safari</b><br/>
              Menu &gt; Preferencje &gt; Prywatność &gt; Pliki cookies – należy wybrać odpowiednią opcję.</p>
          </TextBlock>
        </PostBlock>
        {/*<PostBlock>*/}
        {/*  <TextBlock>*/}
        {/*    <h4>Lo onvallis maximus rem ipsum</h4>*/}
        {/*    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus matti sconvallis maximus. Sed aliquet*/}
        {/*      ueris ligula. Maecenas porttitor odio nec oueto lutpatrci lacinia congueris uetolutpat tristique.</p>*/}
        {/*  </TextBlock>*/}
        {/*</PostBlock>*/}
      </BlogSection>
      {/*<div ref={privacyRef}></div>*/}
      {/*<BlogSection className="no-pt" title={<TitleBlock title="Polityka prywatności"/>}>*/}
      {/*  <PostBlock>*/}
      {/*    <TextBlock>*/}
      {/*      <h4>Lorem ipsum ec orci lacinia</h4>*/}
      {/*      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus mattis convallis maximus. Sed aliquet*/}
      {/*        auctor ligula. Maecenas porttitor odio nec orci lacinia congue. Nulla ac porttitor ante, non varius ipsum.*/}
      {/*        Nullam magna lacus, rutruauris vitae dictum leo. Suspendisse eu ante in risus volutpat tristique.</p>*/}
      {/*    </TextBlock>*/}
      {/*  </PostBlock>*/}
      {/*  <PostBlock>*/}
      {/*    <TextBlock>*/}
      {/*      <h4>Linterdum orem ipsum</h4>*/}
      {/*      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus mattis convallis maximus. Sed aliquet*/}
      {/*        auctor ligula. Maecenas porttitor odio nec orci lacinia congue. Nulla ac porttitor ante, non varius ipsum.*/}
      {/*        Nullam magna lacus, rutrum ut lacinia ac, ultricies eu massa. Pellentesque posuere interdum metus eu*/}
      {/*        auctor. Vivamus vehicula pretium commodo. Nullam malesuada nibh dignissim sem pulvinar, id rhoncus tortor*/}
      {/*        tristique. Mauris vitae dictum leo. Suspendisse eu ante in risus volutpat tristique. Uet auctor ligula.*/}
      {/*        Maecenas porttitor odio nec orci lacinia congue. Nulla ac porttitor ante, non varius ipsum. Nullam magna*/}
      {/*        lacus, rutru</p>*/}
      {/*    </TextBlock>*/}
      {/*  </PostBlock>*/}
      {/*  <PostBlock>*/}
      {/*    <TextBlock>*/}
      {/*      <h4>Lo onvallis maximus rem ipsum</h4>*/}
      {/*      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus matti sconvallis maximus. Sed aliquet*/}
      {/*        ueris ligula. Maecenas porttitor odio nec oueto lutpatrci lacinia congueris uetolutpat tristique.</p>*/}
      {/*    </TextBlock>*/}
      {/*  </PostBlock>*/}
      {/*</BlogSection>*/}
    </div>
  );
};

export default RulesSectionWrapper;

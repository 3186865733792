import React from "react";
import BoxLink from "../../../components/box-link/box-link";
import Container from "../../../components/container/container";
import Col from "../../../components/grid/col";
import Grid from "../../../components/grid/grid";
import Row from "../../../components/grid/row";
import TitleBlock from "../../../components/title-block/title-block";
import { RoutePath } from "../../../route-paths";
import BlogPostImg from "../../../assets/logo_icon.svg";
import HeroSectionWrapper from "./hero-section-wrapper";

const BlogNewsSection: React.FC = () => {
  return (
    <section className="blog-news-section">
      <HeroSectionWrapper />
      <TitleBlock title="Zobacz najnowsze artykuły" />
      <Container className="blog-nav">
        <Grid>
          <Row gap={16}>
            <Col size={[8, 8, 10, 12]} offset={[2, 2, 1, 0]}>
              <BoxLink to={RoutePath.BLOG__DOKUMENTACJA_DO_GLOWIC} dateOfPublishing="2022-08-30">
                <img src={BlogPostImg} alt={"test"} className="box-logo mr-4" />
                <div>
                  Dokumentacja do głowic do poboru pyłu w związku z normą
                  <br /> PN-Z-04507:2022-05 i PN-Z-04508:2022-05.
                </div>
              </BoxLink>
              {/*<BoxLink to={RoutePath.BLOG_POST_NOWA_NORMA}>*/}
              {/*  Lorem ipsum sit amet olen lum*/}
              {/*</BoxLink>*/}
              {/*<BoxLink to={RoutePath.BLOG_POST_NOWA_NORMA}>*/}
              {/*  Maximus ipsum dolor sit amet*/}
              {/*</BoxLink>*/}
              {/*<BoxLink to={RoutePath.BLOG_POST_NOWA_NORMA}>*/}
              {/*  Cons ectetus adipscing elit*/}
              {/*</BoxLink>*/}
              {/*<BoxLink to={RoutePath.BLOG_POST_NOWA_NORMA}>*/}
              {/*  Waximus dolor sit amet ipsum*/}
              {/*</BoxLink>*/}
            </Col>
          </Row>
        </Grid>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </Container>
    </section>
  );
};

export default BlogNewsSection;

import React from "react";
import Button from "../../../components/button/button";
import HeroSection from "../../../sections/hero-section/hero-section";
import HeroImg from "./../../../assets/heroImg.jpg";

interface Props {
  scrollToRef: any;
}

const HeroSectionWrapper: React.FC<Props> = (props: Props) => {
  const handleScrollToSection = (section: React.MutableRefObject<any>) => {
    window.scrollTo(
      0,
      section.current!.getBoundingClientRect().top + window.pageYOffset
    );
  };
  return (
    <HeroSection img={HeroImg} alt="Example img">
      <h1>O nas</h1>
      <p>Jesteśmy zespołem składającym się z doświadczonych pasjonatów nauki</p>
      <Button onClick={() => handleScrollToSection(props.scrollToRef)}>
        Dowiedz się wiecej
      </Button>
    </HeroSection>
  );
};

export default HeroSectionWrapper;

import React from "react";
import TextBlock from "../../../../components/writing-tools/text-block/text-block";
import { ProductContentDto, ProductDto } from "../../../../dto/productDto";
import { ProductTileDto } from "../../../../dto/productTileDto";
import { RoutePath } from "../../../../route-paths";
import TitleBlock from "../../../../components/title-block/title-block";
import PdfIcon from "../../../../components/pdf-icon/pdf-icon";
import LinkWithIcon from "../../../../components/link-with-icon/link-with-icon";
import LinkWithIconWrapper from "../../../../components/link-with-icon/link-with-icon-wrapper";
import Abacus from "../../../../assets/products/przenosne_liczniki_czastek/abacus.png";
import KlotzLogo from "../../../../assets/products/przenosne_liczniki_czastek/klotz_logo.jpg";
import Abakus_ulotka from "../../../../assets/products/przenosne_liczniki_czastek/abakus_ulotka.pdf";
import Abakus_spec from "../../../../assets/products/przenosne_liczniki_czastek/abakus_spec.pdf";

export const ProductsLicznikiCzastekKlotz = [
  {
    tile: {
      title: "Abakus mobil air",
      subtitle: "Producent: Klotz GmbH",
      price: "",
      img: Abacus,
      to: RoutePath.PRODUCT,
    } as ProductTileDto,
    url: "abakus",
    content: {
      images: [KlotzLogo],
      body: (
        <TextBlock>
          <TitleBlock title="Abakus mobil air" smallPaddingTop />
          <hr />
          <p>
            Licznik cząstek Abakus mobil air to system do pomiaru liczby i wielkości cząstek w powietrzu oraz w gazach. Nadaje się on zarówno do zastosowań stacjonarnych, jak i mobilnych. Poprzez wykorzystanie różnych czujników (LDS 228, LDS 328, LDS 328s lub LDS 528) Abakus umożliwia pomiar wielkości cząstek w zakresie od 0,2 μm do 50 μm a z czujnikiem LDS 2/2 cząstek o wielkości od 5 μm do 500 μm, dzięki czemu nadaje się do szerokiego zakresu zadań pomiarowych. Łatwa w obsłudze nawigacja po menu zapewnia wydajną pracę od samego początku. Podczas pomiaru wyświetlacz LCD pokazuje przegląd 16 kanałów wielkości cząstek, datę, godzinę i numer bieżącego pomiaru. Dodatkowo wyniki pomiarów są prezentowane w formie tabeli. Po zakończeniu pomiaru dane można wydrukować na drukarce termicznej. Oprogramowanie „Log and Show” umożliwia dalsze przetwarzanie wyników na komputerze w różnych programach (MS-Excel, Lotus 1-2-3 itp.). Oprogramowanie umożliwia również bezpośredni pomiar i archiwizację. Funkcja autokalibracji licznika umożliwia proste testowanie i wtórną kalibrację systemu.
            <br />
            <br />
          </p>

          <p>
            Abakus® mobil air jest dostępny w dwóch wersjach:
            <ul>
              <li>wersja z zasilaniem z sieci 230V/AC</li>
              <li>wersja z akumulatorem 24V/DC</li>
            </ul>
            <br />
            <br />
          </p>

          <h4>Zastosowanie</h4>
          <p>
            Monitoring i inspekcja pomieszczeń czystych, czyste stoły warsztatowe, izolatory, urządzenia filtrujące w salach operacyjnych, sprężone powietrze, stacje paliw w przemyśle farmaceutycznym, badania wydajności filtrów, pomiary czystości powierzchni itp.
            <br />
            <br />
          </p>
        </TextBlock>
      ),
      tabs: [
        {
          name: "Specifikacja",
          body: (
            <TextBlock>
              <table>
                <tbody>
                  <tr>
                    <td>Metoda</td>
                    <td>rozpraszanie światła 90°</td>
                  </tr>
                  <tr>
                    <td>Zakres rozmiarów cząstek</td>
                    <td>zakres wybieralny, np. 0,5-20 μm / 0,3-10 μm / 0,2-5 μm / 0,1-2 μm</td>
                  </tr>
                  <tr>
                    <td>Kanały pomiarowe</td>
                    <td>max. 16 kanałów</td>
                  </tr>
                  <tr>
                    <td>Limit stężenia</td>
                    <td>1,000,000 cząstek/ft3 (35,300,000 cząstek/m3) przy 10% utracie zgodności dla pomieszczeń czystych klas ISO 1-8</td>
                  </tr>
                  <tr>
                    <td>Waga</td>
                    <td>Ama LDS 328 z baterią: 7 kg aluminum, Ama LDS 228 / Ama LDS 328 / Ama LDS 528: 10 kg aluminum, 13 kg stal nierdzewna</td>
                  </tr>
                  <tr>
                    <td>Wyświetlane dane</td>
                    <td>data, godzina, objętość pomiarowa, liczba cząstek, klasa pomieszczeń czystych</td>
                  </tr>
                  <tr>
                    <td>Odczyt wyników</td>
                    <td>wyświetlacz, wbudowana drukarka, oprogramowanie PC LAS 31</td>
                  </tr>
                  <tr>
                    <td>Komunikacja</td>
                    <td>RS232</td>
                  </tr>
                  <tr>
                    <td>Pojemność pamięci</td>
                    <td>2,400 rekordów + karta pamięci</td>
                  </tr>
                  <tr>
                    <td>Obudowa</td>
                    <td>Aluminium, Stal nierdzewna</td>
                  </tr>
                  <tr>
                    <td>Zasilanie</td>
                    <td>230/115 VAC, 50/60 Hz, max. 250W</td>
                  </tr>
                  <tr>
                    <td>Wymiary</td>
                    <td>Ama LDS 328 z baterią: 200 x 360 x 190 mm, Ama LDS 228 / Ama LDS 328 / Ama LDS 528: 200 x 360 x 260 mm</td>
                  </tr>
                </tbody>
              </table>
            </TextBlock>
          ),
        },
        {
          name: "Do pobrania",
          body: (
            <TextBlock>
              <LinkWithIconWrapper>
                <LinkWithIcon icon={<PdfIcon />} to={Abakus_ulotka}>
                  Ulotka
                </LinkWithIcon>
                <LinkWithIcon icon={<PdfIcon />} to={Abakus_spec}>
                  Dane techniczne
                </LinkWithIcon>
              </LinkWithIconWrapper>
            </TextBlock>
          ),
        },
      ],
    } as ProductContentDto,
  },
] as Array<ProductDto>;

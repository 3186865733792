import React, { useEffect, useState } from "react";
import ContactSection from "../../sections/contact-section/contact-section";
import HeroSectionWrapper from "./sections/hero-section-wrapper";
import "./categories-page.scss";
import CtaSectionWrapper from "./sections/cta-section-wrapper";
import ProductsSection from "./sections/products-section";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { RoutePath } from "../../route-paths";
import { generatePath, useParams } from "react-router-dom";
import { usageData } from "./data/usages-data";

const CategoryPage: React.FC = () => {
  let { usageId } = useParams<{ usageId: string }>();
  const [usage, setUsage] = useState("");

  useEffect(() => {
    const item = usageData.find((x) => x.url === usageId);
    if (usageId === "wszystkie") setUsage("wszystkie");
    else setUsage(item?.tile?.title ?? "");
  }, [usageId]);

  return (
    <div className="categories-page">
      <BreadcrumbsItem to={RoutePath.CATEGORIES}>Produkty</BreadcrumbsItem>
      <BreadcrumbsItem to={generatePath(RoutePath.USAGE, { usageId: usageId })}>{usage}</BreadcrumbsItem>
      <HeroSectionWrapper />
      <ProductsSection />
      <CtaSectionWrapper />
      <ContactSection />
    </div>
  );
};

export default CategoryPage;

/* eslint-disable jsx-a11y/anchor-has-content */
import React, { } from "react";
import "./fullImg-block.scss";

interface Props {
  img: string;
  alt?: string;
  height?: number;
}

const FullImgBlock: React.FC<Props> = (props: Props) => {
  const { img, alt, height } = props;

  return (
    <div className={"fullImg-block"}>
      {height ?
        <div className="bg-img" style={{ backgroundImage: `url('${img}')`, height: height }} title={alt} />
        :
        <img src={img} alt={alt ? alt : ""} />
      }
    </div>
  )
}

export default FullImgBlock;
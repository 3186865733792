import React from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { Switch, Route, Redirect } from "react-router-dom";
import { RoutePath } from "../../../route-paths";
import RealizationMikroklimat from "./realizations-partial-views/mikroklimat";
import RealizationOddychalnosc from "./realizations-partial-views/oddychalnosc-masek";

import DystrybucjaPV from "./services-partial-views/dystrybucja";
import KalibracjaPv from "./services-partial-views/kalibracja";
import ProdukcjaPV from "./services-partial-views/produkcja";
import SerwisPv from "./services-partial-views/serwis";
import SzkoleniaPv from "./services-partial-views/szkolenia";

const ServicesSwitch: React.FC = () => {
  return (
    <>
      <BreadcrumbsItem to={RoutePath.SERVICES}>Usługi</BreadcrumbsItem>
      <Switch>
        <Route exact path={RoutePath.SERVICES__PRODUKCJA} render={() => <ProdukcjaPV />} />
        <Route exact path={RoutePath.SERVICES__DYSTRYBUCJA} render={() => <DystrybucjaPV />} />
        <Route exact path={RoutePath.SERVICES__SZKOLENIA} render={() => <SzkoleniaPv />} />
        <Route exact path={RoutePath.SERVICES__SERWIS} render={() => <SerwisPv />} />
        <Route exact path={RoutePath.SERVICES__KALIBRACJA} render={() => <KalibracjaPv />} />

        <Route exact path={RoutePath.SERVICES__PRODUKCJA__MIERNIKI_MIKROKLIMATU} render={() => <RealizationMikroklimat />} />
        <Route exact path={RoutePath.SERVICES__PRODUKCJA__ODDYCHALNOSC} render={() => <RealizationOddychalnosc />} />

        <Route exact path={RoutePath.SERVICES} render={() => <Redirect to={RoutePath.SERVICES} />} />
      </Switch>
    </>
  );
};

export default ServicesSwitch;

import React from "react";
import ContactSection from "../../sections/contact-section/contact-section";
import HeroSectionWrapper from "./sections/hero-section-wrapper";
import "./categories-page.scss";
import CtaSectionWrapper from "./sections/cta-section-wrapper";
import CategoriesSection from "./sections/categories-section";

const CategoriesPage: React.FC = () => {
  return (
    <div className="categories-page">
      <HeroSectionWrapper />
      <CategoriesSection />
      <CtaSectionWrapper />
      <ContactSection />
    </div>
  );
};

export default CategoriesPage;

import React, { useEffect, useState } from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { generatePath, useParams } from "react-router-dom";
import HeroSection from "../../../sections/hero-section/hero-section";
import { allCategoriesData } from "../data/categories-data";

const HeroSectionWrapper: React.FC = () => {
  let { usageId, categoryId, productId }: any = useParams();
  const [productTitle, setProductTitle] = useState("");
  const [productSubtitle, setProductSubtitle] = useState("");
  const [productUrl, setProductUrl] = useState("");

  const [categoryTitle, setCategoryTitle] = useState("");
  const [categorySubtitle, setCategorySubtitle] = useState("");
  const [categoryUrl, setCategoryUrl] = useState("");

  useEffect(() => {
    let data = allCategoriesData.find((x) => x.url === categoryId);
    if (data) {
      if (productId) {
        let product = data.products.find((x) => x.url === productId);
        setProductTitle(product?.tile.title ?? "");
        setProductSubtitle(product?.tile.subtitle ?? "");
        setProductUrl(product?.tile.to ?? "");
      }
      setCategoryTitle(data.tile.title);
      setCategorySubtitle(data.tile.subtitle);
      setCategoryUrl(data.tile.to);
    } else {
      setCategoryTitle("");
      setCategorySubtitle("");
      setCategoryUrl("");
    }
  }, [categoryId, productId]);

  return (
    <>
      <HeroSection>
        <h1>{productTitle ? productTitle : categoryTitle ? categoryTitle : "Produkty"}</h1>
        <p>{productSubtitle ? productSubtitle : categorySubtitle ? categorySubtitle : "Sprawdź, co posiadamy w ofercie"}</p>
      </HeroSection>
      {categoryUrl ? <BreadcrumbsItem to={generatePath(categoryUrl, { usageId: usageId, categoryId: categoryId })}>{categoryTitle}</BreadcrumbsItem> : null}
      {productUrl ? <BreadcrumbsItem to={generatePath(productUrl, { usageId: usageId, categoryId: categoryId, productId: productId })}>{productTitle}</BreadcrumbsItem> : null}
    </>
  );
};

export default HeroSectionWrapper;

import React from "react";
import { ProductContentDto, ProductDto } from "../../../../dto/productDto";
import { ProductTileDto } from "../../../../dto/productTileDto";
import { RoutePath } from "../../../../route-paths";

import TextBlock from "../../../../components/writing-tools/text-block/text-block";
import TitleBlock from "../../../../components/title-block/title-block";
import EkohigienaLogo from "./../../../../assets/products/mierniki_mikroklimatu/logoEkohigiena.svg";
import EHAMM203IMG from "./../../../../assets/products/mierniki_mikroklimatu/ehamm203.png";
import EHAMM203IMG2 from "./../../../../assets/products/mierniki_mikroklimatu/ehamm203side.png";
import EHAMM203IMG3 from "./../../../../assets/products/mierniki_mikroklimatu/ehamm203on.png";
import EHAMON1 from "../../../../assets/products/mierniki_mikroklimatu/ehamon1.png";

export const ProductsMiernikiMikroklimatu = [
  {
    tile: {
      title: "EHA MM203",
      subtitle: "Producent: Ekohigiena Aparatura",
      price: "",
      img: EHAMM203IMG,
      to: RoutePath.PRODUCT,
    } as ProductTileDto,
    url: "eha-mm203",
    content: {
      images: [EHAMM203IMG2, EHAMM203IMG3, EkohigienaLogo],
      body: (
        <TextBlock>
          <TitleBlock title="Miernik mikroklimatu EHA MM203" smallPaddingTop />
          <hr />
          <p>
            Miernik EHA MM203 został zaprojektowany w oparciu o normę ISO 7726. Miernik jest wyposażony w zestaw sond do pomiaru temperatury powietrza, temperatury czarnej kuli, temperatury naturalnej-wilgotnej, wilgotności względnej i prędkości powietrza. Podczas pomiarów automatycznie wyznaczane są parametry środowisk termicznych:
            <br />
            <br />
          </p>

          <li>mikroklimatu umiarkowanego ISO 7730</li>
          <li>mirkoklimatu gorącego PN-EN 27243</li>
          <li>mikroklimatu zimnego ISO 11079</li>
          <br />
          <br />

          <p>Miernika wyposażony jest w trzy zestawy sond pomiarowych, co umożliwia jednoczesny pomiar na poziomie stóp, brzucha i głowy osoby opomiarowanej, skracając czas pomiarów do minimum. </p>
        </TextBlock>
      ),
      tabs: [
        {
          name: "Specifikacja",
          body: (
            <TextBlock>
              <table>
                <tbody>
                  <tr>
                    <td>Współczynnik rozcieńczenia</td>
                    <td>1:100</td>
                  </tr>
                  <tr>
                    <td>Przepływ objętościowy</td>
                    <td>28,3 L/min</td>
                  </tr>
                  <tr>
                    <td>Metoda regulacji</td>
                    <td>manualna</td>
                  </tr>
                  <tr>
                    <td>Zasilanie</td>
                    <td>9 V zasilanie bateryjne (opcjonalny zasilacz)</td>
                  </tr>
                  <tr>
                    <td>Średnica króćca wlotowego i wylotowego</td>
                    <td>10 mm</td>
                  </tr>
                  <tr>
                    <td>Spadek ciśnienia</td>
                    <td>~ 0,6 kPa</td>
                  </tr>
                  <tr>
                    <td>Warunki środowiskowe, temperatura</td>
                    <td>10 - 50 °C</td>
                  </tr>
                  <tr>
                    <td>Warunki środowiskowe, maksymalne podciśnienie</td>
                    <td>30 kPa</td>
                  </tr>
                </tbody>
              </table>
            </TextBlock>
          ),
        },
      ],
    } as ProductContentDto,
  },
  {
    tile: {
      title: "EHA MON1",
      subtitle: "Producent: Ekohigiena Aparatura",
      price: "",
      img: EHAMON1,
      to: RoutePath.PRODUCT,
    } as ProductTileDto,
    url: "eha-mon1",
    content: {
      images: [EkohigienaLogo],
      body: (
        <TextBlock>
          <TitleBlock title="Panel odczytowy EHA MON1" smallPaddingTop />
          <hr />
          <p>
            Panel odczytowy EHA MON1 umożliwia podłączenia szeregu czujników do pomiaru parametrów powietrza. Obecnie dostępne są następujące sondy pomiarowe
            <br />
            <br />
          </p>

          <li>
            Sonda do pomiaru stężenia CO<sub>2</sub>
          </li>
          <li>Sonda do pomiaru pyłu zawieszonego</li>
          <li>Sonda do pomiaru T/RH, Pa</li>
          <li>Sonda do pomiaru prędkości powietrza</li>
          <li>Sonda do pomiaru przepływu powietrza (0.8-4 l/min, 3-15 l/min, 10-50 l/min, 400-600 l/min)</li>
          <br />
          <br />
        </TextBlock>
      ),
      tabs: [],
    } as ProductContentDto,
  },
] as Array<ProductDto>;

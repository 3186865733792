import React from "react";
import "./text-block.scss";

interface Props {
  children: React.ReactNode,
  className?: string
  variant?: "dark" | "light"
}

const TextBlock: React.FC<Props> = (props: Props) => {
  const { children, className, variant } = props;

  return (
    <div className={`text-block${className ? " " + className : ""} ${variant ? variant : "dark"}`}>
      {children}
    </div>
  )
}

export default TextBlock;

import React from "react";
import InfoTileBlock from "../../../sections/info-tiles-section/info-tile-block";
import InfoTilesSection from "../../../sections/info-tiles-section/info-tiles-section";
import TitleBlock from "../../../components/title-block/title-block";
import InfoTilesMapper from "../../../sections/info-tiles-section/info-tiles-mapper";
import {usageData} from "../../categories-page/data/usages-data";
import {generatePath} from "react-router-dom";

interface Props {
  setRef: any;
}

const InfoTilesSectionWrapper: React.FC<Props> = (props: Props) => {
  return (
    <div ref={props.setRef}>
      <TitleBlock title="Urządzenia laboratoryjne oraz do badania środowiska pracy"
                  subtitle="Aparatura pomiarowa dla laboratoriów środowiska pracy,
                  laboratoriów ochrony środowiska oraz przemysłu farmaceutycznego"/>
      <InfoTilesSection>
        <InfoTilesMapper>
          {usageData.map((x, index) => {
            const to = x.tile.to ? generatePath(x.tile.to, {usageId: x.url}) : undefined;
            return (
              <InfoTileBlock key={index} title={x.tile.title} icon={x.tile.img} to={to}>
                {x.tile.subtitle}
              </InfoTileBlock>
            );
          })}
          {/* <InfoTileBlock title="Pobór prób powietrza na stanowiskach pracy" icon={AirQualityIcon} to={RoutePath.HOME}>
            Aspiratory osobiste, aspiraotry stacjonarne, głowice i cyklony, filtry pomiarowe, wagi analityczne.
          </InfoTileBlock>
          <InfoTileBlock title="Pobór pyłu zawieszonego">Poborniki pyłu PM10 i PM2.5.</InfoTileBlock>
          <InfoTileBlock title="Pomieszczenia czyste">Przenośnie i stacjonarne liczniki cząstek, systemy monitoringu ciągłego, kwalifikacje pomieszczeń czystych.</InfoTileBlock>
          <InfoTileBlock title="Integralność filtrów">Liczniki cząstek, sondy pomiarowe, generatory aerozolu.</InfoTileBlock>
          <InfoTileBlock title="Pomiar parametrów fizycznych">Mierniki mikroklimatu, mierniki drgań, mierniki hałasu, mierniki promieniowania elektromagnetycznego.</InfoTileBlock>
          <InfoTileBlock title="Badania masek" icon={AirQualityIcon}>
            Kompletne stanowiska do testowania masek medycznych i półmasek, pod kątem oporów przepływu, penetracji chlorkiem sodu, penetracji aerozolem mikrobiologicznym.
          </InfoTileBlock>
          <InfoTileBlock title="Wyposażenie laboratoriów">Analizatory cząstek, spektrometry, prasy hydrauliczne, pastylkarki, piece muflowe, wzorcze oznaczeń kwarcu i krystobalitu.</InfoTileBlock> */}
        </InfoTilesMapper>
      </InfoTilesSection>
    </div>
  );
};

export default InfoTilesSectionWrapper;

import React from "react";
import HeroSectionWrapper from "./sections/hero-section-wrapper";
import "./rules-page.scss";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { RoutePath } from "../../route-paths";
import RulesSectionWrapper from "./sections/rules-section-wrapper";

const RulesPage: React.FC = () => {
  return (
    <div className="rules-page">
      <BreadcrumbsItem to={RoutePath.RULES}>Regulamin</BreadcrumbsItem>
      <HeroSectionWrapper />
      <RulesSectionWrapper />
    </div>
  );
};

export default RulesPage;

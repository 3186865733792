import CookiePolicyPopupTypes from './cookiePolicyPopup.types';

const INITIAL_STATE = {
  isPopupVisible: true as boolean,
}

const cookiePolicyPopupReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case CookiePolicyPopupTypes.HIDE_COOKIEPOLICY_POPUP:
      return {
        ...state,
        isPopupVisible: false
      }
    default: {
      return state;
    }
  }
}

export default cookiePolicyPopupReducer;
import React from "react";
import { ProductContentDto, ProductDto } from "../../../../dto/productDto";
import { ProductTileDto } from "../../../../dto/productTileDto";
import { RoutePath } from "../../../../route-paths";

import TextBlock from "../../../../components/writing-tools/text-block/text-block";
import TitleBlock from "../../../../components/title-block/title-block";
import LinkWithIconWrapper from "../../../../components/link-with-icon/link-with-icon-wrapper";
import LinkWithIcon from "../../../../components/link-with-icon/link-with-icon";
import PdfIcon from "../../../../components/pdf-icon/pdf-icon";
import TopasLogo from "./../../../../assets/products/topas-logo.svg";
import ATM228S from "./../../../../assets/products/generatory_aerozolu/P0937H01_ATM228_S_TOPAS.jpeg";
import ATM228L from "./../../../../assets/products/generatory_aerozolu/P0937H02_ATM228_L_TOPAS.jpeg";
import ATM226S from "./../../../../assets/products/generatory_aerozolu/P0452H01-ATM226-S-TOPAS.jpeg";
import ATM226R from "./../../../../assets/products/generatory_aerozolu/P0452H02-ATM226-R-TOPAS.jpeg";
import SAG410S from "./../../../../assets/products/generatory_aerozolu/P0126H1-SAG410-S-TOPAS.jpeg";
import SAG410F from "./../../../../assets/products/generatory_aerozolu/P0126H2-SAG410-F-TOPAS.jpeg";
import SAG410Z from "./../../../../assets/products/generatory_aerozolu/P0126H3-SAG410-Z-TOPAS.jpeg";
import SAG420S from "./../../../../assets/products/generatory_aerozolu/P0203H01-SAG420-S-TOPAS.jpeg";
import SAG420K from "./../../../../assets/products/generatory_aerozolu/P0203H02-SAG420-K-TOPAS.jpeg";
import SAG420F from "./../../../../assets/products/generatory_aerozolu/P0203H03-SAG420-F-TOPAS.jpeg";
import ATM228DataSheet from "./../../../../assets/products/generatory_aerozolu/topas-228-ATM-productsheet-en.pdf";
import ATM226DataSheet from "./../../../../assets/products/generatory_aerozolu/Ulotka_z_logiem_ekohigiena_-_generator_aerozolu_226.pdf";
import SAG410DataSheet from "./../../../../assets/products/generatory_aerozolu/topas-410-SAG-productsheet-en.pdf";
import RelatedProducts from "../../../../components/RelatedProducts/RelatedProducts";

export const ProductsGeneratoryAerozolu = [
  {
    tile: {
      title: "ATM 228",
      subtitle: "Producent: Topas",
      price: "",
      img: ATM228S,
      to: RoutePath.PRODUCT,
    } as ProductTileDto,
    url: "atm228",
    content: {
      images: [ATM228L, TopasLogo],
      body: (
        <TextBlock>
          <TitleBlock title="Generator aerozolu ATM228" smallPaddingTop />
          <hr />
          <h4>
            Atomizer Generator aerozolu ATM 228 do wytwarzania aerozolu z płynów do użytku mobilnego, zintegrowana sprężarka, sterowana pneumatycznie, zdalnie sterowana
            <br />
            <br />
          </h4>

          <p>
            Generator aerozolu ATM 228 jest rozwinięciem generatora aerozolu ATM 226 i służy do mobilnego wytwarzania aerozoli testowych i kalibracyjnych z czystych cieczy, roztworów i zawiesin. Generator spełnia wszystkie wymagania VDI 3491-2.
            <br />
            <br />
            Generowanie aerozolu realizowane jest przez bezszczotkowy kompresor. W związku z tym nie jest wymagane zewnętrzne zasilanie sprężonym powietrzem. W celu uzyskania powtarzalnego i stabilnego długoterminowego wytwarzania aerozolu, natężenie przepływu powietrza jest kontrolowane poprzez spadek ciśnienia w dyszy. Pozwala to również na powtarzalną i stabilną pracę generatora nawet przy bardzo małych szybkościach przepływu powietrza, a zatem również przy najniższych szybkościach wytwarzania cząstek.
            <br />
            <br />
          </p>

          <h4>Zalety</h4>
          <li>stabilne wytwarzanie aerozolu nawet przy najniższych szybkościach produkcji cząstek</li>
          <li>bezpieczna i powtarzalna regulacja punktów pracy</li>
          <li>przystosowany do pracy mobilnej (wewnętrzna sprężarka, opcjonalny tryb pracy bateryjnej)</li>
          <li>zdalne sterowanie przez interfejs szeregowy (opcja)</li>
          <br />
          <br />

          <h4>Zastosowania</h4>
          <li>wytwarzanie aerozolu z czystych cieczy, roztworów i zawiesin</li>
          <li>testowanie integralności i regeneracji filtrów HEPA/ULPA zgodnie z ISO 14644-3</li>
          <li>pomiary odbiorcze dla pomieszczeń czystych, stołów bezpieczeństwa i czujników dymu</li>
          <li>generowanie aerozoli kalibracyjnych (np. do liczników cząstek) przy niskich natężeniach przepływu powietrza</li>
          <br />
          <br />

          <h4>Zasada działania</h4>
          <p>
            Do dyspersji substancji, która ma być aerozolowana (substancja w aerozolu), ATM 228 jest wyposażony w dyszę do dwóch substancji, zaprojektowaną przez Topas GmbH. Dysza pracuje w lub nad substancją aerozolową (tryb pracy w zanurzeniu lub bez) i składa się z dwóch portów wlotowych do dostarczania powietrza i substancji aerozolowej oraz jednego portu wylotowego dla wytworzonego aerozolu pierwotnego.
            <br />
            <br />
            Dostarczenie określonej prędkości przepływu powietrza powoduje powstanie podciśnienia na otworze wlotowym dyszy dla substancji aerozolowej. Substancja aerozolowa wpływa w ten sposób do strefy dyspersji dyszy, gdzie zbiegają się strumienie substancji aerozolowej i gazu, tworząc aerozol kropelkowy.
          </p>
        </TextBlock>
      ),
      tabs: [
        {
          name: "Specifikacja",
          body: (
            <TextBlock>
              <table>
                <tbody>
                  <tr>
                    <td>Przepływ objętościowy</td>
                    <td>20 - 250 L/h</td>
                  </tr>
                  <tr>
                    <td>Przepływ masowy</td>
                    <td>0 - 1,4 g/h</td>
                  </tr>
                  <tr>
                    <td>Parametr regulowany</td>
                    <td>różnica ciśnień w dyszy (hPa)</td>
                  </tr>
                  <tr>
                    <td>Metoda regulacji</td>
                    <td>manualna, kontrolowana</td>
                  </tr>
                  <tr>
                    <td>Zasilanie</td>
                    <td>100 - 240 V AC, 12 V DC (opcjonalne zasilanie bateryjne)</td>
                  </tr>
                  <tr>
                    <td>Pobór mocy</td>
                    <td>max. 36 W</td>
                  </tr>
                  <tr>
                    <td>Medium robocze, gaz/powietrze</td>
                    <td>powietrze otoczenia (filtrowane wewnętrznie)</td>
                  </tr>
                  <tr>
                    <td>Medium robocze, substancja aerozolowa</td>
                    <td>ciecze (DEHS, PAO,...), roztwory (NaCl, KCl,...), zawiesiny (PSL,...)</td>
                  </tr>
                  <tr>
                    <td>Średnica króćca wylotowego</td>
                    <td>8mm</td>
                  </tr>
                  <tr>
                    <td>Warunki środowiskowe, maksymalne podciśnienie</td>
                    <td>20 kPa</td>
                  </tr>
                </tbody>
              </table>
            </TextBlock>
          ),
        },
        {
          name: "Do pobrania",
          body: (
            <TextBlock>
              <LinkWithIconWrapper>
                <LinkWithIcon icon={<PdfIcon />} to={ATM228DataSheet}>
                  Dane techniczne
                </LinkWithIcon>
              </LinkWithIconWrapper>
            </TextBlock>
          ),
        },
        {
          name: "Produkty Powiązane",
          body: <RelatedProducts items={["scan-air", "scan-air-pro"]} />,
        },
      ],
    } as ProductContentDto,
  },
  {
    tile: {
      title: "ATM 226",
      subtitle: "Producent: Topas",
      price: "",
      img: ATM226S,
      to: RoutePath.PRODUCT,
    } as ProductTileDto,
    url: "atm226",
    content: {
      images: [ATM226R, TopasLogo],
      body: (
        <TextBlock>
          <TitleBlock title="Generator aerozolu ATM226" smallPaddingTop />
          <hr />
          <h4>
            ATM 226 Atomizer Generator aerozolu do wytwarzania aerozolu z cieczy do użytku mobilnego (np. pomiar akceptacji w pomieszczeniu czystym), zintegrowana sprężarka
            <br />
            <br />
          </h4>

          <p>
            Generator aerozoli z atomizerem ATM 226 jest szczególnie odpowiedni do zastosowań związanych z testowaniem w pomieszczeniach czystych i filtrach zgodnie z międzynarodowymi standardami.
            <br />
            <br />
            Test działania w komorach z przepływem laminarnym (DIN EN 12469) i stołach bezpieczeństwa cytostatyki (DIN 12980), test jakościowy filtrów na zawiesinę (filtry HEPA i ULPA) oraz pomiary akceptacyjne w pomieszczeniach czystych (zgodnie z DIN ISO 14644 i VDI 2083 ) są niezbędną koniecznością dla bezpieczeństwa osobistego i bezpieczeństwa produktów.
            <br />
            <br />
            Takie pomiary i kasy wymagają odpowiedniego aerozolu testowego. Dlatego dyrektywa VDI 3491 proponuje generator aerozolu do wytwarzania aerozolu testowego o określonych właściwościach, takiego jak ATM 226. Dzięki wysokiej liczbie generowanych cząstek przy rozkładzie wielkości cząstek zorientowanym na rozmiar cząstek o największej penetracji (MPPS) filtra lokalizacja wycieków w systemie jest to możliwe szybko i bezpiecznie. Jeżeli stosowana jest oleista substancja aerozolowa (np. DOP, DEHS), generator aerozolu wytwarza kuliste cząstki głównie w zakresie wielkości 0,1 … 0,5 µm z możliwym do obliczenia czasem przebywania. To prawie nie powoduje dodatkowego zanieczyszczenia materiału filtrującego.
            <br />
            <br />
            Dalszym zakresem zastosowania generatorów aerozoli / nebulizatorów aerozoli ciekłych jest kalibracja urządzeń pomiarowych i wizualizacja prądów. Umożliwia to pomiary lokalnych prędkości i profili przepływu cząstek znacznikowych metodami optycznymi.
            <br />
            <br />
          </p>

          <h4>Zalety</h4>
          <li>kompaktowa obudowa ze stali nierdzewnej – odpowiednia do zastosowania w pomieszczeniach czystych</li>
          <li>podłączenie bezpośrednio do zasilania sieciowego</li>
          <li>aerozol polidyspersyjny, głównie &lt; 1 µm</li>
          <li>bardzo wysoka trwałość generowanego rozkładu wielkości cząstek</li>
          <li>określone i wysokie stężenie cząstek stałych</li>
          <li>łatwa obsługa, przeznaczona do użytku mobilnego (pompa wewnętrzna)</li>
          <li>podłączenie bezpośrednio do napięcia sieciowego 100-240 VAC</li>
          <br />
          <br />

          <h4>Zastosowania</h4>
          <li>testowanie materiałów filtracyjnych HEPA i ULPA</li>
          <li>pomiary odbiorcze pomieszczeń czystych i komór laminarnych</li>
          <li>generowanie cząstek znaczników przy niskich natężeniach przepływu</li>
          <br />
          <br />

          <h4>Zasada działania</h4>
          <p>Nowo opracowany atomizer jest kluczowym elementem ATM 226 (zapowiedź zarejestrowanego projektu GS 9408604.4). Wykonany jest z wysokiej jakości stali nierdzewnej i pracuje jako dwustrumieniowa dysza na zasadzie wtrysku. Unikalną cechą jest to, że wylot jest skierowany w stronę ścianki szklanego naczynia, które działa jak przegroda. Ta sekcja uderzania cząstek usuwa grubo rozpylone kropelki i powoduje rozkład wielkości cząstek głównie poniżej 1 µm. Wraz ze wzrostem natężenia przepływu poprawia się punkt odcięcia impaktora, ale w zakresie submikrometrowym, który ma tylko niewielki wpływ na rozkład wielkości cząstek aerozolu. Cicha sprężarka wytwarza sprężone powietrze potrzebne do wytworzenia aerozolu. Powietrze jest oczyszczane przez filtr HEPA przed wprowadzeniem do atomizera.</p>
        </TextBlock>
      ),
      tabs: [
        {
          name: "Specifikacja",
          body: (
            <TextBlock>
              <table>
                <tbody>
                  <tr>
                    <td>Przepływ objętościowy</td>
                    <td>70 - 300 L/h</td>
                  </tr>
                  <tr>
                    <td>Przepływ masowy</td>
                    <td>0,5 - 2,5 g/h</td>
                  </tr>
                  <tr>
                    <td>Parametr regulowany</td>
                    <td>natężenie przepływu</td>
                  </tr>
                  <tr>
                    <td>Metoda regulacji</td>
                    <td>manualna</td>
                  </tr>
                  <tr>
                    <td>Zakres regulacji</td>
                    <td>5 - 40(oznaczenie skali na rotametrze)</td>
                  </tr>
                  <tr>
                    <td>Zasilanie</td>
                    <td>100 - 240 V AC</td>
                  </tr>
                  <tr>
                    <td>Pobór mocy</td>
                    <td>max. 36 W</td>
                  </tr>
                  <tr>
                    <td>Medium robocze, gaz/powietrze</td>
                    <td>powietrze otoczenia (filtrowane wewnętrznie)</td>
                  </tr>
                  <tr>
                    <td>Medium robocze, substancja aerozolowa</td>
                    <td>ciecze (DEHS, PAO,...), roztwory (NaCl, KCl,...), zawiesiny (PSL,...)</td>
                  </tr>
                  <tr>
                    <td>Medium robocze, substancja aerozolowa - objętość napełnienia</td>
                    <td>20 - 80 mL</td>
                  </tr>
                  <tr>
                    <td>Średnica króćca wylotowego</td>
                    <td>8mm</td>
                  </tr>
                  <tr>
                    <td>Waga</td>
                    <td>4,8 kg</td>
                  </tr>
                  <tr>
                    <td>Wymiary</td>
                    <td>120 × 195 × 300 mm</td>
                  </tr>
                  <tr>
                    <td>Warunki środowiskowe, maksymalne podciśnienie</td>
                    <td>20 kPa</td>
                  </tr>
                </tbody>
              </table>
            </TextBlock>
          ),
        },
        {
          name: "Do pobrania",
          body: (
            <TextBlock>
              <LinkWithIconWrapper>
                <LinkWithIcon icon={<PdfIcon />} to={ATM226DataSheet}>
                  Dane techniczne
                </LinkWithIcon>
              </LinkWithIconWrapper>
            </TextBlock>
          ),
        },
        {
          name: "Produkty Powiązane",
          body: <RelatedProducts items={["scan-air", "scan-air-pro"]} />,
        },
      ],
    } as ProductContentDto,
  },
  {
    tile: {
      title: "SAG 410",
      subtitle: "Producent: Topas",
      price: "",
      img: SAG410S,
      to: RoutePath.PRODUCT,
    } as ProductTileDto,
    url: "sag410",
    content: {
      images: [SAG410F, SAG410Z, TopasLogo],
      body: (
        <TextBlock>
          <TitleBlock title="Generator aerozoli stałych SAG 410" smallPaddingTop />
          <hr />
          <h4>Generator aerozoli stałych SAG 410 do wytwarzania aerozolu z proszków, dozowania z paskiem zębatym, umiarkowanych przepływów masowych</h4>

          <p>
            Aerozole z cząstkami stałymi są wykorzystywane do badań, rozwoju i zarządzania jakością w wielu dziedzinach. Są wytwarzane przez generatory pyłu. W przypadku stosowania substancji suchych i łatwo płynących, odpowiednie są odpylacze serii SAG 410.
            <br />
            <br />
            Transportują materiał stały za pomocą taśmy dozującej (VDI 3491-3). Zapewnia to ciągłe, stałe i dokładne dozowanie. Generatory pyłu firmy Topas GmbH składają się z jednostki sterującej i jednostki dyspergującej.
            <br />
            <br />
            Modułowa konstrukcja umożliwia użytkownikowi realizację szerokiego zakresu przepływów masowych proszku. Precyzyjna regulacja przepływu masy proszku odbywa się poprzez wygodną bezstopniową regulację prędkości taśmy. Na koniec proszek jest transportowany przez odsysanie z taśmy dozującej i dyspergowany za pomocą dwuskładnikowej dyszy (zaprojektowanej zgodnie z ISO 5011).
            <br />
            <br />
            W przypadku stosowania do wytwarzania aerozoli proszków o słabej płynności, takich jak Pural NF, odpowiednie są generatory pyłu SAG 410/U lub SAG 410/P. Transport materiału stałego odbywa się za pomocą ślimaka podającego i pierścienia dozującego. Jeżeli używany materiał stały również ma tendencję do przylegania do powierzchni, zastosowanie specjalnej dyszy (dysza P) zapewnia odsysanie materiału stałego z pierścienia dozującego.
            <br />
            <br />
          </p>

          <h4>Zalety</h4>
          <li>stałe dozowanie proszku w czasie dzięki regulacji prędkości</li>
          <li>modułowa koncepcja łączenia jednostki sterującej z różnymi jednostkami dozującymi i dyspergującymi</li>
          <li>stała i powtarzalna prędkość posuwu w szerokim zakresie – odpowiednia do wielu zastosowań</li>
          <li>bardzo dokładne, niezależne od użytkownika dozowanie proszku</li>
          <li>proste wymienne jednostki dyspergujące pozwalają jednemu instrumentowi mieć ogromny zakres masy wyjściowej</li>
          <li>łatwy w czyszczeniu i wymianie materiału</li>
          <li>dostępne są zdalne jednostki sterujące do sterowania ręcznego lub komputerowego</li>
          <li>wysokie stężenie nawet przy małych natężeniach przepływu – przyspiesza testowanie w aplikacjach o niskim przepływie</li>
          <li>uzupełniane podczas pracy bez wpływu na stężenie aerozolu – idealne do długotrwałych badań</li>
          <li>wygodna jednostka sterująca</li>
          <li>elastyczne użytkowanie i prosta obsługa</li>
          <br />
          <br />

          <h4>Zastosowania</h4>
          <li>pomiar skuteczności filtrów mediów filtracyjnych i innych separatorów</li>
          <li>badania aerozoli</li>
          <li>optymalizacja procesów spalania</li>
          <li>procesy mieszania i powlekania (np. w technologii ceramiki)</li>
          <li>badania inhalacyjne i toksykologiczne</li>
          <li>wizualizacja przepływu, aplikacje LDV i PIV</li>
          <br />
          <br />

          <h4>Zasada działania</h4>
          <p>
            Modele generatorów SAG 410, SAG 410/L i SAG 410/H, SAG 410/M, SAG 410/V wykorzystują tę samą zasadę podawania proszku za pomocą specjalnego pasa podającego. Zdefiniowane segmenty gwarantują stałe i powtarzalne dostarczanie proszku nawet w małych ilościach. Uzyskane stężenie liczbowe cząstek aerozolu wyjściowego można łatwo regulować, ustawiając prędkość taśmy podającej w szerokim zakresie.
            <br />
            <br />
            Napełnianie małych segmentów realizowane jest przez skrobak. Jego konstrukcja umożliwia stałe dozowanie proszku, które jest prawie niezależne od poziomu napełnienia zbiornika proszku. Zbiornik można uzupełniać podczas pracy bez wpływu na stężenie aerozolu.
            <br />
            <br />
            Jednostka dyspergująca składa się z dwustrumieniowej dyszy eżektorowej (podobnej do normy DIN ISO 5011) i połączenia rurowego z obudową do zasilania sprężonym powietrzem. Siły ścinające wytworzone w tym wyrzutniku rozpraszają i dezaglomerują proszek, tworząc aerozol.
            <br />
            <br />
          </p>
        </TextBlock>
      ),
      tabs: [
        {
          name: "Specifikacja",
          body: (
            <TextBlock>
              <table>
                <tbody>
                  <tr>
                    <td>Regulowany parametr 1</td>
                    <td>ciśnienie dyspersji</td>
                  </tr>
                  <tr>
                    <td>Zakres parametru 1</td>
                    <td>1 - 6 bar</td>
                  </tr>
                  <tr>
                    <td>Rozdzielczość regulacji parametru 1</td>
                    <td>płynna regulacja</td>
                  </tr>
                  <tr>
                    <td>Regulowany parametr 2</td>
                    <td>względna prędkość taśmy</td>
                  </tr>
                  <tr>
                    <td>Zakres parametru 2</td>
                    <td>0 - 100 %</td>
                  </tr>
                  <tr>
                    <td>Rozdzielczość regulacji parametru 2</td>
                    <td>0,1 %</td>
                  </tr>
                  <tr>
                    <td>Regulowany parametr 3</td>
                    <td>względna częstotliwość mieszania</td>
                  </tr>
                  <tr>
                    <td>Zakres parametru 3</td>
                    <td>0 - 100 %</td>
                  </tr>
                  <tr>
                    <td>Rozdzielczość regulacji parametru 3</td>
                    <td>płynna regulacja</td>
                  </tr>
                  <tr>
                    <td>Przepływ objętościowy</td>
                    <td>1500 - 4000 L/h</td>
                  </tr>
                  <tr>
                    <td>Przepływ masowy</td>
                    <td>5 - 500 g/h</td>
                  </tr>
                  <tr>
                    <td>Medium robocze, substancja aerozolowa - objętość napełnienia</td>
                    <td>20 - 80 mL</td>
                  </tr>
                  <tr>
                    <td>Zasilanie</td>
                    <td>100 - 260 V AC, 47 - 63 Hz</td>
                  </tr>
                  <tr>
                    <td>Medium robocze, gaz/powietrze - zasilanie sprężonym powietrzem</td>
                    <td>1 - 6 bar</td>
                  </tr>
                  <tr>
                    <td>Medium robocze, substancja aerozolowa</td>
                    <td>proszek, mieszanki proszkowe (&lt; 200 µm)</td>
                  </tr>
                  <tr>
                    <td>Medium robocze, gaz/powietrze</td>
                    <td>suche, pozbawione cząstek sprężone powietrze lub techniczny gaz obojętny</td>
                  </tr>
                  <tr>
                    <td>Waga</td>
                    <td>10,4 kg</td>
                  </tr>
                  <tr>
                    <td>Wymiary</td>
                    <td>330 × 260 × 360 mm</td>
                  </tr>
                </tbody>
              </table>
            </TextBlock>
          ),
        },
        {
          name: "Do pobrania",
          body: (
            <TextBlock>
              <LinkWithIconWrapper>
                <LinkWithIcon icon={<PdfIcon />} to={SAG410DataSheet}>
                  Dane techniczne
                </LinkWithIcon>
              </LinkWithIconWrapper>
            </TextBlock>
          ),
        },
      ],
    } as ProductContentDto,
  },
  {
    tile: {
      title: "SAG 420",
      subtitle: "Producent: Topas",
      price: "",
      img: SAG420S,
      to: RoutePath.PRODUCT,
    } as ProductTileDto,
    url: "sag420",
    content: {
      images: [SAG420K, SAG420F, TopasLogo],
      body: (
        <TextBlock>
          <TitleBlock title="Generator aerozoli stałych SAG 420" smallPaddingTop />
          <hr />
          <h4>Generator aerozoli stałych SAG 420 do wytwarzania aerozolu z proszków, dozowania taśmowego, dużych przepływów masowych, najlepszy do testowania filtrów wlotowych silnika wg. ISO 5011</h4>

          <p>
            Dust Feeder / Dust Disperser SAG 420 to urządzenie do wytwarzania aerozoli stałych poprzez przekształcenie sproszkowanego ciała stałego w stan unoszący się w powietrzu i ma bardzo duży zakres dozowania.
            <br />
            <br />
            W zastosowaniach, w których wymagane są cząstki unoszące się w powietrzu, takich jak testowanie skuteczności separacji materiałów filtracyjnych, należy stosować aerozol o określonych, dobrze określonych cechach charakterystycznych i stabilności w okresie czasu. Jeśli aerozol jest wytwarzany z określonego proszku, charakterystyczne cechy generowanego pyłu będą zdeterminowane przez proszek. Dodatkowe cechy charakterystyczne aerozolu uzyskuje się poprzez przeniesienie proszku do stanu unoszenia się w powietrzu. Proces jest podzielony na dwie części: ciągłe dozowanie strumienia masy proszku i dyspergowanie (rozrzedzanie) proszku, najlepiej aż do pierwotnej wielkości cząstek materiału.
            <br />
            <br />
          </p>

          <h4>Zalety</h4>
          <li>ciągłość dozowania różnych pyłów przez długi okres czasu</li>
          <li>łatwy do ponownego napełnienia w warunkach eksploatacyjnych</li>
          <li>kontrola przepływu masowego poprzez opcję wagową</li>
          <br />
          <br />

          <h4>Zastosowania</h4>
          <li>pomiar skuteczności filtrów mediów filtracyjnych i innych separatorów</li>
          <li>badania aerozoli</li>
          <li>optymalizacja procesów spalania</li>
          <li>procesy mieszania i powlekania (np. w technologii ceramiki)</li>
          <li>badania inhalacyjne i toksykologiczne</li>
          <li>wizualizacja przepływu, aplikacje LDV i PIV</li>
          <br />
          <br />

          <h4>Zasada działania</h4>
          <p>Podajnik pyłu SAG 420 łączy dwa procesy dozowania i dyspergowania w jednym urządzeniu i zapewnia wytwarzanie dobrze zdefiniowanego, stabilnego aerozolu przez określony czas. Zasada działania opiera się na regulowanym i zdefiniowanym dodawaniu drobnych cząstek stałych ze zbiornika magazynującego przez taśmę dozującą do dyszy dyspersyjnej, analogicznie do dyrektywy VDI 3491-3. W dyszy drobną dyspersję uzyskuje się za pomocą sprężonego gazu, korzystnie powietrza. Stały materiał jest transportowany za pomocą ślimaka podającego, a następnie jest wychwytywany w leju zasypowym, który prowadzi do dyszy dyspersyjnej. Ten ostatni działa na zasadzie wtryskiwacza. Propelent (sprężone powietrze) powoduje określony przepływ objętościowy, a zawarty w nim materiał stały będzie rozpraszany przez siły ścinające turbulentnego strumienia gazu. Wytworzony aerozol jest następnie dostępny na wylocie dyszy.</p>
        </TextBlock>
      ),
      tabs: [
        {
          name: "Specifikacja",
          body: (
            <TextBlock>
              <table>
                <tbody>
                  <tr>
                    <td>Regulowany parametr 1</td>
                    <td>ciśnienie dyspersji</td>
                  </tr>
                  <tr>
                    <td>Zakres parametru 1</td>
                    <td>1 - 6 bar</td>
                  </tr>
                  <tr>
                    <td>Rozdzielczość regulacji parametru 1</td>
                    <td>płynna regulacja</td>
                  </tr>
                  <tr>
                    <td>Regulowany parametr 2</td>
                    <td>względna prędkość taśmy</td>
                  </tr>
                  <tr>
                    <td>Zakres parametru 2</td>
                    <td>0 - 100 %</td>
                  </tr>
                  <tr>
                    <td>Rozdzielczość regulacji parametru 2</td>
                    <td>0,1 %</td>
                  </tr>
                  <tr>
                    <td>Regulowany parametr 3</td>
                    <td>względna częstotliwość mieszania</td>
                  </tr>
                  <tr>
                    <td>Zakres parametru 3</td>
                    <td>0 - 100 %</td>
                  </tr>
                  <tr>
                    <td>Rozdzielczość regulacji parametru 3</td>
                    <td>płynna regulacja</td>
                  </tr>
                  <tr>
                    <td>Przepływ objętościowy</td>
                    <td>1500 - 4000 L/h</td>
                  </tr>
                  <tr>
                    <td>Przepływ masowy</td>
                    <td>5 - 500 g/h</td>
                  </tr>
                  <tr>
                    <td>Medium robocze, substancja aerozolowa - objętość napełnienia</td>
                    <td>20 - 80 mL</td>
                  </tr>
                  <tr>
                    <td>Zasilanie</td>
                    <td>100 - 260 V AC, 47 - 63 Hz</td>
                  </tr>
                  <tr>
                    <td>Medium robocze, gaz/powietrze - zasilanie sprężonym powietrzem</td>
                    <td>1 - 6 bar</td>
                  </tr>
                  <tr>
                    <td>Medium robocze, substancja aerozolowa</td>
                    <td>proszek, mieszanki proszkowe (&lt; 200 µm)</td>
                  </tr>
                  <tr>
                    <td>Medium robocze, gaz/powietrze</td>
                    <td>suche, pozbawione cząstek sprężone powietrze lub techniczny gaz obojętny</td>
                  </tr>
                  <tr>
                    <td>Waga</td>
                    <td>10,4 kg</td>
                  </tr>
                  <tr>
                    <td>Wymiary</td>
                    <td>330 × 260 × 360 mm</td>
                  </tr>
                </tbody>
              </table>
            </TextBlock>
          ),
        },
        {
          name: "Do pobrania",
          body: (
            <TextBlock>
              <LinkWithIconWrapper>
                <LinkWithIcon icon={<PdfIcon />} to={SAG410DataSheet}>
                  Dane techniczne
                </LinkWithIcon>
              </LinkWithIconWrapper>
            </TextBlock>
          ),
        },
      ],
    } as ProductContentDto,
  },
] as Array<ProductDto>;

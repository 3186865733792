import React, { useRef } from "react";
import Col from "../../../../components/grid/col";
import Row from "../../../../components/grid/row";
import PostBlock from "../../../../components/writing-tools/post-block/post-block";
import TextBlock from "../../../../components/writing-tools/text-block/text-block";
import Container from "../../../../components/container/container";
import Button from "../../../../components/button/button";
import Grid from "../../../../components/grid/grid";
import TitleBlock from "../../../../components/title-block/title-block";
import HeroSection from "../../../../sections/hero-section/hero-section";
import ExposureImg from "./../../../../assets/exposure.png";
import TopLink from "../../../../components/top-link/top-link";
import { RoutePath } from "../../../../route-paths";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";

const SzkoleniaPv: React.FC = () => {
  const heroTarget = useRef<any>();

  const handleScrollToSection = (section: React.MutableRefObject<any>) => {
    window.scrollTo(0, section.current!.getBoundingClientRect().top + window.pageYOffset);
  };

  return (
    <>
      <BreadcrumbsItem to={RoutePath.SERVICES__SZKOLENIA}>Szkolenia</BreadcrumbsItem>
      <HeroSection img={ExposureImg} alt="Exposure img">
        <h1>Szkolenia</h1>
        <p>Szkolenia z obsługi urządzeń laboratoryjnych i pomiarów oraz wsparcie merytoryczne</p>
        <Button onClick={() => handleScrollToSection(heroTarget)}>Dowiedz się wiecej</Button>
      </HeroSection>
      <section className="training-section" ref={heroTarget}>
        <TitleBlock title="Szkolenia dla zespołu" subtitle="Oferujemy szkolenia grupowe i indywidualne z obsługi urządzeń pomiarowych czy samych pomiarów." smallPaddingTop />
        <Container className="mt-xl-5 mt-4">
          <Grid>
            <Row gap={16}>
              <Col size={[8, 8, 10, 12]} offset={[2, 2, 1, 0]}>
                <PostBlock className="no-padding-top" size={24}>
                  <TextBlock>
                    <h2>Szkolenia dla zespołu</h2>
                    <p>Oferujemy szkolenia grupowe i indywidualne z obsługi urządzeń pomiarowych czy samych pomiarów.</p>
                  </TextBlock>
                </PostBlock>
                <PostBlock size={24}>
                  <TextBlock>
                    <h4>Wsparcie merytoryczne</h4>
                    <p>Przeszkolimy Państwa zespół z obsługi zakupionych u nas urządzeń laboratoryjnych i pomiarów. Zapewniamy również telefoniczne i mailowe wsparcie merytoryczne w tym zakresie.</p>
                  </TextBlock>
                </PostBlock>
                <PostBlock size={24}>
                  <TextBlock>
                    <h4>Na terenie całej Polski</h4>
                    <p>Zakup nowego sprzętu wiąże się z koniecznością poznania jego specyfiki i sposobu działania. Nasi szkoleniowcy prowadzą warsztaty na terenie całej Polski, dla grup pracowników, ale także pojedynczych osób.</p>
                  </TextBlock>
                </PostBlock>
                {/*<PostBlock size={24}>*/}
                {/*  <Row gap={16}>*/}
                {/*    <Col size={6}>*/}
                {/*      <FullImgBlock img={PvExampleImgA} alt="example img A"/>*/}
                {/*    </Col>*/}
                {/*    <Col size={6}>*/}
                {/*      <FullImgBlock img={PvExampleImgB} alt="example img B"/>*/}
                {/*    </Col>*/}
                {/*  </Row>*/}
                {/*</PostBlock>*/}
                <PostBlock size={24}>
                  <TextBlock>
                    <h4>Szkolenia z obsługi i pomiarów</h4>
                    <p>Wprowadzając do firmy nowy sprzęt, nasi klienci zazwyczaj korzystają również ze szkoleń z ich obsługi. Jedziemy wtedy do nich z zakupionym sprzętem i w docelowym miejscu instruujemy z ich prawidłowego wykorzystania. Warto wiedzieć, że:</p>
                    <ul>
                      <li>szkolenie zazwyczaj trwa około 2 godzin, a maksymalnie 1 dzień roboczy</li>
                      <li>cena szkolenia jest zależna od liczebności grupy oraz lokalizacji</li>
                      <li>po zakończeniu szkolenia możemy wystawić certyfikaty uczestnictwa</li>
                      <li>prowadzimy również szkolenia ze sprzętu, który nie został u nas zakupiony oraz z pomiarów np. kwalifikacji pomieszczeń czystych czy badania integralności filtrów</li>
                    </ul>
                    <p>Szkolenie z obsługi sprzętu i jego codziennej konserwacji pozwala na dłuższe bezusterkowe użytkowanie. Zachęcamy do kontaktu, by dowiedzieć się więcej o samych szkoleniach, ich przebiegu i korzyściach z nich płynących.</p>
                    <div className="back-link">
                      <TopLink to={RoutePath.SERVICES}>Powrót do wszystkich usług</TopLink>
                    </div>
                  </TextBlock>
                </PostBlock>
              </Col>
            </Row>
          </Grid>
        </Container>
      </section>
    </>
  );
};

export default SzkoleniaPv;

import React from "react";
import { CategoryDto } from "../../../dto/categoryDto";
import { TileDto } from "../../../dto/tileDto";
import { RoutePath } from "../../../route-paths";
import { ProductsPrzenosneLicznikiCzastek } from "./categories/category-przenosne-liczniki-czastek_products-data";
import { ProductsReczneLicznikiCzastek } from "./categories/category-reczne-liczniki-czastek_products-data";
import { ProductsLicznikiCzastekDoMonitoringuCiaglego } from "./categories/category-liczniki-czastek-do-monitoringu-ciaglego_products-data";
import { ProductsLicznikiCzastekKlotz } from "./categories/category-liczniki-czastek-klotz_products-data";
import { ProductsSondyDoSkanowania } from "./categories/category-sondy-do-skanowania";
import { ProductsGeneratoryAerozolu } from "./categories/category-generatory-aerozolu";
import { ProductsDilutery } from "./categories/category-dilutery";
import { ProductsAspiratoryOsobiste } from "./categories/category-aspiratory-osobiste";
import { ProductsAnalizatoryOptyczneZapylenia } from "./categories/category-analizatory-optyczne-zapylenia";
import { ProductsMiernikiMikroklimatu } from "./categories/category-mierniki-mikroklimatu";
import { ProductsBadanieMasekMedycznych } from "./categories/category-badanie-masek-medycznych";
import { ProductsWagiAnalityczne } from "./categories/category-wagi-analityczne";
import { ProductsAkcesoriaDoAspiratorow } from "./categories/category-akcesoria-do-aspiratorow";
import { ProductsKalibratoryPrzepływu } from "./categories/category-kalibratory-przepływu";
import { ProductsMiernikiDrgan } from "./categories/category-mierniki-drgan";
import { ProductsMiernikiPoziomuDzwieku } from "./categories/category-mierniki-poziomu-dzwieku";
import { ProductsPobornikiMikrobiologiczne } from "./categories/category-poborniki-mikrobiologiczne";
import TextBlock from "../../../components/writing-tools/text-block/text-block";

const categoriesAData = [] as Array<CategoryDto>;

const categoryBadaniePyluZawieszonego = [
  {
    tile: {
      title: "Analizatory optyczne zapylenia",
      subtitle: "",
      to: RoutePath.CATEGORY,
    } as TileDto,
    url: "analizatory-optyczne-zapylenia",
    content: <>Analizatory optyczne zapylenia</>,
    products: ProductsAnalizatoryOptyczneZapylenia,
  },
] as Array<CategoryDto>;

const categoryPoborProbPowietrza = [
  {
    tile: {
      title: "Aspiratory osobiste",
      subtitle: "",
      to: RoutePath.CATEGORY,
    } as TileDto,
    url: "aspiratory-osobiste",
    content: <>Aspiratory osobiste</>,
    products: ProductsAspiratoryOsobiste,
    // tableInfo: (
    //   <TextBlock>
    //     <h3 className="title">Tabelka lorem ipsum</h3>
    //     <table>
    //       <tbody>
    //         <tr>
    //           <td></td>
    //           <td>
    //             <b>Lorem</b>
    //           </td>
    //           <td>
    //             <b>Ipsum</b>
    //           </td>
    //           <td>
    //             <b>Amilan</b>
    //           </td>
    //           <td>
    //             <b>Bareom</b>
    //           </td>
    //         </tr>
    //         <tr>
    //           <td>
    //             <b>Cesar</b>
    //           </td>
    //           <td>12345</td>
    //           <td>23456</td>
    //           <td>12345</td>
    //           <td>23456</td>
    //         </tr>
    //         <tr>
    //           <td>
    //             <b>Apdaem</b>
    //           </td>
    //           <td>54321</td>
    //           <td>65432</td>
    //           <td>12345</td>
    //           <td>23456</td>
    //         </tr>
    //       </tbody>
    //     </table>
    //   </TextBlock>
    // ),
  },
  {
    tile: {
      title: "Kalibratory Przepływu",
      subtitle: "",
      to: RoutePath.CATEGORY,
    } as TileDto,
    url: "kalibratory-przepływu",
    content: <>Kalibratory przepływu</>,
    products: ProductsKalibratoryPrzepływu,
  },
  {
    tile: {
      title: "Akcesoria do aspiratorów",
      subtitle: "",
      to: RoutePath.CATEGORY,
    } as TileDto,
    url: "akcesoria-do-aspiratorow",
    content: <>Akcesoria do aspiratorow</>,
    products: ProductsAkcesoriaDoAspiratorow,
  },
] as Array<CategoryDto>;

const categoryPomieszczeniaCzyste = [
  {
    tile: {
      title: "Przenośne liczniki cząstek",
      subtitle: "",
      to: RoutePath.CATEGORY,
    } as TileDto,
    url: "przenosne-liczniki-czastek",
    content: <>Przenośne liczniki cząstek</>,
    products: ProductsPrzenosneLicznikiCzastek,
  },
  {
    tile: {
      title: "Ręczne liczniki cząstek",
      subtitle: "",
      to: RoutePath.CATEGORY,
    } as TileDto,
    url: "reczne-liczniki-czastek",
    content: <>Ręczne liczniki cząstek</>,
    products: ProductsReczneLicznikiCzastek,
  },
  {
    tile: {
      title: "Liczniki cząstek Klotz",
      subtitle: "",
      to: RoutePath.CATEGORY,
    } as TileDto,
    url: "liczniki-czastek-klotz",
    content: <>Liczniki cząstek klotz</>,
    products: ProductsLicznikiCzastekKlotz,
  },
  {
    tile: {
      title: "Liczniki cząstek do monitoringu ciągłego",
      subtitle: "",
      to: RoutePath.CATEGORY,
    } as TileDto,
    url: "liczniki-czastek-do-monitoringu-ciaglego",
    content: <>Liczniki cząstek do monitoringu ciągłego</>,
    products: ProductsLicznikiCzastekDoMonitoringuCiaglego,
  },
  {
    tile: {
      title: "Poborniki mikrobiologiczne",
      subtitle: "",
      to: RoutePath.CATEGORY,
    } as TileDto,
    url: "poborniki-mikrobiologiczne",
    content: <>Poborniki mikrobiologiczne</>,
    products: ProductsPobornikiMikrobiologiczne,
  },
] as Array<CategoryDto>;

const categoryIntegralnoscFiltrow = [
  {
    tile: {
      title: "Sondy do skanowania",
      subtitle: "",
      to: RoutePath.CATEGORY,
    } as TileDto,
    url: "sondy-do-skanowania",
    content: <>Sondy do skanowania</>,
    products: ProductsSondyDoSkanowania,
  },
  {
    tile: {
      title: "Generatory aerozolu",
      subtitle: "",
      to: RoutePath.CATEGORY,
    } as TileDto,
    url: "generatory-aerozolu",
    content: <>Generatory aerozolu</>,
    products: ProductsGeneratoryAerozolu,
  },
  {
    tile: {
      title: "Dilutery",
      subtitle: "",
      to: RoutePath.CATEGORY,
    } as TileDto,
    url: "dilutery",
    content: <>Dilutery</>,
    products: ProductsDilutery,
  },
] as Array<CategoryDto>;

const categoryPomiarParametrowFizycznych = [
  {
    tile: {
      title: "Mierniki mikroklimatu",
      subtitle: "",
      to: RoutePath.CATEGORY,
    } as TileDto,
    url: "mierniki-mikroklimatu",
    content: <>Mierniki mikroklimatu</>,
    products: ProductsMiernikiMikroklimatu,
  },
  {
    tile: {
      title: "Mierniki drgań",
      subtitle: "",
      to: RoutePath.CATEGORY,
    } as TileDto,
    url: "mierniki-drgan",
    content: <>Mierniki drgań</>,
    products: ProductsMiernikiDrgan,
  },
  {
    tile: {
      title: "Mierniki poziomu dźwięku",
      subtitle: "",
      to: RoutePath.CATEGORY,
    } as TileDto,
    url: "mierniki-poziomu-dzwieku",
    content: <>Mierniki poziomu dźwięku</>,
    products: ProductsMiernikiPoziomuDzwieku,
  },
] as Array<CategoryDto>;

const categoryBadanieMasek = [
  {
    tile: {
      title: "Stanowiska do badania masek medycznych",
      subtitle: "",
      to: RoutePath.CATEGORY,
    } as TileDto,
    url: "badanie-masek-medycznych",
    content: <>Stanowiska do badania masek medycznych</>,
    products: ProductsBadanieMasekMedycznych,
  },
] as Array<CategoryDto>;

const categoryWagiAnalityczne = [
  {
    tile: {
      title: "Wagi analityczne",
      subtitle: "",
      to: RoutePath.CATEGORY,
    } as TileDto,
    url: "wagi-analityczne",
    content: <>WagiAnalityczne</>,
    products: ProductsWagiAnalityczne,
  },
] as Array<CategoryDto>;

const allCategoriesData = [...categoriesAData, ...categoryBadaniePyluZawieszonego, ...categoryPoborProbPowietrza, ...categoryPomieszczeniaCzyste, ...categoryIntegralnoscFiltrow, ...categoryPomiarParametrowFizycznych, ...categoryBadanieMasek, ...categoryWagiAnalityczne];

export { categoriesAData, categoryBadaniePyluZawieszonego, categoryPoborProbPowietrza, categoryPomieszczeniaCzyste, categoryIntegralnoscFiltrow, categoryPomiarParametrowFizycznych, categoryBadanieMasek, categoryWagiAnalityczne, allCategoriesData };

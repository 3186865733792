import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./redux/store";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "core-js/features/promise";
import { Router } from "react-router-dom";
import history from "./history";
import { ThroughProvider } from "react-through";
import 'react-medium-image-zoom/dist/styles.css'

ReactDOM.render(
  <Provider store={store}>
    <ThroughProvider>
      <Router history={history}>
        <PersistGate persistor={persistor}>
          <App />
        </PersistGate>
      </Router>
    </ThroughProvider>
  </Provider>,

  document.getElementById("root")
);

serviceWorker.unregister();

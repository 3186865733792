import React from "react";
import ContactSection from "../../sections/contact-section/contact-section";
import "./blog-page.scss";
import CtaSectionWrapper from "./sections/cta-section-wrapper";
import BlogNewsSection from "./sections/blog-news-section";
import {BreadcrumbsItem} from "react-breadcrumbs-dynamic";
import {RoutePath} from "../../route-paths";
import {Route, Switch} from "react-router-dom";
import BlogSwitch from "./blog-switch";

const BlogPage: React.FC = () => {
  return (
    <div className="blog-page">
      <BreadcrumbsItem to={RoutePath.BLOG}>Blog</BreadcrumbsItem>
      {/* <HeroSectionWrapper />
      <BlogNewsSection />
      <CtaSectionWrapper />
      <ContactSection /> */}
      <Switch>
        <Route exact path={RoutePath.BLOG + "/*"} render={() => <BlogSwitch/>}/>
        <Route exact path={RoutePath.BLOG} render={() => <BlogNewsSection/>}/>
      </Switch>
      <CtaSectionWrapper/>
      <ContactSection/>
    </div>
  );
};

export default BlogPage;

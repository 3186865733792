import React from "react";
import "./grid.scss";

interface Props {
  children: React.ReactNode
}

const Grid: React.FC<Props> = (props: Props) => {
  return (
    <div className="grid">
      {props.children}
    </div>
  )
}

export default Grid;
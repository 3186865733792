import React from "react";
import TextBlock from "../../../../components/writing-tools/text-block/text-block";
import { ProductContentDto, ProductDto } from "../../../../dto/productDto";
import { ProductTileDto } from "../../../../dto/productTileDto";
import { RoutePath } from "../../../../route-paths";
import LighthouseLogo from "./../../../../assets/products/LWS LOGO - BW-transparent.png";
import Handheld2016 from "./../../../../assets/products/przenosne_liczniki_czastek/b_HandHeld-2016.jpeg";
import Handheld3016 from "./../../../../assets/products/przenosne_liczniki_czastek/b_HandHeld-3016.jpeg";
import Handheld5016 from "./../../../../assets/products/przenosne_liczniki_czastek/b_HandHeld-5016.jpeg";
import Handheld3016IAQ from "./../../../../assets/products/przenosne_liczniki_czastek/b_HandHeld-3016-IAQ.jpeg";
import TitleBlock from "../../../../components/title-block/title-block";
import HandHeldBrochure from "./../../../../assets/products/przenosne_liczniki_czastek/Handheld_Brochure_2016-3016-3016IAQ-5016_210124.pdf";
import HandHeldDatasheet from "./../../../../assets/products/przenosne_liczniki_czastek/Handheld_Datasheet_2016_3016_3016IAQ_5016.pdf";
import HandHeldManual from "./../../../../assets/products/przenosne_liczniki_czastek/248083400-1_R7_(OpMan_H2016_H3016_H5016_Gen_F)_Letter.pdf";
import HandHeldIAQManual from "./../../../../assets/products/przenosne_liczniki_czastek/248083402-1_R6_(OpMan_H3016-IAQ_Gen_E)_Letter.pdf";
import PdfIcon from "../../../../components/pdf-icon/pdf-icon";
import LinkWithIcon from "../../../../components/link-with-icon/link-with-icon";
import LinkWithIconWrapper from "../../../../components/link-with-icon/link-with-icon-wrapper";

export const ProductsReczneLicznikiCzastek = [
  {
    tile: {
      title: "HH2016",
      subtitle: "Producent: Lighthouse",
      price: "",
      img: Handheld2016,
      to: RoutePath.PRODUCT,
    } as ProductTileDto,
    url: "handheld-2016",
    content: {
      images: [LighthouseLogo],
      body: (
        <TextBlock>
          <TitleBlock title="Licznik cząstek Handheld 2016" smallPaddingTop />
          <hr />
          <p>Ergonomicznie zaprojektowane i lekkie liczniki cząstek Lighthouse Handheld 2016 charakteryzują się czułością 0,2 i są najbardziej zaawansowanymi ręcznymi licznikami cząstek na rynku. Zapewniając do 6 kanałów wielkości cząstek przy jednoczesnym zliczaniu, ręczne liczniki cząstek Lighthouse mogą wyświetlać skumulowane i różnicowe dane zliczania cząstek, a także dane temperatury/wilgotności względnej na szybkim i łatwym do odczytania kolorowym ekranie dotykowym. Podręczne liczniki cząstek Lighthouse mogą przechowywać do 3000 rekordów danych cząstek, a konfigurowalna baza danych receptur może przechowywać do 50 receptur do pobierania próbek i raportów. Wyjmowane baterie i opcjonalna ładowarka pomagają zmaksymalizować czas pracy urządzenia. Dane można łatwo pobrać za pomocą oprogramowania Lighthouse Data Transfer Software. Handheld może być używany jako mobilny monitor cząstek lub stać się częścią rozbudowanego systemu monitorowania i zarządzania obiektem.</p>
        </TextBlock>
      ),
      tabs: [
        {
          name: "Specifikacja",
          body: (
            <TextBlock>
              <table>
                <tbody>
                  <tr>
                    <td>Zakres rozmiarów cząstek</td>
                    <td>0.2 - 2.0 µm</td>
                  </tr>
                  <tr>
                    <td>Kanały pomiarowe</td>
                    <td>0.2, 0.3, 0.5, 0.7, 1.0, 2.0 µm</td>
                  </tr>
                  <tr>
                    <td>Przepływ</td>
                    <td>0.1 CFM (2.83 LPM)</td>
                  </tr>
                  <tr>
                    <td>Limit stężenia</td>
                    <td>8,000,000 cząstek/ft3 (280,000,000 cząstek/m3) przy 10% utracie zgodności dla pomieszczeń czystych klas ISO 1-8</td>
                  </tr>
                  <tr>
                    <td>Waga</td>
                    <td>2.2 lbs (1 kg)</td>
                  </tr>
                  <tr>
                    <td>Komunikacja</td>
                    <td>RS-232 przez RJ-45 do PC lub opcjonalnej drukarki</td>
                  </tr>
                  <tr>
                    <td>Pojemność pamięci</td>
                    <td>3,000 rekordów</td>
                  </tr>
                  <tr>
                    <td>Obudowa</td>
                    <td>Plastik formowany wtryskowo</td>
                  </tr>
                  <tr>
                    <td>Zasilanie</td>
                    <td>12 VDC, 1.25 A</td>
                  </tr>
                  <tr>
                    <td>Źródło światła</td>
                    <td>Dioda laserowa</td>
                  </tr>
                  <tr>
                    <td>Kalibracja</td>
                    <td>Zgodnie z ISO 21501-4</td>
                  </tr>
                  <tr>
                    <td>Zerowanie</td>
                    <td>&lt; 1 cząstki / 5 minut zgodnie z ISO 21501-4</td>
                  </tr>
                  <tr>
                    <td>Efektywność zliczania</td>
                    <td>50% dla kanału 0.2µm, 100% dla cząstek &gt; 0.3µm</td>
                  </tr>
                  <tr>
                    <td>Czujnik środowiskowy</td>
                    <td>Sonda temperatury/wilgotności względnej do montażu bezpośredniego: 0-150°F (-17,8 do 65,6°C) ±1,8°F przy 77°F, 0-100% ± 5% przy 33%</td>
                  </tr>
                  <tr>
                    <td>Wymiary</td>
                    <td>8.75“ x 5.0” x 2.5”, (22.23 x 12.70 x 6.35 cm)</td>
                  </tr>
                </tbody>
              </table>
            </TextBlock>
          ),
        },
        {
          name: "Do pobrania",
          body: (
            <TextBlock>
              <LinkWithIconWrapper>
                <LinkWithIcon icon={<PdfIcon />} to={HandHeldBrochure}>
                  Ulotka
                </LinkWithIcon>
                <LinkWithIcon icon={<PdfIcon />} to={HandHeldDatasheet}>
                  Dane techniczne
                </LinkWithIcon>
                <LinkWithIcon icon={<PdfIcon />} to={HandHeldManual}>
                  Instrukcja obsługi
                </LinkWithIcon>
              </LinkWithIconWrapper>
            </TextBlock>
          ),
        },
      ],
    } as ProductContentDto,
  },
  {
    tile: {
      title: "HH3016",
      subtitle: "Producent: Lighthouse",
      price: "",
      img: Handheld3016,
      to: RoutePath.PRODUCT,
    } as ProductTileDto,
    url: "handheld-3016",
    content: {
      images: [LighthouseLogo],
      body: (
        <TextBlock>
          <TitleBlock title="Licznik cząstek Handheld 3016" smallPaddingTop />
          <hr />
          <p>Ergonomicznie zaprojektowane i lekkie liczniki cząstek Lighthouse Handheld 3016 charakteryzują się czułością 0,3 i są najbardziej zaawansowanymi ręcznymi licznikami cząstek na rynku. Zapewniając do 6 kanałów wielkości cząstek przy jednoczesnym zliczaniu, ręczne liczniki cząstek Lighthouse mogą wyświetlać skumulowane i różnicowe dane zliczania cząstek, a także dane temperatury/wilgotności względnej na szybkim i łatwym do odczytania kolorowym ekranie dotykowym. Podręczne liczniki cząstek Lighthouse mogą przechowywać do 3000 rekordów danych cząstek, a konfigurowalna baza danych receptur może przechowywać do 50 receptur do pobierania próbek i raportów. Wyjmowane baterie i opcjonalna ładowarka pomagają zmaksymalizować czas pracy urządzenia. Dane można łatwo pobrać za pomocą oprogramowania Lighthouse Data Transfer Software. Handheld może być używany jako mobilny monitor cząstek lub stać się częścią systemu monitorowania i zarządzania dużym obiektem.</p>
        </TextBlock>
      ),
      tabs: [
        {
          name: "Specifikacja",
          body: (
            <TextBlock>
              <table>
                <tbody>
                  <tr>
                    <td>Zakres rozmiarów cząstek</td>
                    <td>0.3 - 25.0 µm</td>
                  </tr>
                  <tr>
                    <td>Kanały pomiarowe</td>
                    <td>
                      0.3, 0.5, 0.7, 1.0, 2.0, 5.0 µm
                      <br />
                      lub
                      <br />
                      0.3, 0.5, 1.0, 3.0, 5.0, 10.0 µm
                      <br />
                      lub
                      <br />
                      0.3, 0.5, 0.7, 1.0, 2.5, 5.0 µm
                      <br />
                      lub
                      <br />
                      0.3, 0.5, 1.0, 2.5, 5.0, 10.0 µm
                      <br />
                      lub
                      <br />
                      0.3, 0.5, 1.0. 5.0, 10.0, 25.0 µm
                    </td>
                  </tr>
                  <tr>
                    <td>Przepływ</td>
                    <td>0.1 CFM (2.83 LPM)</td>
                  </tr>
                  <tr>
                    <td>Limit stężenia</td>
                    <td>8,000,000 cząstek/ft3 (280,000,000 cząstek/m3) przy 10% utracie zgodności dla pomieszczeń czystych klas ISO 1-8</td>
                  </tr>
                  <tr>
                    <td>Waga</td>
                    <td>2.2 lbs (1 kg)</td>
                  </tr>
                  <tr>
                    <td>Komunikacja</td>
                    <td>RS-232 przez RJ-45 do PC lub opcjonalnej drukarki</td>
                  </tr>
                  <tr>
                    <td>Pojemność pamięci</td>
                    <td>3,000 rekordów</td>
                  </tr>
                  <tr>
                    <td>Obudowa</td>
                    <td>Plastik formowany wtryskowo</td>
                  </tr>
                  <tr>
                    <td>Zasilanie</td>
                    <td>12 VDC, 1.25 A</td>
                  </tr>
                  <tr>
                    <td>Źródło światła</td>
                    <td>Dioda laserowa</td>
                  </tr>
                  <tr>
                    <td>Kalibracja</td>
                    <td>Zgodnie z ISO 21501-4</td>
                  </tr>
                  <tr>
                    <td>Zerowanie</td>
                    <td>&lt; 1 cząstki / 5 minut zgodnie z ISO 21501-4</td>
                  </tr>
                  <tr>
                    <td>Efektywność zliczania</td>
                    <td>50% dla kanału 0.3µm, 100% dla cząstek &gt; 0.45µm</td>
                  </tr>
                  <tr>
                    <td>Czujnik środowiskowy</td>
                    <td>Sonda temperatury/wilgotności względnej do montażu bezpośredniego: 0-150°F (-17,8 do 65,6°C) ±1,8°F przy 77°F, 0-100% ± 5% przy 33%</td>
                  </tr>
                  <tr>
                    <td>Wymiary</td>
                    <td>8.75“ x 5.0” x 2.5”, (22.23 x 12.70 x 6.35 cm)</td>
                  </tr>
                </tbody>
              </table>
            </TextBlock>
          ),
        },
        {
          name: "Do pobrania",
          body: (
            <TextBlock>
              <LinkWithIconWrapper>
                <LinkWithIcon icon={<PdfIcon />} to={HandHeldBrochure}>
                  Ulotka
                </LinkWithIcon>
                <LinkWithIcon icon={<PdfIcon />} to={HandHeldDatasheet}>
                  Dane techniczne
                </LinkWithIcon>
                <LinkWithIcon icon={<PdfIcon />} to={HandHeldManual}>
                  Instrukcja obsługi
                </LinkWithIcon>
              </LinkWithIconWrapper>
            </TextBlock>
          ),
        },
      ],
    } as ProductContentDto,
  },
  {
    tile: {
      title: "HH5016",
      subtitle: "Producent: Lighthouse",
      price: "",
      img: Handheld5016,
      to: RoutePath.PRODUCT,
    } as ProductTileDto,
    url: "handheld-5016",
    content: {
      images: [LighthouseLogo],
      body: (
        <TextBlock>
          <TitleBlock title="Licznik cząstek Handheld 5016" smallPaddingTop />
          <hr />
          <p>Ergonomicznie zaprojektowane i lekkie liczniki cząstek Lighthouse Handheld 5016 charakteryzują się czułością 0,5 i są najbardziej zaawansowanymi ręcznymi licznikami cząstek na rynku. Zapewniając do 6 kanałów wielkości cząstek przy jednoczesnym zliczaniu, ręczne liczniki cząstek Lighthouse mogą wyświetlać skumulowane i różnicowe dane zliczania cząstek, a także dane temperatury/wilgotności względnej na szybkim i łatwym do odczytania kolorowym ekranie dotykowym. Podręczne liczniki cząstek Lighthouse mogą przechowywać do 3000 rekordów danych cząstek, a konfigurowalna baza danych receptur może przechowywać do 50 receptur do pobierania próbek i raportów. Wyjmowane baterie i opcjonalna ładowarka pomagają zmaksymalizować czas pracy urządzenia. Dane można łatwo pobrać za pomocą oprogramowania Lighthouse Data Transfer Software. Handheld może być używany jako mobilny monitor cząstek lub stać się częścią systemu monitorowania i zarządzania dużym obiektem.</p>
        </TextBlock>
      ),
      tabs: [
        {
          name: "Specifikacja",
          body: (
            <TextBlock>
              <table>
                <tbody>
                  <tr>
                    <td>Zakres rozmiarów cząstek</td>
                    <td>0.5 - 25.0 µm</td>
                  </tr>
                  <tr>
                    <td>Kanały pomiarowe</td>
                    <td>
                      0.5, 0.7, 1.0, 3.0, 5.0, 10.0 µm
                      <br />
                      lub
                      <br />
                      0.5, 1.0., 3.0, 5.0, 10.0, 25.0 µm
                    </td>
                  </tr>
                  <tr>
                    <td>Przepływ</td>
                    <td>0.1 CFM (2.83 LPM)</td>
                  </tr>
                  <tr>
                    <td>Limit stężenia</td>
                    <td>8,000,000 cząstek/ft3 (280,000,000 cząstek/m3) przy 10% utracie zgodności dla pomieszczeń czystych klas ISO 1-8</td>
                  </tr>
                  <tr>
                    <td>Waga</td>
                    <td>2.2 lbs (1 kg)</td>
                  </tr>
                  <tr>
                    <td>Komunikacja</td>
                    <td>RS-232 przez RJ-45 do PC lub opcjonalnej drukarki</td>
                  </tr>
                  <tr>
                    <td>Pojemność pamięci</td>
                    <td>3,000 rekordów</td>
                  </tr>
                  <tr>
                    <td>Obudowa</td>
                    <td>Plastik formowany wtryskowo</td>
                  </tr>
                  <tr>
                    <td>Zasilanie</td>
                    <td>12 VDC, 1.25 A</td>
                  </tr>
                  <tr>
                    <td>Źródło światła</td>
                    <td>Dioda laserowa</td>
                  </tr>
                  <tr>
                    <td>Kalibracja</td>
                    <td>Zgodnie z ISO 21501-4</td>
                  </tr>
                  <tr>
                    <td>Zerowanie</td>
                    <td>&lt; 1 cząstki / 5 minut zgodnie z ISO 21501-4</td>
                  </tr>
                  <tr>
                    <td>Efektywność zliczania</td>
                    <td>50% dla kanału 0.5µm, 100% dla cząstek &gt; 0.75µm</td>
                  </tr>
                  <tr>
                    <td>Czujnik środowiskowy</td>
                    <td>Sonda temperatury/wilgotności względnej do montażu bezpośredniego: 0-150°F (-17,8 do 65,6°C) ±1,8°F przy 77°F, 0-100% ± 5% przy 33%</td>
                  </tr>
                  <tr>
                    <td>Wymiary</td>
                    <td>8.75“ x 5.0” x 2.5”, (22.23 x 12.70 x 6.35 cm)</td>
                  </tr>
                </tbody>
              </table>
            </TextBlock>
          ),
        },
        {
          name: "Do pobrania",
          body: (
            <TextBlock>
              <LinkWithIconWrapper>
                <LinkWithIcon icon={<PdfIcon />} to={HandHeldBrochure}>
                  Ulotka
                </LinkWithIcon>
                <LinkWithIcon icon={<PdfIcon />} to={HandHeldDatasheet}>
                  Dane techniczne
                </LinkWithIcon>
                <LinkWithIcon icon={<PdfIcon />} to={HandHeldManual}>
                  Instrukcja obsługi
                </LinkWithIcon>
              </LinkWithIconWrapper>
            </TextBlock>
          ),
        },
      ],
    } as ProductContentDto,
  },
  {
    tile: {
      title: "HH3016-IAQ",
      subtitle: "Producent: Lighthouse",
      price: "",
      img: Handheld3016IAQ,
      to: RoutePath.PRODUCT,
    } as ProductTileDto,
    url: "handheld-3016-iaq",
    content: {
      images: [LighthouseLogo],
      body: (
        <TextBlock>
          <TitleBlock title="Licznik cząstek Handheld 3016 IAQ" smallPaddingTop />
          <hr />
          <p>Ergonomiczny i lekki ręczny ręczny licznik cząstek 3016 IAQ Lighthouse to najnowszy, najbardziej zaawansowany ręczny licznik cząstek na rynku, wyposażony w tryb koncentracji masy, który przybliża gęstość w μg/m3. Zapewniając do 6 kanałów wielkości cząstek przy jednoczesnym zliczaniu, ręczne liczniki cząstek Lighthouse mogą wyświetlać skumulowane i różnicowe dane zliczania cząstek, a także dane temperatury/wilgotności względnej na szybkim i łatwym do odczytania kolorowym ekranie dotykowym. Lighthouse Handheld 3016 IAQ może przechowywać do 3000 rekordów danych cząstek, a konfigurowalna baza danych receptur może przechowywać do 50 receptur do pobierania próbek i raportów. Ręczny 3016 IAQ dokładnie i niezawodnie monitoruje poziom cząstek stałych, nawet w „trudno dostępnych” obszarach, gdzie niebezpieczna jest obsługa oburęczna.</p>
        </TextBlock>
      ),
      tabs: [
        {
          name: "Specifikacja",
          body: (
            <TextBlock>
              <table>
                <tbody>
                  <tr>
                    <td>Zakres rozmiarów cząstek</td>
                    <td>0.3 - 10.0 µm</td>
                  </tr>
                  <tr>
                    <td>Kanały pomiarowe</td>
                    <td>0.3, 0.5, 1.0, 2.5, 5.0, 10.0 µm</td>
                  </tr>
                  <tr>
                    <td>Przepływ</td>
                    <td>0.1 CFM (2.83 LPM)</td>
                  </tr>
                  <tr>
                    <td>Limit stężenia</td>
                    <td>8,000,000 cząstek/ft3 (280,000,000 cząstek/m3) przy 10% utracie zgodności dla pomieszczeń czystych klas ISO 1-8</td>
                  </tr>
                  <tr>
                    <td>Waga</td>
                    <td>2.2 lbs (1 kg)</td>
                  </tr>
                  <tr>
                    <td>Komunikacja</td>
                    <td>RS-232 przez RJ-45 do PC lub opcjonalnej drukarki</td>
                  </tr>
                  <tr>
                    <td>Pojemność pamięci</td>
                    <td>3,000 rekordów</td>
                  </tr>
                  <tr>
                    <td>Obudowa</td>
                    <td>Plastik formowany wtryskowo</td>
                  </tr>
                  <tr>
                    <td>Zasilanie</td>
                    <td>12 VDC, 1.25 A</td>
                  </tr>
                  <tr>
                    <td>Źródło światła</td>
                    <td>Dioda laserowa</td>
                  </tr>
                  <tr>
                    <td>Kalibracja</td>
                    <td>Zgodnie z ISO 21501-4</td>
                  </tr>
                  <tr>
                    <td>Zerowanie</td>
                    <td>&lt; 1 cząstki / 5 minut zgodnie z ISO 21501-4</td>
                  </tr>
                  <tr>
                    <td>Efektywność zliczania</td>
                    <td>50% dla kanału 0.3µm, 100% dla cząstek &gt; 0.45µm</td>
                  </tr>
                  <tr>
                    <td>Czujnik środowiskowy</td>
                    <td>Sonda temperatury/wilgotności względnej do montażu bezpośredniego: 0-150°F (-17,8 do 65,6°C) ±1,8°F przy 77°F, 0-100% ± 5% przy 33%</td>
                  </tr>
                  <tr>
                    <td>Wymiary</td>
                    <td>8.75“ x 5.0” x 2.5”, (22.23 x 12.70 x 6.35 cm)</td>
                  </tr>
                </tbody>
              </table>
            </TextBlock>
          ),
        },
        {
          name: "Do pobrania",
          body: (
            <TextBlock>
              <LinkWithIconWrapper>
                <LinkWithIcon icon={<PdfIcon />} to={HandHeldBrochure}>
                  Ulotka
                </LinkWithIcon>
                <LinkWithIcon icon={<PdfIcon />} to={HandHeldDatasheet}>
                  Dane techniczne
                </LinkWithIcon>
                <LinkWithIcon icon={<PdfIcon />} to={HandHeldIAQManual}>
                  Instrukcja obsługi
                </LinkWithIcon>
              </LinkWithIconWrapper>
            </TextBlock>
          ),
        },
      ],
    } as ProductContentDto,
  },
] as Array<ProductDto>;

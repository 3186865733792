import React from "react";
import Container from "../../components/container/container";
import Grid from "../../components/grid/grid";
import Row from "../../components/grid/row";
import "./info-tiles.scss";

interface Props {
  children: React.ReactNode;
}

const InfoTilesSection: React.FC<Props> = (props: Props) => {
  const { children } = props;

  return (
    <section className="info-tiles-section">
      <Container>
        <Grid>
          <Row gap={16}>{children}</Row>
        </Grid>
      </Container>
    </section>
  );
};

export default InfoTilesSection;

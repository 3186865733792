import React from "react";
import Container from "../../components/container/container";
import Grid from "../../components/grid/grid";
import "./split-section.scss";

interface Props {
  children: React.ReactNode;
  titleBlock: React.ReactNode;
}

const SplitSection: React.FC<Props> = (props: Props) => {
  const { children, titleBlock } = props;

  return (
    <section className="split-section">
      <Container>
        {titleBlock}
        <Grid>
          {children}
        </Grid>
      </Container>
    </section>
  )
}

export default SplitSection;
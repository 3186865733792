import React, {useRef} from "react";
import Col from "../../../../components/grid/col";
import Row from "../../../../components/grid/row";
import PostBlock from "../../../../components/writing-tools/post-block/post-block";
import TextBlock from "../../../../components/writing-tools/text-block/text-block";
import Container from "../../../../components/container/container";
import Button from "../../../../components/button/button";
import Grid from "../../../../components/grid/grid";
import TitleBlock from "../../../../components/title-block/title-block";
import HeroSection from "../../../../sections/hero-section/hero-section";
import ProdImg from "./../../../../assets/bplusr.jpg";
import {RoutePath} from "../../../../route-paths";
import BoxLink from "../../../../components/box-link/box-link";
import {BreadcrumbsItem} from "react-breadcrumbs-dynamic";

const ProdukcjaPV: React.FC = () => {
  const heroTarget = useRef<any>();

  const handleScrollToSection = (section: React.MutableRefObject<any>) => {
    window.scrollTo(0, section.current!.getBoundingClientRect().top + window.pageYOffset);
  };

  return (
    <>
      <BreadcrumbsItem to={RoutePath.SERVICES__PRODUKCJA}>Produkcja</BreadcrumbsItem>
      <HeroSection img={ProdImg} alt="Production img">
        <h1>Produkcja urządzeń pomiarowych</h1>
        <p>Tworzymy własne urządzenia pomiarowe – od pomysłu, przez prototyp, po wprowadzenie na rynek – a także
          produkty na zamówienie.</p>
        <Button onClick={() => handleScrollToSection(heroTarget)}>Dowiedz się wiecej</Button>
      </HeroSection>
      <section className="services-section" ref={heroTarget}>
        <TitleBlock title="Produkcja urządzeń pomiarowych"
                    subtitle="Tworzymy własne urządzenia pomiarowe – od pomysłu, przez prototyp, po wprowadzenie
                    na rynek – a także produkty na zamówienie."
                    smallPaddingTop/>
        <Container className="mt-xl-5 mt-4">
          <Grid>
            <Row gap={16}>
              <Col size={[8, 8, 10, 12]} offset={[1, 2, 1, 0]}>
                <PostBlock className="no-padding-top" size={32}>
                  <TextBlock>
                    <h2>Produkcja aparatury pomiarowej</h2>
                    <p>Proces wytwórczy od projektu po wykonanie:</p>
                  </TextBlock>
                </PostBlock>
              </Col>
              <Col size={[8, 8, 10, 12]} offset={[2, 2, 1, 0]}>
                <PostBlock size={24}>
                  <TextBlock>
                    <h2>Potrzeba i pomysł</h2>
                    <p>Nasze produkty powstają dzięki Państwu. Często pytacie nas o konkretne rozwiązania, których na
                      rynku jeszcze nie ma. By spełnić te potrzeby, podejmujemy się wykonania autorskich urządzeń
                      pomiarowych.</p>
                  </TextBlock>
                </PostBlock>
                <PostBlock size={24}>
                  <TextBlock>
                    <h2>Konsultacje z klientami</h2>
                    <p>Staramy się, by nasze produkty w stu procentach spełniały Państwa oczekiwania. Dlatego procesowi
                      ich tworzenia, na każdym etapie, towarzyszą konsultacje z klientami. Dotyczą one nie tylko samych
                      funkcjonalności, ale też specyfiki ich użytkowania.</p>
                  </TextBlock>
                </PostBlock>
                <PostBlock size={24}>
                  <TextBlock>
                    <h2>Prototyp testowy</h2>
                    <p>Zanim wprowadzimy produkt do sprzedaży, przygotowujemy prototyp urządzenia. Zostaje on oddany w
                      ręce naszych klientów do testów. To oni, jako przyszli użytkownicy, są w stanie najdokładniej
                      sprawdzić produkt i pomóc nam go udoskonalić.</p>
                  </TextBlock>
                </PostBlock>
                <PostBlock size={24}>
                  <TextBlock>
                    <h2>Wprowadzenie na rynek</h2>
                    <p>W ostatniej fazie przetestowany sprzęt wprowadzamy do oferty. Tutaj jednak nasza rola się nie
                      kończy – zapewniamy również wsparcie w jego obsłudze, serwis gwarancyjny i pogwarancyjny oraz
                      usługę kalibracji.</p>
                  </TextBlock>
                </PostBlock>
                <PostBlock size={24}>
                  <TextBlock>
                    <h4>Sprawdź przykładowe realizacje</h4>
                  </TextBlock>
                  <BoxLink to={RoutePath.SERVICES__PRODUKCJA__MIERNIKI_MIKROKLIMATU}>Mierniki mikroklimatu</BoxLink>
                  <BoxLink to={RoutePath.SERVICES__PRODUKCJA__ODDYCHALNOSC}>Stanowisko testowania masek</BoxLink>
                </PostBlock>
                {/*<PostBlock size={24}>*/}
                {/*  <TextBlock>*/}
                {/*    <h4>Lo onvallis maximus rem ipsum</h4>*/}
                {/*    <p>Lorem ipsum dolor sit amet, consectetur arttitor odio nec oueto lutpadipiscing elit. Vivamus matti sconvallis maximus. Sed aliquet ueris ligula. Maecenas porttitor odio nec oueto lutpatrci lacinia conva tristique:</p>*/}
                {/*    <ul>*/}
                {/*      <li>Lorem ipsum list</li>*/}
                {/*      <li>Vivamus matti sconvallis maximus</li>*/}
                {/*      <li>Aecenas portt itorttitor odir odio</li>*/}
                {/*      <li>Onsectetur arttitor odio nec oueto lutpad</li>*/}
                {/*      <li>Aatti sconvallis maximu</li>*/}
                {/*    </ul>*/}
                {/*    <p>Lorem ipsum dolor sit amet, consectetur arttitor odio nec oueto lutpadipiscing elit. Vivamus matti sconvallis maximus. Sed aliquet ueris ligula. Maecenas porttitor odio nec oueto lutpatrci lacinia conva tristique:</p>*/}
                {/*    <div className="back-link">*/}
                {/*      <TopLink to={RoutePath.SERVICES}>Powrót do wszystkich usług</TopLink>*/}
                {/*    </div>*/}
                {/*  </TextBlock>*/}
                {/*</PostBlock>*/}
              </Col>
            </Row>
          </Grid>
        </Container>
      </section>
    </>
  );
};

export default ProdukcjaPV;
